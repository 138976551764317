<cui-section-headline class="print-hide">{{ "estadisticas.dg.title" | t }}</cui-section-headline>
<cui-info-card class="print-page-break-after">
    <cui-info-card-title class="print-hide">
        {{ "estadisticas.dg.instrumento" | t }} #{{ instrumento.id }}
    </cui-info-card-title>
    <ogr-icon cui-info-card-icon name="prueba" class="print-hide"></ogr-icon>
    <cui-info-card-left-content class="print-hide">
        <cui-info-card-data-pair>
            <label cui-info-card-data-pair-label>{{ "estadisticas.dg.tipo" | t }}</label>
            <p cui-info-card-data-pair-value>{{ instrumento.generador_instrumento.generador_instrumento }}</p>
        </cui-info-card-data-pair>
        <cui-info-card-data-pair>
            <label cui-info-card-data-pair-label>
                <fa [name]="'clock-o'" class="clock label-icon"></fa>
                {{ "estadisticas.dg.tiempo" | t }}
            </label>
            <p cui-info-card-data-pair-value>{{ instrumento.tiempo }} minutos</p>
        </cui-info-card-data-pair>
        <cui-info-card-data-pair>
            <label cui-info-card-data-pair-label>
                <fa [name]="'pencil-square-o'" class="pregunta label-icon"></fa>
                {{ "estadisticas.dg.n_preguntas" | t }}
            </label>
            <p cui-info-card-data-pair-value>{{ instrumento.numero_preguntas }}</p>
        </cui-info-card-data-pair>

        <ng-container *ngIf="enablePreguntasPiloto">
            <cui-info-card-data-pair>
                <label cui-info-card-data-pair-label>
                    <fa [name]="'pencil-square-o'" class="pregunta"></fa>
                    {{ "estadisticas.dg.piloto" | t }} *
                </label>
                <p cui-info-card-data-pair-value>
                    <ng-container *ngFor="let inst of preguntasPilotos; let i = index">
                        <span class="pilot">
                            {{ inst.orden + 1 }}
                            <span *ngIf="i + 1 == preguntasPilotos.length; else showOther">.</span>
                            <ng-template #showOther>,&nbsp;</ng-template>
                        </span>
                    </ng-container>
                    <ng-container *ngIf="preguntasPilotos.length == 0">
                        <span class="pilot">-</span>
                    </ng-container>
                </p>
            </cui-info-card-data-pair>
            <div>* {{ "estadisticas.dg.piloto_exp" | t }}</div>
        </ng-container>
    </cui-info-card-left-content>
    <cui-info-card-right-content class="print-full-width" *ngIf="enableShowCategorias">
        <div cui-info-card-area>
            <loading-layout #loadingLayout>
                <ng-template [ngIf]="groupedClasificaciones">
                    <h3 class="print-only">{{ "estadisticas.dg.comp" | t }}</h3>
                    <table class="table">
                        <tbody>
                            <ng-container *ngFor="let i of firstColumnIndexes">
                                <tr *ngIf="groupedClasificaciones[groups[i][0]]" class="row">
                                    <th>
                                        {{ meaningObj[groups[i][0]] }}
                                    </th>
                                    <td>
                                        <ul *ngIf="groups[i].length == 1">
                                            <li *ngFor="let c of groupedClasificaciones[groups[i][0]]">
                                                <span class="pull-left">
                                                    {{ c.clasificacion }}
                                                </span>
                                                <span class="pull-right numero-preguntas">
                                                    {{ c.cantidad_preguntas }} {{ "estadisticas.dg.preg_abbr" | t }}
                                                </span>
                                            </li>
                                        </ul>
                                        <div *ngIf="groups[i].length > 1">
                                            <div *ngFor="let c of groupedClasificaciones[groups[i][0]]; let j = index">
                                                <div
                                                    class="toggle-expandable clearfix"
                                                    toggleExpandable="cla_hija_{{ i }}_{{ j }}"
                                                >
                                                    <div class="pull-left">
                                                        {{ c.clasificacion }}
                                                    </div>

                                                    <span class="pull-right tog-icon minus">
                                                        <fa [name]="'minus-square'"></fa>
                                                    </span>
                                                    <span class="pull-right tog-icon plus">
                                                        <fa [name]="'plus-square'"></fa>
                                                    </span>
                                                    <div class="pull-right numero-preguntas-exp">
                                                        {{ c.cantidad_preguntas }} {{ "estadisticas.dg.preg_abbr" | t }}
                                                    </div>
                                                </div>
                                                <ul
                                                    class="expandable-content print-force-expand"
                                                    *ngIf="c.clasificaciones_hijas"
                                                    expandableContent
                                                    id="cla_hija_{{ i }}_{{ j }}"
                                                >
                                                    <li *ngFor="let ch of c.clasificaciones_hijas">
                                                        <span class="pull-left">
                                                            {{ ch.clasificacion }}
                                                        </span>
                                                        <span class="pull-right numero-preguntas">
                                                            {{ ch.cantidad_preguntas }}
                                                            {{ "estadisticas.dg.preg_abbr" | t }}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngFor="let i of secondColumnIndexes">
                                <tr class="row" *ngIf="groupedClasificaciones[groups[i][0]]">
                                    <th>
                                        {{ meaningObj[groups[i][0]] }}
                                    </th>
                                    <td>
                                        <ul *ngIf="groups[i].length == 1">
                                            <ng-container *ngFor="let c of groupedClasificaciones[groups[i][0]]">
                                                <li *ngIf="c.cantidad_preguntas > 0">
                                                    <span class="pull-left">
                                                        {{ c.clasificacion }}
                                                    </span>
                                                    <span class="pull-right numero-preguntas">
                                                        {{ c.cantidad_preguntas }} {{ "estadisticas.dg.preg_abbr" | t }}
                                                    </span>
                                                </li>
                                            </ng-container>
                                        </ul>
                                        <div *ngIf="groups[i].length > 1">
                                            <div *ngFor="let c of groupedClasificaciones[groups[i][0]]; let j = index">
                                                <div
                                                    class="toggle-expandable clearfix"
                                                    toggleExpandable="cla_hija_{{ j }}"
                                                >
                                                    <div class="pull-left">
                                                        {{ c.clasificacion }}
                                                    </div>

                                                    <span class="pull-right tog-icon minus">
                                                        <fa [name]="'minus-square'"></fa>
                                                    </span>
                                                    <span class="pull-right tog-icon plus">
                                                        <fa [name]="'plus-square'"></fa>
                                                    </span>
                                                    <div class="pull-right numero-preguntas-exp">
                                                        {{ c.cantidad_preguntas }} {{ "estadisticas.dg.preg_abbr" | t }}
                                                    </div>
                                                </div>
                                                <ul
                                                    class="expandable-content print-force-expand"
                                                    *ngIf="c.clasificaciones_hijas"
                                                    expandableContent
                                                    id="cla_hija_{{ j }}"
                                                >
                                                    <li *ngFor="let ch of c.clasificaciones_hijas">
                                                        <span class="pull-left">
                                                            {{ ch.clasificacion }}
                                                        </span>
                                                        <span class="pull-right numero-preguntas">
                                                            {{ ch.cantidad_preguntas }}
                                                            {{ "estadisticas.dg.preg_abbr" | t }}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </ng-template>
            </loading-layout>
        </div>
    </cui-info-card-right-content>
</cui-info-card>
