import { NgModule, ModuleWithProviders } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule } from "@angular/forms"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { UtilModule } from "@puntaje/shared/core"

import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { EstablecimientosModule } from "@puntaje/puntaje/new-modules/establecimientos"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { EstablecimientosAdministrarComponent } from "./establecimientos_administrar.component"
import { EstablecimientosComponent } from "./establecimientos.component"
import { EstablecimientoComponent } from "./establecimiento.component"
import { EstablecimientosEditComponent } from "./establecimientos_edit.component"
import { EstablecimientosNewComponent } from "./establecimientos_new.component"

import { EstablecimientoContactosComponent } from "./establecimiento_contactos.component"
import { EstablecimientoContactosEditComponent } from "./establecimiento_contactos_edit.component"
import { EstablecimientoContactosNewComponent } from "./establecimiento_contactos_new.component"
import { EstablecimientoContactoComponent } from "./establecimiento_contacto.component"

import { EstablecimientoReportesComponent } from "./establecimiento_reportes.component"
import { EstablecimientoReportesEditComponent } from "./establecimiento_reportes_edit.component"
import { EstablecimientoReportesNewComponent } from "./establecimiento_reportes_new.component"
import { EstablecimientoReporteComponent } from "./establecimiento_reporte.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CommonsLayoutsModule,
        EstablecimientosModule,
        Angular2FontawesomeModule,
        UtilModule,
        CUILayoutsModule
    ],
    declarations: [
        EstablecimientosAdministrarComponent,
        EstablecimientosComponent,
        EstablecimientoComponent,
        EstablecimientosEditComponent,
        EstablecimientosNewComponent,
        EstablecimientoContactosComponent,
        EstablecimientoContactosNewComponent,
        EstablecimientoContactosEditComponent,
        EstablecimientoContactoComponent,
        EstablecimientoReportesComponent,
        EstablecimientoReportesNewComponent,
        EstablecimientoReportesEditComponent,
        EstablecimientoReporteComponent
    ],
    exports: [
        EstablecimientosAdministrarComponent,
        EstablecimientosComponent,
        EstablecimientoComponent,
        EstablecimientosEditComponent,
        EstablecimientosNewComponent,
        EstablecimientoContactosComponent,
        EstablecimientoContactosNewComponent,
        EstablecimientoContactosEditComponent,
        EstablecimientoContactoComponent,
        EstablecimientoReportesComponent,
        EstablecimientoReportesNewComponent,
        EstablecimientoReportesEditComponent,
        EstablecimientoReporteComponent
    ]
})
export class PuntajeEstablecimientosModule {
    static forRoot(): ModuleWithProviders<PuntajeEstablecimientosModule> {
        return {
            ngModule: PuntajeEstablecimientosModule,
            providers: []
        }
    }
}
