import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { AchievementsAuthService } from "./achievements_auth.service"
import { AchievementsBaseService } from "./achievements_base.service"
import { Actividad } from "./actividades.model"

@Injectable({
    providedIn: "root"
})
export class Actividades extends AchievementsBaseService<Actividad> {
    tableName = "actividades"
    singularTableName = "actividad"
    modelClass = Actividad

    constructor(
        protected http: HttpClient,
        protected auth: AchievementsAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
