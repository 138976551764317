import { PuntajeBlogModule } from "@puntaje/puntaje/core"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { BlogModule } from "@puntaje/puntaje/new-modules/blog"
import { BannersModule } from "@puntaje/puntaje/new-modules/banners"
import { adminBlogRouting } from "./admin_blog.routing"
import { UtilModule, ModelViewModule } from "@puntaje/shared/core"
import { AdminLayoutsModule } from "../layouts/index"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AdminLayoutsModule,
        UtilModule,
        BannersModule,
        ModelViewModule,
        BlogModule,
        adminBlogRouting,
        PuntajeBlogModule
    ],
    declarations: [],
    exports: []
})
export class AdminBlogModule {}
