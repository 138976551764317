import { Component, Input, Output, OnInit, OnChanges, SimpleChanges, EventEmitter } from "@angular/core"
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms"
import { AppConfig } from "@puntaje/shared/core"

declare const config: AppConfig
@Component({
    selector: "buscador-usuario-resumen",
    templateUrl: "./buscador-usuario-resumen.component.html",
    styleUrls: ["./buscador-usuario-resumen.component.scss"]
})
export class BuscadorUsuarioResumenComponent implements OnInit {
    @Input() usuariosResumen: any = []
    @Output() usuariosEnviados: EventEmitter<any> = new EventEmitter<any>()
    @Output() limpiarFiltro: EventEmitter<any> = new EventEmitter<any>()
    usuariosFiltrados = []
    buscadorOpen: boolean = false
    limpiarDatos: boolean = false
    textoBoton: String = "Buscador de alumno"
    buscadorForm: UntypedFormGroup
    usuario: any = { like: {} }
    identificadorUsuarioLabel: string
    identificadorUsuarioEjemplo: string
    identificadoresPais: string[]

    constructor(private formBuilder: UntypedFormBuilder) {}

    ngOnInit(): void {
        const paisToIdentificadorPais = {
            chile: "rut",
            colombia: "cc",
            mexico: "curp",
            brasil: "cpf"
        }

        const identificadoresPais = config.plataforma.identificadorUsuario?.map(iu => iu.toLowerCase()) ?? [
            paisToIdentificadorPais[config.plataforma.pais] ?? "identificador"
        ]
        const identificadorUsuarioAlias = config.plataforma.identificadorUsuario
            ? config.plataforma.identificadorUsuarioAlias
            : {
                  [paisToIdentificadorPais[config.plataforma.pais] ?? "identificador"]:
                      paisToIdentificadorPais[config.plataforma.pais] ?? "Identificador"
              }
        this.identificadorUsuarioLabel = identificadorUsuarioAlias
            ? identificadoresPais.map(iu => identificadorUsuarioAlias[iu]).join(" o ")
            : identificadoresPais.map(iu => iu.toUpperCase()).join(" o ")

        const paisToIdentificadorUsuarioEjemploMap = {
            chile: "12345678-9",
            colombia: "1234567891",
            mexico: "OEAF771012HMCRGR09",
            brasil: "12345678912"
        }
        this.identificadorUsuarioEjemplo =
            identificadoresPais.length == 1 && identificadoresPais[0].toLowerCase() == "usuario_id"
                ? "1234"
                : paisToIdentificadorUsuarioEjemploMap[config.plataforma.pais]
        this.identificadoresPais = identificadoresPais

        this.buscadorForm = this.formBuilder.group({
            rut: [""],
            nombre: [""],
            apellido_materno: [""],
            apellido_paterno: [""],
            con_respuestas: [null]
        })
    }

    search() {

        function normalizedString(string: string) {
            return string.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        }

        const rut = this.buscadorForm.controls.rut.value?.replace(/\s+/g, "")
        const nombre = normalizedString(this.buscadorForm.controls.nombre.value ?? "")
        const apellidoMaterno = normalizedString(this.buscadorForm.controls.apellido_materno.value ?? "")
        const apellidoPaterno = normalizedString(this.buscadorForm.controls.apellido_paterno.value ?? "")

        if (this.buscadorForm.untouched) {
            this.usuariosFiltrados = this.usuariosResumen
        } else {
            this.usuariosFiltrados = this.usuariosResumen.filter(u => {
                const usuarioPais = u[`usuario_${config.plataforma.pais}`]

                return (
                    (!nombre || normalizedString(u.nombre).includes(nombre)) &&
                    (!apellidoMaterno || normalizedString(u.apellido_materno).includes(apellidoMaterno)) &&
                    (!apellidoPaterno || normalizedString(u.apellido_paterno).includes(apellidoPaterno)) &&
                    (!rut || this.identificadoresPais.some(ip => usuarioPais[ip].toString().includes(rut)))
                )
            })
        }
        this.usuariosEnviados.emit({
            usuariosFiltrados: this.usuariosFiltrados,
            conRespuestas: this.buscadorForm.controls.con_respuestas.value
        })
    }

    cleanData() {
        this.buscadorForm.reset()
        this.limpiarDatos = true
        this.limpiarFiltro.emit(this.limpiarDatos)
    }

    showBuscador() {
        if (!this.buscadorOpen) {
            this.buscadorOpen = true
            this.textoBoton = "Cerrar buscador"
        } else {
            this.buscadorOpen = false
            this.textoBoton = "Buscador de alumno"

            this.cleanData()
        }
    }
}
