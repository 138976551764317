import { Component, EventEmitter, OnInit, ViewChild } from "@angular/core"
import { select, Store } from "@ngrx/store"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AuthService } from "@puntaje/shared/core"
import {
    Establecimiento,
    Establecimientos,
    GrupoUsuarios,
    GrupoUsuario,
    AsignaturaEvaluacionTipos
} from "@puntaje/puntaje/api-services"
import {
    selectAsignaturasByEvaluacionTipo,
    selectAsignaturasById,
    selectAsignaturasList,
    State
} from "@puntaje/puntaje/store"
import { Subscription } from "rxjs"
import { filter, first } from "rxjs/operators"
import { IMyDpOptions } from "mydatepicker"
import { Asignatura, AsignaturaWithConfig, TipoInstrumentoPlataformas } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "resumen-utp-anual",
    templateUrl: "resumen_utp_anual.component.html",
    styleUrls: ["resumen_utp_anual.component.scss"]
})
export class ResumenUtpAnualComponent implements OnInit {
    @ViewChild("anualLoading", { static: true }) anualLoading: LoadingLayoutComponent
    openingTab: string = "parcial"
    stats: any
    establecimiento: Establecimiento
    establecimientos: Establecimiento[]
    grupoUsuarios: GrupoUsuario[]
    niveles: string[]
    grupoUsuarioIdNivel = {}
    autonomoTipoInstrumento = {}
    total_evaluaciones = {}
    asignatura_ids = []
    asignaturas = {}
    observaciones = null
    myemitter: EventEmitter<any> = new EventEmitter<any>()
    evaluaciones = {}
    fechaInicial: any = {}
    fechaFinal: any = {}
    tipoInstrumentosDict: any = {}
    year = null
    config = config

    asignaturasTablaResumida = {
        paes: [
            { id: 1, abreviacion: "Matemática" },
            { id: 2, abreviacion: "C.lectora" },
            { id: 3, abreviacion: "Historia" },
            { id: 19, abreviacion: "Ciencias" }
        ],
        "prueba de transición": [
            { id: 1, abreviacion: "Matemática" },
            { id: 2, abreviacion: "C.lectora" },
            { id: 3, abreviacion: "Historia" },
            { id: 19, abreviacion: "Ciencias" }
        ],
        curricular: [
            { id: 1, abreviacion: "Matemática" },
            { id: 2, abreviacion: "Lenguaje" },
            { id: 3, abreviacion: "Historia" },
            { id: 19, abreviacion: "Ciencias" },
            { id: 6, abreviacion: "Física" },
            { id: 32, abreviacion: "Cs para la ciudadanía" },
            { id: 33, abreviacion: "Ed. ciudadana" },
            { id: 7, abreviacion: "Química" },
            { id: 5, abreviacion: "Biología" }
        ],
        saber11: [
            { id: 20, abreviacion: "Matemáticas" },
            { id: 21, abreviacion: "Lectura Crítica" },
            { id: 19, abreviacion: "Ciencias Naturales" },
            { id: 23, abreviacion: "Sociales y ciudadanas" }
        ]
    }

    companyName = config.plataforma.info
        ? config.plataforma.info.companyName || "PuntajeNacional.cl"
        : config.plataforma.name
    logoInforme = config.app.assets.logoInforme

    onlyMiap: boolean = config.plataforma.name == "Aprendolibre Pro"

    public myDatePickerOptions: IMyDpOptions = {
        dateFormat: "dd/mm/yyyy",
        editableDateField: false,
        openSelectorOnInputClick: true
    }

    sub: Subscription
    asignaturasByEvaluacionTipo$ = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x)
    )
    asignaturasById$ = this.store.pipe(
        select(selectAsignaturasById),
        filter(x => !!x)
    )

    constructor(
        protected establecimientosService: Establecimientos,
        protected grupoUsuarioService: GrupoUsuarios,
        protected asignaturaEvaluacionTiposService: AsignaturaEvaluacionTipos,
        private tipoInstrumentoPlataformasService: TipoInstrumentoPlataformas,
        protected authService: AuthService,
        protected store: Store<State>
    ) {}

    ngOnInit() {
        this.anualLoading.ready()

        const now = new Date()
        this.fechaInicial.jsdate = new Date(now.getFullYear(), 0, 1)
        this.fechaFinal.jsdate = new Date(now.getFullYear(), 11, 31)

        Object.keys(config.evaluaciones).forEach(
            ti => (this.autonomoTipoInstrumento[ti] = config.evaluaciones[ti].autonomoInformeUso)
        )
        let params = {
            establecimiento: {
                id: this.authService.getEstablecimientos(),
                activo: 1
            },
            include: "[establecimiento_" + config.plataforma.pais + ",monitores]"
        }

        this.establecimientosService.where(params).then((establecimientos: Establecimiento[]) => {
            this.establecimientos = establecimientos
            this.establecimiento = establecimientos[0]
        })

        this.sub = this.asignaturasByEvaluacionTipo$.subscribe(asignaturasByEvaluacionTipo => {
            this.asignaturas = asignaturasByEvaluacionTipo
            this.asignatura_ids = Array.from(
                new Set(
                    Object.keys(this.asignaturas)
                        .reduce((x, r) => x.concat(asignaturasByEvaluacionTipo[r]), [])
                        .map(x => x.id)
                        .values()
                )
            )
        })

        this.evaluaciones = config.evaluaciones
    }

    asignatura_from_id(tipo_instrumento, id) {
        return this.asignaturas[tipo_instrumento].find(x => x.id == id)
    }

    onSelectPeriodo(event) {
        this.fechaInicial = event.fechaInicial
        this.fechaFinal = event.fechaFinal
        this.year = new Date(event.fechaInicial).getFullYear()
    }

    orderGroup(arr, property, qty) {
        const grupo = [...arr]
        const grupoOrdenado = grupo.sort((a, b) => b[property] - a[property])
        const mejoresDelGrupo = grupoOrdenado.length > qty ? grupoOrdenado.slice(0, qty) : grupoOrdenado
        return mejoresDelGrupo
    }

    launchModal() {
        this.myemitter.emit()
    }

    async showResumen() {
        this.anualLoading.standby()

        await this.getAsignaturas()

        let now = new Date()
        let params = {
            fecha_inicio: this.fechaInicial.jsdate,
            fecha_fin: this.fechaFinal.jsdate,
            asignatura_ids: this.asignatura_ids,
            tipos_instrumento: config.plataforma.tiposInstrumentoInformeUso,
            niveles_por_tipo_instrumento: {}
        }

        this.year = this.fechaInicial.jsdate.getFullYear()

        const niveles = []

        config.plataforma.tiposInstrumentoInformeUso.forEach(ti => {
            const nivelesPorInstrumento = config.evaluaciones[ti].nivelTipoInstrumentoInformeFinal
            params.niveles_por_tipo_instrumento[ti] = nivelesPorInstrumento

            for (const nivelInstrumento of nivelesPorInstrumento) {
                if (niveles.indexOf(nivelInstrumento) === -1) {
                    niveles.push(nivelInstrumento)
                }
            }
        })

        this.niveles = niveles

        this.establecimientosService.enableIgnoreModel()
        this.establecimientosService.stats(this.establecimiento.id, params).then((stats: any) => {
            this.establecimientosService.disableIgnoreModel()

            stats["mejores_cinco_docentes"] = this.orderGroup(stats.profesores, "total_instrumentos", 5)
            stats["docentes_mayores_ingresos"] = this.orderGroup(stats.profesores, "total_ingresos", 5)
            stats["mejores_diez_alumnos"] =
                stats.top_alumnos_general.length > 10
                    ? stats.top_alumnos_general.slice(0, 10)
                    : stats.top_alumnos_general

            const totalPorNivel = {}
            const totalPorModalidad = { total: 0 }

            for (const instrumento in stats.estadisticas_alumnos) {
                for (const nivel in stats.estadisticas_alumnos[instrumento]) {
                    for (const modalidad in stats.estadisticas_alumnos[instrumento][nivel]) {
                        let totalModalidad = 0
                        const totalNivel = totalPorNivel[nivel] || 0
                        const ignorarAutonomo = !this.autonomoTipoInstrumento[instrumento] && modalidad === "autonomo"

                        totalPorNivel[nivel] =
                            totalNivel +
                            (ignorarAutonomo ? 0 : stats.estadisticas_alumnos[instrumento][nivel][modalidad])

                        if (totalPorModalidad[instrumento] && totalPorModalidad[instrumento][modalidad]) {
                            totalModalidad = totalPorModalidad[instrumento][modalidad]
                        }
                        const sumaModalidad = stats.estadisticas_alumnos[instrumento][nivel][modalidad] + totalModalidad
                        totalPorModalidad[instrumento] = {
                            ...totalPorModalidad[instrumento],
                            [modalidad]: sumaModalidad
                        }

                        totalPorModalidad["total"] += ignorarAutonomo
                            ? 0
                            : stats.estadisticas_alumnos[instrumento][nivel][modalidad]
                    }
                }
            }

            stats["total_por_nivel"] = totalPorNivel
            stats["total_por_modalidad"] = totalPorModalidad

            const instrumentosPorModalidad = {}
            let total_instrumentos = 0
            // INSTRUMENTOS COMPARTIDOS

            for (var tipo in stats.numero_instrumentos_por_tipo) {
                let total_compartidos = 0
                for (var x in stats.numero_instrumentos_por_tipo[tipo]) {
                    let sub_total = 0
                    for (var asignatura_id in stats.numero_instrumentos_por_tipo[tipo][x]) {
                        sub_total += stats.numero_instrumentos_por_tipo[tipo][x][asignatura_id]

                        for (const asignaturaPermitida of this.asignaturasTablaResumida[tipo]) {
                            if (asignaturaPermitida.id == asignatura_id) {
                                instrumentosPorModalidad[x] =
                                    stats.numero_instrumentos_por_tipo[tipo][x][asignatura_id] +
                                    (instrumentosPorModalidad[x] ? instrumentosPorModalidad[x] : 0)
                            }
                        }
                    }
                    stats.numero_instrumentos_por_tipo[tipo][x].total = sub_total
                    total_compartidos += sub_total
                }

                stats.numero_instrumentos_por_tipo[tipo].total = total_compartidos
            }

            stats["instrumentos_por_modalidad"] = instrumentosPorModalidad

            for (const tipo in instrumentosPorModalidad) {
                total_instrumentos += instrumentosPorModalidad[tipo]
            }

            stats["total_instrumentos"] = total_instrumentos

            // STATS PARA PERIODO ACTUAL
            let total = stats.estadisticas_alumnos
            let promedio = {}

            let stats_evaluacion = stats.estadisticas_evaluacion

            for (var tipo in stats_evaluacion) {
                promedio[tipo] = {}
                for (var asignatura in stats_evaluacion[tipo]) {
                    let sub_total = 0
                    let sub_puntaje = 0
                    Object.keys(stats_evaluacion[tipo][+asignatura]).forEach(function (cursoFull) {
                        stats_evaluacion[tipo][+asignatura][cursoFull].forEach(item => {
                            if (item.oficial) {
                                sub_total += item.numero_evaluaciones
                                sub_puntaje += item.promedio * item.numero_evaluaciones
                            }
                        })
                    })
                    if (sub_total == 0) promedio[tipo][asignatura] = 0
                    else promedio[tipo][asignatura] = Math.round(sub_puntaje / sub_total)
                }
            }

            stats.total = total
            stats.promedio = promedio

            // STATS PERIODO ANTERIOR
            let promedio_anterior = {}
            let stats_evaluacion_anterior = stats.estadisticas_evaluacion_periodo_anterior

            for (var tipo in stats_evaluacion_anterior) {
                promedio_anterior[tipo] = {}
                for (var asignatura in stats_evaluacion_anterior[tipo]) {
                    let sub_total = 0
                    let sub_puntaje = 0

                    Object.keys(stats_evaluacion_anterior[tipo][+asignatura]).forEach(cursoFull => {
                        stats_evaluacion_anterior[tipo][+asignatura][cursoFull].forEach(item => {
                            sub_total += item.numero_evaluaciones
                            sub_puntaje += item.promedio * item.numero_evaluaciones
                        })
                    })
                    if (sub_total == 0) promedio_anterior[tipo][asignatura] = 0
                    else promedio_anterior[tipo][asignatura] = Math.round(sub_puntaje / sub_total)
                }
            }

            stats.promedio_anterior = promedio_anterior

            this.stats = stats
            let tipoInstrumentosTotales = Array.from(
                new Set(
                    [stats.promedio, stats.promedio_anterior, stats.numero_instrumentos_por_tipo]
                        .map(n => Object.keys(n))
                        .reduce((acc, val) => acc.concat(val), [])
                )
            )
            this.tipoInstrumentoPlataformasService
                .configurarAliases(config.plataforma.name, tipoInstrumentosTotales)
                .then(dict => {
                    this.tipoInstrumentosDict = dict
                    this.anualLoading.ready()
                    window.setTimeout(function () {
                        window.print()
                    }, 1000)
                })
        })
    }

    async getAsignaturas() {
        const asignaturaById = await this.asignaturasById$.pipe(first()).toPromise()

        const aetParams = {
            asignatura_evaluacion_tipo: { plataforma: this.config.plataforma.name },
            evaluacion_tipo: {
                evaluacion_tipo: this.config.plataforma.tiposInstrumentoInformeUso
            },
            render_options: {
                include: {
                    evaluacion_tipo: { only: ["evaluacion_tipo"] },
                    asignatura_material_clasificacion_tipos: null,
                    asignatura_material_niveles: null
                }
            },
            establecimiento_ids: [this.establecimiento.id]
        }

        const asignaturaEvaluacionTipos = await this.asignaturaEvaluacionTiposService.where(aetParams)

        this.asignaturas = asignaturaEvaluacionTipos.reduce((acc, asignaturaEvaluacionTipo) => {
            const asignatura = asignaturaById[asignaturaEvaluacionTipo.asignatura_id]

            if (!asignatura) {
                return acc
            }

            const newAsignatura: AsignaturaWithConfig = new Asignatura() as AsignaturaWithConfig
            Object.assign(newAsignatura, asignatura)
            newAsignatura.clase = asignaturaEvaluacionTipo.clase
            newAsignatura.icono = asignaturaEvaluacionTipo.icono
            newAsignatura.abreviacion = asignaturaEvaluacionTipo.abreviacion
            newAsignatura.asignaturaPlataforma = asignaturaEvaluacionTipo.asignatura_plataforma

            acc[asignaturaEvaluacionTipo.evaluacion_tipo.evaluacion_tipo] =
                acc[asignaturaEvaluacionTipo.evaluacion_tipo.evaluacion_tipo] || []
            acc[asignaturaEvaluacionTipo.evaluacion_tipo.evaluacion_tipo].push(newAsignatura)

            return acc
        }, {})

        this.asignatura_ids = Array.from(new Set(asignaturaEvaluacionTipos.map(aet => aet.asignatura_id)))
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.sub?.unsubscribe()
    }
}
