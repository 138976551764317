import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core"
import { select, Store } from "@ngrx/store"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { TipoInstrumentoPlataformas, AsignaturaWithConfig } from "@puntaje/nebulosa/api-services"
import { Establecimientos } from "@puntaje/puntaje/api-services"
import { selectAsignaturasByEvaluacionTipo, State } from "@puntaje/puntaje/store"
import { filter, first } from "rxjs/operators"
import { AllReadyService } from "@puntaje/puntaje/new-modules/shared"

@Component({
    selector: "tabla-numero-evaluaciones-realizadas",
    templateUrl: "./tabla-numero-evaluaciones-realizadas.component.html",
    styleUrls: ["./tabla-numero-evaluaciones-realizadas.component.scss"]
})
export class TablaNumeroEvaluacionesRealizadasComponent implements OnInit, OnChanges {
    @Input() establecimientoId: number
    @Input() fechaInicial: Date
    @Input() fechaFinal: Date
    // nEvaluacionesByTipoInstrumento: any
    // nTotalEvaluacionesByTipoInstrumento: any
    @Input() asignaturasByEvaluacionTipo: { [tipoEvaluacion: string]: AsignaturaWithConfig[] }
    @Input() tipoInstrumentosDict: any
    // tiposInstrumento: string[] = ["ensayo", "curricular"]

    tiposInstrumentoInformeUso: string[] = config.plataforma.tiposInstrumentoInformeUso || ["ensayo", "curricular"]
    configuracion = {}
    configuracionLoaded: boolean = false

    constructor(
        protected establecimientosService: Establecimientos,
        protected store: Store<State>,
        protected allreadyservice: AllReadyService,
        private tipoInstrumentoPlataformasService: TipoInstrumentoPlataformas
    ) {}

    async ngOnInit() {
        this.configurar()
    }

    configurar() {
        this.configuracionLoaded = false
        this.tiposInstrumentoInformeUso.forEach(t => {
            this.configuracion[t] = {
                promise: null,
                dataTotal: {
                    compartidas_presencial: 0,
                    compartidas_no_presencial: 0,
                    no_compartidas: 0
                },
                dataAsignaturas: {}
            }
            this.asignaturasByEvaluacionTipo[t].forEach(a => {
                this.configuracion[t]["dataAsignaturas"][a.id] = {
                    compartidas_presencial: 0,
                    compartidas_no_presencial: 0,
                    no_compartidas: 0
                }
            })
            const params = {
                fecha_inicial: this.fechaInicial,
                fecha_final: this.fechaFinal,
                tipo_instrumento: t,
                asignatura_ids: this.asignaturasByEvaluacionTipo[t].map(x => x.id)
            }

            const promise = this.establecimientosService
                .tabla_numero_evaluaciones_por_asignatura(this.establecimientoId, params)
                .then((numero_evaluaciones: any) => {
                    const data = numero_evaluaciones
                    this.asignaturasByEvaluacionTipo[t].forEach(a => {
                        if (data[a.id]) {
                            if (data[a.id]["compartidas_presencial"]) {
                                this.configuracion[t]["dataTotal"]["compartidas_presencial"] +=
                                    data[a.id]["compartidas_presencial"]
                                this.configuracion[t]["dataAsignaturas"][a.id]["compartidas_presencial"] +=
                                    data[a.id]["compartidas_presencial"]
                            }

                            if (data[a.id]["compartidas_no_presencial"]) {
                                this.configuracion[t]["dataTotal"]["compartidas_no_presencial"] +=
                                    data[a.id]["compartidas_no_presencial"]
                                this.configuracion[t]["dataAsignaturas"][a.id]["compartidas_no_presencial"] +=
                                    data[a.id]["compartidas_no_presencial"]
                            }

                            if (data[a.id]["no_compartidas"]) {
                                this.configuracion[t]["dataTotal"]["no_compartidas"] += data[a.id]["no_compartidas"]
                                this.configuracion[t]["dataAsignaturas"][a.id]["no_compartidas"] +=
                                    data[a.id]["no_compartidas"]
                            }
                        }
                    })
                })

            this.configuracion[t]["promise"] = promise
        })
        if (!this.tipoInstrumentosDict) {
            this.tipoInstrumentosDict = {}
            this.tiposInstrumentoInformeUso.map(ti => {
                this.tipoInstrumentosDict[ti] = ti
            })
        }
        const promises = Object.keys(this.configuracion).map(k => this.configuracion[k]["promise"])
        Promise.all(promises).then(values => {
            this.configuracionLoaded = true
            this.allreadyservice.updateCounterWithKey("informe-anual")
        })  
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            (changes["establecimientoId"] &&
                !changes["establecimientoId"].firstChange &&
                changes["establecimientoId"].currentValue != changes["establecimientoId"].previousValue) ||
            (changes["fechaInicial"] &&
                !changes["fechaInicial"].firstChange &&
                changes["fechaInicial"].currentValue != changes["fechaInicial"].previousValue) ||
            (changes["fechaFinal"] &&
                !changes["fechaFinal"].firstChange &&
                changes["fechaFinal"].currentValue != changes["fechaFinal"].previousValue)
        ) {
            this.configurar()
        }
    }
}
