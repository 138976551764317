import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { AyudaModule as AyudaMod } from "@puntaje/puntaje/new-modules/ayuda"
import { UtilModule, ModelViewModule, PaginatorModule } from "@puntaje/shared/core"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { AyudasAdminComponent } from "./ayudas-admin.component"
import { CategoriaAyudaNewComponent } from "./categoria-ayuda-new/categoria-ayuda-new.component"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { AyudasAdminModule } from "@puntaje/puntaje/new-modules/ayudas-admin"
import { CategoriaAyudaAdminComponent } from "./categoria-ayuda-admin/categoria-ayuda-admin.component"
import { CategoriaAyudaEditComponent } from "./categoria-ayuda-edit/categoria-ayuda-edit.component"
import { ToolNavbarModule } from "@puntaje/shared/core"

@NgModule({
    imports: [
        AyudasAdminModule,
        CommonModule,
        FormsModule,
        UtilModule,
        ReactiveFormsModule,
        AyudaMod,
        CUILayoutsModule,
        CommonsLayoutsModule,
        ModelViewModule,
        PaginatorModule,
        ToolNavbarModule
    ],
    declarations: [
        AyudasAdminComponent,
        CategoriaAyudaNewComponent,
        CategoriaAyudaAdminComponent,
        CategoriaAyudaEditComponent
    ],
    exports: [
        AyudasAdminComponent,
        CategoriaAyudaNewComponent,
        CategoriaAyudaAdminComponent,
        CategoriaAyudaEditComponent
    ]
})
export class PuntajeAyudasAdminModule {}
