import { Component, HostBinding } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Asignaturas } from "@puntaje/nebulosa/api-services"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"

/*#################################

Menú a la izquierda del sitio. En este momento es el menú de puntaje.

#################################*/

@Component({
    templateUrl: "sidemenu.component.html",
    selector: "sidemenu",
    styleUrls: ["sidemenu.component.scss"]
})
export class SidemenuComponent extends MenuComponent {
    config: typeof config = config
    evaluacionTipo = config.plataforma.evaluacionDefault

    constructor(store: Store<State>) {
        super(store)
    }

    @HostBinding("class.show-sidemenu") showSidemenu: boolean = false

    toggleSidemenu() {
        this.showSidemenu = !this.showSidemenu
    }

    closeSidemenu() {
        this.showSidemenu = false
    }
}
