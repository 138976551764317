import { Component, OnInit, Output, EventEmitter } from "@angular/core"
import { Logros, Logro, Categorias, Categoria } from "@puntaje/achievements/api-services"
import { LogrosBuscadorService } from "./logros-buscador.service"
import { Subscription } from "rxjs"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { IMyDpOptions } from "mydatepicker"
import { SessionService } from "@puntaje/shared/core"

@Component({
    selector: "logros-buscador",
    templateUrl: "logros-buscador.component.html"
})
export class LogrosBuscadorComponent implements OnInit {
    logro: any = {}

    categorias: Categoria[]
    categoria_id: number

    // sirve para poder poner campos por defecto que se llenen de forma asincrona
    @Output() onReady = new EventEmitter()

    sub: Subscription
    open: boolean = false
    filteredCategorias: Categoria[] = []

    constructor(
        protected logrosBuscadorService: LogrosBuscadorService,
        protected categoriasService: Categorias,
        protected sessionService: SessionService
    ) {}

    ngOnInit() {
        this.sub = this.logrosBuscadorService.openSearch.subscribe(() => {
            this.open = !this.open
        })

        //por defecto que aparezca abierto
        this.logrosBuscadorService.openSearch.next(true)

        //categorias para llenar el select
        this.categoriasService.where({}).then(categorias => {
            this.categorias = categorias

            this.onReady.emit()
            this.search()
        })
    }

    search() {
        this.logrosBuscadorService.search(this.logro)
    }

    onChangeCategoria(event) {
        this.logro.categoria_id = event.target.value == "Todas" ? undefined : event.target.value
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
