import { Component, OnInit, ViewChild, Input, ElementRef, ChangeDetectorRef } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription, BehaviorSubject, combineLatest } from "rxjs"
import { IMyDpOptions, IMyDayLabels, IMyDateModel } from "mydatepicker"

import {
    Establecimiento,
    Establecimientos,
    Lugar,
    Lugares,
    Asociaciones,
    GrupoUsuarios,
    GrupoUsuario
} from "@puntaje/puntaje/api-services"
import { Asignatura, Asignaturas, GeneradorInstrumento, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { AuthService, KeysPipe } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasByEvaluacionTipo } from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"

@Component({
    selector: "resumen-utp",
    templateUrl: "resumen_utp.component.html",
    styleUrls: ["resumen_utp.component.scss"]
})
export class ResumenUtpComponent implements OnInit {
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("loadingLayoutResultados", { static: true }) loadingLayoutResultados: LoadingLayoutComponent
    fechaInicio: any = {}
    fechaFin: any = {}
    @Input() tipo_resumen: string

    establecimientos: Establecimiento[]
    establecimiento: Establecimiento
    currentEstablecimiento: Establecimiento
    selectedEstablecimientos: Establecimiento[] = []
    dropdownEstablecimientosSettings = {
        singleSelection: false,
        idField: "id",
        textField: "establecimiento",
        selectAllText: "Seleccionar todos",
        unSelectAllText: "Deseleccionar todos",
        itemsShowLimit: 4,
        allowSearchFilter: false
    }

    lugares: Lugar[]
    resumenActive: boolean = false
    stats: any
    statsCombined: any

    config: typeof config = config

    asignaturas: Asignatura[]
    asignatura: Asignatura
    htmlPopover: string = `<p> Instrumentos generados: N° de instrumentos (únicos) generados por los docentes en el rango de fechas definidas.</p>
                           <p>Evaluaciones compartidas: N° de evaluaciones (online + presencial) compartidas por los docentes en el rango de fechas definidas.</p>`

    generadorInstrumentos: GeneradorInstrumento[]
    generadorInstrumento: GeneradorInstrumento

    evaluacionTipo: string
    evaluacionTipoSubject = new BehaviorSubject(null)

    sub: Subscription
    asignaturasByEvaluacionTipo$ = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x)
    )

    asociaciones = []
    asociacion: any

    grupoUsuario: GrupoUsuario
    grupoUsuarios: GrupoUsuario[]

    public myDatePickerOptions: IMyDpOptions = {
        dateFormat: "dd/mm/yyyy",
        editableDateField: false,
        openSelectorOnInputClick: true
    }

    constructor(
        protected route: ActivatedRoute,
        protected authService: AuthService,
        protected establecimientosService: Establecimientos,
        protected store: Store<State>,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected lugaresService: Lugares,
        protected asociacionService: Asociaciones,
        protected grupoUsuariosService: GrupoUsuarios
    ) {}

    ngOnInit() {
        //this.loadingLayoutResultados.ready();
        let now = new Date()
        this.fechaInicio.jsdate = new Date(now.getFullYear(), 0, 1, 0, 0, 0, 0)
        this.fechaFin.jsdate = new Date(now.getFullYear(), 11, 31, 23, 59, 59, 0)

        if (this.tipo_resumen == "asociacion") {
            let param = {
                usuario_id: this.authService.getUserData().id
            }
            this.asociacionService.where(param).then(res => {
                this.asociaciones = res
                if (this.asociaciones.length > 0) {
                    this.asociacion = this.asociaciones[0]
                    this.obtenerEstablecimientos(this.asociaciones[0])
                    this.loadingLayout.ready()
                } else {
                    this.showResumen()
                    this.loadingLayout.ready()
                }
            })
        } else {
            let params = { establecimiento: { id: this.authService.getEstablecimientos(), activo: 1 } }
            params["include"] = `[establecimiento_${config.plataforma.pais}]`

            this.establecimientosService.where(params).then((establecimientos: Establecimiento[]) => {
                this.establecimientos = establecimientos
                this.establecimiento = establecimientos[0]

                this.dropdownEstablecimientosSettings["enableCheckAll"] =
                    this.establecimientos.length > 1 ? true : false
                if (this.establecimientos.length > 0) this.selectedEstablecimientos = [this.establecimiento]

                this.getGrupoUsuarios().then(() => {
                    this.loadingLayout.ready()
                    this.showResumen()
                })
            })
        }

        this.sub = combineLatest(
            this.evaluacionTipoSubject.pipe(filter(x => !!x)),
            this.asignaturasByEvaluacionTipo$
        ).subscribe(([evaluacionTipo, asignaturasByEvaluacionTipo]) => {
            this.asignatura = undefined
            this.generadorInstrumento = undefined
            this.asignaturas = asignaturasByEvaluacionTipo[this.evaluacionTipo]
        })
    }

    getGrupoUsuarios() {
        this.grupoUsuario = undefined
        if (this.selectedEstablecimientos.length > 1) {
            this.grupoUsuarios = undefined
        }

        const grupoUsuarioParams = {
            grupo_usuario: {
                establecimiento_id: this.selectedEstablecimientos[0].id
            },
            fecha_inicial: this.fechaInicio.jsdate.toISOString(),
            fecha_final: this.fechaFin.jsdate.toISOString()
        }

        return this.grupoUsuariosService.where(grupoUsuarioParams).then(grupoUsuarios => {
            this.grupoUsuarios = grupoUsuarios
        })
    }

    onEvaluacionTipoChange(evaluacionTipo) {
        this.evaluacionTipo = evaluacionTipo
        this.evaluacionTipoSubject.next(evaluacionTipo)
    }

    onAsignaturaChange() {
        this.generadorInstrumento = undefined

        let params = {
            asignatura_plataforma: {
                asignatura_id: this.asignatura.id,
                plataforma_id: config.plataforma.id
            },
            tipo_instrumento: {
                tipo_instrumento: this.evaluacionTipo
            }
        }

        this.generadorInstrumentosService.where(params).then((generadorInstrumentos: GeneradorInstrumento[]) => {
            this.generadorInstrumentos = generadorInstrumentos
        })
    }

    obtenerEstablecimientos(asociacion) {
        let params = {
            asociacion_id: asociacion.id
        }
        params["include"] = `[establecimiento_${config.plataforma.pais}]`
        this.establecimientosService.where(params).then((establecimientos: Establecimiento[]) => {
            this.establecimientos = establecimientos
            this.showResumen()
        })
    }

    showResumen() {
        if (this.loadingLayoutResultados) this.loadingLayoutResultados.standby()

        let params: any = {
            fecha_inicio: this.fechaInicio.jsdate,
            fecha_fin: this.fechaFin.jsdate,
            pais: config.plataforma.pais
        }
        if (this.generadorInstrumento) params.generador_instrumento_id = this.generadorInstrumento.id

        if (this.tipo_resumen == "asociacion") {
            if (this.asociaciones.length > 0) {
                this.asociacionService.stats(this.asociacion.id, params).then((stats: any) => {
                    this.stats = stats
                    for (let s of this.stats) {
                        s.establecimiento = this.establecimientos.find(e => e.id === s.establecimiento_id)
                    }
                    this.resumenActive = true
                    if (this.loadingLayoutResultados) this.loadingLayoutResultados.ready()
                })
            } else {
                this.loadingLayoutResultados.ready()
            }
        } else {
            let lugaresPromise = this.lugaresService
                .find(this.establecimiento.lugar_id, { recursive: 1, with_lugar_tipo: 1 })
                .then((lugar: Lugar) => {
                    this.lugares = [lugar]
                    let l = lugar
                    while (l.lugar) {
                        this.lugares.push(l)
                        l = l.lugar
                    }
                })

            params["id"] = this.selectedEstablecimientos.map(e => e.id)
            params["exclude_from_estadisticas"] = false
            params["exclude_alumnos"] = false

            if (this.grupoUsuario) params["grupo_usuario_id"] = this.grupoUsuario.id

            let statsPromise = this.establecimientosService.statsMultiple(params).then((stats: any) => {
                this.stats = stats
                if (this.stats.length > 1) this.sumStats()
            })

            Promise.all([lugaresPromise, statsPromise]).then(res => {
                this.currentEstablecimiento = this.establecimiento
                this.resumenActive = true
                if (this.loadingLayoutResultados) this.loadingLayoutResultados.ready()
            })
        }
    }

    sumStats() {
        const keys = [
            "numero_docentes",
            "login_docentes",
            "numero_instrumentos_docentes",
            "numero_evaluaciones_docentes",
            "numero_evaluaciones_respondidas",
            "numero_evaluaciones_impresas_docentes",
            "numero_evaluaciones_presenciales",
            "numero_alumnos",
            "login_alumnos",
            "numero_admins",
            "login_admins",
            "numero_evaluaciones_respondidas_total",
            "numero_evaluaciones_generadas_alumnos",
            "cantidad_contactos_efectivos",
            "numero_instrumentos_administradores"
        ]

        // init counts 0
        this.statsCombined = keys.reduce((acc, key) => {
            acc[key] = 0
            return acc
        }, {})
        // sum counts from stats
        this.statsCombined = this.stats.reduce(function (acc, statsEstablecimiento) {
            keys.forEach(key => {
                acc[key] += statsEstablecimiento.stats[key]
            })
            return acc
        }, this.statsCombined)
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.sub.unsubscribe()
    }
}
