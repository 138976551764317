import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { Actividad, Actividades } from "@puntaje/achievements/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Router } from "@angular/router"

@Component({
    selector: "actividad-edit",
    templateUrl: "./actividad-edit.component.html",
    styleUrls: ["./actividad-edit.component.scss"]
})
export class ActividadEditComponent implements OnInit {
    @Input() actividadId: number
    actividad: Actividad
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(protected actividadesService: Actividades, protected router: Router) {}

    ngOnInit() {
        this.loadingLayout.standby()
        this.actividadesService.find(this.actividadId).then(actividad => {
            this.actividad = actividad
            this.loadingLayout.ready()
        })
    }

    save(actividad: Actividad) {
        this.actividadesService.update(this.actividadId, actividad).then(_ => {
            this.router.navigate(["/achievements/actividades"])
        })
    }
}
