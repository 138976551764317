import { PuntajeAsociacionesModule } from "@puntaje/puntaje/core"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"

import { UtilModule } from "@puntaje/shared/core"
import { AdminLayoutsModule } from "../layouts/admin_layouts.module"
import { AsociacionesModule } from "@puntaje/puntaje/new-modules/asociaciones"

//import { AsociacionesDeleteComponent } 	from './asociaciones.delete.component';
//import { AsociacionesToolNavbarComponent }	from './asociaciones.toolnavbar.component';
import { asociacionesRouting } from "./admin_asociaciones.routing"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        AdminLayoutsModule,
        AsociacionesModule,
        asociacionesRouting,
        PuntajeAsociacionesModule
    ],
    declarations: [],
    exports: []
})
export class AdminAsociacionesModule {}
