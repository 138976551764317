import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef, Injector } from "@angular/core"
import { FormBuilder, FormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    Noticia,
    Noticias,
    NoticiaForm,
    Establecimiento,
    Establecimientos,
    GrupoUsuarios,
    GrupoUsuario,
    NoticiaUsuario,
    NoticiasPerfiles,
    NoticiasPerfil,
    NoticiaTipos,
    Lugares
} from "@puntaje/puntaje/api-services"
import { FormsModule } from "@angular/forms"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AuthService } from "@puntaje/shared/core"
import { NoticiaBaseComponent } from "./noticias.base.component"

@Component({
    selector: "noticias-new",
    templateUrl: "noticias.form.component.html",
    styleUrls: ["noticias-form.component.scss"]
})
export class NoticiasNewComponent extends NoticiaBaseComponent implements OnInit {
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @Output() closeModal: EventEmitter<any> = new EventEmitter<any>()
    @Input() fromProfesor: boolean = false
    @Input() noticias: Noticia[]

    params: any

    constructor(
        noticiasService: Noticias,
        establecimientosService: Establecimientos,
        router: Router,
        grupoUsuariosService: GrupoUsuarios,
        noticiaPerfilesService: NoticiasPerfiles,
        authService: AuthService,
        cdr: ChangeDetectorRef,
        noticiaTiposService: NoticiaTipos,
        lugaresService: Lugares,
        injector: Injector
    ) {
        super(
            noticiasService,
            establecimientosService,
            router,
            grupoUsuariosService,
            noticiaPerfilesService,
            authService,
            cdr,
            noticiaTiposService,
            lugaresService
        )
        NoticiaForm.injector = injector
        this.params = NoticiaForm.formParams
    }

    ngOnInit() {
        this.getNoticiasPerfiles()
        this.getNoticiaTipos()
        if (this.fromProfesor) {
            this.getGrupoUsuarios()
            this.params.tipo.type = "hidden"
            this.params.notificar.type = "hidden"
            this.params.noticias_perfil_id.type = "hidden"
            this.params.noticia_tipo_id.type = "hidden"
            this.params.destacado.type = "hidden"
        }
        this.form = NoticiaForm.getForm(this.noticia)
        this.loadingLayout.ready()
    }

    // con nueva tabla
    save() {
        NoticiaForm.markFormControlsAsTouched(this.form)
        this.showWarning = false
        if (this.grupoUsuarios && this.gruposSeleccionados.length <= 0) {
            this.showWarning = true
            return
        }
        if (this.form.valid) {
            const noticiaUsuario = new NoticiaUsuario()
            noticiaUsuario.usuario_id = this.authService.getUserData().id

            if (this.establecimiento) {
                this.withOpcion(noticiaUsuario, { nombre: "Establecimiento", id: this.establecimiento.id })
            } else if (typeof this.gruposSeleccionados !== "undefined" && this.gruposSeleccionados.length > 0) {
                this.withGrupoUsuarios()
            } else {
                this.withOpcion(noticiaUsuario, { nombre: "Usuario", id: null })
            }

            if (this.lugarColegio) {
                this.noticia.lugar_id = this.lugarColegio.id
            }

            const noticiaTipo = this.noticiaTipos.find(nt => nt.id == this.noticia.noticia_tipo_id)
            if (noticiaTipo && noticiaTipo.noticia_tipo == "aviso") {
                this.noticia.tipo = "ninguno"
            }

            this.noticiasService.save(this.noticia).then((noticia: Noticia) => {
                this.clear()
                if (this.fromProfesor) {
                    this.noticias.push(noticia)
                    this.closeModal.emit()
                }

                this.router.navigate(["noticias"])
            })
        }
    }
}
