import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from "@angular/core"
import { NgModel } from "@angular/forms"
import {
    Usuario,
    GrupoUsuario,
    Usuarios,
    GrupoUsuarios,
    GrupoUsuarioUsuarios,
    GrupoUsuarioUsuario
} from "@puntaje/puntaje/api-services"
import { Router, ActivatedRoute } from "@angular/router"
import { SimpleModalService } from "@puntaje/shared/layouts"
import { Clasificaciones } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "asociar-usuario-masivo",
    templateUrl: "./asociar-usuario-masivo.component.html",
    styleUrls: ["./asociar-usuario.component.scss"]
})
export class AsociarUsuarioMasivoComponent implements OnInit {
    pais: string = config.plataforma.pais
    idPais: any = config.plataforma.identificadorUsuario
    aliases = config.plataforma?.identificadorUsuarioAlias
    nombreId: string = this.idPais.map(id => this.aliases?.[id] || id).join(" o ")
    grupoUsuarioAlias: string = config.plataforma.grupoUsuarioAlias

    idValue: string
    nombre: string
    apellidoPaterno: string
    apellidoMaterno: string
    email: string

    usuario: Usuario
    usuarioLoaded: boolean = false
    tipoUsuario: string
    _grupoUsuarios: GrupoUsuario[]
    grupoUsuariosAsociados: number[]
    grupoUsuariosTotales: GrupoUsuario[]
    selectedGrupoUsuarios: { [key: number]: boolean } = {}
    addUsuarioOpen: boolean = false
    isModifiedSelectedGrupoUsuarios: boolean = false

    @Input() fechaInicial: Date
    @Input() fechaFinal: Date
    @Input() toggleId: string = ""
    @Input() propietario: boolean = false
    @Input() grupoUsuario: GrupoUsuario
    @Input()
    set grupoUsuarios(gus: GrupoUsuario[]) {
        this._grupoUsuarios = gus
    }
    get grupoUsuarios() {
        return this._grupoUsuarios
    }

    @Output("updateGrupoUsuarios") parentGrupoUsuarios: EventEmitter<any> = new EventEmitter()

    msgPais = {
        chile: `El ${this.nombreId} ingresado no es válido.`,
        colombia: "El número de identificación no es válido.",
        mexico: "El número de identificación no es válido."
    }

    searchById: boolean = false

    @Output() usuarioAsociadoEvent = new EventEmitter()

    @ViewChild("idValueUsuario") idValueUsuario: NgModel

    constructor(
        protected usuariosService: Usuarios,
        protected grupoUsuariosService: GrupoUsuarios,
        protected grupoUsuarioUsuariosService: GrupoUsuarioUsuarios,
        protected router: Router,
        protected route: ActivatedRoute,
        protected simpleModalService: SimpleModalService,
        protected clasificacionesService: Clasificaciones
    ) {}

    ngOnInit() {
        this.tipoUsuario = this.propietario ? "docente" : "alumno"
    }

    validateIdValueFun(idValue) {
        if (!idValue) idValue = ""

        return {
            valid: this.validateIdPais(idValue) || this.validateId(idValue),
            msg: this.msgPais[this.pais] || "País de la plataforma no disponible"
        }
    }

    validateIdValue() {
        return this.validateIdValueFun(this.idValue)
    }

    validateIdPais(idValue) {
        switch (this.pais) {
            case "chile":
                return this.validaRut(idValue)
            case "colombia":
                return idValue.length > 8 && idValue.length <= 11 && /^[1-9][0-9]+$/.test(idValue)
            case "mexico":
                return idValue.length > 4
            default:
                return false
        }
    }

    validateId(idValue) {
        return /^\d+$/.test(idValue) && this.msgPais[this.pais]
    }

    getUsuarioFun(idValue, propietario = null) {
        let paramsUsuario = {}

        if (this.grupoUsuario) {
            paramsUsuario["grupo_usuario"] = { id: this.grupoUsuario.id }
        } else if (this.grupoUsuarios) {
            paramsUsuario["grupo_usuario"] = { id: this.grupoUsuarios.map(gu => gu.id) }
        } else {
            return null
        }

        if (propietario != null) {
            paramsUsuario["grupo_usuario_usuario"] = { propietario: propietario }
        }

        paramsUsuario["usuario_" + this.pais] = {}
        paramsUsuario["usuario_" + this.pais][this.idPais.join("_o_").toLowerCase()] = idValue

        return this.usuariosService.where(paramsUsuario).then((usuarios: Usuario[]) => {
            delete paramsUsuario["grupo_usuario"]
            delete paramsUsuario["grupo_usuario_usuario"]
            if (usuarios[0]) {
                // dirty stuff: si no viene propietario, es porque lo estoy usando en la edicion de usuario,
                // y necesito el usuario que va a buscar
                if (propietario == null || propietario == undefined || this.grupoUsuarios) {
                    this.usuarioLoaded = true
                    this.usuario = usuarios[0]

                    if (this.grupoUsuarios) this.setGrupoUsuariosAsociados()
                }
            } else {
                this.usuariosService.where(paramsUsuario).then((usuarios: Usuario[]) => {
                    this.usuarioLoaded = true
                    this.usuario = usuarios[0]

                    if (this.grupoUsuarios) this.setGrupoUsuariosAsociados()
                })
            }
        })
    }

    getUsuario() {
        let propietario = this.propietario ? 1 : 0
        this.usuarioLoaded = false

        this.nombre = ""
        this.apellidoPaterno = ""
        this.apellidoMaterno = ""
        this.email = ""

        this.searchById = !this.validateIdPais(this.idValue)

        this.getUsuarioFun(this.idValue, propietario)
    }

    setGrupoUsuariosAsociados() {
        this.grupoUsuariosAsociados = []
        this.grupoUsuariosTotales = []

        if (this.usuario && this.usuario.grupo_usuarios.length > 0) {
            this.grupoUsuarios.forEach(gu => {
                if (this.usuario.grupo_usuarios.filter(ugu => ugu.id == gu.id).length > 0) {
                    this.grupoUsuariosAsociados.push(gu.id)
                    this.selectedGrupoUsuarios[gu.id] = true
                } else {
                    this.selectedGrupoUsuarios[gu.id] = false
                }
                this.grupoUsuariosTotales.push(gu)
            })
        } else {
            this.grupoUsuariosTotales = this.grupoUsuarios
        }
    }

    createUsuario() {
        let usuario = new Usuario()
        delete usuario.usuario_chile
        delete usuario.usuario_colombia

        usuario.nombre = this.nombre
        usuario.apellido_paterno = this.apellidoPaterno
        usuario.apellido_materno = this.apellidoMaterno
        usuario.email = this.email
        usuario.password = this.idValue.substring(0, 6)
        usuario.password_confirmation = this.idValue.substring(0, 6)
        usuario.nomina = true
        usuario.confirmed_at = new Date()
        usuario["usuario_" + this.pais] = new Usuario["usuario_" + this.pais]()
        usuario["usuario_" + this.pais][this.idPais[0].toLowerCase()] = this.idValue.toLowerCase()
        usuario.manual_reset_password = true

        if (!this.grupoUsuario && !this.grupoUsuarios) {
            return
        }

        usuario.grupo_usuario_usuarios = this.createGrupoUsuarioUsuarios()

        this.usuariosService.enableIgnoreModel()
        this.usuariosService.save(usuario).then((usuario: Usuario) => {
            this.usuariosService.disableIgnoreModel()
            this.finishProcess()
            this.parentGrupoUsuarios.emit()
        })
    }

    createGrupoUsuarioUsuario(grupoUsuarioId, propietario) {
        let grupoUsuarioUsuario = new GrupoUsuarioUsuario()
        grupoUsuarioUsuario.propietario = propietario
        grupoUsuarioUsuario.grupo_usuario_id = grupoUsuarioId

        return grupoUsuarioUsuario
    }

    createGrupoUsuarioUsuarios() {
        let grupoUsuarioUsuarios = []
        let propietario = this.propietario ? 1 : 0
        if (this.grupoUsuario) {
            let grupoUsuarioUsuario = this.createGrupoUsuarioUsuario(this.grupoUsuario.id, propietario)

            grupoUsuarioUsuarios = [grupoUsuarioUsuario]
        } else if (this.grupoUsuarios) {
            let grupoUsuarios = this.grupoUsuariosTotales.filter(gu => this.selectedGrupoUsuarios[gu.id])
            grupoUsuarioUsuarios = grupoUsuarios.map(gu => this.createGrupoUsuarioUsuario(gu.id, propietario))
        }

        return grupoUsuarioUsuarios
    }

    updateGrupoUsuarioUsuarios() {
        let grupoUsuarioUsuarios = []
        let propietario = this.propietario ? 1 : 0
        let keys = Object.keys(this.selectedGrupoUsuarios).filter(i => this.selectedGrupoUsuarios[i] === true)
        let selectedIds = keys.map(str => parseInt(str))
        grupoUsuarioUsuarios = selectedIds.map(id => this.createGrupoUsuarioUsuario(id, propietario))
        return grupoUsuarioUsuarios
    }

    addUsuario(usuario) {
        delete usuario.usuario_chile
        delete usuario.usuario_colombia

        this.simpleModalService.cleanModal()
        this.simpleModalService.setModalHeader("Aviso")
        this.simpleModalService.setDisableBtnCancel(true)

        if ((!this.grupoUsuario && !this.grupoUsuarios) || !this.isModifiedSelectedGrupoUsuarios) {
            this.simpleModalService.setModalStringContent("No haz realizado ningún cambio")
            this.simpleModalService.showSimpleModal()
            return
        }

        let guus = this.updateGrupoUsuarioUsuarios()
        let ids = guus.map(guu => guu.grupo_usuario_id)
        if (guus.length == 0) {
            usuario.grupo_usuario_usuarios_delete_all = 1
        } else {
            usuario.grupo_usuario_usuarios = guus
            ;(usuario as any).grupo_usuario_usuarios_delete_rest = 1
        }
        usuario.grupo_usuarios = this.grupoUsuarios.filter(gu => ids.includes(gu.id))
        ;(usuario.fecha_inicial as any) = this.fechaInicial.toISOString()
        ;(usuario.fecha_final as any) = this.fechaFinal.toISOString()

        this.usuariosService.enableIgnoreModel()
        this.usuariosService.update(usuario.id, usuario).then((usuario: Usuario) => {
            this.usuariosService.disableIgnoreModel()
            this.parentGrupoUsuarios.emit()
            this.simpleModalService.setModalStringContent("Tus cambios se han realizado exitosamente")
            this.simpleModalService.showSimpleModal()
            this.isModifiedSelectedGrupoUsuarios = false
        })
    }

    finishProcess() {
        this.idValue = ""
        this.usuarioLoaded = false
        this.resetSelectedGrupoUsuarios()
        this.addUsuarioOpen = false
        if (this.idValueUsuario) {
            this.idValueUsuario.control.markAsPristine()
        }
        this.showModal()

        this.usuarioAsociadoEvent.emit({ addUsuarioOpen: this.addUsuarioOpen })
    }

    showModal() {
        this.simpleModalService.cleanModal()
        this.simpleModalService.setModalHeader("Aviso")
        this.simpleModalService.setModalStringContent("Grupos actualizados con éxito.")
        this.simpleModalService.showSimpleModal()
    }

    validaRut(rutCompleto) {
        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false
        var tmp = rutCompleto.split("-")
        var digv = tmp[1]
        var rut = tmp[0]
        if (digv == "K") digv = "k"
        return this.dv(rut) == digv
    }

    dv(T) {
        var M = 0,
            S = 1
        for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11
        return S ? S - 1 : "k"
    }

    resetSelectedGrupoUsuarios() {
        Object.keys(this.selectedGrupoUsuarios).map(id => (this.selectedGrupoUsuarios[id] = false))
    }

    updateGrupoUsuariosAsociaados() {
        if (!this.isModifiedSelectedGrupoUsuarios) this.isModifiedSelectedGrupoUsuarios = true
        this.grupoUsuariosAsociados = Object.keys(this.selectedGrupoUsuarios)
            .filter(id => this.selectedGrupoUsuarios[id])
            .map(i => +i)
    }
}
