import {
    AyudasAdminComponent,
    CategoriaAyudaNewComponent,
    CategoriaAyudaAdminComponent,
    CategoriaAyudaEditComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

const routes: Routes = [
    {
        path: "categoria_ayudas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: AyudasAdminComponent, canActivate: [AuthGuard, TokenGuard] }]
    },
    {
        path: "categoria_ayudas/new",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: CategoriaAyudaNewComponent, canActivate: [AuthGuard, TokenGuard] }]
    },
    {
        path: "categoria_ayudas/:id/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: CategoriaAyudaEditComponent, canActivate: [AuthGuard, TokenGuard] }]
    },
    {
        path: "categoria_ayudas/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: CategoriaAyudaAdminComponent, canActivate: [AuthGuard, TokenGuard] }]
    }
]

export const ayudasRouting = RouterModule.forChild(routes)
