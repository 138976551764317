import { TextView, BooleanView } from "@puntaje/shared/core"
import { EscalaGlobales } from "./escala_globales.service"

export class EscalaGlobalView {
    public static get viewParams() {
        return {
            id: {
                label: "ID",
                type: TextView,
                tableVisible: true,
                options: { class: EscalaGlobales, key: "id" }
            },
            nombre: {
                label: "Nombre Escala",
                type: TextView,
                tableVisible: true
            },
            tipo: {
                label: "Tipo Escala",
                type: TextView,
                tableVisible: true
            },
            activa: {
                label: "Activa",
                type: BooleanView,
                tableVisible: true
            },
            porcentaje_aprobacion: {
                label: "Porcentaje de Aprobación",
                type: TextView,
                tableVisible: false
            },
            nota_min: {
                label: "Nota Mínima",
                type: TextView,
                tableVisible: false
            },
            nota_max: {
                label: "Nota Máxima",
                type: TextView,
                tableVisible: false
            }
        }
    }
}
