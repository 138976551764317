import { PuntajeAchievementsModule } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { AdminLayoutsModule } from "../layouts/admin_layouts.module"

import { AchievementsActividadesModule } from "@puntaje/puntaje/new-modules/achievements"
import { LogrosModule } from "@puntaje/puntaje/new-modules/logros"
import { achievementsActividadesRouting } from "./actividades/admin-achievements-actividades.routing"
import { achievementsLogrosRouting } from "./logros/admin-achievements-logros.routing"

@NgModule({
    declarations: [],
    imports: [
        achievementsActividadesRouting,
        achievementsLogrosRouting,
        CommonModule,
        CommonsLayoutsModule,
        AdminLayoutsModule,
        AchievementsActividadesModule,
        LogrosModule,
        PuntajeAchievementsModule
    ]
})
export class AdminAchievementsModule {}
