import { Component, OnInit, Input, EventEmitter } from "@angular/core"
import { Clasificacion, Clasificaciones } from "@puntaje/nebulosa/api-services"
import { FiltroEstadisticasService } from "@puntaje/puntaje/new-modules/estadisticas"
import { Router } from "@angular/router"
import { AppConfig, AuthService, SessionService } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "datos-generales-evaluacion",
    templateUrl: "datos_generales_evaluacion.component.html",
    styleUrls: ["datos_generales_evaluacion.component.scss"]
})
export class DatosGeneralesEvaluacionComponent implements OnInit {
    @Input() evaluacion
    @Input() groupedBy
    groupedClasificaciones: Clasificacion
    nombreGrupo: string = "curso"
    currentDate: Date = new Date()
    currenStatus: string[] = ["Programado", "En curso", "Terminado"]
    currenStatusClass: string[] = ["pendiente", "encurso", "terminado"]
    currentStatusIndex: number = 0

    groups: string[]
    firstColumnIndexes: number[]
    secondColumnIndexes: number[]

    openModalEdit = new EventEmitter<any>()

    canEdit: boolean = true

    constructor(
        protected clasificacionesService: Clasificaciones,
        protected filtroEstadisticasService: FiltroEstadisticasService,
        protected router: Router,
        protected authService: AuthService,
        protected sessionService: SessionService
    ) {
        this.nombreGrupo = config.plataforma.grupoUsuarioAlias ? config.plataforma.grupoUsuarioAlias : "curso"
    }

    ngOnInit() {
        const utpByEstablecimientoId = this.authService.getEstablecimientos().reduce((acc, establecimientoId) => {
            acc[establecimientoId] = true

            return acc
        }, {})

        const usuarioId = this.authService.getUserData().id
        const asociacionIds = this.sessionService.getAsociaciones().map(asociacionId => +asociacionId)
        this.canEdit =
            this.evaluacion.evaluacion_usuarios[0].usuario_id == usuarioId ||
            (utpByEstablecimientoId[this.evaluacion.evaluacion_usuarios[0].grupo_usuario.establecimiento.id] &&
                (this.evaluacion.evaluacion_usuarios[0].usuario.asociacion_ids.length == 0 ||
                    this.evaluacion.evaluacion_usuarios[0].usuario.hasSomeAsociacion(asociacionIds)))

        this.checkDateInterval()
    }

    onVerEstadisticas(establecimientoId, grupoUsuarioId, evaluacionId, generadorInstrumentoId, asignaturaId) {
        this.filtroEstadisticasService.setFiltrosId(
            establecimientoId,
            grupoUsuarioId,
            null,
            evaluacionId,
            generadorInstrumentoId,
            this.evaluacion.evaluacion_tipo.evaluacion_tipo,
            asignaturaId
        )
        this.router.navigate(["/estadisticas"], {
            queryParams: {
                asignatura_id: asignaturaId,
                tipo_instrumento: this.evaluacion.evaluacion_tipo.evaluacion_tipo
            }
        })
    }

    checkDateInterval() {
        let fecha_inicio = new Date(this.evaluacion.fecha_inicio)
        let fecha_termino = new Date(this.evaluacion.fecha_termino)
        if (this.currentDate.getTime() < fecha_inicio.getTime()) {
            this.currentStatusIndex = 0
            return
        }

        if (
            fecha_inicio.getTime() <= this.currentDate.getTime() &&
            this.currentDate.getTime() <= fecha_termino.getTime()
        ) {
            this.currentStatusIndex = 1
            return
        }

        if (fecha_termino.getTime() < this.currentDate.getTime()) {
            this.currentStatusIndex = 2
            return
        }
    }

    edit() {
        this.openModalEdit.emit()
    }
}
