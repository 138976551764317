<loading-layout #loadingLayout>
    <div class="row">
        <div class="col-md-8 col-sm-12">
            <div *ngIf="form">
                <form novalidate [formGroup]="form">
                    <form-input
                        [form]="form"
                        [params]="params.nombre"
                        [(value)]="escalaGlobal['nombre']"
                        [key]="'nombre'"
                    ></form-input>
                    <form-input
                        [hidden]="evaluacionTipo"
                        [form]="form"
                        [params]="params.evaluacion_tipo_id"
                        [(value)]="escalaGlobal['evaluacion_tipo_id']"
                        [key]="'evaluacion_tipo_id'"
                    ></form-input>
                    <form-input
                        [form]="form"
                        [params]="params.tipo"
                        [value]="escalaGlobal['tipo']"
                        [key]="'tipo'"
                        (valueChange)="selectedTipo($event)"
                    ></form-input>

                    <form-input
                        [form]="form"
                        [params]="params.porcentaje_aprobacion"
                        [(value)]="escalaGlobal['porcentaje_aprobacion']"
                        [key]="'porcentaje_aprobacion'"
                    ></form-input>

                    <form-input
                        *ngIf="showNotaMinMAx"
                        [form]="form"
                        [params]="params.nota_min"
                        [(value)]="escalaGlobal['nota_min']"
                        [key]="'nota_min'"
                        (valueChange)="changeNotas()"
                    ></form-input>
                    <form-input
                        *ngIf="showNotaMinMAx"
                        [form]="form"
                        [params]="params.nota_max"
                        [(value)]="escalaGlobal['nota_max']"
                        [key]="'nota_max'"
                        (valueChange)="changeNotas()"
                    ></form-input>

                    <form-input
                        [form]="form"
                        [params]="params.activa"
                        [(value)]="escalaGlobal['activa']"
                        [key]="'activa'"
                    ></form-input>

                    <form-input
                        *ngIf="showRangoPorcentaje"
                        [form]="form"
                        [params]="params.porcentaje_logro"
                        [(value)]="escalaGlobal['porcentaje_logro']"
                        [key]="'porcentaje_logro'"
                        (valueChange)="selectedRango($event)"
                    ></form-input>

                    <button
                        class="agregarTramo"
                        *ngIf="showPersonalizado"
                        class="btn btn-default"
                        (click)="agregarTramo()"
                    >
                        Agregar Tramo +
                    </button>

                    <br />

                    <table *ngIf="escalaGlobalAliases.length > 0" class="table table-bordered table_equivalencias">
                        <tbody>
                            <tr>
                                <td class="equivalenciasHeader">% equiv.</td>
                                <td class="aliasHeader">Alias escala</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr
                                *ngFor="let escalaGlobalAlias of escalaGlobalAliases; let i = index"
                                class="tableData"
                                [class.isDestroy]="escalaGlobalAlias._destroy"
                            >
                                <td *ngIf="!showPersonalizado">
                                    {{ escalaGlobalAlias.equivalencia_min }}-{{ escalaGlobalAlias.equivalencia_max }} %
                                </td>
                                <td *ngIf="showPersonalizado">
                                    <div class="rango" *ngIf="showPersonalizado">
                                        <div class="itemDescripcion">{{ escalaGlobalAlias.equivalencia_min }} %</div>
                                        <div class="wrap" role="group" aria-labelledby="multi-lbl">
                                            <label class="sr-only" for="a">Value min:</label>
                                            <input
                                                class="colorMin"
                                                id="a"
                                                type="range"
                                                min="0"
                                                max="100"
                                                (change)="setRam($event, 'min', i)"
                                                [value]="escalaGlobalAliases[i].equivalencia_min"
                                            />
                                            <label class="sr-only" for="b">Value max:</label>
                                            <input
                                                class="colorMax"
                                                [disabled]="i == 0"
                                                id="b"
                                                type="range"
                                                min="0"
                                                max="100"
                                                (change)="setRam($event, 'max', i)"
                                                [value]="escalaGlobalAliases[i].equivalencia_max"
                                            />
                                        </div>

                                        <div class="itemDescripcion">{{ escalaGlobalAlias.equivalencia_max }} %</div>
                                    </div>
                                </td>
                                <td *ngIf="escalaGlobal.tipo == 'Continua'">{{ escalaGlobalAlias.alias }}</td>
                                <td *ngIf="escalaGlobal.tipo == 'Imagen'">
                                    <div class="contentImg">
                                        <div class="inputWrapper btn btn_style btn-default">
                                            <input
                                                type="file"
                                                class="fileInput"
                                                accept="image/*"
                                                (change)="onFileInputChange($event, i)"
                                            />
                                            <label for="myFile">Selecciona una imagen</label>
                                        </div>

                                        <img class="img" *ngIf="escalaGlobalAlias.img" [src]="escalaGlobalAlias.img" />
                                        <img
                                            class="img"
                                            *ngIf="escalaGlobalAlias.alias"
                                            [src]="escalaGlobalAlias.alias"
                                        />
                                    </div>
                                </td>
                                <td *ngIf="escalaGlobal.tipo == 'Discreta'">
                                    <input
                                        type="text"
                                        [value]="escalaGlobalAlias.alias"
                                        (change)="setAlias($event, i)"
                                    />
                                </td>
                                <td *ngIf="showPersonalizado">
                                    <div class="delete">
                                        <button class="btn-remove" (click)="removeItem(i)">×</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button (click)="save()" type="button" class="btn btn-default">{{ save_button_text }}</button>
                    <button (click)="clear()" *ngIf="escalaGlobalId" type="button" class="btn btn-default">
                        Deshacer
                    </button>
                </form>
            </div>
        </div>
    </div>
</loading-layout>
