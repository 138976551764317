import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core"
import { Router } from "@angular/router"
import { EscalaGlobales } from "@puntaje/puntaje/api-services"

@Component({
    selector: "escala-globales-edit",
    templateUrl: "./escala-globales-edit.component.html",
    styleUrls: ["./escala-globales-edit.component.scss"]
})
export class EscalaGlobalesEditComponent implements OnInit {
    @Input() escalaGlobalId
    @Input() fromProfesor
    @Input() evaluacionTipo: string
    @Output() saving = new EventEmitter()

    constructor(protected escalaGlobalServices: EscalaGlobales, protected router: Router) {}

    ngOnInit() {}

    save = escalaGlobal => {
        this.escalaGlobalServices.update(escalaGlobal.id, escalaGlobal).then(response => {
            this.goTo(response)
        })
    }

    goTo(response) {
        if (this.fromProfesor) {
            this.saving.emit(response)
        } else {
            this.router.navigate(["escala_globales"])
        }
    }
}
