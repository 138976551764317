import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { AchievementsAuthService } from "./achievements_auth.service"
import { AchievementsBaseService } from "./achievements_base.service"
import { Categoria } from "./categorias.model"

@Injectable({
    providedIn: "root"
})
export class Categorias extends AchievementsBaseService<Categoria> {
    tableName = "categorias"
    singularTableName = "categorias"
    modelClass = Categoria

    constructor(
        protected http: HttpClient,
        protected auth: AchievementsAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
