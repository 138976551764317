import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"

import { AchievementsActividadesModule } from "@puntaje/puntaje/new-modules/achievements"
import { LogrosModule } from "@puntaje/puntaje/new-modules/logros"
import { ActividadesComponent } from "./actividades/actividades/actividades.component"
import { ActividadEditComponent } from "./actividades/actividad-edit/actividad-edit.component"
import { LogrosComponent } from "./logros/logros/logros.component"
import { LogroEditComponent } from "./logros/logro-edit/logro-edit.component"
import { CUILayoutsModule } from "@puntaje/shared/cui"

@NgModule({
    declarations: [ActividadesComponent, ActividadEditComponent, LogrosComponent, LogroEditComponent],
    exports: [ActividadesComponent, ActividadEditComponent, LogrosComponent, LogroEditComponent],
    imports: [CommonModule, CommonsLayoutsModule, AchievementsActividadesModule, LogrosModule, CUILayoutsModule]
})
export class PuntajeAchievementsModule {}
