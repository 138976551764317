import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core"
import { LongWaitLoadingModalComponent } from "../long_wait_loading_modal.component"
import { PlanPersonales, PlanPersonal } from "@puntaje/puntaje/api-services"
import { SimpleModalService } from "@puntaje/shared/layouts"

@Component({
    selector: "btn-generar-reforzamientos",
    templateUrl: "./btn-generar-reforzamientos.component.html",
    styleUrls: ["./btn-generar-reforzamientos.component.scss"]
})
export class BtnGenerarReforzamientosComponent implements OnInit {
    message: string

    @Input() evaluacionId: number
    @Output() onSuccess = new EventEmitter()

    @ViewChild(LongWaitLoadingModalComponent)
    longWaitLoadingModal: LongWaitLoadingModalComponent

    constructor(protected planPersonalesService: PlanPersonales, protected simpleModalService: SimpleModalService) {}

    ngOnInit() {}

    enviarReforzamiento() {
        this.message = "Se está generando el reforzamiento de los alumnos. Por favor espere."
        this.longWaitLoadingModal.show()
        let params = {
            evaluacion_id: this.evaluacionId,
            sessions: 1
        }
        this.planPersonalesService.createReforzamientos(params).then((planPersonales: PlanPersonal[]) => {
            this.message = ""
            this.longWaitLoadingModal.hide()
            this.simpleModalService.cleanModal()
            this.simpleModalService.setModalHeader("Aviso")
            this.simpleModalService.setModalStringContent("El reforzamiento se ha creado correctamente.")
            this.simpleModalService.showSimpleModal()
            this.onSuccess.emit()
        })
    }
}
