import { Component, OnInit, Input, SimpleChanges, OnChanges } from "@angular/core"
import { Establecimientos, GrupoUsuario } from "@puntaje/puntaje/api-services"
import { AllReadyService } from "@puntaje/puntaje/new-modules/shared"

@Component({
    selector: "tabla-numero-estudiantes-por-curso",
    templateUrl: "./tabla-numero-estudiantes-por-curso.component.html",
    styleUrls: ["./tabla-numero-estudiantes-por-curso.component.scss"]
})
export class TablaNumeroEstudiantesPorCursoComponent implements OnInit, OnChanges {
    @Input() establecimientoId: number
    @Input() fechaInicial: Date
    @Input() fechaFinal: Date
    grupoUsuarios: GrupoUsuario[]
    totalAlumnos: number = 0

    constructor(protected establecimientosService: Establecimientos, protected allreadyservice: AllReadyService) {}

    ngOnInit() {
        this.setData()
    }

    setData() {
        let params = { fecha_inicial: this.fechaInicial, fecha_final: this.fechaFinal }
        this.establecimientosService
            .tabla_numero_alumnos_por_curso(this.establecimientoId, params)
            .then((grupoUsuarios: any[]) => {
                this.totalAlumnos = grupoUsuarios.reduce((acc, gu) => (acc += gu.numero_alumnos), 0)

                this.grupoUsuarios = grupoUsuarios.sort((a: GrupoUsuario, b: GrupoUsuario) =>
                    a.nombre > b.nombre ? 1 : -1
                )
                this.allreadyservice.updateCounterWithKey("informe-anual")
            })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            (changes["establecimientoId"] &&
                !changes["establecimientoId"].firstChange &&
                changes["establecimientoId"].currentValue != changes["establecimientoId"].previousValue) ||
            (changes["fechaInicial"] &&
                !changes["fechaInicial"].firstChange &&
                changes["fechaInicial"].currentValue != changes["fechaInicial"].previousValue) ||
            (changes["fechaFinal"] &&
                !changes["fechaFinal"].firstChange &&
                changes["fechaFinal"].currentValue != changes["fechaFinal"].previousValue)
        ) {
            this.setData()
        }
    }
}
