import { HomeComponent, TermsAndConditionsComponent, PlaceholderPageComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { LandingComponent } from "./landing.component"

export const LandingRoutes: Routes = [
    { path: "landing", component: LandingComponent },
    {
        path: "home",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomeComponent, canActivate: [AuthGuard] }]
    },
    { path: "landing/home", redirectTo: "home", pathMatch: "full" },
    {
        path: "condicionesUso",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: TermsAndConditionsComponent }]
    },
    { path: "pronto", component: LoggedLayoutComponent, children: [{ path: "", component: PlaceholderPageComponent }] }
]

export const LandingRouting = RouterModule.forChild(LandingRoutes)
