<div class="tbl">
    <div class="tbl-row">
        <div class="tbl-cell"><b>Nombre:</b></div>
        <div class="tbl-cell">
            <input
                [(ngModel)]="grupoUsuario.nombre"
                (ngModelChange)="updateNombre()"
                type="text"
                class="form-control"
                required
            />
            <div class="tbl-cell" *ngIf="nombreVacio">
                <p class="instrucciones">No ha señalado un nombre para el curso.</p>
            </div>
        </div>
    </div>
    <div class="tbl-row">
        <div class="tbl-cell"><b>Nivel</b></div>
        <div class="tbl-cell">
            <select class="form-control" [(ngModel)]="grupoUsuario.curso_id" (ngModelChange)="updateCursos()" required>
                <option selected [ngValue]="undefined">Seleccione nivel</option>
                <option *ngFor="let clasificacion of clasificaciones" [ngValue]="clasificacion.id">
                    {{ clasificacion.clasificacion }}
                </option>
            </select>
            <div class="tbl-cell" *ngIf="cursoNoSeleccionado">
                <p class="instrucciones">No ha elegido un nivel para el curso.</p>
            </div>
        </div>
    </div>
    <div class="tbl-row">
        <div class="tbl-cell"><b>Fecha de inicio:</b></div>
        <div class="tbl-cell">
            <my-date-picker
                [options]="{
                    dateFormat: 'dd/mm/yyyy',
                    editableDateField: false,
                    openSelectorOnInputClick: true
                }"
                [(ngModel)]="fechaInicial"
                [locale]="'es'"
            ></my-date-picker>
        </div>
    </div>
    <div class="tbl-row">
        <div class="tbl-cell"><b>Fecha de término:</b></div>
        <div class="tbl-cell">
            <my-date-picker
                [options]="{
                    dateFormat: 'dd/mm/yyyy',
                    editableDateField: false,
                    openSelectorOnInputClick: true
                }"
                [(ngModel)]="fechaFinal"
                [locale]="'es'"
            ></my-date-picker>
        </div>
    </div>
</div>
<div class="clearfix btn-container">
    <cui-button-link class="btn_style pull-right" (click)="onSave()">Guardar</cui-button-link>
    <cui-button-link buttonType="cancel" class="btn_style_2 pull-right small-margin-right" (click)="onCancel()">
        Cancelar
    </cui-button-link>
</div>
