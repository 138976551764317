import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, OnDestroy } from "@angular/core"
import { Post, PostView, Posts } from "@puntaje/puntaje/api-services"
import { PaginatorComponent, AuthService } from "@puntaje/shared/core"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { Router } from "@angular/router"
import { Subscription } from "rxjs"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { PostsBuscadorService } from "../posts-buscador/posts-buscador.service"

@Component({
    selector: "posts",
    templateUrl: "./posts.component.html",
    styleUrls: ["./posts.component.scss"]
})
export class PostsComponent implements OnInit, OnDestroy {
    posts: Post[] = []
    params = PostView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("tableLoading", { static: true }) tableLoading: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent
    postId: number
    sub: Subscription
    searchParams: any = { post: {} }

    constructor(
        protected postsService: Posts,
        protected postsBuscadorService: PostsBuscadorService,
        protected simpleModalService: SimpleModalService,
        protected router: Router,
        protected authService: AuthService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: {
                function: this.view.bind(this),
                text: "Ver",
                callModal: false,
                glyphicon: "glyphicon-search"
            },
            edit: {
                function: this.edit.bind(this),
                text: "Editar",
                glyphicon: "glyphicon-pencil"
            },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }

        this.sub = this.postsBuscadorService.searchParams.subscribe(params => {
            this.searchParams = params
            this.paginator.changePage(1)
        })

        this.showAll()
        this.loadingLayout.ready()
    }

    showAll = (page: number = 1, count: number = 20) => {
        this.tableLoading.standby()

        let params: any = {
            page: page,
            per: count,
            raw: 1,
            include: "[usuario]",
            sort_by: "id",
            order: "desc"
        }

        params = {
            ...params,
            post: {
                ...this.searchParams.post
            }
        }

        return this.postsService.where(params).then((posts: Post[], total: number) => {
            this.posts = posts
            this.tableLoading.ready()
            return total
        })
    }

    view(post: Post) {
        this.router.navigate(["posts/" + post.id])
    }

    edit(post: Post) {
        this.router.navigate(["posts/" + post.id + "/edit"])
    }

    delete(id: number) {
        this.loadingLayout.standby()
        this.postsService.remove(id).then(_ => {
            this.paginator.reload()
            this.loadingLayout.ready()
        })
    }

    showModalDelete(post: Post) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar este post?")
        this.simpleModalService.setModalCallback(() => this.delete(post.id))
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
