<div class="pregunta_ensayo" id="preg_{{ index + 1 }}" *ngIf="pregunta">
    <div class="pregunta_ensayo_contenido">
        <div class="">
            <pregunta-taxativo
                *ngIf="pregunta.taxativos.length > 0"
                [taxativo]="pregunta.taxativos[0]?.taxativo"
            ></pregunta-taxativo>

            <div class="alternativas">
                <ul class="alternativas-list">
                    <li
                        *ngFor="let alternativa of alternativas; let i = index"
                        [class.alternativa-correcta]="alternativa.correcta"
                    >
                        <div class="alternativa_container">
                            <div class="letra">{{ letras[i] }})</div>
                            <div
                                class="alternativa"
                                [innerHTML]="alternativa.alternativa | clearLatex | katex | trustedhtml"
                            ></div>
                        </div>
                        <div *ngIf="alternativa.correcta" class="alternativa-correcta-icon">
                            <ogr-icon name="ticket-circle"></ogr-icon>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
