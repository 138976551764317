<cui-section-headline>Estudiantes por curso</cui-section-headline>
<p class="tutorial">
    En este ítem se detallan los estudiantes vinculados al establecimiento y el estado en el que se encuentran según su
    inscripción en el sistema.
</p>
<table *ngIf="grupoUsuarios" class="table tabla-cursos">
    <thead class="thead-dark">
        <tr>
            <th scope="col">
                <ogr-icon name="comunidad-o"></ogr-icon>
                &nbsp;Curso
            </th>
            <th scope="col" class="centered">En nómina</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let grupo of grupoUsuarios">
            <td>{{ grupo.nombre }}</td>
            <td class="centered">{{ grupo.numero_alumnos }}</td>
        </tr>
        <tr>
            <td>TOTAL</td>
            <td class="centered">{{ totalAlumnos }}</td>
        </tr>
    </tbody>
</table>
