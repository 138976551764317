import { Component, EventEmitter, Input, OnInit, ViewChild } from "@angular/core"
import { I18nService, SessionService } from "@puntaje/shared/core"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Clasificaciones, Material, Materiales } from "@puntaje/nebulosa/api-services"
import { ModalDirective } from "ngx-bootstrap/modal"
import {
    Evaluacion,
    Evaluaciones,
    GrupoUsuarios,
    Instrumento,
    Instrumentos,
    EvaluacionForma
} from "@puntaje/puntaje/api-services"
import { Router } from "@angular/router"
import { AppEnv } from "@puntaje/shared/core"
import { ModalInstructivoSubirRespuestasComponent } from "@puntaje/puntaje/new-modules/shared"
import { OpcionesInstrumentoService } from "./opciones_instrumento.service"

declare const environment: AppEnv

@Component({
    selector: "opciones-instrumento",
    templateUrl: "opciones_instrumento.component.html",
    styleUrls: ["opciones_instrumento.component.scss"]
})
export class OpcionesInstrumentoComponent implements OnInit {
    @Input() tipoId: number
    @Input() instrumento: Instrumento
    @Input() instrumentos: Instrumento[]
    @Input() tipoEvaluacion: string
    @Input() evaluacionId: number
    @Input() redirect: string
    @Input() compartirTodosCursos: boolean
    @Input() evaluacionFormas: EvaluacionForma[]
    @Input() showButtonGuardar = true
    openModal: EventEmitter<any> = new EventEmitter<any>()
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent
    @ViewChild("mandatoryLoading", { static: true }) mandatoryLoading: ModalDirective
    canceled = false
    errorMsg = false
    pilotajesId = []
    material: Material
    hideImprimirEvaluacion: boolean = config.plataforma.hideImprimirEvaluacion
    showAsignarImpreso: boolean

    ultimaEvaluacionId: number
    ultimasEvaluacionesIds: number[]
    imprimirAlCompartir = false
    isAdmin = false
    historialQueryParams: any = {}
    @Input() enableAdminOptions = false
    @Input() urlHistorial: string[] = []
    showLinkAlumnoAlCompartir = false
    isPresencial = false
    hideGuardarSinConfigurar: boolean = config.plataforma.hideGuardarSinConfigurar
    environment = environment
    urlHojaRespuesta: string
    openInstructivoImprimirEvent: EventEmitter<any> = new EventEmitter<any>()

    constructor(
        protected evaluacionesService: Evaluaciones,
        protected grupoUsuariosService: GrupoUsuarios,
        protected clasificacionesService: Clasificaciones,
        protected instrumentosService: Instrumentos,
        protected sessionService: SessionService,
        protected router: Router,
        protected simpleModal: SimpleModalService,
        protected translatorService: I18nService,
        protected opcionesInstrumentoService: OpcionesInstrumentoService,
        protected materialesService: Materiales
    ) {
        this.showAsignarImpreso = this.instrumento && this.instrumento.propio ? true : !this.hideImprimirEvaluacion
    }

    ngOnInit() {
        this.ultimaEvaluacionId = this.opcionesInstrumentoService.getUltimaEvaluacionId(this.instrumento.id)

        if (this.instrumento.instrumento_material) {
            this.materialesService.find(this.instrumento.instrumento_material.material_id).then((m: Material) => {
                this.material = m
            })
        }

        this.evaluacionesService.urlHojaRespuesta().then((response: any) => {
            this.urlHojaRespuesta = response.url
        })
        this.showAsignarImpreso = this.instrumento.propio ? true : !this.hideImprimirEvaluacion
        this.showLinkAlumnoAlCompartir = config.plataforma.showLinkAlumnoAlCompartir
        this.showAsignarImpreso = this.instrumento.propio ? true : !this.hideImprimirEvaluacion

        if (
            !this.tipoEvaluacion &&
            (!this.instrumento.generador_instrumento || !this.instrumento.generador_instrumento.tipo_instrumento)
        ) {
            return
        }
        this.tipoEvaluacion =
            this.tipoEvaluacion || this.instrumento.generador_instrumento.tipo_instrumento.tipo_instrumento

        if (this.enableAdminOptions) {
            this.checkAdmin()
        }

        if (this.urlHistorial.length == 0) {
            if (config.app.name == "admin") {
                this.urlHistorial = ["/instrumentos"]
            } else {
                this.urlHistorial =
                    config.navegacion && config.navegacion.urlHistorial
                        ? config.navegacion.urlHistorial
                        : ["/evaluaciones"]
                this.historialQueryParams = {
                    tipo_instrumento: this.tipoEvaluacion,
                    asignatura_id: this.instrumento.asignatura_id
                }
            }
        }
    }

    onEvaluacionCompartida(e: Evaluacion) {
        this.evaluacionFormas = e.evaluacion_formas
        this.isPresencial = e.presencial
        this.ultimaEvaluacionId = e.id
        this.opcionesInstrumentoService.setUltimaEvaluacionId(e.id, this.instrumento.id)
        if (this.imprimirAlCompartir) {
            setTimeout(() => {
                this.imprimir("alumno")
            }, 2000)
        }
    }

    onEvaluacionesCompartidas(evaluaciones: Evaluacion[]) {
        this.ultimasEvaluacionesIds = evaluaciones.map(e => e.id)
        if (this.imprimirAlCompartir) {
            setTimeout(() => {
                this.imprimirTodos("alumno")
            }, 2000)
        }
    }

    imprimirTodos(perfil: string = null) {
        this.ultimasEvaluacionesIds.forEach(ultimaEvaluacionId => {
            this.ultimaEvaluacionId = ultimaEvaluacionId

            this.imprimir(perfil)
        })
    }

    imprimirFormas(perfil: string = null) {
        let time = 1500
        this.evaluacionFormas.forEach(evaluacionForma => {
            this.imprimir(perfil, evaluacionForma, (time = time))
            time += 1500
        })
    }

    imprimirHojasTodos() {
        this.ultimasEvaluacionesIds.forEach(ultimaEvaluacionId => {
            this.ultimaEvaluacionId = ultimaEvaluacionId

            this.imprimirHojas()
        })
    }

    imprimir(perfil: string = null, evaluacionForma: EvaluacionForma = null, time = 0) {
        this.openInstructivoImprimirEvent.emit(false)
        this.canceled = false
        this.errorMsg = false
        this.mandatoryLoading.show()
        let params: any = {}

        if (this.instrumento.generador_instrumento.maximo_alternativas) {
            params.cantidad_alternativas = this.instrumento.generador_instrumento.maximo_alternativas
        }
        params.plataforma = config.plataforma.name

        if (perfil == "profesor") {
            params.profesor = 1
            params.group_by =
                config.evaluaciones[
                    this.instrumento.generador_instrumento.tipo_instrumento.tipo_instrumento
                ].clasificaciones.groupBy
            if (!this.instrumento.propio)
                params.clasificacion_tipos_tabla_spec =
                    config.evaluaciones[
                        this.instrumento.generador_instrumento.tipo_instrumento.tipo_instrumento
                    ].clasificaciones.clasificacionTiposTablaEspecificaciones
        } else if (perfil == "alumno") {
            params.alumno = 1
        }

        if (evaluacionForma) {
            params.evaluacion_forma_id = evaluacionForma.id
        }

        const evaluacionId = this.evaluacionId || this.ultimaEvaluacionId

        this.evaluacionesService.enableIgnoreModel()
        this.evaluacionesService.enableIgnoreCatch()
        this.evaluacionesService
            .imprimir(evaluacionId, params)
            .then((obj: any) => {
                if (this.canceled) {
                    throw new Error("Cancelado")
                }
                this.evaluacionesService.disableIgnoreModel()
                const info = obj.info
                this.logInfoLatex(info.statusCode, info.exit_status, info.stdout, info.stderr)
                this.mandatoryLoading.hide()
                const evaluacionFormaStr = evaluacionForma ? "_" + evaluacionForma.forma : ""
                const downloadString = "evaluacion_" + evaluacionId + "_forma_" + evaluacionFormaStr + ".pdf"
                this.createLinkAndOpen(info.link, downloadString, (time = time))
            })
            .catch(response => {
                this.evaluacionesService.disableIgnoreModel()
                if (!this.canceled) {
                    this.errorMsg = true
                }
            })
    }

    logInfoLatex(statusCodeLambda, exitStatus, stdout, stderr) {
        console.log("STATUS CODE LAMBDA")
        console.log("%c " + statusCodeLambda, "color: blue")
        console.log("EXIT STATUS LATEX")
        console.log("%c " + exitStatus, "color: blue")
        console.log("SALIDA ESTANDAR LATEX")
        console.log("%c " + stdout, "color: blue")
        console.log("SALIDA DE ERRORES LATEX")
        console.log("%c " + stderr, "color: crimson")
    }

    createLinkAndOpen(link, downloadString, time = 0, target = undefined) {
        const a = document.createElement("a")
        a.download = downloadString
        a.href = link
        if (target) {
            a.target = "_blank"
        }
        setTimeout(() => {
            a.click()
        }, time)
    }

    imprimirHojas(d: boolean = false) {
        this.openInstructivoImprimirEvent.emit(false)
        this.canceled = false
        this.errorMsg = false
        this.mandatoryLoading.show()
        let params: any = {}
        params.plataforma = config.plataforma.name
        params["con_forma"] = d

        let evaluacionId = this.evaluacionId || this.ultimaEvaluacionId

        this.evaluacionesService.enableIgnoreModel()
        this.evaluacionesService.enableIgnoreCatch()
        this.evaluacionesService
            .imprimirHojas(evaluacionId, params)
            .then((obj: any) => {
                if (this.canceled) {
                    throw new Error("Cancelado")
                }
                this.evaluacionesService.disableIgnoreModel()
                const info = obj.info
                this.logInfoLatex(info.statusCode, info.exit_status, info.stdout, info.stderr)
                this.mandatoryLoading.hide()
                const downloadString = "hoja_respuestas_" + evaluacionId + ".pdf"
                this.createLinkAndOpen(info.link, downloadString)
            })
            .catch(response => {
                this.evaluacionesService.disableIgnoreModel()
                if (!this.canceled) {
                    this.errorMsg = true
                }
            })
    }

    cancelPrint() {
        this.evaluacionesService.disableIgnoreModel()
        this.mandatoryLoading.hide()
        this.canceled = true
    }

    fallbackPrint() {
        const evaluacionId = this.evaluacionId || this.ultimaEvaluacionId
        const originalTitle = document.title
        document.title = "evaluacion_" + evaluacionId + ".pdf"
        window.print()
        document.title = originalTitle
        this.mandatoryLoading.hide()
    }

    descargarTablaEspecificaciones() {
        let clasificaciones = []
        let tipoTabla = this.instrumento.generador_instrumento.tipo_instrumento.tipo_instrumento
        switch (config.plataforma.name) {
            case "Mineduc":
            case "Puntaje Nacional":
                switch (tipoTabla) {
                    case "ensayo":
                        clasificaciones = ["subeje tematico", "habilidad cognitiva"]
                        break
                    case "curricular":
                        if (this.instrumento.generador_instrumento.generador_instrumento.includes("ºM")) {
                            clasificaciones = ["aprendizaje esperado"]
                        } else {
                            clasificaciones = ["eje temático LGE", "habilidad cognitiva LGE"]
                        }
                        break
                    case "paes":
                        clasificaciones = ["Eje PAES", "Unidad Temática PAES", "Habilidad PAES"]
                        break
                }
                break
            case "Aprendolibre":
                switch (tipoTabla) {
                    case "curricular":
                        clasificaciones = ["eje temático LGE", "habilidad LGE"]
                        break
                }
                break
            case "Puntaje Colombia":
                clasificaciones = ["componente", "competencia"]
                break
            default:
                break
        }

        let par = {
            instrumento: {
                id: this.instrumento.id
            },
            clasificaciones: clasificaciones
        }

        this.instrumentosService.csvTablaEspecificaciones(this.instrumento.id, par).then((obj: any) => {
            const replacer = (key, value) => (value === null ? "" : value) // specify how you want to handle null values here

            const header = Object.keys(obj[0])
            let csv = obj.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(","))
            let csvArray = csv.join("\r\n")

            let a = document.createElement("a")
            var blob = new Blob([csvArray], { type: "text/csv" }),
                url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = "Tabla_Especificaciones_Instrumento_" + this.instrumento.id + ".csv"
            a.click()
            window.URL.revokeObjectURL(url)
            a.remove()
        })
    }

    checkAdmin() {
        let some_admin = this.sessionService.getRoles().find(r => {
            return r == "SuperAdministrador" || r == "Administrador" || r == "Monitor"
        })
        if (some_admin) {
            this.isAdmin = true
        }
    }

    copyToClipboard(element) {
        if (window.getSelection) {
            if (window.getSelection().empty) {
                // Chrome
                window.getSelection().empty()
            } else if (window.getSelection().removeAllRanges) {
                // Firefox
                window.getSelection().removeAllRanges()
            }
        } else if ((<any>document).selection) {
            // IE?
            ;(<any>document).selection.empty()
        }

        if ((<any>document).selection) {
            const range = (<any>document.body).createTextRange()
            range.moveToElementText(element)
            range.select().createTextRange()
            document.execCommand("copy")
        } else if (window.getSelection) {
            const range = document.createRange()
            range.selectNode(element)
            window.getSelection().addRange(range)
            document.execCommand("copy")
        }
    }

    listaPilotaje() {
        let listaPilotaje = this.instrumento.instrumento_preguntas.filter(ip => ip.piloto)
        return listaPilotaje
    }

    popUpPilotaje() {
        let pPilotaje = this.listaPilotaje()
        const headerSubject = this.translatorService.translate("evaluar.generar_instrumento.opciones.popup.titulo") //"¿Está seguro que desea proceder con esta acción?>>"
        let contentStringSubject
        this.pilotajesId = pPilotaje.map(ip => ip.orden + 1)

        if (pPilotaje.length == 0) {
            contentStringSubject = this.translatorService.translate(
                "evaluar.generar_instrumento.opciones.popup.contenido",
                {
                    num_preguntas: this.instrumento.instrumento_preguntas.length
                }
            )
        } else {
            contentStringSubject = this.translatorService.translate(
                "evaluar.generar_instrumento.opciones.popup.contenido",
                {
                    num_preguntas: this.instrumento.instrumento_preguntas.length,
                    num_pilotaje: pPilotaje.length,
                    id_pilotajes: this.pilotajesId
                }
            )
        }

        this.simpleModal.cleanModal()
        this.simpleModal.showSimpleModal()
        this.simpleModal.headerSubject.next(headerSubject)
        this.simpleModal.contentStringSubject.next(contentStringSubject)
        this.simpleModal.setModalCallback(() => this.openModal.emit())
    }

    downloadInstrumentoPropio() {
        const link = this.material.url
        const filename = this.material.material
        this.createLinkAndOpen(link, filename, 0, "_blank")
    }
}
