import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from "@angular/core"
import { Excels, Usuario } from "@puntaje/puntaje/api-services"
import { AppConfig, I18nService, RoundFloatPipe } from "@puntaje/shared/core"
declare const config: AppConfig
import {
    ClasificacionTipo,
    Clasificacion,
    ClasificacionTipoAliases,
    ClasificacionTipoAlias
} from "@puntaje/nebulosa/api-services"

@Component({
    selector: "tabla-desempeno-por-usuario-clasificacion",
    templateUrl: "tabla_desempeno_por_usuario_clasificacion.component.html",
    styleUrls: [
        "tabla_desempeno_por_usuario_clasificacion.component.scss",
        "estadisticas_tablas_graficos.component.scss"
    ]
})
export class TablaDesempenoPorUsuarioClasificacionComponent implements OnInit {
    @Input() usuarios: Usuario[]
    @Input() estadisticaUsuarios: any[]
    @Input() clasificacionTipo: ClasificacionTipo
    @Input() clasificacionTipoReferenced: ClasificacionTipo
    @Input() asignaturaId: number
    @Input() enablePaginator: boolean = false
    @Input() onPaginatorChange: (page: number, per: number) => Promise<number>
    clasificacion_tipo_aliases: ClasificacionTipoAlias[]
    alias: string
    inverted: boolean = true
    noData: boolean = false

    colorRanges = config.plataforma.rangoColoresTablaDesempenoUsuarioClasificacion ?? [
        { ranges: [0, 0.25], class: "wrong-0" },
        { ranges: [0.25, 0.4], class: "wrong-25" },
        { ranges: [0.4, 0.6], class: "wrong-50" },
        { ranges: [0.6, 0.75], class: "wrong-75" },
        { ranges: [0.75, 1], class: "wrong-100" }
    ]

    dataArr: any[] = []
    estadisticaClasificaciones: any[] = []
    estadisticaClasificacionesInverted: any
    qtyClasificacionesHijas: any = {}

    estadisticaClasificacionesGrouped: any[][] = []
    estadisticaClasificacionesInvertedGrouped: any

    _showAll: boolean = false
    hasEstadisticaClasificacionesInverted: any
    roundFloatPipe = new RoundFloatPipe()

    constructor(
        private clasificacionTipoAliasesService: ClasificacionTipoAliases,
        protected excelService: Excels,
        protected i18nService: I18nService
    ) {}

    async ngOnInit() {
        this.alias = this.clasificacionTipo.clasificacion_tipo

        this.setData()
        await this.setAlias()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["usuarios"] || changes["estadisticaUsuarios"]) {
            this.setData()
        }
    }

    setData() {
        this.estadisticaClasificaciones = []
        this.noData = false

        const maxClasificaciones = 9
        this.usuarios = this.usuarios.slice()

        if (this.estadisticaUsuarios.length > 0) {
            const estadisticaUsuariosHash = {}
            const dataInverted: any = {}
            const estadisticaUsuario = this.estadisticaUsuarios.find(eu =>
                eu.estadistica_clasificaciones?.find(
                    ec => ec.clasificacion.clasificacion_tipo_id == this.clasificacionTipo.id
                )
            )
            if (!estadisticaUsuario) {
                this.noData = true

                return
            }
            const estadisticaClasificaciones = estadisticaUsuario.estadistica_clasificaciones.filter(
                ec => ec.clasificacion.clasificacion_tipo_id == this.clasificacionTipo.id
            )

            if (estadisticaClasificaciones.length == 0) {
                this.noData = true

                return
            }

            this.qtyClasificacionesHijas = {}
            estadisticaClasificaciones.forEach(ec => {
                const data: any = { estadistica_usuario_clasificaciones: [] }

                if (this.clasificacionTipoReferenced && ec.clasificacion.clasificaciones) {
                    const clasificacionReferenced = ec.clasificacion.clasificaciones.find(
                        c => c.clasificacion_tipo_id == this.clasificacionTipoReferenced.id
                    )

                    data.clasificacion_referenced = clasificacionReferenced

                    this.qtyClasificacionesHijas[clasificacionReferenced.id] =
                        this.qtyClasificacionesHijas[clasificacionReferenced.id] || 0
                    this.qtyClasificacionesHijas[clasificacionReferenced.id] += 1
                }

                data.clasificacion = ec.clasificacion

                this.usuarios.forEach(u => {
                    const dataClasificacion: any = {}
                    dataClasificacion.usuario = u
                    dataClasificacion.clasificacion_referenced = data.clasificacion_referenced

                    estadisticaUsuariosHash[u.id] =
                        estadisticaUsuariosHash[u.id] || this.estadisticaUsuarios.filter(eu => eu.usuario_id == u.id)
                    const estadisticaUsuarios = estadisticaUsuariosHash[u.id]

                    estadisticaUsuarios.forEach(estadisticaUsuario => {
                        const estadisticaClasificacion = estadisticaUsuario.estadistica_clasificaciones.find(
                            ecl => ec.clasificacion.id == ecl.clasificacion.id
                        )

                        if (estadisticaClasificacion) {
                            if (dataClasificacion.estadistica_clasificacion) {
                                dataClasificacion.estadistica_clasificacion = {
                                    ...dataClasificacion.estadistica_clasificacion,
                                    correctas:
                                        dataClasificacion.estadistica_clasificacion.correctas +
                                        estadisticaClasificacion.correctas,
                                    incorrectas:
                                        dataClasificacion.estadistica_clasificacion.incorrectas +
                                        estadisticaClasificacion.incorrectas,
                                    omitidas:
                                        dataClasificacion.estadistica_clasificacion.omitidas +
                                        estadisticaClasificacion.omitidas,
                                    tiempo_total:
                                        dataClasificacion.estadistica_clasificacion.tiempo_total &&
                                        estadisticaClasificacion.tiempo_total
                                            ? dataClasificacion.estadistica_clasificacion.tiempo_total +
                                              estadisticaClasificacion.tiempo_total
                                            : dataClasificacion.estadistica_clasificacion.tiempo_total ??
                                              estadisticaClasificacion.tiempo_total,
                                    cantidad_respuestas_tiempo:
                                        dataClasificacion.estadistica_clasificacion.cantidad_respuestas_tiempo &&
                                        estadisticaClasificacion.cantidad_respuestas_tiempo
                                            ? dataClasificacion.estadistica_clasificacion.cantidad_respuestas_tiempo +
                                              estadisticaClasificacion.cantidad_respuestas_tiempo
                                            : dataClasificacion.estadistica_clasificacion.cantidad_respuestas_tiempo ??
                                              estadisticaClasificacion.cantidad_respuestas_tiempo
                                }
                            } else {
                                dataClasificacion.estadistica_clasificacion = estadisticaClasificacion
                            }

                            const { correctas, incorrectas, omitidas, tiempo_total, cantidad_respuestas_tiempo } =
                                dataClasificacion.estadistica_clasificacion

                            dataClasificacion.estadistica_clasificacion.desempeno =
                                (100 * correctas) / (correctas + incorrectas + omitidas)

                            if (tiempo_total != undefined && cantidad_respuestas_tiempo) {
                                dataClasificacion.estadistica_clasificacion.tiempo_promedio =
                                    tiempo_total / cantidad_respuestas_tiempo
                            }
                        }
                    })

                    data.estadistica_usuario_clasificaciones.push(dataClasificacion)

                    dataInverted[u.id] = dataInverted[u.id] || []
                    dataInverted[u.id].push(dataClasificacion)
                })

                this.estadisticaClasificaciones.push(data)
            })

            this.estadisticaClasificaciones = this.estadisticaClasificaciones.sort((ec1, ec2) => {
                if (!ec1.clasificacion_referenced || !ec2.clasificacion_referenced) {
                    return 0
                }

                return ec2.clasificacion_referenced.id - ec1.clasificacion_referenced.id
            })

            this.estadisticaClasificacionesGrouped = this.estadisticaClasificaciones.reduce((acc, ec, i) => {
                acc[~~(i / maxClasificaciones)] = acc[~~(i / maxClasificaciones)] || []

                acc[~~(i / maxClasificaciones)].push(ec)

                return acc
            }, [])

            this.estadisticaClasificacionesInverted = dataInverted
            this.hasEstadisticaClasificacionesInverted = {}
            Object.keys(this.estadisticaClasificacionesInverted).forEach(usuarioId => {
                this.hasEstadisticaClasificacionesInverted[usuarioId] = this.estadisticaClasificacionesInverted[
                    usuarioId
                ].reduce((acc, u) => acc || u.estadistica_clasificacion, false)
            })

            this.estadisticaClasificacionesInvertedGrouped = {}
            this.usuarios.forEach(u => {
                this.estadisticaClasificacionesInverted[u.id] = this.estadisticaClasificacionesInverted[u.id].sort(
                    (euc1, euc2) => {
                        if (!euc1.clasificacion_referenced || !euc2.clasificacion_referenced) {
                            return 0
                        }

                        return euc2.clasificacion_referenced.id - euc1.clasificacion_referenced.id
                    }
                )

                this.estadisticaClasificacionesInvertedGrouped[u.id] = this.estadisticaClasificacionesInverted[
                    u.id
                ].reduce((acc, euc, i) => {
                    acc[~~(i / maxClasificaciones)] = acc[~~(i / maxClasificaciones)] || []
                    acc[~~(i / maxClasificaciones)].push(euc)

                    return acc
                }, [])
            })
        }

        this.dataToExcel()
    }

    getDesempeno = (usuario: Usuario, clasificacionId: number) => {
        let euc = this.estadisticaClasificacionesInverted[usuario.id].find(
            eci => eci.estadistica_clasificacion && eci.estadistica_clasificacion.clasificacion.id == clasificacionId
        )

        if (!euc) return ""

        return euc.estadistica_clasificacion.desempeno
    }

    setColorClass(percentage) {
        if (percentage == null || percentage == undefined) return ""

        percentage = 1 - percentage / 100
        for (var i = 0; i < this.colorRanges.length; i++) {
            let c = this.colorRanges[i]
            if (c.ranges[0] <= percentage && percentage <= c.ranges[1]) {
                return c.class
            }
        }
        return ""
    }

    async setAlias() {
        const params = {
            clasificacion_tipo_alias: { clasificacion_tipo_id: this.clasificacionTipo.id },
            render_options: {
                include: {
                    asignatura_plataforma: {
                        include: {
                            asignatura: null,
                            plataforma: null
                        }
                    }
                }
            }
        }
        this.clasificacionTipoAliasesService.where(params).then((response: ClasificacionTipoAlias[]) => {
            this.clasificacion_tipo_aliases = response
            if (this.clasificacion_tipo_aliases.length > 0) {
                let ctas = this.clasificacion_tipo_aliases.filter(
                    cta =>
                        cta.asignatura_plataforma.plataforma_id == config.plataforma.id &&
                        cta.asignatura_plataforma.asignatura_id == this.asignaturaId
                )
                if (ctas.length > 0) {
                    this.alias = ctas[0].alias
                }
            }
        })
    }

    showAll() {
        this._showAll = !this._showAll
    }

    /**
     * Construye los datos para exportar a excel
     */
    dataToExcel() {
        const withClasificacionPadre = !!this.clasificacionTipoReferenced
        const dataExcel = []
        const options = {
            merge_range: [], // Rangos de celdas a combinar
            data_row_offset: withClasificacionPadre ? 3 : 2 // Cantidad de filas de encabezado
        }

        const rowClasificacionPadre = [""]
        const rowLabelsClasificacion = [""]
        const rowLabelsData = [this.i18nService.translate("estadisticas.graficos.tdpuc.estudiante", {})]

        const mergeRangeClasificacionesPadre = {}
        const index_row_clasificaciones = withClasificacionPadre ? 1 : 0

        this.estadisticaClasificaciones.forEach(estadisticaClasificacion => {
            const clasificacionPadre = estadisticaClasificacion.clasificacion_referenced
                ? estadisticaClasificacion.clasificacion_referenced
                : null
            const clasificacion = estadisticaClasificacion.clasificacion.clasificacion

            rowClasificacionPadre.push(clasificacionPadre ? clasificacionPadre.clasificacion : "", "")
            rowLabelsClasificacion.push(clasificacion, "")
            rowLabelsData.push("Desempeño", "Tiempo promedio")

            if (withClasificacionPadre && clasificacionPadre) {
                if (mergeRangeClasificacionesPadre[clasificacionPadre.id]) {
                    mergeRangeClasificacionesPadre[clasificacionPadre.id].last_col += 2
                } else {
                    mergeRangeClasificacionesPadre[clasificacionPadre.id] = {
                        first_row: 0,
                        last_row: 0,
                        first_col: rowClasificacionPadre.length - 2,
                        last_col: rowClasificacionPadre.length - 1
                    }
                }
            }

            options.merge_range.push({
                first_row: index_row_clasificaciones,
                last_row: index_row_clasificaciones,
                first_col: rowLabelsClasificacion.length - 2,
                last_col: rowLabelsClasificacion.length - 1
            })
        })

        if (withClasificacionPadre) {
            options.merge_range.push(...Object.values(mergeRangeClasificacionesPadre))
            dataExcel.push(rowClasificacionPadre)
        }

        dataExcel.push(rowLabelsClasificacion)
        dataExcel.push(rowLabelsData)

        this.usuarios.forEach(usuario => {
            const rowData = [usuario.nombreCompleto()]
            if (this.estadisticaClasificacionesInverted && this.estadisticaClasificacionesInverted[usuario.id]) {
                this.estadisticaClasificacionesInverted[usuario.id].forEach(estadisticaUsuarioClasificacion => {
                    const estadisticaClasificacion = estadisticaUsuarioClasificacion.estadistica_clasificacion
                    const desempeno = estadisticaClasificacion ? `${estadisticaClasificacion.desempeno}%` : ""

                    let tiempoPromedio = estadisticaClasificacion
                        ? this.roundFloatPipe.transform(estadisticaClasificacion.tiempo_promedio, 1)
                        : ""
                    tiempoPromedio = tiempoPromedio ? `${tiempoPromedio} seg` : ""

                    rowData.push(desempeno, tiempoPromedio)
                })
            }
            dataExcel.push(rowData)
        })

        return { dataExcel, options }
    }

    /**
     * Descarga el reporte en excel
     */
    getExcel() {
        this.excelService.enableIgnoreModel()

        const { dataExcel, options } = this.dataToExcel()

        const buff = Buffer.from(JSON.stringify(dataExcel))
        const prepareData = buff.toString("base64")

        const zone = Intl.DateTimeFormat().resolvedOptions().timeZone
        this.excelService.fromData({ data: prepareData, zone, ...options }).then(resp => {
            this.excelService.downdloadExcel(resp, "tabla_desempeno_por_estudiante.xlsx")
        })
    }
}
