import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core"
import { Router } from "@angular/router"
import { EscalaGlobales, Usuario } from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "escala-globales-new",
    templateUrl: "./escala-globales-new.component.html",
    styleUrls: ["./escala-globales-new.component.scss"]
})
export class EscalaGlobalesNewComponent implements OnInit {
    @Input() fromProfesor
    @Input() evaluacionTipo
    @Output() saving = new EventEmitter()
    usuario: Usuario

    constructor(
        protected escalaGlobalServices: EscalaGlobales,
        protected router: Router,
        protected authService: AuthService
    ) {}

    ngOnInit() {
        this.usuario = this.authService.getUserData()
    }

    save = escalaGlobal => {
        escalaGlobal.usuario_id = this.usuario.id
        if (this.fromProfesor) {
            escalaGlobal.profesor = true
            escalaGlobal.activa = true
        }
        this.escalaGlobalServices.save(escalaGlobal).then(response => {
            this.goTo(response)
        })
    }

    goTo(response) {
        if (this.fromProfesor) {
            this.saving.emit(response)
        } else {
            this.router.navigate(["escala_globales"])
        }
    }
}
