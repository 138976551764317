import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { Logro, Logros } from "@puntaje/achievements/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "logro-edit-view",
    templateUrl: "./logro-edit.component.html",
    styleUrls: ["./logro-edit.component.scss"]
})
export class LogroEditComponent implements OnInit {
    @Input() logroId: number
    logro: Logro

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(protected logrosService: Logros, protected router: Router) {}

    ngOnInit() {
        this.loadingLayout.standby()

        this.logrosService.find(this.logroId).then(logro => {
            this.logro = logro
            this.loadingLayout.ready()
        })
    }

    save(logro: Logro) {
        this.logrosService.update(this.logroId, logro).then(_ => {
            this.router.navigate(["achievements/logros"])
        })
    }
}
