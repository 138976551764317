import { EstadisticasDeclaradosComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"

export const estadisticasRoutes: Routes = [
    {
        path: "estadisticas/declarados",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstadisticasDeclaradosComponent, canActivate: [AuthGuard] }]
    }
]

export const estadisticasRouting = RouterModule.forChild(estadisticasRoutes)
