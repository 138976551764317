import { NgModule } from "@angular/core"
import { ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { UtilModule, FormModule, PaginatorModule, ModelViewModule } from "@puntaje/shared/core"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { ActividadesListComponent } from "./actividades-list/actividades-list.component"
import { ActividadEditComponent } from "./actividad-edit/actividad-edit.component"
import { ActividadFormComponent } from "./actividad-form/actividad-form.component"
import { ActividadesToolnavbarComponent } from "./actividades-toolnavbar/actividades-toolnavbar.component"
import { ActividadesSearchService } from "./actividades-search.service"

@NgModule({
    declarations: [
        ActividadesListComponent,
        ActividadesToolnavbarComponent,
        ActividadEditComponent,
        ActividadFormComponent
    ],
    imports: [
        CommonModule,
        CommonsLayoutsModule,
        UtilModule,
        FormModule,
        ReactiveFormsModule,
        PaginatorModule,
        ModelViewModule
    ],

    exports: [ActividadesListComponent, ActividadesToolnavbarComponent, ActividadEditComponent],
    providers: [ActividadesSearchService]
})
export class AchievementsActividadesModule {}
