import {
    Component,
    OnInit,
    Input,
    ViewChildren,
    QueryList,
    ChangeDetectorRef,
    SimpleChanges,
    OnChanges
} from "@angular/core"
import { Asignaturas, Asignatura, ClasificacionTipos, AsignaturaWithConfig } from "@puntaje/nebulosa/api-services"
import { Estadisticas, Establecimientos } from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { GraficosTipoClasificacionComponent } from "../graficos-tipo-clasificacion/graficos-tipo-clasificacion.component"
import { AllReadyService } from "@puntaje/puntaje/new-modules/shared"
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasByEvaluacionTipo } from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"

@Component({
    selector: "graficos-clasificacion-tipo-container",
    templateUrl: "./graficos-clasificacion-tipo-container.component.html",
    styleUrls: ["./graficos-clasificacion-tipo-container.component.scss"]
})
export class GraficosClasificacionTipoContainerComponent implements OnInit, OnChanges {
    estadisticas: any
    clasificacionTipos: any
    grupoUsuariosPorNivel: any
    generadorInstrumentoIds: any
    shouldRenderGraph: any
    niveles: any
    @Input() establecimientoId: number
    @Input() tipoEvaluacion: string
    @Input() fechaInicial: Date
    @Input() fechaFinal: Date
    @ViewChildren(GraficosTipoClasificacionComponent) gtc: QueryList<GraficosTipoClasificacionComponent>
    @Input() asignaturasByEvaluacionTipo: { [tipoEvaluacion: string]: AsignaturaWithConfig[] }

    constructor(
        protected establecimientosService: Establecimientos,
        protected asignaturaService: Asignaturas,
        protected estadisticasService: Estadisticas,
        protected clasificacionTiposService: ClasificacionTipos,
        protected cdr: ChangeDetectorRef,
        protected ars: AllReadyService,
        protected store: Store<State>
    ) {}

    ngOnInit() {
        this.setData()
    }

    setData() {
        this.asignaturaService
            .where({ with_generador_instrumentos: true, plataforma_id: config.plataforma.id })
            .then((asignaturas: Asignatura[]) => {
                this.generadorInstrumentoIds = {}
                let generadorInstrumentoIdsForParams = []
                asignaturas.forEach((asignatura: Asignatura) => {
                    this.generadorInstrumentoIds[+asignatura.id] =
                        asignatura.asignatura_plataformas[0].generador_instrumentos
                            .filter(gi => gi.tipo_instrumento.tipo_instrumento == this.tipoEvaluacion)
                            .map(gi => gi.id)
                    generadorInstrumentoIdsForParams = generadorInstrumentoIdsForParams.concat(
                        asignatura.asignatura_plataformas[0].generador_instrumentos
                            .filter(gi => gi.tipo_instrumento.tipo_instrumento == this.tipoEvaluacion)
                            .map(gi => gi.id)
                    )
                })

                const estadisticasParams = {
                    collection: "EstadisticaEvaluacion",
                    estadistica: {
                        evaluacion_tipo: this.tipoEvaluacion,
                        establecimiento_id: this.establecimientoId,
                        oficial: 1,
                        generador_instrumento_id: generadorInstrumentoIdsForParams,
                        grupo_usuarios_oficial: true,
                        fecha_inicial: this.fechaInicial,
                        fecha_final: this.fechaFinal
                    }
                }

                this.estadisticasService.wherePost(estadisticasParams).then(estadisticas => {
                    this.estadisticas = estadisticas
                    this.checkIfLoadFinished()
                })
            })

        const clasificacionTiposParams = {
            clasificacion_tipo: {
                clasificacion_tipo: this.clasificacionesTipos().map(ct => ct.nombre)
            }
        }
        this.tipoEvaluacion = this.tipoEvaluacion ? this.tipoEvaluacion : config.plataforma.evaluacionDefault

        this.clasificacionTiposService.where(clasificacionTiposParams).then(clasificacionTipos => {
            this.clasificacionTipos = clasificacionTipos
            this.checkIfLoadFinished()
        })

        // const niveles = config.evaluaciones[this.tipoEvaluacion].cursos.map(curso => curso.label)
        const clasificaciones =
            config.evaluaciones[this.tipoEvaluacion]?.informeFinal?.cursos ||
            config.evaluaciones[this.tipoEvaluacion].cursos
        this.niveles = clasificaciones.map(x => x.clasificacion)

        let establecimientosParams: any = {
            clasificacion: {
                clasificacion: this.niveles
            },
            fecha_inicial: this.fechaInicial,
            fecha_final: this.fechaFinal
        }
        const evaluacionTipoCurso =
            config.evaluaciones[this.tipoEvaluacion]?.cursoTipo ||
            config.plataforma.clasificacionTipoNivel ||
            Object.values(config.evaluaciones)[0]?.cursoTipo
        if (evaluacionTipoCurso) {
            establecimientosParams.clasificacion_tipo = {
                clasificacion_tipo: evaluacionTipoCurso
            }
        }

        this.establecimientosService
            .grupoUsuariosPorNivel(this.establecimientoId, establecimientosParams)
            .then(grupoUsuarios => {
                this.grupoUsuariosPorNivel = grupoUsuarios
                this.shouldRenderGraph = {}
                Object.keys(this.grupoUsuariosPorNivel).forEach(nivel => {
                    this.shouldRenderGraph[nivel] =
                        config.evaluaciones[this.tipoEvaluacion].nivelTipoInstrumentoInformeFinal.includes(nivel) ||
                        this.niveles.includes(nivel)
                })
                this.checkIfLoadFinished()
            })
    }

    clasificacionesTipos() {
        return config.evaluaciones[this.tipoEvaluacion].clasificacionTiposEstadisticas.filter(ct => ct.informeAnual)
    }

    checkIfLoadFinished() {
        if (
            this.estadisticas &&
            this.clasificacionTipos &&
            this.grupoUsuariosPorNivel &&
            this.generadorInstrumentoIds
        ) {
            this.cdr.detectChanges()
            this.ars.updateCounterWithKey("informe-anual")
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            (changes["establecimientoId"] &&
                !changes["establecimientoId"].firstChange &&
                changes["establecimientoId"].currentValue != changes["establecimientoId"].previousValue) ||
            (changes["fechaInicial"] &&
                !changes["fechaInicial"].firstChange &&
                changes["fechaInicial"].currentValue != changes["fechaInicial"].previousValue) ||
            (changes["fechaFinal"] &&
                !changes["fechaFinal"].firstChange &&
                changes["fechaFinal"].currentValue != changes["fechaFinal"].previousValue)
        ) {
            this.setData()
        }
    }
}
