import { Component, Input } from "@angular/core"
import { Pregunta, Preguntas, GrupoPregunta, Clasificacion, Alternativa } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "pregunta-informe-estadistica",
    templateUrl: "pregunta_informe_estadistica.component.html",
    styleUrls: ["pregunta_informe_estadistica.component.scss"]
})
export class PreguntaInformeEstadisticaComponent {
    @Input() pregunta: Pregunta
    @Input() index: number
    @Input() nAlternativas: number
    alternativas: Alternativa[]
    showSolution: boolean = false
    letras = "ABCDEF"
    constructor() {}

    ngOnInit() {
        let alternativas = this.pregunta.contestables[0].alternativas ?? []

        //ocultar alternativas extras
        this.nAlternativas ||= alternativas.length
        let extraAlternativas = alternativas.length - this.nAlternativas
        while (extraAlternativas > 0) {
            const alternativaAOcultar = alternativas
                .filter(a => a.orden_ocultar != null)
                .sort((a, b) => b.orden_ocultar - a.orden_ocultar)[0]
            alternativas = alternativas.filter(a => a.id != alternativaAOcultar.id)
            extraAlternativas--
        }

        this.alternativas = alternativas.sort((a, b) => a.orden - b.orden)
    }
}
