import { Component, OnInit } from "@angular/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "actividades",
    templateUrl: "./actividades.component.html",
    styleUrls: ["./actividades.component.scss"]
})
export class ActividadesComponent implements OnInit {
    constructor(protected titleService: TitleService) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.achievements.actividades.list")
    }
}
