import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "logro-edit",
    templateUrl: "./logro-edit.component.html",
    styleUrls: ["./logro-edit.component.scss"]
})
export class LogroEditComponent implements OnInit {
    logroID: number
    loggedLayoutTitle = "Editar logro"
    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.achievements.logros.edit")
        this.route.params.subscribe(params => {
            this.logroID = +params["id"]
        })
    }
}
