import { Component, OnInit, ViewChild, ChangeDetectorRef, Input } from "@angular/core"
import { FormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { CategoriaAyudaForm, CategoriaAyudas, CategoriaAyuda } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "categoria-ayudas-edit",
    templateUrl: "categoria-ayudas.form.component.html"
})
export class CategoriaAyudasEditComponent implements OnInit {
    @Input() categoriaAyudaId: number
    oCategoriaAyuda: CategoriaAyuda = new CategoriaAyuda()
    categoriaAyuda: CategoriaAyuda = new CategoriaAyuda()
    params = CategoriaAyudaForm.formParams
    form: FormGroup
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(
        protected router: Router,
        protected authService: AuthService,
        protected cdr: ChangeDetectorRef,
        protected categoriaAyudaService: CategoriaAyudas
    ) {}

    ngOnInit() {
        this.form = CategoriaAyudaForm.getForm(this.categoriaAyuda)
        this.categoriaAyudaService.find(this.categoriaAyudaId, {}).then((response: CategoriaAyuda) => {
            this.categoriaAyuda = response
            this.oCategoriaAyuda = response.clone()
            this.loadingLayout.ready()
            this.cdr.detectChanges()
        })
    }

    // con nueva tabla
    save() {
        CategoriaAyudaForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.doUpdate()
        }
    }

    doUpdate = () => {
        this.categoriaAyudaService.update(this.categoriaAyuda.id, this.categoriaAyuda).then(_ => {
            this.router.navigate(["categoria_ayudas"])
        })
    }

    clear() {
        setTimeout(() => {
            this.categoriaAyuda = this.oCategoriaAyuda.clone()
            CategoriaAyudaForm.markFormControlsAsPristine(this.form)
            CategoriaAyudaForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
