import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./categoria-ayuda-edit.component.html"
})
export class CategoriaAyudaEditComponent implements OnInit {
    id: number

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    //subscribe to url params
    ngOnInit() {
        this.titleService.setTitle("titles.core.ayudas_admin.categoria_ayudas.edit")
        this.route.params.subscribe(params => {
            this.id = params["id"]
        })
    }
}
