<ng-container *ngIf="planPersonal.fecha_inicial">
    <cui-section-headline>{{ 'estudiar.plan_de_clases.avance' | t }}</cui-section-headline>
    <div class="icon-wrap">
        <div class="contenido-barra">
            <div *ngIf="!hideBarraAvanceEstimado">
                <div class="content-label">
                    <label class="label-barra">{{ 'estudiar.plan_de_clases.avance_est' | t }}</label>
                    <label class="label-barra">{{ avanceEsperado }}/{{ totalProgress }}</label>
                </div>

                <div class="barra-wrap">
                    <div class="barra-avance" [class.fullwidth]="startAnimValues">
                        <div class="avance-esperado" [style.width]="avanceEsperadoPorcentual + '%'"></div>
                    </div>
                </div>
            </div>
            <div class="content-label">
                <label class="label-barra">{{ nombreSesiones | capitalize }} vistas</label>
                <label class="label-barra">{{ avance }}/{{ totalProgress }}</label>
            </div>
            <div class="barra-wrap">
                <div class="barra-avance" [class.fullwidth]="startAnimValues">
                    <div class="avance" [style.width]="avancePorcentual + '%'"></div>
                </div>
            </div>
            <div class="fechas">
                <p *ngIf="startDate">{{ startDate | date: "MMMM y" | capitalize }}</p>
                <p *ngIf="endDate">{{ endDate | date: "MMMM y" | capitalize }}</p>
            </div>
        </div>
    </div>
</ng-container>
