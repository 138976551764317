<div class="row">
    <div class="col-md-4 print-hide">
        <div class="filtro">
            <loading-layout #anualLoading>
                <h4>
                    <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
                    Filtrar datos
                </h4>
                <div class="bottom-space">
                    <label [attr.for]="establecimientos">Establecimiento:</label>
                    <select class="form-control push-down" id="establecimientos" [(ngModel)]="establecimiento">
                        <option *ngFor="let e of establecimientos" [ngValue]="e">{{ e.establecimiento }}</option>
                    </select>
                </div>
                <label [attr.for]="establecimientos">Periodo:</label>
                <div>
                    <label>Desde:</label>
                    <my-date-picker
                        [(ngModel)]="fechaInicial"
                        [options]="myDatePickerOptions"
                        class="bottom-space"
                    ></my-date-picker>
                </div>
                <div>
                    <label>Hasta:</label>
                    <my-date-picker
                        [(ngModel)]="fechaFinal"
                        [options]="myDatePickerOptions"
                        class="bottom-space"
                    ></my-date-picker>
                </div>
                <!-- <filtro-periodo (select)="onSelectPeriodo($event)"></filtro-periodo> -->
                <div class="clearfix text-right">
                    <cui-button class="btn-info btn_style" (click)="launchModal()" *ngIf="config.app.name === 'admin'">
                        Observaciones
                    </cui-button>
                    <cui-button class="btn-primary btn_style" id="generar-boton" (click)="showResumen()">
                        Generar
                    </cui-button>
                </div>
            </loading-layout>
        </div>
    </div>
    <div class="resumen-anual print-show-block" style="display: none" *ngIf="establecimiento">
        <div class="col-md-8">
            <div class="resumen" *ngIf="stats">
                <div class="clearfix logo-container">
                    <img class="logo" [src]="logoInforme" alt="logo del informe" />
                </div>
                <h2 class="text-center">Informe de Uso de {{ companyName }}</h2>
                <h4 class="text-center">
                    ({{ fechaInicial.jsdate | date: "dd/MM/yyyy" }} - {{ fechaFinal.jsdate | date: "dd/MM/yyyy" }})
                </h4>

                <h3 class="text-left">Datos del establecimiento</h3>
                <table class="table table-condensed">
                    <tr>
                        <td>Nombre Establecimiento</td>
                        <td>{{ establecimiento.establecimiento }}</td>
                    </tr>
                    <tr>
                        <td>Contacto Principal</td>
                        <td>{{ establecimiento.encargado_nombre | easyplaceholder: "-" }}</td>
                    </tr>
                    <tr>
                        <td *ngIf="onlyMiap; else otherTd">Monitor Coach</td>
                        <ng-template #otherTd>
                            <td>Ejecutivo a cargo</td>
                        </ng-template>
                        <td>
                            {{ (establecimiento.monitores[0] || {}).nombre | easyplaceholder: "-" }}
                            {{ (establecimiento.monitores[0] || {}).apellido_paterno }}
                        </td>
                    </tr>
                    <tr>
                        <td>{{ establecimiento.getEstablecimientoPais().getFieldName() }}</td>
                        <td id="establecimiento-chile-rbd">
                            {{ establecimiento.getEstablecimientoPais().getFieldValue() }}
                        </td>
                    </tr>
                </table>

                <h3 class="text-left">Registro de usuarios</h3>
                <table class="table table-condensed">
                    <tr>
                        <td>Número total alumnos (según nómina establecimiento)</td>
                        <td>{{ stats.numero_alumnos }}</td>
                    </tr>
                    <tr>
                        <td>Número de alumnos que ingresan a la plataforma</td>
                        <td>{{ stats.login_alumnos }}</td>
                    </tr>
                    <tr>
                        <td>% de ingreso alumnos</td>
                        <td>{{ stats.login_alumnos / stats.numero_alumnos | percent: "1.0-1" }}</td>
                    </tr>
                    <tr>
                        <td>Número total docentes (según nómina de establecimiento)</td>
                        <td>{{ stats.numero_docentes }}</td>
                    </tr>
                    <tr>
                        <td>Número profesores que ingresan a la plataforma</td>
                        <td>{{ stats.login_docentes }}</td>
                    </tr>
                    <tr>
                        <td>% de ingreso docentes</td>
                        <td>{{ stats.login_docentes / stats.numero_docentes | percent: "1.0-1" }}</td>
                    </tr>
                </table>

                <div class="page print-page-break-before">
                    <h3>Datos de uso Docente:</h3>
                    <p>
                        En esta tabla encontraremos el n° de instrumentos creados por los docentes del establecimiento,
                        diferenciado por:
                    </p>
                    <ul>
                        <li>Tipo de evaluación</li>
                        <li>Asignatura.</li>
                        <li>
                            Modalidad de aplicación:
                            <b>online</b>
                            (instrumentos para ser realizados en la plataforma),
                            <b>impresos</b>
                            (instrumentos para ser realizadas en aula con hoja de respuesta),
                            <b>sin configurar</b>
                            (instrumentos creados pero no compartidos).
                        </li>
                    </ul>

                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th></th>
                                <th *ngFor="let tipo_instrumento of stats.numero_instrumentos_por_tipo | keys">
                                    {{
                                        tipoInstrumentosDict[tipo_instrumento]
                                            | transformInstrumento: "pluralCompleto"
                                            | capitalize
                                    }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Tipo</td>
                                <td
                                    *ngFor="let tipo_instrumento of stats.numero_instrumentos_por_tipo | keys"
                                    class="table-without-padding"
                                >
                                    <table class="table table-borderless">
                                        <tr>
                                            <td
                                                *ngFor="let asignatura of asignaturasTablaResumida[tipo_instrumento]"
                                                class="cell-bordered cell-asignaturas"
                                            >
                                                {{ asignatura.abreviacion }}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td class="cell-padding">total</td>
                            </tr>
                            <tr>
                                <td class="cell-padding">Online</td>
                                <td
                                    *ngFor="let tipo_instrumento of stats.numero_instrumentos_por_tipo | keys"
                                    class="table-without-padding"
                                >
                                    <table class="table table-borderless">
                                        <tr>
                                            <td
                                                *ngFor="let asignatura of asignaturasTablaResumida[tipo_instrumento]"
                                                class="cell-bordered cell-asignaturas"
                                                id="instrumentos_{{ asignatura.abreviacion }}"
                                            >
                                                {{
                                                    stats.numero_instrumentos_por_tipo[tipo_instrumento].online[
                                                        asignatura.id
                                                    ] | easyplaceholder: "0"
                                                }}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td class="cell-total">{{ stats.instrumentos_por_modalidad.online }}</td>
                            </tr>
                            <tr>
                                <td class="cell-padding">Presencial</td>
                                <td
                                    *ngFor="let tipo_instrumento of stats.numero_instrumentos_por_tipo | keys"
                                    class="table-without-padding"
                                >
                                    <table class="table table-borderless">
                                        <tr>
                                            <td
                                                *ngFor="let asignatura of asignaturasTablaResumida[tipo_instrumento]"
                                                class="cell-bordered cell-asignaturas"
                                            >
                                                {{
                                                    stats.numero_instrumentos_por_tipo[tipo_instrumento].presencial[
                                                        asignatura.id
                                                    ] | easyplaceholder: "0"
                                                }}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td class="cell-total">{{ stats.instrumentos_por_modalidad.presencial }}</td>
                            </tr>
                            <tr>
                                <td>Sin configurar</td>
                                <td
                                    *ngFor="let tipo_instrumento of stats.numero_instrumentos_por_tipo | keys"
                                    class="table-without-padding"
                                >
                                    <table class="table table-borderless">
                                        <tr>
                                            <td
                                                *ngFor="let asignatura of asignaturasTablaResumida[tipo_instrumento]"
                                                class="cell-bordered cell-asignaturas"
                                            >
                                                {{
                                                    stats.numero_instrumentos_por_tipo[tipo_instrumento].no_compartidos[
                                                        asignatura.id
                                                    ] | easyplaceholder: "0"
                                                }}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td id="instrumentos_no_compartidos" class="cell-total">
                                    {{ stats.instrumentos_por_modalidad.no_compartidos }}
                                </td>
                            </tr>
                            <tr>
                                <td class="cell-padding">Total</td>
                                <td
                                    *ngFor="let tipo_instrumento of stats.numero_instrumentos_por_tipo | keys"
                                    class="table-without-padding"
                                >
                                    <table class="table table-borderless">
                                        <tr>
                                            <td
                                                *ngFor="let asignatura of asignaturasTablaResumida[tipo_instrumento]"
                                                class="cell-bordered cell-asignaturas"
                                            >
                                                {{
                                                    (stats.numero_instrumentos_por_tipo[tipo_instrumento].presencial[
                                                        asignatura.id
                                                    ] || 0) +
                                                        (stats.numero_instrumentos_por_tipo[tipo_instrumento].online[
                                                            asignatura.id
                                                        ] || 0) +
                                                        (stats.numero_instrumentos_por_tipo[tipo_instrumento]
                                                            .no_compartidos[asignatura.id] || 0)
                                                }}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td id="total_instrumentos" class="cell-total">
                                    {{ stats.total_instrumentos }}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <p class="table-padding-top">
                        En esta tabla encontraremos el ingreso en la sección biblioteca, por la visualización y/o
                        descarga de materiales:
                    </p>
                    <table class="table table-bordered table-width">
                        <thead>
                            <tr>
                                <th>N° docentes que usan biblioteca</th>
                                <th>N° de vistas del material</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td id="docentes_biblioteca">
                                    {{ stats.uso_biblioteca_docentes ? stats.uso_biblioteca_docentes.docentes : 0 }}
                                </td>
                                <td id="docentes_material">
                                    {{
                                        stats.uso_biblioteca_docentes
                                            ? stats.uso_biblioteca_docentes.vistas_material
                                            : 0
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="page print-page-break-before">
                    <h3>Monitoreo docente</h3>
                    <p>- Top 5 mayor cantidad de instrumentos creados</p>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Instrumentos Generados</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let docente of stats.mejores_cinco_docentes; let i = index"
                                id="mejor_docente_{{ i }}"
                            >
                                <td>
                                    {{ docente.nombre }} {{ docente.apellido_paterno }} {{ docente.apellido_materno }}
                                </td>
                                <td>{{ docente.total_instrumentos || 0 }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <p>- Top 5 mayor cantidad de ingresos en la plataforma</p>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th># Ingresos</th>
                                <th>Último login</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let docente of stats.docentes_mayores_ingresos">
                                <td>
                                    {{ docente.nombre }} {{ docente.apellido_paterno }} {{ docente.apellido_materno }}
                                </td>
                                <td>{{ docente.total_ingresos || 0 }}</td>
                                <td>{{ docente.ultimo_ingreso | date: "dd-MM-yyyy HH:mm" | easyplaceholder: "-" }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="page print-page-break-before">
                    <h3>Datos de uso Estudiante:</h3>
                    <p>
                        En esta tabla encontraremos el n° evaluaciones contestadas por los estudiantes del
                        establecimiento, diferenciado por:
                    </p>
                    <ul>
                        <li>Tipo de evaluación: Curricular</li>
                        <li>
                            Modalidad de aplicación:
                            <b>online</b>
                            (evaluaciones realizadas en la plataforma),
                            <b>impresos</b>
                            (evaluaciones corregidas con hoja de respuesta),
                        </li>
                        <li>
                            Importante considerar que estamos contabilizando las evaluaciones realizadas que envió el
                            docente y las creadas y contestadas por el estudiante de forma autónoma.
                        </li>
                    </ul>

                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th></th>
                                <th *ngFor="let tipo_instrumento of stats.numero_instrumentos_por_tipo | keys">
                                    {{
                                        tipoInstrumentosDict[tipo_instrumento]
                                            | transformInstrumento: "pluralCompleto"
                                            | capitalize
                                    }}
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Nivel</td>
                                <td
                                    *ngFor="let tipo_instrumento of stats.numero_instrumentos_por_tipo | keys"
                                    class="table-without-padding"
                                >
                                    <table class="table table-borderless">
                                        <tr>
                                            <td
                                                class="cell-bordered cell-nivel"
                                                *ngIf="autonomoTipoInstrumento[tipo_instrumento]"
                                            >
                                                Autónomo
                                            </td>
                                            <td class="cell-bordered cell-nivel">Online</td>
                                            <td class="cell-borderless cell-nivel">Impresa</td>
                                        </tr>
                                    </table>
                                </td>
                                <td>total</td>
                            </tr>
                            <tr *ngFor="let nivel of niveles; let i = index">
                                <td class="cell-padding">{{ nivel }}</td>
                                <td
                                    *ngFor="let tipo_instrumento of stats.numero_instrumentos_por_tipo | keys"
                                    class="table-without-padding"
                                >
                                    <table class="table table-borderless">
                                        <tr>
                                            <td
                                                *ngIf="autonomoTipoInstrumento[tipo_instrumento]"
                                                class="cell-bordered cell-nivel"
                                            >
                                                {{ stats.total[tipo_instrumento][nivel].autonomo }}
                                            </td>
                                            <td class="cell-bordered cell-nivel">
                                                {{ stats.total[tipo_instrumento][nivel].online }}
                                            </td>
                                            <td class="cell-borderless cell-nivel" id="total_presencial_nivel_{{ i }}">
                                                {{ stats.total[tipo_instrumento][nivel].presencial }}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td id="total_nivel_{{ i }}">
                                    {{ stats.total_por_nivel[nivel] }}
                                </td>
                            </tr>
                            <tr>
                                <td class="cell-padding">Total</td>
                                <td
                                    *ngFor="let tipo_instrumento of stats.numero_instrumentos_por_tipo | keys"
                                    class="table-without-padding"
                                >
                                    <table class="table table-borderless">
                                        <tr>
                                            <td
                                                *ngIf="autonomoTipoInstrumento[tipo_instrumento]"
                                                class="cell-bordered cell-nivel"
                                            >
                                                {{ stats.total_por_modalidad[tipo_instrumento].autonomo }}
                                            </td>
                                            <td class="cell-bordered cell-nivel">
                                                {{ stats.total_por_modalidad[tipo_instrumento].online }}
                                            </td>
                                            <td class="cell-borderless cell-nivel">
                                                {{ stats.total_por_modalidad[tipo_instrumento].presencial }}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td id="total_tabla_alumnos">{{ stats.total_por_modalidad.total }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <p class="table-padding-top">
                        En esta tabla encontraremos el ingreso en la sección biblioteca, por la visualización y/o
                        descarga de materiales:
                    </p>
                    <table class="table table-bordered table-width" id="biblioteca_alumnos">
                        <thead>
                            <tr>
                                <th id="biblioteca_alumnos_label">N° estudiantes que usan biblioteca</th>
                                <th>N° de vistas del material</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td id="alumnos_biblioteca">
                                    {{ stats.uso_biblioteca_alumnos ? stats.uso_biblioteca_alumnos.alumnos : 0 }}
                                </td>
                                <td id="alumnos_material">
                                    {{
                                        stats.uso_biblioteca_alumnos ? stats.uso_biblioteca_alumnos.vistas_material : 0
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div>
                    <h3 class="page print-page-break-before">Monitoreo estudiante</h3>
                    <p>- Top 10 mayor evaluaciones realizadas</p>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Número</th>
                                <th>Nombre Completo</th>
                                <th>Curso Estudiante</th>
                                <th>Último Login</th>
                                <th>Número de evaluaciones realizadas</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let alumno of stats.mejores_diez_alumnos; let i = index"
                                id="top_alumnos_{{ i }}"
                            >
                                <td>{{ i + 1 }}</td>
                                <td>{{ alumno.nombre }} {{ alumno.apellido_paterno }} {{ alumno.apellido_materno }}</td>
                                <td>{{ alumno.nombre_curso }}</td>
                                <td>{{ alumno.ultimo_login | date: "dd-MM-yyyy HH:mm" | easyplaceholder: "-" }}</td>
                                <td>{{ alumno.numero_evaluacion_instancias_usuario || 0 }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <h3 class="table-padding-top">Observaciones</h3>
                    <p>{{ observaciones | easyplaceholder: "-" }}</p>
                    <div class="text-center">
                        <p>
                            {{ establecimiento.monitores[0]?.nombre }}
                            {{ establecimiento.monitores[0]?.apellido_paterno }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<generic-modal [id]="'mymodal'" [buttonClicked]="myemitter">
    <modal-contenido>
        <div class="form-group">
            <label for="observaciiones">Observaciones</label>
            <textarea class="form-control" rows="5" name="observaciones" [(ngModel)]="observaciones"></textarea>
        </div>
    </modal-contenido>
    <modal-botones>
        <cui-button type="button" class="btn_style_2" data-dismiss="modal">Cerrar</cui-button>
    </modal-botones>
</generic-modal>
