import { Component, OnInit, OnDestroy, ViewChild, Input } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { CategoriaAyuda, CategoriaAyudaView, CategoriaAyudas } from "@puntaje/puntaje/api-services"
import { AppConfig, PaginatorComponent } from "@puntaje/shared/core"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { Subscription } from "rxjs"

declare const config: AppConfig

@Component({
    selector: "ayudas-admin",
    templateUrl: "ayudas-admin.component.html"
})
export class AyudasAdminComponent implements OnInit {
    private sub: Subscription
    params = CategoriaAyudaView.viewParams
    tableButtonsArray: any
    @Input() i18nKeyTitulo: string
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("tableLoading", { static: true }) tableLoading: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent
    categoriaAyudas: CategoriaAyuda[]

    constructor(
        public route: ActivatedRoute,
        public categoriaAyudasService: CategoriaAyudas,
        public router: Router,
        public simpleModalService: SimpleModalService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: {
                function: this.view.bind(this),
                text: "Ver",
                callModal: false,
                glyphicon: "glyphicon-search"
            },
            edit: {
                function: this.edit.bind(this),
                text: "Editar",
                glyphicon: "glyphicon-pencil"
            },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }

        this.showAll()
        this.loadingLayout.ready()
    }

    view(categoriaAyuda: CategoriaAyuda) {
        this.router.navigate(["categoria_ayudas/" + categoriaAyuda.id])
    }

    edit(categoriaAyuda: CategoriaAyuda) {
        this.router.navigate(["categoria_ayudas/" + categoriaAyuda.id + "/edit"])
    }

    delete(id: number) {
        this.loadingLayout.standby()
        this.categoriaAyudasService.remove(id).then(() => {
            this.paginator.reload()
            this.loadingLayout.ready()
        })
    }

    showModalDelete(categoriaAyuda: CategoriaAyuda) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar esta categoría?")
        this.simpleModalService.setModalCallback(() => this.delete(categoriaAyuda.id))
    }

    showAll = (page: number = 1, count: number = 20) => {
        this.tableLoading.standby()

        const params: any = {
            page: page,
            per: count,
            raw: 1,
            sort_by: "orden",
            order: "asc"
        }

        return this.categoriaAyudasService.where(params).then((categoriaAyudas: CategoriaAyuda[], total: number) => {
            this.categoriaAyudas = categoriaAyudas
            this.tableLoading.ready()
            return total
        })
    }
}
