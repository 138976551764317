import { Component, OnInit, OnDestroy } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "ayudas-admin.component.html"
})
export class AyudasAdminComponent implements OnInit, OnDestroy {
    private sub: Subscription
    i18nKeyTitulo: string
    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.ayudas_admin.list")
        this.sub = this.route.data.subscribe(data => {
            this.i18nKeyTitulo = data.i18nKeyTitulo ?? this.i18nKeyTitulo
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
