<div *ngIf="stats">
    <p class="tutorial">
        La siguiente tabla muestra a los 10 docentes que han compartido más evaluaciones en el establecimiento durante
        el año.
    </p>
    <table class="table print-page-break-avoid">
        <thead>
            <tr>
                <th></th>
                <th>Rut</th>
                <th>Apellidos</th>
                <th>Nombre</th>
                <th>N Evaluaciones total</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let docenteStat of stats; let i = index">
                <td>{{ i + 1 }}</td>
                <td>
                    {{ docenteStat.docente["usuario_" + pais] ? docenteStat.docente["usuario_" + pais][idPais] : "-" }}
                </td>
                <td>{{ docenteStat.docente.apellido_paterno }} {{ docenteStat.docente.apellido_materno }}</td>
                <td>{{ docenteStat.docente.nombre }}</td>
                <td>{{ docenteStat.n_ensayos }}</td>
            </tr>
        </tbody>
    </table>
</div>
