import {
    CuentasComponent,
    CuentasNewComponent,
    CuentasEditComponent,
    CuentasDeshabilitadasComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"

export const cuentasRoutes: Routes = [
    {
        path: "cuentas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: CuentasComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "cuentas/new",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: CuentasNewComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "cuentas/:id/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: CuentasEditComponent, canActivate: [AuthGuard] }]
    }
]

export const cuentasRouting = RouterModule.forChild(cuentasRoutes)
