import { BaseForm } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"
import { GeneradorInstrumento } from "@puntaje/nebulosa/api-services"

export class PlanPersonalForm extends BaseForm {
    public static get formParams() {
        let params: any = {
            id: { label: "Id", type: "hidden", visible: false },
            plan_personal: {
                label: "Nombre",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true },
            usuario_id: { label: "usuario", type: "hidden" },
            descripcion: { label: "Descripción", type: "textarea", visible: true, validations: [Validators.required] },
            fecha_inicial: {
                label: "Fecha Inicial",
                type: "datepicker",
                visible: true,
                disableTimepicker: true,
                asISOString: true
            },
            plan_personal_tipo_id: { label: "plan_personal_tipo_id", type: "hidden" },
            generador_instrumento_id: {
                label: "generador instrumento",
                type: "hidden",
                visible: false,
                validations: [Validators.required]
            },
            archivo_url: {
                label: "Archivo (pdf anual)",
                type: "text",
                visible: true
            },
            libro_url_profesor: {
                label: "Url Libro Profesor",
                type: "text",
                visible: true
            },
            libro_url_alumno: {
                label: "Url Libro Alumno",
                type: "text",
                visible: true
            }
        }

        return params
    }
}
