<loading-layout #loadingLayout>
    <posts-buscador></posts-buscador>
    <loading-layout #tableLoading>
        <table-with-actions
            *ngIf="posts"
            [contentArray]="posts"
            [showParams]="params"
            [tableButtonsArray]="tableButtonsArray"
        ></table-with-actions>
    </loading-layout>
    <paginator [getList]="showAll"></paginator>
</loading-layout>
