<loading-layout>
    <div *ngIf="!loadingLayout.loading">
        <div class="establecimiento-data container-fluid print-hide">
            <ng-container *ngFor="let key of params | keys">
                <model-simple-view
                    *ngIf="!params[key]['showHidden']"
                    [params]="params[key]"
                    [value]="categoriaAyuda"
                    [key]="key"
                ></model-simple-view>
            </ng-container>
        </div>

        <div class="recursos-container">
            <div class="row">
                <div class="col-md-6">
                    <div class="agregar-container">
                        <div class="form-group">
                            <label>Agregar ayuda (por ID)</label>
                            <input class="form-control" type="number" [(ngModel)]="ayudaId" />
                        </div>
                        <div *ngIf="mensajeError" class="alert alert-danger">
                            {{ mensajeError }}
                        </div>
                        <cui-button [disabled]="btnDisabled" class="btn_style" (click)="agregar()">
                            Agregar ayuda
                        </cui-button>
                    </div>

                    <h2 class="subtitle">Ayudas asociadas (puedes arrastrarlos para cambiar el orden)</h2>
                    <ng-template #itemTemplate let-item="item" let-index="index">
                        <div class="recurso">
                            <div class="recurso-text">
                                {{ item.value.orden }}. ID: {{ item.value.ayuda_id }} - "{{ item.value.ayuda.ayuda }}" -
                                <span
                                    [ngClass]="
                                        item.value.ayuda.target_app === 'alumnos' || 'Alumnos'
                                            ? 'alumnoClass'
                                            : 'profesorClass'
                                    "
                                >
                                    {{ item.value.ayuda.target_app }}
                                </span>
                            </div>

                            <a class="recurso-eliminar" href="javascript:void(0)" (click)="eliminar(item.value)">
                                <span class="glyphicon glyphicon-remove"></span>
                            </a>
                        </div>
                    </ng-template>
                    <div class="recursos-list">
                        <bs-sortable
                            [(ngModel)]="categoriaAyuda.ayuda_categoria_ayudas"
                            [itemTemplate]="itemTemplate"
                            (onChange)="setOrder($event)"
                        ></bs-sortable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</loading-layout>
