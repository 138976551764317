import { Component, OnInit, Input, EventEmitter, SimpleChanges, ChangeDetectorRef, ViewChild } from "@angular/core"
import {
    Evaluacion,
    Evaluaciones,
    GrupoUsuario,
    GrupoUsuarios,
    EvaluacionUsuario,
    Establecimiento
} from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { PaginatorComponent, GenericModalComponent } from "@puntaje/shared/core"

@Component({
    selector: "editar-evaluacion",
    templateUrl: "editar_evaluacion.component.html",
    styleUrls: ["editar_evaluacion.component.scss"]
})
export class EditarEvaluacionComponent implements OnInit {
    @Input() openModalEdit: EventEmitter<any>
    @Input() tipoEvaluacion: string
    @Input() evaluacion: Evaluacion
    @Input() paginator: PaginatorComponent
    @Input() establecimiento: Establecimiento

    @ViewChild(GenericModalComponent, { static: true }) genericModal: GenericModalComponent

    evaluacionCopy: Evaluacion

    gruposProfesor: GrupoUsuario[] = []
    grupoUsuarios: GrupoUsuario[] = []

    nombreGrupo: string = "curso"

    atLeastSameGrupos: boolean = true

    constructor(
        protected evaluacionesService: Evaluaciones,
        protected grupoUsuariosService: GrupoUsuarios,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.nombreGrupo = config.plataforma.grupoUsuarioAlias || "curso"
    }

    groupBy = (gu: GrupoUsuario) => gu.establecimiento.establecimiento

    ngOnChanges(changes: SimpleChanges) {
        if (changes["evaluacion"] && changes["evaluacion"].currentValue) {
            this.evaluacionCopy = this.evaluacion.clone()
            this.setGruposProfesor()
        }

        if (changes["establecimiento"]) {
            this.setGrupoUsuarios()
        }
    }

    setGrupoUsuarios() {
        let params: any = {
            establecimiento_id_not_nil: 1,
            propios: 1,
            activos: 1,
            utp_establecimiento: 1,
            utp_asociacion: 1,
            grupo_usuario: {
                privado: 1
            },
            grupo_usuario_usuario: {
                propietario: 1
            },
            establecimiento: {
                activo: 1
            },
            render_options: {
                only: ["id", "nombre", "establecimiento_id", "curso_id"],
                include: {
                    establecimiento: {
                        only: ["id", "establecimiento"]
                    }
                }
            }
        }

        if (this.establecimiento) {
            params.grupo_usuario = {
                establecimiento_id: this.establecimiento.id
            }
        }

        this.grupoUsuariosService.where(params).then((grupoUsuarios: GrupoUsuario[]) => {
            this.grupoUsuarios = grupoUsuarios

            if (this.evaluacionCopy) {
                this.setGruposProfesor()
            }
        })
    }

    setGruposProfesor() {
        this.gruposProfesor = this.grupoUsuarios.filter(gu =>
            this.evaluacionCopy.evaluacion_usuarios.find(
                eu => eu.receptor_type == "GrupoUsuario" && eu.receptor_id == gu.id
            )
        )
    }

    checkLessGrupos() {
        const evaluacionUsuariosGrupos = this.evaluacionCopy.evaluacion_usuarios.filter(
            eu => eu.receptor_type == "GrupoUsuario" && eu.receptor_id
        )

        const gruposOriginalesSelected = evaluacionUsuariosGrupos.filter(eu =>
            this.gruposProfesor.find(g => g.id == eu.receptor_id)
        )

        this.atLeastSameGrupos = evaluacionUsuariosGrupos.length == gruposOriginalesSelected.length
    }

    validateDateInterval() {
        if (this.evaluacionCopy.fecha_inicio && this.evaluacionCopy.fecha_termino) {
            const fechaInicio: Date = new Date(this.evaluacionCopy.fecha_inicio as string)
            const fechaTermino: Date = new Date(this.evaluacionCopy.fecha_termino as string)

            if ((fechaTermino as any).getTime() < (fechaInicio as any).getTime()) {
                return false
            }
        }

        return true
    }

    update() {
        if (this.validateDateInterval() && this.gruposProfesor.length > 0) {
            const evaluacion = new Evaluacion()
            evaluacion.fecha_inicio = this.evaluacionCopy.fecha_inicio
            evaluacion.fecha_termino = this.evaluacionCopy.fecha_termino
            evaluacion.fecha_mostrar_respuestas = this.evaluacionCopy.fecha_mostrar_respuestas
            evaluacion.evaluacion = this.evaluacionCopy.evaluacion

            let evaluacionUsuariosToAdd = this.gruposProfesor
                .filter(
                    gp =>
                        !this.evaluacionCopy.evaluacion_usuarios.find(
                            eu => eu.receptor_type == "GrupoUsuario" && eu.receptor_id == gp.id
                        )
                )
                .map(gu => {
                    let evaluacionUsuario = new EvaluacionUsuario()
                    evaluacionUsuario.receptor_type = "GrupoUsuario"
                    evaluacionUsuario.receptor_id = gu.id

                    return evaluacionUsuario
                })

            let evaluacionUsuariosToDelete = this.evaluacionCopy.evaluacion_usuarios
                .filter(
                    eu =>
                        eu.receptor_type == "GrupoUsuario" &&
                        eu.receptor_id &&
                        !this.gruposProfesor.find(gp => gp.id == eu.receptor_id)
                )
                .map(eu => {
                    let evaluacionUsuario = new EvaluacionUsuario()
                    evaluacionUsuario.id = eu.id
                    ;(evaluacionUsuario as any)._destroy = true

                    return evaluacionUsuario
                })

            evaluacion.evaluacion_usuarios = evaluacionUsuariosToAdd.concat(evaluacionUsuariosToDelete)

            this.evaluacionesService.update(this.evaluacionCopy.id, evaluacion).then((ev: Evaluacion) => {
                this.genericModal.close()
                Object.assign(this.evaluacion, {
                    fecha_inicio: ev.fecha_inicio,
                    fecha_termino: ev.fecha_termino,
                    fecha_mostrar_respuestas: ev.fecha_mostrar_respuestas,
                    evaluacion: ev.evaluacion
                })

                if (this.paginator) {
                    this.paginator.reload()
                }
            })
        }
    }
}
