<form class="row" *ngIf="open">
    <div class="col-md-6">
        <div class="form-group">
            <label>ID</label>
            <input type="text" [(ngModel)]="post.id" name="id" class="form-control" />
        </div>

        <div class="form-group">
            <label>Titulo</label>
            <input type="text" [(ngModel)]="post.titulo" name="titulo" class="form-control" />
        </div>

        <cui-button-link class="btn_style" (click)="search()">Buscar</cui-button-link>
    </div>
</form>
