import { Component, OnInit, Input } from "@angular/core"
import { Asignatura, ClasificacionTipos, Asignaturas, ClasificacionTipo } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Estadisticas, GrupoUsuario } from "@puntaje/puntaje/api-services"

@Component({
    selector: "graficos-tipo-clasificacion",
    templateUrl: "./graficos-tipo-clasificacion.component.html",
    styleUrls: ["./graficos-tipo-clasificacion.component.scss"]
})
export class GraficosTipoClasificacionComponent implements OnInit {
    @Input() asignatura: Asignatura
    @Input() clasificacionTipos: any
    @Input() estadisticas: any
    @Input() grupoUsuarios: GrupoUsuario[]
    @Input() generadorInstrumentoIds: number[]
    @Input() nivel: string
    @Input() tipoEvaluacion: string
    grupoUsuarioIds: number[]
    clasificaciones = {}
    correctas = {}
    totales = {}
    promediosGrupoUsuario = {}
    nivelTipoInstrumentoInformeFinal: any
    emptyData: boolean = false

    constructor() {}

    ngOnInit() {
        this.grupoUsuarioIds = this.grupoUsuarios.map(gu => +gu.id)
        this.setData()
    }

    setData() {
        this.emptyData = false
        let stats = {}
        this.clasificacionesTipos().forEach(clasificacion => (stats[clasificacion.nombre] = {}))

        let parcialGrupoUsuarios = {}
        parcialGrupoUsuarios = {}
        this.grupoUsuarios.forEach(
            grupoUsuario => (parcialGrupoUsuarios[grupoUsuario.id] = { calificacion_total: 0, total_evaluaciones: 0 })
        )

        this.clasificacionTipos.forEach(clasificacionTipo => {
            this.estadisticas.forEach(estadistica => {
                if (
                    this.grupoUsuarios.map(grupoUsuario => grupoUsuario.id).includes(estadistica.grupo_usuario_id) &&
                    this.generadorInstrumentoIds.includes(+estadistica.generador_instrumento_id) &&
                    estadistica.estadistica_clasificaciones
                ) {
                    parcialGrupoUsuarios[estadistica.grupo_usuario_id].total_evaluaciones +=
                        estadistica.numero_evaluaciones
                    parcialGrupoUsuarios[estadistica.grupo_usuario_id].calificacion_total +=
                        estadistica.numero_evaluaciones * estadistica.promedio

                    estadistica.estadistica_clasificaciones.forEach(estadisticaClasificacion => {
                        if (
                            clasificacionTipo.id == estadisticaClasificacion.clasificacion.clasificacion_tipo_id &&
                            this.asignatura.id == estadisticaClasificacion.clasificacion.asignatura_id
                        ) {
                            if (
                                Object.keys(stats[clasificacionTipo.clasificacion_tipo]).includes(
                                    estadisticaClasificacion.clasificacion.clasificacion
                                )
                            ) {
                                stats[clasificacionTipo.clasificacion_tipo][
                                    estadisticaClasificacion.clasificacion.clasificacion
                                ].correctas += estadisticaClasificacion.correctas
                                stats[clasificacionTipo.clasificacion_tipo][
                                    estadisticaClasificacion.clasificacion.clasificacion
                                ].total +=
                                    estadisticaClasificacion.correctas +
                                    estadisticaClasificacion.incorrectas +
                                    estadisticaClasificacion.omitidas
                            } else {
                                stats[clasificacionTipo.clasificacion_tipo][
                                    estadisticaClasificacion.clasificacion.clasificacion
                                ] = {
                                    correctas: estadisticaClasificacion.correctas,
                                    total:
                                        estadisticaClasificacion.correctas +
                                        estadisticaClasificacion.incorrectas +
                                        estadisticaClasificacion.omitidas
                                }
                            }
                        }
                    })
                }
            })
        })

        this.promediosGrupoUsuario = {}
        let dataNotEmpty = false
        Object.keys(parcialGrupoUsuarios).forEach(grupoUsuarioId => {
            let promedio =
                parcialGrupoUsuarios[+grupoUsuarioId].total_evaluaciones == 0
                    ? 0
                    : parcialGrupoUsuarios[+grupoUsuarioId].calificacion_total /
                      parcialGrupoUsuarios[+grupoUsuarioId].total_evaluaciones
            this.promediosGrupoUsuario[
                this.grupoUsuarios.find(grupoUsuario => grupoUsuario.id == +grupoUsuarioId).nombre
            ] = Math.round(promedio)
            dataNotEmpty = dataNotEmpty || promedio > 0
        })
        this.emptyData = !dataNotEmpty

        Object.keys(stats).forEach(clasificacionTipo => {
            this.totales[clasificacionTipo] = Object.keys(stats[clasificacionTipo]).map(
                clasificacion => stats[clasificacionTipo][clasificacion].total
            )
            this.correctas[clasificacionTipo] = Object.keys(stats[clasificacionTipo]).map(
                clasificacion => stats[clasificacionTipo][clasificacion].correctas
            )
            this.clasificaciones[clasificacionTipo] = Object.keys(stats[clasificacionTipo])
        })
    }

    clasificacionesTipos() {
        return config.evaluaciones[this.tipoEvaluacion].clasificacionTiposEstadisticas.filter(ct => ct.informeAnual)
    }

    mostrar(tipoClasificacion: string, nivel: string) {
        if (this.tipoEvaluacion == "curricular") {
            if (nivel == "3º Medio" || nivel == "4º Medio") {
                return tipoClasificacion == "aprendizaje esperado"
            } else {
                return tipoClasificacion == "eje temático LGE"
            }
        } else {
            return true
        }
    }
}
