import { Component, EventEmitter, Input, Output } from "@angular/core"
import { Clasificacion, Clasificaciones } from "@puntaje/nebulosa/api-services"
import { GrupoUsuario } from "@puntaje/puntaje/api-services"

@Component({
    selector: "grupo-usuario-form",
    templateUrl: "./grupo-usuario-form.component.html",
    styleUrls: ["./grupo-usuario-form.component.scss"]
})
export class GrupoUsuarioFormComponent {
    _grupoUsuario: GrupoUsuario = new GrupoUsuario()

    fechaInicial: any = {
        jsdate: new Date(new Date().getFullYear(), 0, 1)
    }
    fechaFinal: any = {
        jsdate: new Date(new Date().getFullYear(), 11, 31)
    }

    nombreVacio: boolean = false
    cursoNoSeleccionado: boolean = false

    @Output() save = new EventEmitter<GrupoUsuario>()
    @Output() cancel = new EventEmitter()
    @Input() clasificaciones: Clasificacion[]

    @Input()
    set grupoUsuario(value: GrupoUsuario) {
        this._grupoUsuario = Object.assign(new GrupoUsuario(), value)

        this.setValues()
    }

    get grupoUsuario(): GrupoUsuario {
        return this._grupoUsuario
    }

    setValues() {
        if (this.grupoUsuario.fecha_inicial) {
            this.fechaInicial = {
                jsdate: new Date(this.grupoUsuario.fecha_inicial)
            }
        } else {
            this.fechaInicial = {
                jsdate: new Date(new Date().getFullYear(), 0, 1)
            }
        }

        if (this.grupoUsuario.fecha_final) {
            this.fechaFinal = {
                jsdate: new Date(this.grupoUsuario.fecha_final)
            }
        } else {
            this.fechaFinal = {
                jsdate: new Date(new Date().getFullYear(), 11, 31)
            }
        }
    }

    updateNombre() {
        if (!this.grupoUsuario.nombre || this.grupoUsuario.nombre.length <= 0) this.nombreVacio = true
        else this.nombreVacio = false
    }

    updateCursos() {
        if (!this.grupoUsuario.curso_id) this.cursoNoSeleccionado = true
        else this.cursoNoSeleccionado = false
    }

    checkValidity() {
        if (!this.grupoUsuario.nombre || this.grupoUsuario.nombre.length <= 0) {
            this.nombreVacio = true
        } else {
            this.nombreVacio = false
        }

        if (!this.grupoUsuario.curso_id) {
            this.cursoNoSeleccionado = true
        } else {
            this.cursoNoSeleccionado = false
        }
    }

    onSave() {
        this.checkValidity()

        if (!this.nombreVacio && !this.cursoNoSeleccionado) {
            this.grupoUsuario.privado = true
            this.grupoUsuario.fecha_inicial = this.fechaInicial.jsdate
            this.grupoUsuario.fecha_final = this.fechaFinal.jsdate

            this.save.emit(this.grupoUsuario)
        }
    }

    onCancel() {
        this.cancel.emit()
    }
}
