import { DateView, TextView } from "@puntaje/shared/core"
import { Actividad } from "./actividades.model"

export class ActividadView {
    public static get viewParams() {
        return {
            id: {
                label: "ID",
                type: TextView,
                tableVisible: true,
                options: { class: Actividad, key: "id" }
            },
            actividad: {
                label: "Actividad",
                type: TextView,
                tableVisible: true
            },
            funcion: {
                label: "Funcion",
                type: TextView,
                tableVisible: false
            },
            experiencia: {
                label: "Experiencia",
                type: TextView,
                tableVisible: true
            },
            codigo: {
                label: "Código",
                type: TextView,
                tableVisible: true
            },
            plataforma_id: {
                label: "Plataforma",
                type: TextView,
                tableVisible: false
            },

            created_at: {
                label: "Fecha de creación",
                type: DateView,
                tableVisible: true
            },
            updated_at: {
                label: "Fecha de actualización",
                type: DateView,
                tableVisible: true
            }
        }
    }
}
