import {
    NoticiaAdminComponent,
    NoticiasEditComponent,
    NoticiasNewComponent,
    NoticiasComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"

export const routes: Routes = [
    {
        path: "noticias",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: NoticiasComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "noticias/new",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: NoticiasNewComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "noticias/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: NoticiaAdminComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "noticias/:id/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: NoticiasEditComponent, canActivate: [AuthGuard] }]
    }
]

export const routing = RouterModule.forChild(routes)
