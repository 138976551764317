<ng-container *ngIf="estadisticas && clasificacionTipos && grupoUsuariosPorNivel && generadorInstrumentoIds">
    <div *ngFor="let nivel of niveles" class="wrap">
        <ng-container *ngIf="shouldRenderGraph[nivel]">
            <cui-section-headline>{{ nivel }}</cui-section-headline>
            <p class="tutorial">
                A continuación, se presenta un resumen por área según logros de {{ nivel }} en el desarrollo de los Ejes
                temáticos.
            </p>
            <div
                *ngFor="let asignatura of asignaturasByEvaluacionTipo[tipoEvaluacion]"
                class="wrap print-page-break-avoid"
            >
                <h4>
                    <ogr-icon name="{{ asignatura.icono }}" align="top" class="icono"></ogr-icon>
                    {{ asignatura.asignatura }}
                </h4>
                <graficos-tipo-clasificacion
                    *ngIf="generadorInstrumentoIds[+asignatura.id]"
                    [tipoEvaluacion]="tipoEvaluacion"
                    [nivel]="nivel"
                    [grupoUsuarios]="grupoUsuariosPorNivel[nivel]"
                    [generadorInstrumentoIds]="generadorInstrumentoIds[+asignatura.id]"
                    [asignatura]="asignatura"
                    [estadisticas]="estadisticas"
                    [clasificacionTipos]="clasificacionTipos"
                ></graficos-tipo-clasificacion>
            </div>
        </ng-container>
    </div>
</ng-container>
