import { Component, OnInit, Input, ViewChild } from "@angular/core"
import {
    CategoriaAyudas,
    CategoriaAyuda,
    CategoriaAyudaView,
    AyudaCategoriaAyuda,
    AyudaCategoriaAyudas
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Ayuda, Ayudas, AyudaView } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "categoria-ayuda",
    templateUrl: "./categoria-ayuda.component.html",
    styleUrls: ["./categoria-ayuda.component.scss"]
})
export class CategoriaAyudaComponent implements OnInit {
    @Input() categoriaAyudaId: number

    ayudaId: number
    mensajeError: string
    categoriaAyuda: CategoriaAyuda

    // grupoRecursoTipo: any

    btnDisabled = false

    timer: any
    skipSetOrder = false

    params = CategoriaAyudaView.viewParams

    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        protected categoriaAyudasService: CategoriaAyudas,
        protected ayudaCategoriaAyudasService: AyudaCategoriaAyudas,
        protected ayudasService: Ayudas
    ) {}

    ngOnInit() {
        this.getCategoriaAyuda()
    }

    getCategoriaAyuda() {
        this.loadingLayout.standby()

        this.categoriaAyudasService.enableIgnoreModel()
        this.categoriaAyudasService
            .find(this.categoriaAyudaId, { include: "ayuda_categoria_ayudas" })
            .then(categoriaAyuda => {
                this.categoriaAyudasService.disableIgnoreModel()
                this.categoriaAyuda = categoriaAyuda
                this.ayudasService
                    .where({ ayuda: { id: categoriaAyuda.ayuda_categoria_ayudas.map(x => x.ayuda_id) } })
                    .then(ayudas => {
                        categoriaAyuda.ayuda_categoria_ayudas.forEach(x => {
                            const ayuda = ayudas.find(y => y.id == x.ayuda_id)
                            x.ayuda = ayuda
                        })
                        this.loadingLayout.ready()
                    })
            })
    }

    setOrder(list: any[]) {
        if (list.length == 0) return

        list.forEach((gr, index) => {
            gr.orden = index
        })

        if (this.skipSetOrder) {
            this.skipSetOrder = false

            return
        }

        if (this.timer) {
            clearTimeout(this.timer)
            this.timer = 0
        }

        this.timer = setTimeout(() => {
            this.saveOrder()
        }, 1000)
    }

    saveOrder() {
        const categoriaAyuda = new CategoriaAyuda()
        categoriaAyuda.id = this.categoriaAyudaId

        categoriaAyuda.ayuda_categoria_ayudas = this.categoriaAyuda.ayuda_categoria_ayudas.map(gr => {
            const ayudaCategoriaAyuda = new AyudaCategoriaAyuda()
            ayudaCategoriaAyuda.id = gr.id
            ayudaCategoriaAyuda.orden = gr.orden

            return ayudaCategoriaAyuda
        })

        this.categoriaAyudasService.update(categoriaAyuda.id, categoriaAyuda).then(_ => {})
    }

    eliminar(ayudaCategoriaAyuda) {
        this.ayudaCategoriaAyudasService.remove(ayudaCategoriaAyuda.id).then(() => {
            this.getCategoriaAyuda()
        })
    }

    agregar() {
        this.btnDisabled = true

        this.mensajeError = ""

        // const exists = this.categoriaAyuda.ayuda_categoria_ayudas.some(
        //     gr => gr.recurso_type == this.recursoTipo && gr.recurso_id == this.recursoId
        // )
        // if (exists) {
        //     this.mensajeError = "El Instrumento predefinido ya existe."
        //     this.btnDisabled = false

        //     return
        // }

        const ayudaCategoriaAyuda = new AyudaCategoriaAyuda()

        ayudaCategoriaAyuda.orden = this.categoriaAyuda.ayuda_categoria_ayudas.length
        ayudaCategoriaAyuda.categoria_ayuda_id = this.categoriaAyuda.id
        ayudaCategoriaAyuda.ayuda_id = this.ayudaId

        this.ayudaCategoriaAyudasService.enableIgnoreCatch()
        this.ayudaCategoriaAyudasService
            .save(ayudaCategoriaAyuda)
            .then(ayudaCategoriaAyuda => {
                this.ayudaCategoriaAyudasService.disableIgnoreCatch()
                this.getCategoriaAyuda()
                this.skipSetOrder = true

                this.btnDisabled = false
            })
            .catch(e => {
                this.mensajeError = "El Instrumento Predefinido no existe."
                this.btnDisabled = false
            })
    }
}
