import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Actividad, ActividadForm } from "@puntaje/achievements/api-services"

@Component({
    selector: "actividad-form",
    templateUrl: "./actividad-form.component.html",
    styleUrls: ["./actividad-form.component.scss"]
})
export class ActividadFormComponent implements OnInit {
    @Input() actividad: Actividad
    fromEdit = false
    oldActividad: Actividad
    params = ActividadForm.formParams
    form: UntypedFormGroup

    @Output() save = new EventEmitter<Actividad>()

    constructor() {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes["actividad"].currentValue != changes["actividad"].previousValue && this.actividad) {
            this.form = ActividadForm.getForm(this.actividad)
            this.oldActividad = this.actividad.clone()
            this.fromEdit = true
        }
    }

    onSave() {
        ActividadForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.save.emit(this.actividad)
        }
    }

    clear() {}
}
