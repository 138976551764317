import { Chart } from "./chart.interface"
import { BaseChart } from "./base_chart.model"
import { ChartType } from "./chart_type.enum"

export class StackedBarChart extends BaseChart {
    // Gráfico de barras horizontales en stack

    constructor(g: Chart) {
        super(g)
    }

    public setChartData() {
        //tipo
        this.tipo = ChartType.StackedBar
        this.chartData.chart = {
            type: "bar"
        }
        //opciones de ploteo
        this.chartData.plotOptions = {
            series: {
                stacking: "percent"
            }
        }
        // this.chartData.legend = {
        //     reversed: true
        // }
        //EjeX
        if (this.ejeX) {
            let height = this.rowHeight * this.ejeX.length > 400 ? this.rowHeight * this.ejeX.length : null
            if (height) {
                this.chartData.chart.height = height
            }

            this.chartData.xAxis = {
                categories: this.ejeX
            }
        }
        //EjeY
        if (this.ejeY) {
            this.chartData.yAxis = {
                reversedStacks: false,
                min: 0,
                title: {
                    text: this.ejeY
                }
            }
        }

        if (this.tooltip) {
            this.chartData.tooltip = {
                pointFormat: this.tooltip,
                shared: true
            }
        }
        //datos
        let data_array = []
        for (let d of this.data) {
            if (d.nombre && d.data) {
                let v = { name: d.nombre, data: d.data }
                data_array.push(v)
            }
        }
        this.chartData.series = data_array
    }
}
