<div class="row print-hide">
    <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="filtro">
            <h4>
                <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
                Filtrar datos
            </h4>
            <div class="bottom-space" *ngIf="enableEstablecimientoAutocomplete">
                <label>Establecimiento:</label>
                <autocomplete
                    class="autocomplete"
                    [(ngModel)]="establecimiento"
                    [options]="filteredEstablecimientos"
                    (filterChange)="getEstablecimientos($event)"
                    showTextFun="toStringWithIdPais"
                    [placeholder]="'Escribe y selecciona un establecimiento'"
                ></autocomplete>
            </div>
            <div class="bottom-space" *ngIf="enableEstablecimientoSelect">
                <label>Establecimiento:</label>
                <select class="form-control" [(ngModel)]="establecimiento">
                    <option *ngFor="let e of establecimientosUsuario" [ngValue]="e">{{ e.establecimiento }}</option>
                </select>
            </div>
            <div class="bottom-space">
                <label>Periodo:</label>
                <filtro-periodo class="filtro-periodo" (select)="onSelectPeriodo($event)"></filtro-periodo>
            </div>
            <div class="clearfix">
                <cui-button class="btn_style pull-right" (click)="verInforme()">
                    <fa name="file-o"></fa>
                    Ver informe
                </cui-button>
            </div>
        </div>
    </div>
</div>
<long-wait-loading-modal #loadingModal class="print-hide" (onCancel)="cancelled()"></long-wait-loading-modal>

<div *ngIf="showInforme" class="informe print-show-block">
    <div class="clearfix print-hide">
        <cui-button class="btn_style pull-right" (click)="print()">
            <fa name="print"></fa>
            Imprimir
        </cui-button>
    </div>
    <section class="portada print-page-break-after">
        <img class="logo" src="{{ config?.app.assets.logoMedium }}" alt="{{ config?.plataforma.info.companyName }}" />
        <h1>Informe de desempeño</h1>
        <h3>{{ selectedEstablecimiento.establecimiento }}</h3>
        <p class="subtitle">
            Periodo: del {{ selectedFechaInicial | date: "dd/MM/yyyy" }} al
            {{ selectedFechaFinal | date: "dd/MM/yyyy" }}
        </p>
        <br />
    </section>

    <section class="print-show-block introduccion print-page-break-after">
        <h2>Introducción</h2>
        <p>
            Este documento reúne la información expuesta según los logros obtenidos hasta el día
            {{ selectedFechaFinal | date: "dd/MM/yyyy" }}, por el establecimiento y sus estudiantes, y puede ser
            utilizado para análisis por su parte según corresponda.
        </p>
        <p>
            Todos los datos fueron recopilados por el sistema en el transcurso del uso del mismo por parte de los
            estudiantes. Debido a que es un sistema automatizado, no hay análisis de los resultados, siendo esta una
            labor fundamental que debe realizar el equipo docente para que esta información tenga la importancia que
            debe.
        </p>
        <p>
            Todos los datos recopilados fueron basados en el listado de estudiantes enviado por el establecimiento para
            el año {{ selectedFechaInicial.getFullYear() }}. Basándose en esa generación, se han calculado todos los
            datos que el presente informe entrega. Por lo anterior, es responsabilidad del establecimiento entregar el
            listado de estudiantes más actualizado que posea al equipo de {{ config.plataforma.info.companyName }}, de
            lo contrario, la información mostrada en este informe, no tendrá validez. De haber algún cambio en el
            listado de estudiantes, favor informar a su monitor dicha situación para que podamos resolverla a la
            brevedad.
        </p>
        <p>
            Este informe puede ser generado las veces que lo solicite usted como establecimiento sin compromiso alguno.
            Cualquier duda que tenga con respecto a algún dato u otra cosa, favor hágala saber a su monitor respectivo.
        </p>
        <br />
    </section>

    <section class="tabla-contenido print-page-break-after">
        <h2>Contenido</h2>
        <ol>
            <li>
                Información general estudiantes
                <ul>
                    <li>N° de estudiantes por curso</li>
                    <li *ngFor="let tipoInstrumento of tiposInstrumentoInformeUso">
                        Nº de estudiantes activos (con
                        {{ tipoInstrumentosDict[tipoInstrumento] | transformInstrumento: "pluralCompleto" | capitalize }}
                        oficiales del Establecimiento)
                    </li>
                </ul>
            </li>
            <li>Evaluaciones</li>
            <li *ngIf="mostrarTablaDesempeno">Tabla de desempeño por año</li>
            <li *ngFor="let tipoInstrumento of tiposInstrumentoInformeUso">
                Desempeño {{ tipoInstrumentosDict[tipoInstrumento] | transformInstrumento: "pluralCompleto" | capitalize }} por nivel
                <ul>
                    <li *ngFor="let nivel of niveles[tipoInstrumento]">
                        {{ nivel.clasificacion }}
                    </li>
                </ul>
            </li>
            <li>Top 10 de docentes</li>
        </ol>
    </section>

    <section class="print-page-break-after">
        <h2>
            <b>{{ calcularIndice(1) }}.</b>
            Información General Estudiantes
        </h2>
        <tabla-numero-estudiantes-por-curso
            [establecimientoId]="selectedEstablecimiento.id"
            [fechaInicial]="selectedFechaInicial"
            [fechaFinal]="selectedFechaFinal"
        ></tabla-numero-estudiantes-por-curso>
        <br />
        <numero-estudiantes-activos
            [establecimientoId]="selectedEstablecimiento.id"
            [fechaInicial]="selectedFechaInicial"
            [fechaFinal]="selectedFechaFinal"
            [tipoInstrumentosDict]="tipoInstrumentosDict"
        ></numero-estudiantes-activos>
        <br />
    </section>

    <section class="print-page-break-after">
        <h2>
            <b>{{ calcularIndice(2) }}.</b>
            Evaluaciones
        </h2>
        <tabla-numero-evaluaciones-realizadas
            [establecimientoId]="selectedEstablecimiento.id"
            [asignaturasByEvaluacionTipo]="asignaturasByEvaluacionTipo"
            [fechaInicial]="selectedFechaInicial"
            [fechaFinal]="selectedFechaFinal"
            [tipoInstrumentosDict]="tipoInstrumentosDict"
        ></tabla-numero-evaluaciones-realizadas>
        <br />
    </section>
    <section class="print-page-break-after" *ngIf="mostrarTablaDesempeno">
        <h2>
            <b>{{ calcularIndice(3) }}.</b>
            Tabla de desempeño por año
        </h2>
        <tabla-desempeno-estudiantes
            [year]="anioFecha"
            [establecimientoId]="selectedEstablecimiento.id"
            [fechaInicial]="selectedFechaInicial"
            [fechaFinal]="selectedFechaFinal"
            [tipoInstrumentosDict]="tipoInstrumentosDict"
        ></tabla-desempeno-estudiantes>
        <br />
    </section>

    <section *ngFor="let tipoInstrumento of tiposInstrumentoInformeUso; let i = index" class="print-page-break-after">
        <h2>
            <b>{{ calcularIndice(4) + i }}.</b>
            Desempeño {{ tipoInstrumentosDict[tipoInstrumento] | transformInstrumento: "pluralCompleto" | capitalize }} por nivel
        </h2>
        <graficos-clasificacion-tipo-container
            [tipoEvaluacion]="tipoInstrumento"
            [establecimientoId]="selectedEstablecimiento.id"
            [asignaturasByEvaluacionTipo]="asignaturasByEvaluacionTipo"
            [fechaInicial]="selectedFechaInicial"
            [fechaFinal]="selectedFechaFinal"
        ></graficos-clasificacion-tipo-container>
        <br />
    </section>

    <section class="print-page-break-after">
        <h2>
            <b>{{ calcularIndice(4) + tiposInstrumentoInformeUso.length }}.</b>
            Top 10 de docentes
        </h2>
        <top-docentes-anual [year]="anioFecha" [establecimientoId]="selectedEstablecimiento.id"></top-docentes-anual>
        <br />
    </section>

    <p>
        Este informe reúne la información expuesta según los logros obtenidos hasta el
        {{ selectedFechaFinal | date: "dd/MM/yyyy" }}, por el establecimiento y sus estudiantes, para el libre análisis
        por su parte según corresponda.
    </p>
</div>
