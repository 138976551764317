<div *ngIf="configuracionLoaded">
    <div *ngFor="let tipoInstrumento of tiposInstrumentoInformeUso">
        <cui-section-headline>
            {{ tipoInstrumentosDict[tipoInstrumento] | transformInstrumento: "pluralCompleto" | capitalize }}
            {{ "realizados" | genderize: tipoInstrumento }}
        </cui-section-headline>
        <p class="tutorial">
            Tabla resumen de los
            {{ tipoInstrumentosDict[tipoInstrumento] | transformInstrumento: "pluralCompleto" }}
            {{ "realizados" | genderize: tipoInstrumento }}
            por los docentes del establecimiento en el periodo seleccionado.
        </p>
        <table class="table print-page-break-avoid">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">{{ tipoInstrumentosDict[tipoInstrumento] | transformInstrumento: "pluralCompleto" | capitalize }}</th>
                    <th scope="col">Compartidos</th>
                    <th scope="col">No Compartidos</th>
                    <th scope="col">Presenciales</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let asignatura of asignaturasByEvaluacionTipo[tipoInstrumento]">
                    <td>
                        {{ asignatura.asignatura }}
                    </td>
                    <td>
                        {{
                            configuracion[tipoInstrumento]["dataAsignaturas"][asignatura.id][
                                "compartidas_no_presencial"
                            ]
                        }}
                    </td>
                    <td>
                        {{ configuracion[tipoInstrumento]["dataAsignaturas"][asignatura.id]["no_compartidas"] }}
                    </td>
                    <td>
                        {{ configuracion[tipoInstrumento]["dataAsignaturas"][asignatura.id]["compartidas_presencial"] }}
                    </td>
                </tr>
                <tr>
                    <td><b>TOTAL</b></td>
                    <td>{{ configuracion[tipoInstrumento]["dataTotal"]["compartidas_no_presencial"] }}</td>
                    <td>{{ configuracion[tipoInstrumento]["dataTotal"]["no_compartidas"] }}</td>
                    <td>{{ configuracion[tipoInstrumento]["dataTotal"]["compartidas_presencial"] }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
