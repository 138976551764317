<table class="table tabla-promedios" *ngIf="!emptyData">
    <thead>
        <tr>
            <th>Nivel</th>
            <th class="centered">Calificación Promedio</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let curso of promediosGrupoUsuario | keys">
            <td>{{ curso }}</td>
            <td class="centered">{{ promediosGrupoUsuario[curso] }}</td>
        </tr>
    </tbody>
</table>
<div *ngFor="let tipoClasificacion of clasificaciones | keys">
    <ng-container *ngIf="mostrar(tipoClasificacion, nivel) && totales[tipoClasificacion] != 0">
        <grafico-desempeno-clasificacion
            class="print-page-break-avoid"
            [tipoClasificacion]="tipoClasificacion"
            [asignatura]="asignatura"
            [clasificaciones]="clasificaciones[tipoClasificacion]"
            [data]="correctas[tipoClasificacion]"
            [dataTotal]="totales[tipoClasificacion]"
        ></grafico-desempeno-clasificacion>
    </ng-container>
</div>
<ng-container *ngIf="emptyData">
    <p class="not-found">Sin datos disponibles para esta categoría.</p>
</ng-container>
