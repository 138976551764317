import { PostAdminComponent, PostsAdminComponent, PostsEditComponent, PostsNewComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"

export const bannersRoutes: Routes = [
    {
        path: "posts",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PostsAdminComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "posts/new",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PostsNewComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "posts/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PostAdminComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "posts/:id/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PostsEditComponent, canActivate: [AuthGuard] }]
    }
]

export const adminBlogRouting = RouterModule.forChild(bannersRoutes)
