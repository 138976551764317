<generic-modal #genericModal [buttonClicked]="openModalEdit" [id]="'editar-evaluacion'">
    <modal-titulo>{{ 'evaluacion.editar.editar' | t }} {{ tipoEvaluacion | transformInstrumento: "singular" }}</modal-titulo>
    <modal-contenido class="modal-contenido">
        <ng-container *ngIf="evaluacionCopy">
            <div class="row">
                <div class="col-sm-12">
                    <h4 class="h4_sub_title_1 no-bottom-margin">{{ 'evaluacion.editar.nombre' | t }}</h4>
                    <div>
                        <input
                            type="text"
                            [(ngModel)]="evaluacionCopy.evaluacion"
                            name="nombre"
                            placeholder="{{ 'evaluacion.editar.nombre' | t }} {{ tipoEvaluacion | transformInstrumento: 'singular' }}"
                            class="form-control"
                            required
                        />
                    </div>
                </div>
            </div>

            <ng-template [ngIf]="!evaluacionCopy.presencial">
                <h4 class="h4_sub_title_1 no-bottom-margin">
                    {{ 'evaluacion.editar.alumnos_can' | t }} {{ tipoEvaluacion | transformInstrumento: "conDeterminante" }}:
                </h4>
                <div class="row">
                    <div class="col-sm-6">
                        <p>{{ 'evaluacion.editar.desde' | t }}:</p>
                        <datepicker-form
                            #fechaInicio="ngModel"
                            name="fechaInicio"
                            [(ngModel)]="evaluacionCopy.fecha_inicio"
                            required
                        ></datepicker-form>
                        <div
                            *ngIf="fechaInicio.errors && (fechaInicio.dirty || fechaInicio.touched)"
                            class="error-warning"
                        >
                            <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                            {{ tipoEvaluacion | transformInstrumento: "conArticulo" | capitalize }} {{ 'evaluacion.editar.desde_warn' | t }}.
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <p>{{ 'evaluacion.editar.hasta' | t }}:</p>
                        <datepicker-form
                            #fechaTermino="ngModel"
                            name="fechaTermino"
                            [(ngModel)]="evaluacionCopy.fecha_termino"
                            required
                        ></datepicker-form>
                        <div
                            *ngIf="fechaTermino.errors && (fechaTermino.dirty || fechaTermino.touched)"
                            class="error-warning"
                        >
                            <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                            {{ tipoEvaluacion | transformInstrumento: "conArticulo" | capitalize }} {{ 'evaluacion.editar.hasta_warn' | t }}.
                        </div>
                    </div>
                </div>
                <div *ngIf="!validateDateInterval()" class="error-warning">
                    <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                    {{ 'evaluacion.editar.fechas_warn' | t }}.
                </div>
                <h4 class="h4_sub_title_1">
                    {{ 'evaluacion.editar.desde_ver1' | t }}
                    {{ tipoEvaluacion | transformInstrumento: "conDeterminante" }} {{ 'evaluacion.editar.desde_ver2' | t }}:
                </h4>
                <div class="row">
                    <div class="col-sm-6">
                        <datepicker-form
                            #fechaResultados="ngModel"
                            name="fechaResultados"
                            [(ngModel)]="evaluacionCopy.fecha_mostrar_respuestas"
                            required
                        ></datepicker-form>
                    </div>
                </div>

                <div
                    *ngIf="fechaResultados.errors && (fechaResultados.dirty || fechaResultados.touched)"
                    class="error-warning"
                >
                    <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                    {{ tipoEvaluacion | transformInstrumento: "conArticulo" | capitalize }} {{ 'evaluacion.editar.desde_ver_warn' | t }}.
                </div>
            </ng-template>

            <ng-template [ngIf]="grupoUsuarios && grupoUsuarios.length > 0">
                <h4 class="h4_sub_title_1">
                    {{ nombreGrupo | pluralize | capitalize }} {{ 'evaluacion.editar.grupos_ver' | t }}
                    {{ tipoEvaluacion | transformInstrumento: "conArticulo" }}:
                </h4>
                <ng-template [ngIf]="grupoUsuarios">
                    <checkboxes
                        #grupos="ngModel"
                        [options]="grupoUsuarios"
                        [(ngModel)]="gruposProfesor"
                        (ngModelChange)="checkLessGrupos()"
                        [groupBy]="groupBy"
                        name="grupos"
                    ></checkboxes>
                    <div *ngIf="grupos.dirty && gruposProfesor.length <= 0" class="error-warning">
                        <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                        {{ 'evaluacion.editar.grupos_ver_warn' | t }} {{ nombreGrupo }}
                    </div>
                    <div *ngIf="grupos.dirty && !atLeastSameGrupos" class="error-warning">
                        <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                        {{ 'evaluacion.editar.grupos_ver_warn2_1' | t }} {{ nombreGrupo }}, {{ 'evaluacion.editar.grupos_ver_warn2_2' | t }}.
                    </div>
                </ng-template>
            </ng-template>

            <ng-template [ngIf]="grupoUsuarios && grupoUsuarios.length == 0">
                {{ 'evaluacion.editar.sin_grupos1' | t }} {{ nombreGrupo | pluralize }} {{ 'evaluacion.editar.sin_grupos2' | t }}.
            </ng-template>
        </ng-container>
    </modal-contenido>
    <modal-botones>
        <cui-button class="btn_style_2" (click)="genericModal.close()">{{ 'evaluacion.editar.cancelar' | t }}</cui-button>
        <cui-button class="btn_style" (click)="update()">{{ 'evaluacion.editar.editar' | t }}</cui-button>
    </modal-botones>
</generic-modal>
