import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from "@angular/core"
import { Subject } from "rxjs"

@Component({
    selector: "sorter",
    templateUrl: "sorter.component.html",
    styleUrls: ["sorter.component.scss"]
})
export class SorterComponent {
    constructor(protected cdr: ChangeDetectorRef) {}
    @Input() objects: any[]
    @Output() objectsChange: EventEmitter<any[]> = new EventEmitter<any[]>()

    @Input() key: any

    @Input() objectValue = (o, key) => {
        let val = o[key]

        if (!val) val = ""

        return val
    }

    @Input() getObjects: any

    sortSubject: Subject<any> = new Subject<any>()

    order: number

    sort() {
        if (this.order) this.order = -this.order
        if (!this.order) this.order = 1

        if (!this.getObjects) {
            this.objects = this.objects.sort((o1, o2) => {
                let oto1 = this.objectValue(o1, this.key)
                let oto2 = this.objectValue(o2, this.key)

                if (oto1 == "" && oto2 == "") {
                    return 0
                } else if (oto1 == "") {
                    return -1 * this.order
                } else if (oto2 == "") {
                    return 1 * this.order
                } else {
                    if (oto1 < oto2) {
                        return -1 * this.order
                    } else if (oto2 < oto1) {
                        return 1 * this.order
                    } else {
                        return 0
                    }
                }
            })
            this.cdr.detectChanges()
            this.objectsChange.emit(this.objects)
            this.sortSubject.next(this.order)
        } else {
            this.getObjects(1, 10, this.order, this.key).then(objects => {
                this.objects = objects
                this.cdr.detectChanges()
                this.objectsChange.emit(this.objects)
                this.sortSubject.next(this.order)
            })
        }
    }

    resetOrder() {
        this.order = null
    }
}
