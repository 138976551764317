<div class="row">
    <div class="col-md-6">
        <div class="col-container">
            <div class="sub-title">
                <cui-button-link
                    buttonType="confirm"
                    class="btn_style_3 option-btn pull-right add-btn only-icon"
                    title="Asociar docente"
                    toggleExpandable="addDocenteToGrupoUsuarios"
                    [openOnlyExpandable]="true"
                >
                    <fa name="user-plus"></fa>
                </cui-button-link>
                <cui-button-link
                    buttonType="confirm"
                    class="btn_style_3 option-btn pull-right add-btn only-icon"
                    title="Crear {{ config.plataforma.grupoUsuarioAlias }}"
                    toggleExpandable="addGrupoUsuario"
                    [openOnlyExpandable]="true"
                    #buttonGrupoUsuarioManager
                >
                    <ogr-icon name="comunidad-plus"></ogr-icon>
                </cui-button-link>
                <div class="periodo">
                    <filtro-periodo (select)="onSelectPeriodo($event)"></filtro-periodo>
                </div>
                <cui-section-headline>
                    {{ config.plataforma.grupoUsuarioAlias | pluralize | capitalize }} asociados
                </cui-section-headline>
            </div>
            <div id="addGrupoUsuario" class="new" expandableContent>
                <!-- Agregar grupo -->
                <div class="step">
                    <grupo-usuario-form
                        [grupoUsuario]="newGrupoUsuario"
                        [clasificaciones]="clasificaciones"
                        (save)="createGrupoUsuario($event)"
                        (cancel)="cancelCrearGrupoUsuario()"
                    ></grupo-usuario-form>
                </div>
            </div>
            <asociar-usuario-masivo
                *ngIf="grupoUsuarios"
                [toggleId]="'addDocenteToGrupoUsuarios'"
                [propietario]="true"
                [grupoUsuarios]="grupoUsuarios"
                [fechaInicial]="fechaInicialPeriodo"
                [fechaFinal]="fechaFinalPeriodo"
                (updateGrupoUsuarios)="actualizarGrupoUsuarios()"
            ></asociar-usuario-masivo>
            <div class="tbl grupo-usuarios-tbl">
                <!-- lista de grupos -->
                <div class="tbl-row" *ngFor="let grupo_usuario of grupoUsuarios">
                    <div class="tbl-cell">
                        <ogr-icon name="comunidad"></ogr-icon>
                        &nbsp;{{ grupo_usuario.nombre }}
                    </div>
                    <div class="tbl-cell">
                        <div class="perfil-container">
                            <a (click)="verDocentes(grupo_usuario)" class="link-n-docentes">
                                {{ grupo_usuario.numero_propietarios }} docentes
                            </a>
                            <a
                                *ngIf="showVaciar"
                                class="grupo-vaciar-docentes"
                                (click)="vaciarGrupoUsuario(grupo_usuario, 1)"
                            >
                                <fa name="graduation-cap"></fa>
                                VACIAR
                            </a>
                        </div>
                    </div>
                    <div class="tbl-cell">
                        <div class="perfil-container">
                            <a (click)="verEstudiantes(grupo_usuario)" class="link-n-estudiantes">
                                {{ grupo_usuario.numero_alumnos }} estudiantes
                            </a>
                            <a
                                *ngIf="showVaciar"
                                class="grupo-vaciar-estudiantes"
                                (click)="vaciarGrupoUsuario(grupo_usuario, 0)"
                            >
                                <fa name="user"></fa>
                                VACIAR
                            </a>
                        </div>
                    </div>
                    <div class="tbl-cell clearfix">
                        <cui-button-link
                            class="btn_style option-btn pull-right only-icon"
                            (click)="editarGrupoUsuario(grupo_usuario)"
                        >
                            <fa name="pencil"></fa>
                        </cui-button-link>
                        <cui-button-link
                            class="btn_style option-btn pull-right only-icon"
                            (click)="deleteGrupoUsuario(grupo_usuario)"
                        >
                            <fa name="trash"></fa>
                        </cui-button-link>
                    </div>
                </div>
            </div>
            <p *ngIf="!grupoUsuarios || grupoUsuarios.length == 0" class="not-found">
                Sin {{ config.plataforma.grupoUsuarioAlias | pluralize }} asociados para el periodo seleccionado.
            </p>

            <div class="btn-general-container" *ngIf="hasRol['Administrador'] || hasRol['SuperAdministrador']">
                <div class="separator"></div>
                <cui-button-link class="btn_style" id="btn-borrar-cursos" (click)="openBorrarTodos()">
                    Borrar todos los cursos
                </cui-button-link>
                <cui-button-link class="btn_style btn-margin" id="btn-vaciar-doc" (click)="openVaciarTodos(1)">
                    Vaciar todos los profesores
                </cui-button-link>
                <cui-button-link class="btn_style" id="btn-vaciar-est" (click)="openVaciarTodos(0)">
                    Vaciar todos los estudiantes
                </cui-button-link>
            </div>
        </div>

        <div *ngIf="hasRol.Administrador || hasRol.Monitor || hasRol.SuperAdministrador" class="informacion-general">
            <p id="unique-students">Nº de estudiantes únicos: {{ allStudents }}</p>
            <p id="unique-teachers">Nº de docentes únicos: {{ allTeachers }}</p>
        </div>
    </div>

    <div
        *ngIf="estudiantesOpen"
        class="col-md-6"
        expandableContent
        [visibility]="!!usuarios"
        [autoOnTransitionEnd]="true"
    >
        <!-- Sección estudiantes -->
        <div class="col-container">
            <div class="sub-title">
                <cui-button-link
                    buttonType="confirm"
                    class="btn_style_3 option-btn pull-right add-btn only-icon"
                    toggleExpandable="addEstudiante"
                    [openOnlyExpandable]="true"
                >
                    <fa name="user-plus"></fa>
                </cui-button-link>
                <cui-button-link class="btn_style option-btn pull-right reset-all-button" (click)="resetAllPasswords()">
                    <ogr-icon name="reset-password" class="icono"></ogr-icon>
                    Restablecer todas las contraseñas
                </cui-button-link>
                <cui-section-headline>
                    Estudiantes del {{ config.plataforma.grupoUsuarioAlias }} {{ grupoUsuario.nombre }}
                </cui-section-headline>
            </div>

            <!-- Agregar estudiante -->
            <asociar-usuario
                *ngIf="grupoUsuario"
                [toggleId]="'addEstudiante'"
                [propietario]="false"
                [grupoUsuario]="grupoUsuario"
                (usuarioAsociadoEvent)="finishAsociarUsuario('estudiante', $event)"
            ></asociar-usuario>

            <!-- Agregar estudiante -->
            <div *ngFor="let alumno of usuarios" class="cell-container">
                <div class="tbl">
                    <div class="tbl-row">
                        <div class="tbl-cell">
                            <div>
                                <span class="fix-width"><fa name="user"></fa></span>
                                &nbsp;{{ alumno.nombreCompleto() }}
                            </div>
                            <div>
                                <span class="fix-width"><fa name="id-badge"></fa></span>
                                &nbsp;{{ alumno.id }}
                            </div>
                            <div>
                                <span class="fix-width"><fa name="envelope"></fa></span>
                                &nbsp;{{ alumno.email }}
                            </div>
                            <div>
                                <span class="fix-width"><fa name="id-card"></fa></span>
                                &nbsp;{{
                                    alumno["usuario_" + pais] &&
                                        alumno["usuario_" + pais][$any(alumno).identificadorPais?.toLowerCase()]
                                        | easyplaceholder: "-"
                                }}
                            </div>
                            <div>
                                <span class="fix-width"><fa name="calendar"></fa></span>
                                &nbsp;
                                <label>Última fecha de ingreso:</label>
                                &nbsp;{{
                                    alumno.fecha_ultimo_login
                                        | date: "dd/MM/yyyy HH:mm"
                                        | easyplaceholder: "Sin fecha de ingreso"
                                }}
                            </div>
                        </div>

                        <div class="tbl-cell clearfix">
                            <cui-button-link
                                class="btn_style option-btn pull-right only-icon"
                                (click)="deleteUsuarioFromGroup(alumno, 0)"
                            >
                                <fa name="trash"></fa>
                            </cui-button-link>
                            <cui-button-link
                                class="btn_style option-btn pull-right only-icon"
                                toggleExpandable="editEstudiante_{{ alumno.id }}"
                            >
                                <fa name="edit"></fa>
                            </cui-button-link>
                            <cui-button-link class="btn_style option-btn pull-right" (click)="resetPassword(alumno)">
                                <ogr-icon name="reset-password" class="icono"></ogr-icon>
                                Restablecer contraseña
                            </cui-button-link>
                        </div>
                    </div>
                </div>
                <div class="tbl edit">
                    <div class="tbl-row">
                        <div class="tbl-cell">
                            <div id="editEstudiante_{{ alumno.id }}" class="edit" expandableContent>
                                <div class="step">
                                    <div>
                                        <div
                                            *ngIf="
                                                idValueAlumnoEdit.dirty &&
                                                idValueAlumnoEdit.touched &&
                                                !validateIdValueUsuario(alumno).valid
                                            "
                                        >
                                            {{ validateIdValue().msg }}
                                        </div>
                                        <div
                                            *ngIf="
                                                idValueAlumnoEdit.dirty &&
                                                idValueAlumnoEdit.touched &&
                                                usuario &&
                                                usuario.id != alumno.id
                                            "
                                        >
                                            Ya existe un estudiante con ese {{ idPais[0].toLowerCase() }}
                                        </div>
                                        <div class="tbl">
                                            <div class="tbl-row">
                                                <div class="tbl-cell">
                                                    <b>{{ nombreId }}</b>
                                                </div>
                                                <div class="tbl-cell">
                                                    <input
                                                        #idValueAlumnoEdit="ngModel"
                                                        [(ngModel)]="alumno['usuario_' + pais][idPais[0].toLowerCase()]"
                                                        (focusout)="
                                                            getUsuarioFun(
                                                                alumno['usuario_' + pais][idPais[0].toLowerCase()]
                                                            )
                                                        "
                                                        required
                                                        type="text"
                                                        class="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div class="tbl-row">
                                                <div class="tbl-cell"><b>Nombre</b></div>
                                                <div class="tbl-cell">
                                                    <input
                                                        [(ngModel)]="alumno.nombre"
                                                        required
                                                        type="text"
                                                        class="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div class="tbl-row">
                                                <div class="tbl-cell"><b>Apellido Paterno</b></div>
                                                <div class="tbl-cell">
                                                    <input
                                                        [(ngModel)]="alumno.apellido_paterno"
                                                        required
                                                        type="text"
                                                        class="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div class="tbl-row">
                                                <div class="tbl-cell"><b>Apellido Materno</b></div>
                                                <div class="tbl-cell">
                                                    <input
                                                        [(ngModel)]="alumno.apellido_materno"
                                                        type="text"
                                                        class="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div class="tbl-row">
                                                <div class="tbl-cell"><b>Email</b></div>
                                                <div class="tbl-cell">
                                                    <input
                                                        [(ngModel)]="alumno.email"
                                                        required
                                                        type="text"
                                                        class="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clearfix">
                                            <cui-button
                                                class="btn_style pull-right"
                                                [disabled]="
                                                    (idValueAlumnoEdit.dirty &&
                                                        idValueAlumnoEdit.touched &&
                                                        !validateIdValueUsuario(alumno).valid) ||
                                                    (usuario && usuario.id != alumno.id)
                                                "
                                                (click)="editUsuario(alumno, idValueAlumnoEdit)"
                                            >
                                                Guardar
                                            </cui-button>
                                            <cui-button-link
                                                buttonType="cancel"
                                                class="btn_style_2 pull-right small-margin-right"
                                                toggleExpandable="editEstudiante_{{ alumno.id }}"
                                            >
                                                Cancelar
                                            </cui-button-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!usuarios || usuarios.length == 0">
                <p class="not-found">Sin estudiantes asociados.</p>
            </div>
            <br />
            <paginator #paginator [getList]="verUsuariosFun(grupoUsuario.id, 0)"></paginator>
        </div>
    </div>

    <div *ngIf="docentesOpen" class="col-md-6" expandableContent [visibility]="!!usuarios" [autoOnTransitionEnd]="true">
        <!-- Agregar docentes -->
        <div class="col-container">
            <div class="sub-title">
                <cui-button-link
                    buttonType="confirm"
                    class="btn_style_3 option-btn pull-right add-btn only-icon"
                    toggleExpandable="addDocente"
                    [openOnlyExpandable]="true"
                >
                    <fa name="user-plus"></fa>
                </cui-button-link>
                <cui-button-link
                    class="btn_style option-btn pull-right reset-all-button"
                    (click)="resetAllPasswords(true)"
                >
                    <ogr-icon name="reset-password" class="icono"></ogr-icon>
                    Restablecer todas las contraseñas
                </cui-button-link>
                <cui-section-headline>
                    Docentes del {{ config.plataforma.grupoUsuarioAlias }} {{ grupoUsuario.nombre }}
                </cui-section-headline>
            </div>

            <!-- Agregar docente -->
            <asociar-usuario
                *ngIf="grupoUsuario"
                [toggleId]="'addDocente'"
                [propietario]="true"
                [grupoUsuario]="grupoUsuario"
                (usuarioAsociadoEvent)="finishAsociarUsuario('docente', $event)"
            ></asociar-usuario>

            <div *ngFor="let propietario of usuarios" class="cell-container">
                <div class="tbl">
                    <div class="tbl-row">
                        <div class="tbl-cell">
                            <div>
                                <span class="fix-width"><fa name="user"></fa></span>
                                &nbsp;{{ propietario.nombreCompleto() }}
                            </div>
                            <div>
                                <span class="fix-width"><fa name="id-badge"></fa></span>
                                &nbsp;{{ propietario.id }}
                            </div>
                            <div>
                                <span class="fix-width"><fa name="envelope"></fa></span>
                                &nbsp;{{ propietario.email }}
                            </div>
                            <div>
                                <span class="fix-width"><fa name="id-card"></fa></span>
                                &nbsp;{{
                                    propietario["usuario_" + pais] &&
                                        propietario["usuario_" + pais][
                                            $any(propietario).identificadorPais?.toLowerCase()
                                        ]
                                }}
                            </div>
                            <div>
                                <span class="fix-width"><fa name="calendar"></fa></span>
                                &nbsp;
                                <label>Última fecha de ingreso:</label>
                                &nbsp;{{
                                    propietario.fecha_ultimo_login
                                        | date: "dd/MM/yyyy HH:mm"
                                        | easyplaceholder: "Sin fecha de ingreso"
                                }}
                            </div>
                        </div>
                        <div class="tbl-cell clearfix">
                            <cui-button-link
                                class="btn_style option-btn pull-right only-icon"
                                (click)="deleteUsuarioFromGroup(propietario, 1)"
                            >
                                <fa name="trash"></fa>
                            </cui-button-link>
                            <cui-button-link
                                class="btn_style option-btn pull-right only-icon"
                                toggleExpandable="editDocente_{{ propietario.id }}"
                            >
                                <fa name="edit"></fa>
                            </cui-button-link>
                            <cui-button-link
                                class="btn_style option-btn pull-right"
                                (click)="resetPassword(propietario)"
                            >
                                <ogr-icon name="reset-password" class="icono"></ogr-icon>
                                Restablecer contraseña
                            </cui-button-link>
                        </div>
                    </div>
                </div>

                <div class="tbl edit">
                    <div class="tbl-row">
                        <div class="tbl-cell">
                            <div id="editDocente_{{ propietario.id }}" class="edit" expandableContent>
                                <div class="step">
                                    <div>
                                        <div
                                            *ngIf="
                                                idValueDocenteEdit.dirty &&
                                                idValueDocenteEdit.touched &&
                                                !validateIdValueUsuario(propietario).valid
                                            "
                                        >
                                            {{ validateIdValue().msg }}
                                        </div>
                                        <div
                                            *ngIf="
                                                idValueDocenteEdit.dirty &&
                                                idValueDocenteEdit.touched &&
                                                usuario &&
                                                usuario.id != propietario.id
                                            "
                                        >
                                            Ya existe un estudiante con ese {{ idPais[0].toLowerCase() }}
                                        </div>
                                        <div class="tbl">
                                            <div class="tbl-row">
                                                <div class="tbl-cell">
                                                    <b>{{ nombreId }}</b>
                                                </div>
                                                <div class="tbl-cell">
                                                    <input
                                                        #idValueDocenteEdit="ngModel"
                                                        [(ngModel)]="
                                                            propietario['usuario_' + pais][idPais[0].toLowerCase()]
                                                        "
                                                        (focusout)="
                                                            getUsuarioFun(
                                                                propietario['usuario_' + pais][idPais[0].toLowerCase()]
                                                            )
                                                        "
                                                        required
                                                        type="text"
                                                        class="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div class="tbl-row">
                                                <div class="tbl-cell"><b>Nombre</b></div>
                                                <div class="tbl-cell">
                                                    <input
                                                        [(ngModel)]="propietario.nombre"
                                                        required
                                                        type="text"
                                                        class="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div class="tbl-row">
                                                <div class="tbl-cell"><b>Apellido Paterno</b></div>
                                                <div class="tbl-cell">
                                                    <input
                                                        [(ngModel)]="propietario.apellido_paterno"
                                                        required
                                                        type="text"
                                                        class="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div class="tbl-row">
                                                <div class="tbl-cell"><b>Apellido Materno</b></div>
                                                <div class="tbl-cell">
                                                    <input
                                                        [(ngModel)]="propietario.apellido_materno"
                                                        type="text"
                                                        class="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div class="tbl-row">
                                                <div class="tbl-cell"><b>Email</b></div>
                                                <div class="tbl-cell">
                                                    <input
                                                        [(ngModel)]="propietario.email"
                                                        required
                                                        type="text"
                                                        class="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clearfix">
                                            <cui-button
                                                class="btn_style pull-right"
                                                [disabled]="
                                                    (idValueDocenteEdit.dirty &&
                                                        idValueDocenteEdit.touched &&
                                                        !validateIdValueUsuario(propietario).valid) ||
                                                    (usuario && usuario.id != propietario.id)
                                                "
                                                (click)="editUsuario(propietario, idValueDocenteEdit)"
                                            >
                                                Guardar
                                            </cui-button>
                                            <cui-button-link
                                                buttonType="cancel"
                                                class="btn_style_2 pull-right small-margin-right"
                                                toggleExpandable="editDocente_{{ propietario.id }}"
                                            >
                                                Cancelar
                                            </cui-button-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!usuarios || usuarios.length == 0">
                <p class="not-found">Sin docentes asociados.</p>
            </div>
            <br />
            <paginator #paginator [getList]="verUsuariosFun(grupoUsuario.id, 1)"></paginator>
        </div>
    </div>
</div>

<generic-modal [buttonClicked]="openModal">
    <modal-titulo id="confirm-title">Confirmación</modal-titulo>
    <modal-contenido>
        <div>
            <div id="confirm-text">
                ¿Está seguro de querer vaciar a todos los {{ nombrePropietario }} de todos los cursos del periodo?
            </div>
            <div>Por favor escriba VACIAR en el siguiente campo de texto (en mayúsculas).</div>
            <div class="form-group" id="vaciar-container">
                <input class="form-control" type="text" ngModel #vaciarInput="ngModel" />
            </div>
        </div>
    </modal-contenido>
    <modal-botones>
        <div class="pull-right">
            <button
                id="btn-aceptar-vaciar"
                class="btn btn-default small-margin-right"
                (click)="vaciarTodos(vaciarInput.value); vaciarInput.reset()"
            >
                Aceptar
            </button>
            <button class="btn btn-default" (click)="genericModal.close()">Cancelar</button>
        </div>
    </modal-botones>
</generic-modal>

<generic-modal #genericModalBorrar [buttonClicked]="openModalBorrar">
    <modal-titulo id="title-borrar-cursos">Confirmación</modal-titulo>
    <modal-contenido>
        <div>
            <div id="content-borrar-cursos">¿Está seguro de borrar todos los cursos del periodo?</div>
            <div>Por favor escriba BORRAR en el siguiente campo de texto (en mayúsculas).</div>
            <div class="form-group" id="borrar-container">
                <input class="form-control" type="text" ngModel #borrarInput="ngModel" />
            </div>
        </div>
    </modal-contenido>
    <modal-botones>
        <div class="pull-right">
            <button
                id="btn-aceptar-borrar-cursos"
                class="btn btn-default small-margin-right"
                (click)="borrarTodos(borrarInput.value); borrarInput.reset()"
            >
                Aceptar
            </button>
            <button class="btn btn-default" (click)="genericModalBorrar.close()">Cancelar</button>
        </div>
    </modal-botones>
</generic-modal>

<generic-modal #genericModalEditar [buttonClicked]="openModalEditar">
    <modal-titulo id="title-editar-grupo">Editar Curso #{{ grupoUsuarioToEdit?.id }}</modal-titulo>
    <modal-contenido>
        <grupo-usuario-form
            *ngIf="grupoUsuarioToEdit"
            [grupoUsuario]="grupoUsuarioToEdit"
            [clasificaciones]="clasificaciones"
            (save)="updateGrupoUsuario($event)"
            (cancel)="cancelEditarGrupoUsuario()"
        ></grupo-usuario-form>
    </modal-contenido>
    <modal-botones></modal-botones>
</generic-modal>
