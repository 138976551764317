import {
    Component,
    OnInit,
    ViewChildren,
    ViewChild,
    ElementRef,
    QueryList,
    Input,
    SimpleChanges,
    EventEmitter
} from "@angular/core"
import {
    Instrumento,
    EvaluacionInstancias,
    EvaluacionInstancia,
    Evaluacion,
    InstrumentoPreguntas,
    InstrumentoPregunta,
    Instrumentos,
    EvaluacionTipo
} from "@puntaje/puntaje/api-services"
import {
    Pregunta,
    Preguntas,
    Alternativas,
    Alternativa,
    Material,
    ContestableTipo,
    PreguntaMaterial,
    Contestable,
    Clasificacion,
    ClasificacionPregunta
} from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig, AuthService, GenericModalComponent } from "@puntaje/shared/core"
import { SubirPautaMixtaComponent } from "../subir-pauta-mixta/subir-pauta-mixta.component"
import { ClasificadorRecursosComponent } from "@puntaje/puntaje/new-modules/clasificaciones"

declare const config: AppConfig

@Component({
    selector: "pauta-prueba-propia",
    templateUrl: "pauta-prueba-propia.component.html",
    styleUrls: ["../subir-prueba-propia.component.scss"]
})
export class PautaPruebaPropiaComponent implements OnInit {
    @Input() instrumento: Instrumento
    @Input() evaluacion: Evaluacion
    /* @Input() pauta: {letra: string, id: number}[] */
    pauta: any[]
    pautaToEdit: any[]
    @ViewChildren("letraInput") letraInputs: QueryList<ElementRef>
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    preguntas: Pregunta[]
    showPauta: boolean = false
    hasInstancias: boolean = false
    showDescripcion: boolean = false
    curriculum: string
    numeroPreguntasEdit: number
    clasificacionesByPreguntaIndex: Clasificacion[][]

    openModal = new EventEmitter<any>()

    @ViewChild(GenericModalComponent) genericModal: GenericModalComponent
    @ViewChild(SubirPautaMixtaComponent) subirPautaMixta: SubirPautaMixtaComponent
    @ViewChild(ClasificadorRecursosComponent) clasificadorRecursos: ClasificadorRecursosComponent

    constructor(
        protected instrumentoPreguntasService: InstrumentoPreguntas,
        protected preguntasService: Preguntas,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected alternativasService: Alternativas,
        protected authService: AuthService,
        protected instrumentosService: Instrumentos
    ) {}

    ngOnInit() {
        //this.pauta = new Array(90).fill(0).map(_ => ({letra: ""}))
        this.curriculum =
            EvaluacionTipo.curriculumByTipo[this.instrumento.generador_instrumento.tipo_instrumento.tipo_instrumento]

        this.getHasInstancias()
        this.getPauta()
        this.getShowDescripcion()
        this.loadingLayout.ready()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["instrumento"]) {
            this.getHasInstancias()
            this.getPauta()
            this.loadingLayout.ready()
        }
    }

    async getHasInstancias() {
        const evaluacionInstancias = (await this.evaluacionInstanciasService.wherePost({
            per: 1,
            evaluacion: { instrumento_id: this.instrumento.id }
        })) as EvaluacionInstancia[]
        this.hasInstancias = evaluacionInstancias.length > 0
    }

    getPauta() {
        this.loadingLayout.standby()
        const preguntaIds = this.instrumento.instrumento_preguntas.map(ip => ip.pregunta_id)
        const params = {
            pregunta: {
                id: preguntaIds
            },
            with_clasificaciones: 1
        }
        this.preguntasService.where(params).then((preguntas: Pregunta[]) => {
            this.preguntas = preguntas
            this.numeroPreguntasEdit = preguntas.length
            this.pauta = []
            this.preguntas.forEach((p, i) => {
                let alternativaCorrecta = p.contestables[0]?.alternativas.find(a => a.correcta)

                this.pauta[i] = {
                    letra: alternativaCorrecta?.letra,
                    piloto: this.instrumento.instrumento_preguntas.find(ip => ip.pregunta_id == p.id).piloto
                }
            })
            this.showPauta = true
            this.loadingLayout.ready()
        })
    }

    edit() {
        this.pautaToEdit = this.preguntas.map((pregunta, index) => {
            const piloto = this.instrumento.instrumento_preguntas[index].piloto
            const puntuacion = this.instrumento.instrumento_preguntas[index].puntuacion
            const alternativaCorrecta = pregunta.contestables[0].alternativas.find(a => a.correcta)
            const letra = alternativaCorrecta ? alternativaCorrecta.letra : null
            const contestableTipo = pregunta.contestables[0].contestable_tipo
            const cantidadAlternativas = pregunta.contestables[0].alternativas.length

            return {
                piloto,
                letra,
                contestableTipo,
                cantidadAlternativas,
                puntuacion
            }
        })

        this.openModal.emit()
    }

    cancelModal() {
        this.genericModal.close()
    }

    continueModal() {
        this.clasificadorRecursos.sendData()
        this.subirPautaMixta.continue()
    }

    cancelEditPauta() {
        this.genericModal.close()
    }

    continueEditPauta() {
        this.subirPautaMixta.continue()
    }

    cancelClasificar() {
        this.genericModal.close()
    }

    continueClasificar() {
        this.clasificadorRecursos.sendData()
        this.genericModal.close()
    }

    updateNumeroPreguntas(numeroPreguntas) {
        this.numeroPreguntasEdit = numeroPreguntas
    }

    async updateClasificaciones({ clasificacionesByItemIndex }) {
        this.clasificacionesByPreguntaIndex = clasificacionesByItemIndex
    }

    async updatePauta({ pauta, numero_alternativas, numero_preguntas }) {
        this.loadingLayout.standby()
        this.genericModal.close()

        const usuario = this.authService.getUserData()
        let persona_externa = {
            usuario_id: usuario.id,
            nombre: usuario.nombre,
            apellido_paterno: usuario.apellido_paterno,
            apellido_materno: usuario.apellido_materno,
            plataforma_id: config.plataforma.id
        }

        const preguntasToAdd = pauta.slice(this.preguntas.length).map((p, i) => {
            return this.createPregunta(
                this.instrumento.asignatura_id,
                this.instrumento.instrumento_material.material_id,
                i + this.preguntas.length,
                p.letra,
                p.contestableTipo,
                p.cantidadAlternativas
            )
        })

        const preguntasToUpdate = this.preguntas.slice(0, this.pauta.length).map((pregunta, i) => {
            let preguntaAux = {}
            preguntaAux["id"] = pregunta.id

            if (this.clasificacionesByPreguntaIndex[i]) {
                preguntaAux["clasificacion_preguntas_attributes"] = this.clasificacionesByPreguntaIndex[i].map(
                    clasificacion => {
                        return { clasificacion_id: clasificacion.id }
                    }
                )
            } else {
                preguntaAux["clasificacion_preguntas_attributes"] = []
            }

            return preguntaAux
        })

        const preguntasToDelete = this.preguntas.slice(pauta.length).map(p => p.id)

        const contestablesToUpdate = this.preguntas
            .slice(0, pauta.length)
            .map((pregunta, index) => {
                return {
                    id: pregunta.contestables[0].id,
                    updatedContestable:
                        pregunta.contestables[0].contestable_tipo.contestable_tipo !=
                        pauta[index].contestableTipo.contestable_tipo,
                    contestable_tipo_id: pauta[index].contestableTipo?.id
                }
            })
            .filter(c => c.updatedContestable)
            .map(({ updatedContestable, ...rest }) => rest)

        const alternativasToDelete = this.preguntas
            .slice(0, pauta.length)
            .map((pregunta, index) => {
                return pregunta.contestables[0].alternativas
                    .filter((a, i) => i >= pauta[index].cantidadAlternativas)
                    .map(a => a.id)
            })
            .flat()

        const alternativasToAdd = this.preguntas
            .slice(0, pauta.length)
            .map((pregunta, index) => {
                const permittedLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
                const alternativas = []
                const qtyAlternativasToAdd =
                    pauta[index].cantidadAlternativas - pregunta.contestables[0].alternativas.length

                for (let i = 0; i < qtyAlternativasToAdd; i++) {
                    const letraAlternativa = permittedLetters[pregunta.contestables[0].alternativas.length + i]

                    const alternativa = new Alternativa()
                    alternativa.alternativa = "-"
                    alternativa.letra = letraAlternativa.toUpperCase()
                    alternativa.correcta = pauta[index].letra == letraAlternativa
                    alternativa.orden = i
                    alternativa.ocultable = false
                    alternativa.contestable_id = pregunta.contestables[0].id

                    alternativas.push(alternativa)
                }

                return alternativas
            })
            .flat()

        const alternativasToUpdate = this.preguntas
            .slice(0, pauta.length)
            .map((pregunta, index) => {
                if (pauta[index].letra === null) return []

                return pregunta.contestables[0].alternativas
                    .filter(a => a.correcta != (pauta[index].letra.toUpperCase() == a.letra.toUpperCase()))
                    .map(a => ({
                        id: a.id,
                        correcta: pauta[index].letra.toUpperCase() == a.letra.toUpperCase()
                    }))
            })
            .flat()

        const instrumentoPreguntasToUpdate = this.instrumento.instrumento_preguntas
            .slice(0, pauta.length)
            .map((ip, index) => {
                return {
                    id: ip.id,
                    piloto: pauta[index].piloto,
                    updatedPiloto: ip.piloto != pauta[index].piloto
                }
            })
            .filter(ip => ip.updatedPiloto)
            .map(({ updatedPiloto, ...rest }) => rest)

        const instrumentoPreguntasToAdd = pauta.slice(this.preguntas.length).map((p, i) => {
            return { piloto: p.piloto }
        })

        const contestableTiposPauta = pauta
            .map(p => p.contestableTipo.contestable_tipo)
            .filter((c, i, a) => a.indexOf(c) === i)
        const tipoContestables = contestableTiposPauta.length > 1 ? "Mixto" : contestableTiposPauta[0]

        this.instrumentosService
            .updatePropio(this.instrumento.id, {
                instrumento: {
                    numero_alternativas,
                    numero_preguntas,
                    tipo_contestables: tipoContestables
                },
                preguntas: {
                    add: preguntasToAdd,
                    delete: preguntasToDelete,
                    update: preguntasToUpdate
                },
                contestables: {
                    update: contestablesToUpdate
                },
                alternativas: {
                    add: alternativasToAdd,
                    delete: alternativasToDelete,
                    update: alternativasToUpdate
                },
                instrumento_preguntas: {
                    update: instrumentoPreguntasToUpdate,
                    add: instrumentoPreguntasToAdd
                },
                persona_externa
            })
            .then((instrumento: Instrumento) => {
                this.instrumento = instrumento
            })
            .finally(() => {
                this.getPauta()
            })
    }

    private createPregunta(
        asignaturaId: number,
        materialId: number,
        i: number,
        letra: string = null,
        contestableTipo: ContestableTipo = null,
        cantidadAlternativas: number = null
    ) {
        const contestableTipoPregunta = contestableTipo
        const cantidadAlternativasPregunta = cantidadAlternativas

        const permittedLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

        const pregunta = new Pregunta(true)

        pregunta.asignatura_id = asignaturaId
        pregunta.estado = 1
        pregunta.visible = false
        pregunta.profesor_id = 7
        pregunta.encargado_reporte_id = 7

        const preguntaMaterial = new PreguntaMaterial()
        preguntaMaterial.material_id = materialId
        preguntaMaterial.orden = i
        pregunta.pregunta_material = preguntaMaterial

        // clasificaciones
        if (this.clasificacionesByPreguntaIndex[i] && this.clasificacionesByPreguntaIndex[i].length > 0) {
            ;(pregunta as any).clasificacion_preguntas_attributes = this.clasificacionesByPreguntaIndex[i].map(
                clasificacion => {
                    let clasificacionPregunta = new ClasificacionPregunta()
                    clasificacionPregunta.clasificacion_id = clasificacion.id

                    return clasificacionPregunta
                }
            )
        }

        const contestable = new Contestable(contestableTipoPregunta.id, 0)

        pregunta.contestables = [contestable]

        if (contestableTipoPregunta.contestable_tipo == "Pregunta de alternativas") {
            const letraCorrecta = letra.toUpperCase()
            contestable.alternativas = []

            for (let j = 0; j < cantidadAlternativasPregunta; j++) {
                const letraAlternativa = permittedLetters[j]

                const alternativa = new Alternativa()
                alternativa.alternativa = "-"
                alternativa.letra = letraAlternativa.toUpperCase()
                alternativa.correcta = letraCorrecta == letraAlternativa
                alternativa.orden = j
                alternativa.ocultable = false

                contestable.alternativas.push(alternativa)
            }
        }

        return pregunta
    }

    getShowDescripcion() {
        this.instrumento.instrumento_preguntas.forEach(ip => {
            if (ip.piloto == true) {
                this.showDescripcion = true
            }
        })
    }
}
