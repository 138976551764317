import { TextView, DateView, BooleanView } from "@puntaje/shared/core"

export class PostView {
    public static get viewParams() {
        return {
            id: { label: "Id", type: TextView, tableVisible: true },
            titulo: { label: "Titulo", type: TextView, tableVisible: true },
            resumen: { label: "Resumen", type: TextView, tableVisible: true },
            tag: { label: "Tag", type: TextView, tableVisible: true },
            tiempo_lectura: { label: "Tiempo de lectura", type: TextView, tableVisible: true },
            usuario_id: { label: "Autor", type: TextView, tableVisible: true },
            fecha_inicial: { label: "Fecha inicial", type: DateView, tableVisible: true },
            visible: {
                label: "Visibilidad",
                type: BooleanView,
                tableVisible: true,
                options: { labelTrue: "Visible", labelFalse: "No visible" }
            },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
