import { NominaAlumnosComponent, NominaProfesoresComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"

export const nominasRoutes: Routes = [
    {
        path: "nominas/alumnos",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: NominaAlumnosComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "nominas/profesores",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: NominaProfesoresComponent, canActivate: [AuthGuard] }]
    }
]

export const nominasRouting = RouterModule.forChild(nominasRoutes)
