<generic-modal [id]="modalId" [modalLarge]="true" (closeCrossCallback)="closeCross()">
    <modal-titulo>Vista Previa Evaluación #{{ evaluacionId }}</modal-titulo>
    <modal-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="enableImprimir" class="btn_container">
                <cui-button-link
                    [isBlock]="true"
                    id="print_link"
                    class="btn_style"
                    (click)="imprimirComponent.imprimir('alumno')"
                >
                    <span class="glyphicon glyphicon-print"></span>
                    Imprimir para alumnos
                </cui-button-link>
                <p class="details">(sin respuestas)</p>
                <cui-button-link
                    [isBlock]="true"
                    id="print_link_prof"
                    class="btn_style"
                    (click)="imprimirComponent.imprimir('profesor')"
                >
                    <span class="glyphicon glyphicon-print"></span>
                    Imprimir para profesor
                </cui-button-link>
                <p class="details">(con respuestas)</p>
                <cui-button-link
                    [isBlock]="true"
                    id="print_link_prof"
                    class="btn_style"
                    (click)="imprimirComponent.imprimirHojas()"
                >
                    <span class="glyphicon glyphicon-print"></span>
                    Imprimir hojas de respuestas
                </cui-button-link>
                <p class="details">(por alumno)</p>
            </div>
            <datos-generales-evaluacion
                *ngIf="evaluacion"
                [evaluacion]="evaluacion"
                [groupedBy]="['curso', 'habilidad cognitiva', 'eje temático,eje temático']"
                class="print-hide"
            ></datos-generales-evaluacion>
            <datos-generales
                *ngIf="instrumento"
                [instrumento]="instrumento"
                [groupedBy]="['curso', 'habilidad cognitiva', 'eje temático,eje temático']"
            ></datos-generales>
            <instrumento [enableReporte]="enableReporte" [instrumento]="instrumento"></instrumento>
        </loading-layout>
    </modal-contenido>
</generic-modal>
<imprimir [evaluacionId]="evaluacionId" [instrumento]="instrumento"></imprimir>
