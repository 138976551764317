import { Component, OnInit, ViewChild, EventEmitter, Input, SimpleChanges } from "@angular/core"
import { Alternativa, Preguntas, Pregunta, GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import { GenericModalComponent } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Subscription } from "rxjs"

@Component({
    selector: "ver-pregunta-modal",
    templateUrl: "./ver-pregunta-modal.component.html",
    styleUrls: ["./ver-pregunta-modal.component.scss"]
})
export class VerPreguntaModalComponent implements OnInit {
    @ViewChild(GenericModalComponent, { static: true }) genericModal: GenericModalComponent
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent
    @Input() openModal: EventEmitter<any>
    @Input() dataModal
    @Input() evaluacionTipo: string
    @Input() generadorInstrumento: GeneradorInstrumento

    sub: Subscription

    constructor(protected preguntasService: Preguntas) {}

    ngOnInit() {
        this.sub = this.openModal.subscribe(() => {})
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes["dataModal"] &&
            changes["dataModal"].currentValue != changes["dataModal"].previousValue &&
            changes["dataModal"].currentValue &&
            !changes["dataModal"].currentValue.pregunta
        ) {
            this.getPregunta()
        }
    }

    getPregunta() {
        const params = {
            with_grupo_pregunta: 1,
            with_clasificaciones: 1,
            with_clasificaciones_with_clasificacion_tipo: 1,
            pregunta: {
                id: this.dataModal.pregunta_id
            }
        }

        this.preguntasService.where(params).then((preguntas: Pregunta[]) => {
            this.dataModal.pregunta = preguntas[0]
        })
    }

    public open() {
        this.genericModal.buttonPressed()
    }

    public close() {
        this.genericModal.close()
    }

    getLetra(pos: number) {
        return Alternativa.getLetra(pos)
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
