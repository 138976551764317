import { Component, ViewEncapsulation } from "@angular/core"

@Component({
    selector: "cui-title-headline",
    template: `
        <p class="cui-title-headline"><ng-content></ng-content></p>
    `,
    styleUrls: ["cui-title-headline.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class CUITitleHeadlineComponent {
    constructor() {}
}
