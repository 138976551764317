<generic-modal [buttonClicked]="openModal">
    <modal-titulo>Pregunta</modal-titulo>
    <modal-contenido>
        <loading-layout [loading]="!dataModal || !dataModal.pregunta">
            <div
                *ngIf="dataModal.pregunta"
                class="pregunta_ensayo print-page-break-avoid"
                id="preg_{{ dataModal.pregunta.id }}"
            >
                <grupo-pregunta
                    *ngIf="
                        dataModal.pregunta && dataModal.pregunta.grupo_pregunta && dataModal.pregunta.grupo_pregunta.id
                    "
                    [grupoPregunta]="dataModal.pregunta.grupo_pregunta"
                    [preguntaIndex]="1"
                    [enableGrupoPreguntaId]="true"
                ></grupo-pregunta>
                <div class="pregunta_ensayo_contenido">
                    <div class="numero_pregunta numero_pregunta_style">
                        {{ dataModal.numero }}
                    </div>
                    <div class="enunciado-alternativas">
                        <span class="id_pregunta print-hide">#{{ dataModal.pregunta.id }}</span>
                        <pregunta-taxativo [taxativo]="dataModal.pregunta.taxativos[0].taxativo"></pregunta-taxativo>

                        <div class="alternativas">
                            <ul class="alternativas-list">
                                <li
                                    *ngFor="
                                        let alternativa of dataModal.pregunta.contestables[0].getAlternativas(
                                            evaluacionTipo
                                        );
                                        let i = index
                                    "
                                    [class.alternativa-correcta]="alternativa.correcta"
                                >
                                    <div class="alternativa_container">
                                        <div class="letra">{{ getLetra(i) }})</div>
                                        <div
                                            class="alternativa"
                                            [innerHTML]="alternativa.alternativa | clearLatex | katex | trustedhtml"
                                        ></div>
                                    </div>
                                    <div *ngIf="alternativa.correcta" class="alternativa-correcta-icon print-hide">
                                        <ogr-icon name="ticket-circle"></ogr-icon>
                                    </div>
                                </li>
                            </ul>
                            <!-- <div class="autor print-hide">Autor: {{ autor }}</div> -->
                        </div>
                    </div>
                </div>

                <tabla-clasificaciones-pregunta
                    [pregunta]="dataModal.pregunta"
                    [evaluacionTipo]="evaluacionTipo"
                    class="print-hide"
                ></tabla-clasificaciones-pregunta>

                <solucion [pregunta]="dataModal.pregunta" class="print-hide"></solucion>

                <!-- <p class="print-show-block hidden-info">
                        <b>Autor:</b>
                        &nbsp;{{ autor }}
                    </p> -->
                <p class="print-show-block hidden-info">
                    <b>Pregunta ID:</b>
                    &nbsp;{{ dataModal.pregunta.id }}
                </p>
            </div>
        </loading-layout>
    </modal-contenido>
</generic-modal>
