import { TextView, DateView, BooleanView } from "@puntaje/shared/core"

export class CategoriaAyudaView {
    public static get viewParams() {
        return {
            id: { label: "Id", type: TextView, tableVisible: true },
            categoria_ayuda: { label: "Nombre", type: TextView, tableVisible: true },
            orden: { label: "Orden", type: TextView, tableVisible: true },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
