<p class="tutorial">
    La presente tabla detalla el desempeño logrado a través de los años para la presente generación, basada en la última
    lista oficial enviada por el establecimiento.
</p>
<div *ngIf="configuracionLoaded">
    <div *ngFor="let tipoInstrumento of tiposInstrumentoInformeUso">
        <cui-section-headline>
            {{ tipoInstrumentosDict[tipoInstrumento] | transformInstrumento: "pluralCompleto" | capitalize }}
        </cui-section-headline>
        <table class="table">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">Año</th>
                    <th scope="col">Nivel</th>
                    <th scope="col"># Estudiantes activos</th>
                    <th scope="col">
                        # {{ tipoInstrumentosDict[tipoInstrumento] | transformInstrumento: "pluralCompleto" | capitalize }}
                        {{ "realizados" | genderize: tipoInstrumento }}
                    </th>
                    <th scope="col">
                        # {{ tipoInstrumentosDict[tipoInstrumento] | transformInstrumento: "pluralCompleto" | capitalize }} por Estudiante
                        activo (prom)
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let nivel of configuracion[tipoInstrumento]['niveles']; let i = index">
                    <td *ngIf="i == 0" [attr.rowspan]="configuracion[tipoInstrumento]['niveles'].length">{{ year }}</td>
                    <td>{{ nivel }}</td>
                    <td>{{ configuracion[tipoInstrumento]["data"][nivel]["n_alumnos"] }}</td>
                    <td>{{ configuracion[tipoInstrumento]["data"][nivel]["n_evaluaciones"] }}</td>
                    <td>{{ configuracion[tipoInstrumento]["data"][nivel]["n_evaluaciones_prom"] }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
