import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./post-admin.component.html",
    styleUrls: ["./post-admin.component.scss"]
})
export class PostAdminComponent implements OnInit {
    postId: number
    protected sub: Subscription

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.blog.posts_admin.show")
        this.sub = this.route.params.subscribe(params => {
            this.postId = +params["id"]
        })
    }
}
