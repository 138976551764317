<ll-titulo>{{ "instrumento.titulo" | t }} #{{ instrumento?.id }}</ll-titulo>

<!-- Datos generales Instrumento -->
<datos-generales
    *ngIf="instrumento"
    [instrumento]="instrumento"
    [groupedBy]="['curso', 'habilidad cognitiva', 'eje temático,eje temático']"
></datos-generales>
<div class="row top-margin">
    <div class="col-md-4 inverted-col padding-bottom">
        <ng-container *ngIf="instrumento && !instrumento.instrumento_material">
            <!-- Opciones costado derecho Instrumento -->
            <opciones-instrumento
                *ngIf="instrumento"
                [instrumento]="instrumento"
                [tipoId]="1"
                [evaluacionId]="evaluacionId"
                [scrollAndFollow]="true"
                [responsiveBreak]="991"
                [topPadding]="20"
                [bottomPadding]="20"
                [followTopReference]="followReference"
                [followBottomReference]="followReference"
                [showButtonGuardar]="showButtonGuardar"
                [enableAdminOptions]="enableAdminOptions"
            ></opciones-instrumento>
        </ng-container>
        <ng-container *ngIf="instrumento && instrumento.instrumento_material">
            <div class="tab-content">
                <cui-tabs justifyContent="space-around">
                    <cui-tab title="Pauta" ariaLabelledBy="pauta">
                        <pauta-prueba-propia [instrumento]="instrumento"></pauta-prueba-propia>
                    </cui-tab>
                    <cui-tab title="Compartir" ariaLabelledBy="compartir">
                        <opciones-instrumento
                            *ngIf="instrumento"
                            [instrumento]="instrumento"
                            [tipoId]="1"
                            [evaluacionId]="evaluacionId"
                            [showButtonGuardar]="showButtonGuardar"
                        ></opciones-instrumento>
                    </cui-tab>
                </cui-tabs>
            </div>
        </ng-container>
    </div>
    <div class="col-md-8" #followReference>
        <!-- Vista previa Instrumento -->
        <cui-section-headline>{{ "instrumento.vista_previa" | t }}</cui-section-headline>
        <instrumento [instrumento]="instrumento"></instrumento>
    </div>
</div>
