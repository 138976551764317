import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./categoria-ayuda-admin.component.html",
    styleUrls: ["./categoria-ayuda-admin.component.scss"]
})
export class CategoriaAyudaAdminComponent implements OnInit {
    categoriaAyudaId: number
    protected sub: Subscription

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.ayudas_admin.categoria_ayudas.admin")
        this.sub = this.route.params.subscribe(params => {
            this.categoriaAyudaId = +params["id"]
        })
    }
}
