import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "filtro-evaluacion-tipo",
    templateUrl: "filtro_evaluacion_tipo.component.html"
})
export class FiltroEvaluacionTipoComponent implements OnInit {
    @Output() evaluacionTipoChange: EventEmitter<string> = new EventEmitter<string>()
    config: typeof config = config
    @Input() evaluacionTipo: string
    @Input() transformacion: string = "singular"

    constructor() {}

    ngOnInit() {}
}
