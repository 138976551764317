import { Component, ContentChildren, QueryList } from "@angular/core"
import { SorterComponent } from "./sorter.component"
import { Subscription } from "rxjs"

@Component({
    selector: "sorter-group,[sorterGroup]",
    templateUrl: "sorter_group.component.html"
})
export class SorterGroupComponent {
    subscriptions: Subscription[] = []
    sortedSorter: SorterComponent

    @ContentChildren(SorterComponent, { descendants: true }) sorters: QueryList<SorterComponent>

    ngAfterContentInit() {
        this.sorters.forEach(sorter => {
            let subscription = sorter.sortSubject.subscribe(order => {
                if (this.sortedSorter != sorter) {
                    this.sortedSorter = sorter
                    this.sorters.forEach(s => {
                        if (s != sorter) s.resetOrder()
                    })
                }
            })
        })
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe())
    }
}
