import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { S3, S3Service } from "@puntaje/shared/core"
import {
    EscalaGlobal,
    EscalaGlobalAlias,
    EscalaGlobalAliases,
    EscalaGlobales,
    EscalaGlobalForm,
    EvaluacionTipos
} from "@puntaje/puntaje/api-services"

@Component({
    selector: "escala-globales-form",
    templateUrl: "./escala-globales-form.component.html",
    styleUrls: ["./escala-globales-form.component.scss"]
})
export class EscalaGlobalesFormComponent implements OnInit {
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    escalaGlobal: EscalaGlobal = new EscalaGlobal()
    oldEscalaGlobal: EscalaGlobal = new EscalaGlobal()
    escalaGlobalAliases: EscalaGlobalAlias[] = []
    oldEscalaGlobalAliases: EscalaGlobalAlias[] = []
    params = EscalaGlobalForm.formParams
    form: UntypedFormGroup
    @Input() save_button_text = "Guardar"
    @Input() evaluacionTipo: string
    @Input() escalaGlobalId: number
    @Output() saving: EventEmitter<any> = new EventEmitter<any>()
    showNotaMinMAx: boolean = false
    showRangoPorcentaje: boolean = false
    showPersonalizado: boolean = false
    porcentajeLogro: number = 0

    constructor(
        protected cdr: ChangeDetectorRef,
        protected escalaGlobalServices: EscalaGlobales,
        protected escalaGlobalAliasServices: EscalaGlobalAliases,
        protected evaluacionTiposService: EvaluacionTipos,
        private s3Service: S3Service
    ) {}

    async ngOnInit() {
        this.form = EscalaGlobalForm.getForm(this.escalaGlobal)
        const evaluacionTipoId = await this.evaluacionTiposService.where({
            evaluacion_tipo: { evaluacion_tipo: this.evaluacionTipo }
        })
        if (this.evaluacionTipo && evaluacionTipoId) {
            this.form.controls.evaluacion_tipo_id.setValue(evaluacionTipoId[0]?.id)
            this.form.controls["evaluacion_tipo_id"].disable()
        }

        if (this.escalaGlobalId) {
            this.getEscalaGlobal()
        } else {
            this.loadingLayout.ready()
        }
    }

    getEscalaGlobal() {
        const params = {
            render_options: {
                include: ["escala_global_aliases"]
            }
        }
        this.escalaGlobalServices.find(this.escalaGlobalId, params).then((escalaGlobal: EscalaGlobal) => {
            this.showNotaMinMAx = escalaGlobal.tipo == "Continua"
            this.showRangoPorcentaje = escalaGlobal.tipo == "Discreta" || escalaGlobal.tipo == "Imagen"
            this.showPersonalizado = escalaGlobal.porcentaje_logro == 101 //101 es la opcion personalizado
            this.oldEscalaGlobal = escalaGlobal
            this.escalaGlobalAliases = escalaGlobal.escala_global_aliases
            this.oldEscalaGlobalAliases = [...this.escalaGlobalAliases].map(valorEscalaGlobalAlias =>
                Object.assign({}, valorEscalaGlobalAlias)
            )
            this.escalaGlobal = escalaGlobal.clone()
            this.loadingLayout.ready()
        })
    }

    createRango(start, porcentajeLogro) {
        this.escalaGlobalAliases = []
        while (start < 100) {
            const estacalaGlobalAlias = new EscalaGlobalAlias()
            estacalaGlobalAlias.equivalencia_min = start != 0 ? start + 1 : 0
            start = start + porcentajeLogro
            estacalaGlobalAlias.equivalencia_max = start
            estacalaGlobalAlias.alias = ""
            this.escalaGlobalAliases.push(estacalaGlobalAlias)
        }
    }

    generateContinua() {
        this.escalaGlobal.porcentaje_logro = 3
        this.showPersonalizado = false
        this.createRango(1, 3)
        this.escalaGlobalAliases[0].equivalencia_min = 0
        this.escalaGlobalAliases.sort((a, b) => b.equivalencia_max - a.equivalencia_max)
        this.changeNotas()
    }

    generateNotContinua(porcentajeLogro) {
        this.createRango(0, porcentajeLogro)
        if (this.escalaGlobalAliases[this.escalaGlobalAliases.length - 1].equivalencia_max > 100) {
            this.removeItem(this.escalaGlobalAliases.length - 1)
            this.escalaGlobalAliases[this.escalaGlobalAliases.length - 1].equivalencia_max = 100
        }
        this.escalaGlobalAliases.sort((a, b) => b.equivalencia_max - a.equivalencia_max)
    }

    changeNotas() {
        if (this.escalaGlobal.nota_min != null && this.escalaGlobal.nota_max != null) {
            this.escalaGlobalAliases.map(escalaGlobalAlias => {
                let alias = Math.round((this.escalaGlobal.nota_max / 100) * escalaGlobalAlias.equivalencia_max) //Math.min(Math.ceil(, valor))
                alias =
                    alias < this.escalaGlobal.nota_min && this.escalaGlobal.nota_min < this.escalaGlobal.nota_max
                        ? this.escalaGlobal.nota_min
                        : Math.min(alias)
                return (escalaGlobalAlias.alias = alias.toString())
            })
        }
    }

    selectedTipo(valor) {
        this.showNotaMinMAx = valor == "Continua"
        this.showRangoPorcentaje = valor == "Discreta" || valor == "Imagen"
        this.showPersonalizado = this.escalaGlobal.porcentaje_logro == 101 //101 es la opcion personalizado
        if (valor != this.escalaGlobal.tipo) {
            this.escalaGlobal.tipo = valor
            if (this.showNotaMinMAx) this.generateContinua()
        }
    }

    selectedRango(valor) {
        this.escalaGlobalAliases = []
        this.showPersonalizado = valor == 101
        if (!this.showPersonalizado) this.generateNotContinua(this.escalaGlobal.porcentaje_logro)
    }

    returnValorPositivo(valor) {
        return Math.sign(valor) == 1 ? valor : 0
    }

    agregarTramo() {
        const estacalaGlobalAlias = new EscalaGlobalAlias()
        const totalALiases = this.escalaGlobalAliases.length
        if (totalALiases == 0 || this.escalaGlobalAliases[totalALiases - 1].equivalencia_min > 0) {
            estacalaGlobalAlias.equivalencia_max =
                totalALiases > 0 ? this.escalaGlobalAliases[totalALiases - 1].equivalencia_min - 1 : 100

            estacalaGlobalAlias.equivalencia_min =
                totalALiases > 0 ? this.returnValorPositivo(estacalaGlobalAlias.equivalencia_max - 1) : 95

            if (this.escalaGlobal.tipo == "Discreta") estacalaGlobalAlias.alias = ""

            this.escalaGlobalAliases.push(estacalaGlobalAlias)
        }
    }

    setRam(valor, tipo, i) {
        const valorEdit = valor.target.value
        const totalALiases = this.escalaGlobalAliases.length

        this.escalaGlobalAliases[i][`equivalencia_${tipo}`] = valorEdit

        if (this.escalaGlobalAliases[i].equivalencia_min > this.escalaGlobalAliases[i].equivalencia_max) {
            this.escalaGlobalAliases[i].equivalencia_min = this.returnValorPositivo(
                this.escalaGlobalAliases[i].equivalencia_max - 1
            )
        } else if (this.escalaGlobalAliases[i].equivalencia_max < this.escalaGlobalAliases[i].equivalencia_min) {
            this.escalaGlobalAliases[i].equivalencia_max = this.escalaGlobalAliases[i].equivalencia_min + 1
        }

        if (tipo == "min") {
            if (totalALiases == i + 1) return
            for (let index = i + 1; index < this.escalaGlobalAliases.length; index++) {
                this.escalaGlobalAliases[index].equivalencia_max = this.returnValorPositivo(
                    this.escalaGlobalAliases[index - 1].equivalencia_min - 1
                )
                this.escalaGlobalAliases[index].equivalencia_min = this.returnValorPositivo(
                    this.escalaGlobalAliases[index].equivalencia_max - 1
                )
            }
        }

        if (tipo == "max") {
            for (let index = i - 1; index >= 0; index--) {
                if (
                    this.escalaGlobalAliases[index + 1].equivalencia_max >=
                    this.escalaGlobalAliases[index].equivalencia_max
                ) {
                    this.removeItem(index)
                } else {
                    this.escalaGlobalAliases[index].equivalencia_min =
                        parseInt(this.escalaGlobalAliases[index + 1].equivalencia_max.toString()) + 1
                }
            }
        }
    }

    setAlias(valor, i) {
        this.escalaGlobalAliases[i][`alias`] = valor.target.value
    }

    onFileInputChange(event: any, i) {
        const files = event.target.files
        this.escalaGlobalAliases[i][`file`] = files[0]
        let reader = new FileReader()
        reader.readAsDataURL(files[0])
        reader.onload = _event => {
            this.escalaGlobalAliases[i][`img`] = reader.result
        }
        if (this.escalaGlobalId) {
            this.escalaGlobalAliases[i][`alias`] = null
        }
    }

    removeItem(index) {
        this.escalaGlobalAliases.splice(index, 1)
    }

    setValoresDeleted() {
        this.oldEscalaGlobalAliases.forEach(escala => {
            if (!this.escalaGlobalAliases.find(newEscala => newEscala.id == escala.id)) {
                escala["_destroy"] = 1
                this.escalaGlobalAliases.push(escala)
            }
        })
    }

    save() {
        EscalaGlobalForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.loadingLayout.standby()
            if (this.escalaGlobalAliases) {
                if (this.escalaGlobalId && this.showPersonalizado) {
                    this.setValoresDeleted()
                }
                this.escalaGlobal.escala_global_aliases = this.escalaGlobalAliases
            }

            if (
                this.escalaGlobal.escala_global_aliases &&
                this.escalaGlobal.escala_global_aliases.length > 0 &&
                this.escalaGlobal.tipo == "Imagen"
            ) {
                let promises = this.escalaGlobal.escala_global_aliases.map(escalaGlobalAlias => {
                    if (escalaGlobalAlias.file) {
                        return new Promise((resolve, reject) => {
                            this.s3Service.where(escalaGlobalAlias.getS3Params()).then((policies: S3[]) => {
                                let policy = policies as any as S3
                                escalaGlobalAlias.alias = policy["key"]
                                this.s3Service.uploadToS3(
                                    policy,
                                    escalaGlobalAlias.file,
                                    this.escalaGlobalAliasServices.tableName,
                                    resolve as () => void
                                )
                            })
                        })
                    }

                    return null
                })
                Promise.all(promises).then(res => {
                    this.saving.emit(this.escalaGlobal)
                })
            } else {
                this.saving.emit(this.escalaGlobal)
            }
        }
    }

    clear() {
        setTimeout(() => {
            this.escalaGlobal = this.oldEscalaGlobal.clone()
            this.escalaGlobalAliases = [...this.oldEscalaGlobalAliases].map(valorEscalaGlobalAlias =>
                Object.assign({}, valorEscalaGlobalAlias)
            )
            EscalaGlobalForm.markFormControlsAsPristine(this.form)
            EscalaGlobalForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
