<ng-container *ngIf="estadisticaClasificaciones.length > 0 && !noData">
    <div class="scroll-x scroll-y" *ngIf="!inverted">
        <h4 class="tg-title">
            {{ "estadisticas.graficos.tdpuc.title1" | t }} {{ alias }} {{ "estadisticas.graficos.tdpuc.title2" | t }}.
        </h4>
        <p class="tg-description">{{ "estadisticas.graficos.tdpuc.descripcion" | t }}.</p>
        <div class="tg-info">
            <p class="tg-description">{{ "estadisticas.graficos.tdpuc.descripcion" | t }}.</p>
            <div class="tg-right-options">
                <cui-button [id]="'btnExcel'" class="btn_style_sm print-hide" (click)="getExcel()">
                    {{ "estadisticas.graficos.trepa.descargar_excel" | t }}
                </cui-button>
            </div>
        </div>
        <table *ngIf="estadisticaClasificaciones.length != 0 && !noData" class="table overflow-scroll">
            <thead>
                <tr class="tdpuc-row">
                    <th class="nombre-per first-column-fix">{{ "estadisticas.graficos.tdpuc.estudiante" | t }}</th>
                    <ng-container *ngFor="let usuario of usuarios">
                        <th class="visible-print nombre-per">
                            {{ usuario.nombre[0] }}.{{ usuario.apellido_paterno[0] }}.
                        </th>
                        <th
                            [popover]="usuario.nombreCompleto()"
                            placement="top"
                            triggers="mouseenter:mouseleave"
                            class="nombre-per hidden-print"
                        >
                            {{ usuario.nombre[0] }}.{{ usuario.apellido_paterno[0] }}.
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let ec of estadisticaClasificaciones; let i = index"
                    [class.ocultarFila]="i > 19 ? !_showAll : false"
                    class="tdpuc-row"
                >
                    <td class="first-column first-column-fix">{{ ec.clasificacion.clasificacion }}</td>
                    <td
                        *ngFor="let euc of ec.estadistica_usuario_clasificaciones"
                        class="nombre-per {{ setColorClass(euc.estadistica_clasificacion?.desempeno) }}"
                    >
                        {{ euc.estadistica_clasificacion?.desempeno | percentage: 1 | easyplaceholder: "-" }}
                        <br />
                        <fa name="clock-o"></fa>
                        {{ euc.estadistica_clasificacion?.tiempo_promedio | roundfloat: 1 | easyplaceholder: "-"
                        }}{{ euc.estadistica_clasificacion?.tiempo_promedio != undefined ? "s" : "" }}
                    </td>
                </tr>
                <div class="clearfix">
                    <a *ngIf="!_showAll && estadisticaClasificaciones.length > 19" (click)="showAll()" class="pointer">
                        {{ "estadisticas.graficos.tdpuc.ver_mas" | t }}
                    </a>
                    <a *ngIf="_showAll && estadisticaClasificaciones.length > 19" (click)="showAll()" class="pointer">
                        {{ "estadisticas.graficos.tdpuc.ocultar" | t }}
                    </a>
                </div>
            </tbody>
        </table>

        <div *ngIf="estadisticaClasificaciones.length == 0 || noData">
            {{ "estadisticas.graficos.tdpuc.not_found" | t }}
        </div>
    </div>

    <div class="scroll-x scroll-y print-hide" *ngIf="inverted">
        <h4>
            {{ "estadisticas.graficos.tdpuc.title1" | t }} {{ alias }} {{ "estadisticas.graficos.tdpuc.title2" | t }}
        </h4>
        <div class="tg-info">
            <p class="tg-description">{{ "estadisticas.graficos.tdpuc.descripcion" | t }}.</p>
            <div class="tg-right-options">
                <cui-button [id]="'btnExcel'" class="btn_style_sm print-hide" (click)="getExcel()">
                    {{ "estadisticas.graficos.trepa.descargar_excel" | t }}
                </cui-button>
            </div>
        </div>
        <table
            *ngIf="estadisticaClasificaciones.length != 0 && !noData && estadisticaClasificacionesInverted"
            class="table overflow-scroll"
        >
            <thead>
                <tr *ngIf="clasificacionTipoReferenced" class="tdpuc-row">
                    <th class="referenced-header first-column-fix"></th>
                    <ng-container *ngFor="let ec of estadisticaClasificaciones; let i = index">
                        <ng-template #tooltipHTML>
                            <div [innerHTML]="ec.clasificacion_referenced.descripcion"></div>
                        </ng-template>
                        <th
                            class="referenced-header"
                            *ngIf="
                                ec.clasificacion_referenced &&
                                (!estadisticaClasificaciones[i - 1] ||
                                    estadisticaClasificaciones[i - 1].clasificacion_referenced.id !=
                                        ec.clasificacion_referenced.id)
                            "
                            [ngClass]="{ 'border-right': true }"
                            [tooltip]="tooltipHTML"
                            [isDisabled]="!ec.clasificacion_referenced.descripcion"
                            [attr.colspan]="qtyClasificacionesHijas[ec.clasificacion_referenced.id]"
                        >
                            {{ ec.clasificacion_referenced.clasificacion }}
                        </th>
                    </ng-container>
                </tr>
                <tr sorterGroup class="tdpuc-row">
                    <th class="nombre-per first-column-fix">{{ "estadisticas.graficos.tdpuc.estudiante" | t }}</th>
                    <ng-container *ngFor="let ec of estadisticaClasificaciones; let i = index">
                        <ng-template #tootltipHTML>
                            <div [innerHTML]="ec.clasificacion.descripcion"></div>
                        </ng-template>
                        <th class="visible-print nombre-per">{{ ec.clasificacion.clasificacion }}</th>
                        <th
                            class="nombre-per hidden-print"
                            [ngClass]="{
                                'border-right':
                                    ec.clasificacion_referenced &&
                                    ec.clasificacion_referenced.id !=
                                        estadisticaClasificaciones[i + 1]?.clasificacion_referenced?.id
                            }"
                            [tooltip]="tootltipHTML"
                            [isDisabled]="!ec.clasificacion.descripcion"
                        >
                            <sorter [objectValue]="getDesempeno" [key]="ec.clasificacion.id" [(objects)]="usuarios">
                                {{ ec.clasificacion.clasificacion }}
                            </sorter>
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let usuario of usuarios; let i = index" [class.ocultarFila]="i > 9 ? !_showAll : false" class="tdpuc-row">
                    <td class="first-column first-column-fix">{{ usuario.nombreCompleto() }}</td>
                    <td
                        *ngFor="let euc of estadisticaClasificacionesInverted[usuario.id]; let i = index"
                        class="nombre-per {{ setColorClass(euc.estadistica_clasificacion?.desempeno) }}"
                        [ngClass]="{
                            'border-right':
                                euc.clasificacion_referenced &&
                                euc.clasificacion_referenced.id !=
                                    estadisticaClasificacionesInverted[usuario.id][i + 1]?.clasificacion_referenced?.id
                        }"
                    >
                        {{ euc.estadistica_clasificacion?.desempeno | percentage: 1 | easyplaceholder: "-" }}
                        <br />
                        <fa name="clock-o"></fa>
                        {{ euc.estadistica_clasificacion?.tiempo_promedio | roundfloat: 1 | easyplaceholder: "-"
                        }}{{ euc.estadistica_clasificacion?.tiempo_promedio != undefined ? "s" : "" }}
                    </td>
                </tr>
                <div class="clearfix">
                    <a *ngIf="!_showAll && usuarios.length > 9" (click)="showAll()" class="pointer">
                        {{ "estadisticas.graficos.tdpuc.ver_mas" | t }}
                    </a>
                    <a *ngIf="_showAll && usuarios.length > 9" (click)="showAll()" class="pointer">
                        {{ "estadisticas.graficos.tdpuc.ocultar" | t }}
                    </a>
                </div>
            </tbody>
        </table>

        <div *ngIf="estadisticaClasificaciones.length == 0 || noData">
            {{ "estadisticas.graficos.tdpuc.not_found" | t }}
        </div>
    </div>

    <div class="scroll-x scroll-y" [hidden]="true" class="print-show-block">
        <h4 class="tg-title">
            {{ "estadisticas.graficos.tdpuc.title1" | t }} {{ alias }} {{ "estadisticas.graficos.tdpuc.title2" | t }}.
        </h4>
        <p class="tg-description">{{ "estadisticas.graficos.tdpuc.descripcion" | t }}.</p>
        <div *ngIf="estadisticaClasificaciones.length != 0 && !noData">
            <table *ngFor="let ecs of estadisticaClasificacionesGrouped; let i = index" class="table overflow-scroll">
                <thead>
                    <tr sorterGroup class="tdpuc-row">
                        <th class="nombre-per first-column-fix">{{ "estadisticas.graficos.tdpuc.estudiante" | t }}</th>
                        <ng-container *ngFor="let ec of ecs">
                            <th class="visible-print nombre-per">
                                {{ ec.clasificacion.clasificacion }}
                            </th>
                            <th class="nombre-per hidden-print">
                                <sorter [objectValue]="getDesempeno" [key]="ec.clasificacion.id" [(objects)]="usuarios">
                                    {{ ec.clasificacion.clasificacion }}
                                </sorter>
                            </th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let usuario of usuarios"
                        [class.print-hide]="!hasEstadisticaClasificacionesInverted[usuario.id]"
                        class="tdpuc-row"
                    >
                        <td class="first-column first-column-fix">{{ usuario.nombreCompleto() }}</td>
                        <td
                            *ngFor="let euc of estadisticaClasificacionesInvertedGrouped[usuario.id][i]"
                            class="nombre-per {{ setColorClass(euc.estadistica_clasificacion?.desempeno) }}"
                        >
                            {{ euc.estadistica_clasificacion?.desempeno | percentage: 1 | easyplaceholder: "-" }}
                            <br />
                            <fa name="clock-o"></fa>
                            {{ euc.estadistica_clasificacion?.tiempo_promedio | roundfloat: 1 | easyplaceholder: "-"
                            }}{{ euc.estadistica_clasificacion?.tiempo_promedio != undefined ? "s" : "" }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div *ngIf="estadisticaClasificaciones.length == 0 || noData">
            {{ "estadisticas.graficos.tdpuc.not_found" | t }}
        </div>
    </div>
</ng-container>

<div [hidden]="estadisticaClasificaciones.length == 0 || noData" id="paginator-container">
    <paginator *ngIf="enablePaginator && inverted" [getList]="onPaginatorChange"></paginator>
</div>
