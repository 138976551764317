import { Component, OnInit, Input, ViewChild } from "@angular/core"
import { PlanPersonal, PlanPersonales } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Observable } from "rxjs/internal/Observable"
import {
    AsignaturaWithConfig,
    GeneradorInstrumentos,
    GeneradorInstrumento,
    Clasificacion,
    GeneradorInstrumentoCategoria
} from "@puntaje/nebulosa/api-services"
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasById } from "@puntaje/puntaje/store"
import { filter, first } from "rxjs/operators"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "datos-generales-plan-clase",
    templateUrl: "./datos-generales-plan-clase.component.html",
    styleUrls: ["./datos-generales-plan-clase.component.scss"]
})
export class DatosGeneralesPlanClaseComponent implements OnInit {
    asignaturasById$: Observable<{ [idAsignatura: number]: AsignaturaWithConfig }> = this.store.pipe(
        select(selectAsignaturasById),
        filter(x => !!x)
    )

    /**
     * Espera que venga con el generador de instrumento
     *
     * @type {PlanPersonal}
     * @memberof DatosGeneralesPlanClaseComponent
     */
    @Input() planPersonal: PlanPersonal
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    disableNivelCalugas = config.plataforma.disableNivelCalugas
    numeroSemanas: number
    nivel: Clasificacion
    nombreSesiones: string = config.plataforma.nombreSesiones || "semanas"

    constructor(protected store: Store<State>, protected generadorInstrumentoService: GeneradorInstrumentos) {}

    ngOnInit() {
        this.numeroSemanas = this.planPersonal
            ? this.planPersonal.plan_personal_ciclos.reduce((acc, ppc) => acc + ppc.plan_personal_sesiones.length, 0)
            : null
        this.generadorInstrumentoService
            .find(this.planPersonal.generador_instrumento_id, {
                include: "generador_instrumento_categorias:[categoria:clasificacion_tipo]"
            })
            .then((generador: GeneradorInstrumento) => {
                const categoriasCurso = generador.generador_instrumento_categorias.filter(
                    (gic: GeneradorInstrumentoCategoria) =>
                        gic.categoria.clasificacion_tipo.clasificacion_tipo == "curso"
                )
                if (categoriasCurso.length > 0) {
                    this.nivel = categoriasCurso[0].categoria
                }

                this.loadingLayout.ready()
            })
    }
}
