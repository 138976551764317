import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef } from "@angular/core"
import { FormBuilder, UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { PostForm, Posts, Post } from "@puntaje/puntaje/api-services"
import { FormsModule } from "@angular/forms"
import { S3, S3Service } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "posts-new",
    templateUrl: "posts.form.component.html",
    styleUrls: ["posts.form.component.scss"]
})
export class PostsNewComponent implements OnInit {
    post: Post = new Post()
    params = PostForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Crear post"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(
        protected router: Router,
        private s3Service: S3Service,
        protected authService: AuthService,
        protected cdr: ChangeDetectorRef,
        protected postsService: Posts
    ) {}

    ngOnInit() {
        this.form = PostForm.getForm(this.post)
        this.loadingLayout.ready()
    }

    save() {
        PostForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            let promise: Promise<any> = Promise.resolve()

            if (this.post.image_file) {
                promise = new Promise((resolve, reject) => {
                    this.s3Service.where(this.post.getS3Params()).then((policies: S3[]) => {
                        const policy = policies as any as S3
                        this.post.imagen = policy["key"]
                        this.s3Service.uploadToS3(policy, this.post.image_file, this.postsService.tableName, resolve)
                    })
                })
            }

            let promiseSm: Promise<any> = Promise.resolve()
            if (this.post.image_file_sm) {
                promiseSm = new Promise(resolve => {
                    this.s3Service.where(this.post.getS3ParamsSm()).then((policies: S3[]) => {
                        const policy = policies as any as S3
                        this.post.imagen_sm = policy["key"]
                        this.s3Service.uploadToS3(policy, this.post.image_file_sm, this.postsService.tableName, resolve)
                    })
                })
            }

            Promise.all([promise, promiseSm]).then(() => {
                this.doSave()
            })
        }
    }

    doSave = () => {
        this.postsService.save(this.post).then(response => {
            this.router.navigate(["posts"])
        })
    }

    clear() {
        setTimeout(() => {
            this.post = new Post()
            PostForm.markFormControlsAsPristine(this.form)
            PostForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
