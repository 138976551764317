import { BaseForm } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"
import { EvaluacionTipos } from "./evaluacion_tipos.service"

export class EscalaGlobalForm extends BaseForm {
    public static get formParams() {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            nombre: { label: "nombre", type: "text", visible: true, validations: [Validators.required] },
            tipo: {
                label: "tipo",
                type: "select",
                visible: false,
                options: [
                    {
                        id: "Continua",
                        toString: function () {
                            return "Continua"
                        }
                    },
                    {
                        id: "Discreta",
                        toString: function () {
                            return "Discreta"
                        }
                    },
                    {
                        id: "Imagen",
                        toString: function () {
                            return "Imagen"
                        }
                    }
                ],
                validations: [Validators.required]
            },
            evaluacion_tipo_id: {
                label: "Evaluacion tipo",
                type: "select",
                visible: true,
                options: { class: EvaluacionTipos },
                validations: [Validators.required]
            },
            activa: { label: "Activa", type: "checkbox", visible: true },
            porcentaje_aprobacion: {
                label: "porcentaje de aprobación",
                type: "number",
                visible: true,
                validations: [Validators.required]
            },
            nota_min: { label: "nota min", type: "number", visible: true },
            nota_max: { label: "nota max", type: "number", visible: true },
            porcentaje_logro: {
                label: "Selecciona el rango de porcentaje de logro:",
                type: "radio",
                visible: true,
                options: [
                    {
                        id: 10,
                        toString: function () {
                            return "10%"
                        }
                    },
                    {
                        id: 25,
                        toString: function () {
                            return "25%"
                        }
                    },
                    {
                        id: 33.3,
                        toString: function () {
                            return "33.3%"
                        }
                    },
                    {
                        id: 101,
                        toString: function () {
                            return "Personalizado"
                        }
                    }
                ]
            }
        }
    }
}
