import { Component, OnInit, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { ToolNavbar } from "@puntaje/shared/core"
import { PostsBuscadorService } from "../posts-buscador/posts-buscador.service"

@Component({
    templateUrl: "posts-toolnavbar.component.html",
    selector: "posts-toolnavbar"
})
export class PostsToolNavbarComponent extends ToolNavbar implements OnInit {
    @Input() postId: number
    @Input() displayButtons: string

    displayBtnAll: boolean = false
    displayBtnNew: boolean = false
    displayBtnEdit: boolean = false
    displayBtnSearch: boolean = false
    displayToolNavbar: boolean = true

    constructor(router: Router, route: ActivatedRoute, protected postsBuscadorService: PostsBuscadorService) {
        super(router, route)
    }

    ngOnInit() {
        this.urlAll = "/posts"
        this.urlNew = "/posts/new"
        if (this.postId != null) {
            this.urlEdit = "/posts/" + this.postId + "/edit"
        }

        this.displayButtonsInput = this.displayButtons
        this.checkDisplayButtons()
    }

    checkDisplayButtons() {
        if (this.displayButtons != null) {
            this.displayBtnAll = this.displayButtons.indexOf("all") !== -1
            this.displayBtnNew = this.displayButtons.indexOf("new") !== -1
            this.displayBtnEdit = this.displayButtons.indexOf("edit") !== -1
            this.displayBtnSearch = this.displayButtons.indexOf("search") !== -1
        }
        if (!this.displayBtnAll && !this.displayBtnNew) {
            this.displayToolNavbar = false
        }
    }

    edit() {
        if (this.postId != null) {
            this.urlEdit = "/posts/" + this.postId + "/edit"
        }
        super.edit()
    }

    search() {
        this.postsBuscadorService.openSearch.next()
    }
}
