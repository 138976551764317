<div
    class="modal fade staticModal"
    bsModal
    #mandatoryLoading="bs-modal"
    [config]="{ backdrop: 'static', keyboard: false }"
    tabindex="0"
    role="dialog"
    aria-labelledby="mySmallModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body">
                <ng-container *ngIf="!errorMsg">
                    <loading-layout #loadingLayout2 [noMarginY]="true" class="loading"></loading-layout>
                    <p class="loading-message">
                        Estamos generando el archivo para su impresión.
                        <br />
                        Esta operación puede tardar unos minutos, por favor espere.
                    </p>
                </ng-container>
                <ng-container *ngIf="errorMsg">
                    <p class="warning-icon">
                        <fa name="warning"></fa>
                    </p>
                    <p class="error-message">
                        Se ha producido un error al generar su archivo para impresión.
                        <br />
                        Por favor espere unos minutos e intente de nuevo.
                    </p>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-default"
                    data-dismiss="modal"
                    (click)="cancelPrint()"
                    *ngIf="!errorMsg"
                >
                    Cancelar
                </button>
                <button
                    type="button"
                    class="btn btn-default"
                    data-dismiss="modal"
                    *ngIf="errorMsg"
                    (click)="mandatoryLoading.hide()"
                >
                    Cerrar
                </button>
            </div>
        </div>
    </div>
</div>
