import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { PeriodosService } from "./periodos.service"

@Component({
    selector: "filtro-periodo",
    templateUrl: "filtro_periodo.component.html"
})
export class FiltroPeriodoComponent implements OnInit {
    @Output() select: EventEmitter<{
        fechaInicial: Date
        fechaFinal: Date
        periodo: number[]
    }> = new EventEmitter<{
        fechaInicial: Date
        fechaFinal: Date
        periodo: number[]
    }>()

    @Input() periodo: number[]

    periodos: number[][] = []
    years: number[]
    periodosConfig = config.plataforma.periodos

    constructor(protected periodosService: PeriodosService) {}

    ngOnInit() {
        const now = new Date()
        let year = new Date().getFullYear()

        this.years = new Array(year - config.plataforma.añoInicial + 1).fill(0).map((x, i) => year - i)

        const cantidadPeriodos = config.plataforma.periodos.length
        const periodosInverted = config.plataforma.periodos.map((p, i, arr) => {
            return arr[cantidadPeriodos - (i + 1)]
        })

        this.years.forEach(year => {
            periodosInverted.forEach((periodo, i) => {
                const [month , day] = periodo.fechaInicial
                const  fechaInicial = new Date(year, month, day)
                if (now >= fechaInicial) {
                    this.periodos.push([year, cantidadPeriodos - (i + 1)])
                }
            })
        })

        this.setPeriodo()
        this.onChangeSelect(this.periodo)
    }

    setPeriodo() {
        if (!!this.periodo) {
            this.periodo = this.periodos.find(p => p[0] == this.periodo[0] && p[1] == this.periodo[1])
        }
        // caso viene vacio en el input o no existe en los periodos
        if (!this.periodo) {
            this.periodo = this.periodos[0]
        }
    }

    onChangeSelect([year, i]: any) {

        const [month , day] =  config.plataforma.periodos[i].fechaInicial
        const  fechaInicial = new Date(year, month, day)

        // let fechaInicial = config.plataforma.periodos[i].fechaInicial(year)
        const [month2, day2, hour, min, second] =  config.plataforma.periodos[i].fechaFinal
        const fechaFinal = new Date(year, month2, day2, hour, min, second)
        // let fechaFinal = config.plataforma.periodos[i].fechaFinal(year)

        this.select.emit({ fechaInicial, fechaFinal, periodo: this.periodo })
        this.periodosService.periodosChange.next({ fechaInicial, fechaFinal })
    }
}
