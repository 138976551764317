import { Component, OnInit, ViewChild, Input, SimpleChanges } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Asignatura, ClasificacionTipos, ClasificacionTipo } from "@puntaje/nebulosa/api-services"
import { BaseChart, StackedBarChart, ChartColorType, I18nService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
/*********************************
Gráfico de stack de barras de desempeño por clasificación.
Recibe dataOmitidas: number[], dataCorrectas: number[], dataIncorrectas: number[]
ej: [5, 3, 4, 7, 2], [5, 3, 4, 7, 2], [5, 3, 4, 7, 2]
**********************************/

@Component({
    selector: "grafico-desempeno-clasificacion-compuesto",
    template: `
        <loading-layout #loadingLayout>
            <estadisticas-grafico
                [grafico]="grafico"
                *ngIf="!loadingLayout.loading && dataTotal.length > 0"
            ></estadisticas-grafico>
        </loading-layout>
    `
})
export class GraficoDesempenoClasificacionCompuestoComponent implements OnInit {
    @Input() asignatura: Asignatura
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @Input() clasificaciones: string[] = []
    @Input() dataOmitidas: number[] = []
    @Input() dataCorrectas: number[] = []
    @Input() dataIncorrectas: number[] = []
    @Input() dataTotal: number[] = []
    @Input() tipoClasificacion: string
    @Input() evaluacionTipo: string
    grafico: BaseChart
    enableGraph: boolean = false
    clasificacion_tipo: ClasificacionTipo

    constructor(private clasificacionTiposService: ClasificacionTipos, private i18nService: I18nService) {}

    ngOnInit() {
        if (!this.tipoClasificacion) {
            this.tipoClasificacion = "eje temático"
        }
    }

    setGrafico() {
        this.dataCorrectas = this.dataCorrectas || []
        this.dataOmitidas = this.dataOmitidas || []
        this.dataIncorrectas = this.dataIncorrectas || []
        this.dataTotal = this.dataTotal || []

        let sufijo = ""
        if (this.asignatura) {
            const para = this.i18nService.translate("estadisticas.graficos.dcc.para", {})

            let confEvaluacionTipo = this.asignatura["confByEvaluacionTipo"]?.[this.evaluacionTipo]
            if (!confEvaluacionTipo) {
                confEvaluacionTipo = this.asignatura["confByEvaluacionTipo"]?.[config.plataforma.evaluacionDefault]
            }

            sufijo += " " + para + " " + (confEvaluacionTipo?.abreviacion ?? this.asignatura.asignatura)
        }

        this.loadingLayout.standby()
        const g1: any = {}

        g1.titulo =
            this.i18nService.translate("estadisticas.graficos.dcc.desempeno", {}) + this.tipoClasificacion + sufijo
        g1.descripcion =
            this.i18nService.translate("estadisticas.graficos.dcc.descripcion1", {}) +
            this.tipoClasificacion +
            this.i18nService.translate("estadisticas.graficos.dcc.descripcion2", {})
        g1.ejeX = this.clasificaciones
        g1.ejeY = " "
        g1.data = [
            { nombre: this.i18nService.translate("estadisticas.graficos.dcc.correctas", {}), data: this.dataCorrectas },
            {
                nombre: this.i18nService.translate("estadisticas.graficos.dcc.incorrectas", {}),
                data: this.dataIncorrectas
            },
            { nombre: this.i18nService.translate("estadisticas.graficos.dcc.omitidas", {}), data: this.dataOmitidas }
        ]
        g1.tooltip =
            '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.1f}%)<br/>'
        g1.tipoColor = ChartColorType.Resultados
        this.grafico = new StackedBarChart(g1)
        this.loadingLayout.ready()
    }

    setClasificacionName(tipoClasificacion: string) {
        const params = {
            clasificacion_tipo: tipoClasificacion,
            render_options: {
                include: {
                    clasificacion_tipo_aliases: {
                        include: {
                            asignatura_plataforma: null
                        }
                    }
                }
            }
        }

        this.clasificacionTiposService.where(params).then((response: ClasificacionTipo[]) => {
            this.clasificacion_tipo = response[0]
            if (this.clasificacion_tipo.clasificacion_tipo_aliases.length > 0) {
                let ctas = this.clasificacion_tipo.clasificacion_tipo_aliases.filter(
                    cta =>
                        cta.asignatura_plataforma.plataforma_id == config.plataforma.id &&
                        cta.asignatura_plataforma.asignatura_id == this.asignatura.id
                )
                if (ctas.length > 0) {
                    this.tipoClasificacion = ctas[0].alias
                }
            }
            this.setGrafico()
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        this.setClasificacionName(this.tipoClasificacion)
    }
}
