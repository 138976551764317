import { Component, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"

import { Actividad, Actividades, ActividadView } from "@puntaje/achievements/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig, PaginatorComponent } from "@puntaje/shared/core"

@Component({
    selector: "actividades-list",
    templateUrl: "./actividades-list.component.html",
    styleUrls: ["./actividades-list.component.scss"]
})
export class ActividadesListComponent implements OnInit {
    tableButtonsArray: any
    actividades: Actividad[]
    params: any
    searchParams: any

    @ViewChild("loadingLayout", { static: true })
    loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent)
    paginator: PaginatorComponent

    constructor(protected router: Router, protected actividadesService: Actividades, protected config: AppConfig) {}

    ngOnInit() {
        this.actividades = []
        this.params = ActividadView.viewParams
        this.searchParams = {}

        this.tableButtonsArray = {
            // view: {
            //     function: this.view.bind(this),
            //     text: "Ver",
            //     glyphicon: "glyphicon-search"
            // },
            edit: {
                function: this.edit.bind(this),
                text: "Editar",
                glyphicon: "glyphicon-pencil"
            }
        }

        /* ToDo:  integrar toolnavbar y search component para realizar busquedas */

        // this.searchSub = this.actividadesSearchService.searchParams.subscribe(params => {
        //     this.searchParams = params
        //     this.paginator.changePage(1)
        // })
    }

    showAll = (page: number = 1, count: number = 20) => {
        const params = {
            page,
            per: count,
            plataforma: {
                plataforma: this.config.plataforma.name
            }
        }

        this.loadingLayout.standby()

        return this.actividadesService.where(params).then((response: Actividad[], total: number) => {
            this.actividades = response
            this.loadingLayout.ready()

            return total
        })
    }

    view(actividad: Actividad) {
        // this.router.navigate(["achievements/actividades" + actividad.id])
    }

    edit(actividad: Actividad) {
        this.router.navigate(["achievements/actividades/" + actividad.id + "/edit"])
    }
}
