import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./actividad-edit.component.html",
    styleUrls: ["./actividad-edit.component.scss"]
})
export class ActividadEditComponent implements OnInit {
    sub: Subscription
    actividadId: number

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.achievements.actividades.edit")
        this.sub = this.route.params.subscribe(params => {
            this.actividadId = +params["id"]
        })
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe()
    }
}
