import { Component, OnInit, Input, ViewChild } from "@angular/core"
import { Instrumento, Instrumentos } from "@puntaje/puntaje/api-services"
import { Clasificacion, Clasificaciones } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "datos-generales",
    templateUrl: "datos_generales.component.html",
    styleUrls: ["datos_generales.component.scss"]
})
export class DatosGeneralesComponent implements OnInit {
    @Input() instrumento
    @Input() groupedBy
    groupedClasificaciones: Clasificacion
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent

    groups: string[]
    firstColumnIndexes: number[]
    secondColumnIndexes: number[]
    preguntasPilotos = []
    enablePreguntasPiloto: boolean = true
    enableShowCategorias: boolean = true

    constructor(private clasificacionesService: Clasificaciones) {}

    ngOnInit() {
        if (this.instrumento.instrumento_material) {
            this.enablePreguntasPiloto = false
            this.enableShowCategorias = false
            if (this.loadingLayout) this.loadingLayout.ready()
        } else {
            this.preguntasPilotos = this.obtenerPreguntasPiloto(this.instrumento.instrumento_preguntas)
            this.getClasificaciones()
        }
    }

    getClasificaciones() {
        let clasificacionIds = JSON.parse(this.instrumento.categorias)
        if (!this.instrumento.generador_instrumento || !this.instrumento.generador_instrumento.tipo_instrumento) {
            this.groupedClasificaciones = undefined
            if (this.loadingLayout) this.loadingLayout.ready()
            return
        }

        // TODO: Sacar el input del groupedBy porq esto viene del config.
        this.groupedBy =
            config.evaluaciones[
                this.instrumento.generador_instrumento.tipo_instrumento.tipo_instrumento
            ].clasificaciones.groupBy
        this.groups = this.groupedBy.map(t => t.split(","))
        let until = Math.max(0, Math.ceil((this.groups.length - 3) / 2))

        this.firstColumnIndexes = new Array(until).fill(0).map((v, i) => i)
        this.secondColumnIndexes = new Array(this.groups.length - until).fill(0).map((v, i) => i + until)

        let params = {
            group_by: this.groupedBy,
            clasificacion: {
                id: clasificacionIds
            },
            with_cantidad_preguntas: 1,
            pregunta: {
                id: this.instrumento.instrumento_preguntas.map(ip => ip.pregunta_id).concat(null)
            }
        }

        this.clasificacionesService.wherePost(params).then((clasificaciones: Clasificacion[]) => {
            let groupBy = (arr: any[], f: Function) => {
                return arr.reduce((acc, x) => {
                    acc[f(x)] = acc[f(x)] || []
                    acc[f(x)].push(x)

                    return acc
                }, {})
            }

            this.groupedClasificaciones = groupBy(clasificaciones, c => c.clasificacion_tipo.clasificacion_tipo)
            if (this.loadingLayout) this.loadingLayout.ready()
        })
    }

    public obtenerPreguntasPiloto(preguntasPilotos) {
        let result = []
        for (let inst of preguntasPilotos) {
            if (inst.piloto == true) {
                result.push(inst)
            }
        }
        return result
    }

    meaningObj = {
        curso: "Nivel de dificultad de contenidos",
        "habilidad cognitiva": "Habilidades cognitivas abarcadas",
        "eje temático": "Ejes Temáticos abarcados",
        "eje temático LGE": "Ejes abarcados",
        "unidad LOCE": "Unidades abarcadas",
        componente: "Componentes abarcados",
        competencia: "Competencias abarcadas"
    }
}
