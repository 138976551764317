<form class="row" *ngIf="open">
    <div class="col-md-6">
        <div class="form-group">
            <label>ID</label>
            <input type="text" [(ngModel)]="logro.id" name="id" class="form-control" />
        </div>

        <div class="form-group">
            <label>Logro</label>
            <input type="text" [(ngModel)]="logro.logro" name="logro" class="form-control" />
        </div>

        <div *ngIf="categorias" class="form-group">
            <label>Categoria</label>
            <select
                class="form-control"
                [(ngModel)]="categoria_id"
                name="categoria-id"
                (input)="onChangeCategoria($event)"
            >
                <option [value]="">Todas</option>
                <option *ngFor="let categoria of categorias" [value]="categoria.id">
                    {{ categoria.categoria }}
                </option>
            </select>
        </div>

        <cui-button-link class="btn_style" (click)="search()">Buscar</cui-button-link>
    </div>
</form>
