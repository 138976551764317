<loading-layout #loadingLayout>
    <logros-buscador (onReady)="onBuscadorReady()"></logros-buscador>
    <br />
    <div class="overflowable">
        <loading-layout #tableLoading>
            <table-with-actions
                *ngIf="logros"
                [getObjects]="showAll"
                [contentArray]="logros"
                [showParams]="params"
                [tableButtonsArray]="tableButtonsArray"
            ></table-with-actions>
        </loading-layout>
    </div>
    <paginator [getList]="showAll"></paginator>
</loading-layout>
