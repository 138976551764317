import { PuntajeUsuariosModule } from "@puntaje/puntaje/core"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { UsuariosModule } from "@puntaje/puntaje/new-modules/usuarios"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"

import { routing } from "./admin_usuarios.routing"
import { UtilModule, FormModule, ModelViewModule } from "@puntaje/shared/core"
import { AdminLayoutsModule } from "../layouts/index"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AdminLayoutsModule,
        UtilModule,
        UsuariosModule,
        ModelViewModule,
        routing,
        Angular2FontawesomeModule,
        PuntajeUsuariosModule
    ],
    declarations: [],
    exports: []
})
export class AdminUsuariosModule {}
