import { Component, OnInit, Output, EventEmitter } from "@angular/core"
import { Posts, Post } from "@puntaje/puntaje/api-services"
import { PostsBuscadorService } from "./posts-buscador.service"
import { Subscription } from "rxjs"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { SessionService } from "@puntaje/shared/core"

@Component({
    selector: "posts-buscador",
    templateUrl: "posts-buscador.component.html"
})
export class PostsBuscadorComponent implements OnInit {
    post: any = {}

    // sirve para poder poner campos por defecto que se llenen de forma asincrona
    @Output() onReady = new EventEmitter()

    sub: Subscription
    open: boolean = false

    constructor(protected postsBuscadorService: PostsBuscadorService, protected sessionService: SessionService) {}

    ngOnInit() {
        this.sub = this.postsBuscadorService.openSearch.subscribe(() => {
            this.open = !this.open
        })

        this.onReady.emit()
    }

    search() {
        this.postsBuscadorService.search(this.post)
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
