import { PuntajeLandingModule } from "@puntaje/puntaje/core"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule } from "@angular/forms"

import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { UtilModule } from "@puntaje/shared/core"

import { LandingComponent } from "./landing.component"

import { AdminLayoutsModule } from "../layouts/index"
import { LandingRouting } from "./admin_landing.routing"
import { UsuariosModule } from "@puntaje/puntaje/new-modules/usuarios"
import { LandingModule } from "@puntaje/puntaje/new-modules/landing"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        LandingRouting,
        AdminLayoutsModule,
        LandingModule,
        UsuariosModule,
        Angular2FontawesomeModule,
        UtilModule,
        PuntajeLandingModule
    ],
    declarations: [LandingComponent],
    exports: [LandingComponent]
})
export class AdminLandingModule {
    static forRoot(): ModuleWithProviders<AdminLandingModule> {
        return {
            ngModule: AdminLandingModule,
            providers: []
        }
    }
}
