import { BaseForm, I18nService } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"
import { NoticiasPerfil } from "./noticias_perfil.model"
import { NoticiasPerfiles } from "./noticias_perfil.service"
import { NoticiaTipos } from "./noticia_tipos.service"

export class NoticiaForm extends BaseForm {
    public static get formParams() {
        let translatorService
        if (this.injector) translatorService = this.injector.get(I18nService)
        return {
            id: { label: "Id", type: "hidden", visible: false },
            titulo: { label: "Titulo", type: "text", visible: true, validations: [Validators.required] },
            noticia: { label: this.injector ? translatorService.translate("noticias.form.noticia") : "Noticia", type: "ckeditor", visible: true },
            destacado: { label: "Destacado", type: "checkbox", visible: true },
            notificar: { label: "Notificar", type: "checkbox", visible: true },
            noticia_tipo_id: {
                label: "Tipo de Noticia",
                type: "select",
                visible: true,
                options: { class: NoticiaTipos }
            },
            noticias_perfil_id: {
                label: "Noticia Perfil",
                type: "select",
                visible: true,
                options: { class: NoticiasPerfiles },
                withLabel: false
            },
            tipo: {
                label: "Mostrar popup al logear",
                type: "select",
                visible: true,
                options: [
                    {
                        id: "login",
                        toString: function () {
                            return "En cada login"
                        }
                    },
                    {
                        id: "una_sola_vez",
                        toString: function () {
                            return "Sólo una vez"
                        }
                    },
                    {
                        id: "ninguno",
                        toString: function () {
                            return "No mostrar"
                        }
                    }
                ]
            },
            fecha_inicio: { label: this.injector ? translatorService.translate("noticias.form.fecha_inicio") : "Fecha Inicio Noticia", type: "datepicker", visible: true },
            fecha_termino: { label: this.injector ? translatorService.translate("noticias.form.fecha_termino") : "Fecha Termino Noticia", type: "datepicker", visible: true },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
        }
    }
}
