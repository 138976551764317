import { Component, OnInit, Input, OnDestroy, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Establecimientos, Establecimiento, AsignaturaEvaluacionTipos } from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"
import { AllReadyService } from "@puntaje/puntaje/new-modules/shared"
import { LongWaitLoadingModalComponent } from "@puntaje/puntaje/new-modules/shared"
import { Subscription } from "rxjs"
import { select, Store } from "@ngrx/store"
import { selectAsignaturasById, State } from "@puntaje/puntaje/store"
import { filter, first } from "rxjs/operators"
import { Asignatura, AsignaturaWithConfig, TipoInstrumentoPlataformas } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "informe-anual",
    templateUrl: "informe_anual.component.html",
    styleUrls: ["informe_anual.component.scss"]
})
export class InformeAnualComponent implements OnInit {
    config: typeof config
    @Input() enableEstablecimientoSelect: boolean = false
    @Input() enableEstablecimientoAutocomplete: boolean = false
    @Input() mostrarTablaDesempeno: boolean = true
    filteredEstablecimientos: Establecimiento[]
    establecimientosUsuario: Establecimiento[]
    establecimiento: Establecimiento
    selectedEstablecimiento: Establecimiento
    showInforme: boolean = false
    fechaInicial: Date
    fechaFinal: Date
    anioFecha: number
    selectedFechaInicial: Date
    selectedFechaFinal: Date
    sub: Subscription
    allReadyKey: string = "informe-anual"
    tiposInstrumentoInformeUso: string[] = config.plataforma.tiposInstrumentoInformeUso || ["ensayo", "curricular"]
    niveles: any = {}
    tipoInstrumentosDict: any = {}
    @ViewChild("loadingModal", { static: true }) loadingModal: LongWaitLoadingModalComponent
    wasCancelled: boolean = false
    lastReady: Date
    secciones: any = [
        { pos: 1, titulo: "Información General Estudiantes" },
        { pos: 2, titulo: "Evaluaciones" },
        { pos: 3, titulo: "Tabla de desempeño por año" },
        { pos: 4, titulo: "Desempeño" }
    ]

    asignaturasById$ = this.store.pipe(
        select(selectAsignaturasById),
        filter(x => !!x)
    )
    asignaturasByEvaluacionTipo: { [et: string]: AsignaturaWithConfig[] }

    constructor(
        protected establecimientosService: Establecimientos,
        protected authService: AuthService,
        protected allreadyservice: AllReadyService,
        protected store: Store<State>,
        protected asignaturaEvaluacionTiposService: AsignaturaEvaluacionTipos,
        private tipoInstrumentoPlataformasService: TipoInstrumentoPlataformas
    ) {
        this.config = config
        this.sub = this.allreadyservice.allReady.subscribe(key => {
            if (key == "informe-anual" && !this.wasCancelled) {
                setTimeout(() => {
                    this.loadingModal.hide()
                    this.lastReady = new Date()
                    this.print()
                }, 2000)
            }
        })

        if (!this.mostrarTablaDesempeno) this.secciones.splice(2, 1)
    }

    ngOnInit() {
        this.tiposInstrumentoInformeUso.forEach(t => {
            this.niveles[t] = config.evaluaciones[t]?.informeFinal?.cursos || config.evaluaciones[t].cursos
        })
        this.tipoInstrumentoPlataformasService
            .configurarAliases(config.plataforma.name, this.tiposInstrumentoInformeUso)
            .then(dict => {
                this.tipoInstrumentosDict = dict
            })
        if (!this.enableEstablecimientoAutocomplete) {
            // Aquí debería ir a buscar el id del establecimeinto del utp
            let params = {
                establecimiento: {
                    id: this.authService.getEstablecimientos(),
                    activo: 1
                }
            }

            this.establecimientosService.where(params).then((establecimientos: Establecimiento[]) => {
                this.establecimientosUsuario = establecimientos
                this.establecimiento = establecimientos[0]
                this.selectedEstablecimiento = this.establecimiento
                this.enableEstablecimientoSelect = this.establecimientosUsuario.length > 1
            })
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    print() {
        window.print()
    }

    getEstablecimientos(establecimientoFilter: string) {
        this.establecimientosService
            .where({ per: 100, establecimiento: { establecimiento: establecimientoFilter } })
            .then((establecimientos: Establecimiento[]) => {
                this.filteredEstablecimientos = establecimientos
            })
    }

    onSelectPeriodo({ fechaInicial, fechaFinal }) {
        this.fechaInicial = fechaInicial
        this.fechaFinal = fechaFinal
        this.anioFecha = this.fechaInicial.getFullYear()
    }

    cancelled() {
        this.wasCancelled = true
    }

    calcularIndice(pos: number) {
        const indice = this.secciones.findIndex(item => item.pos === pos)

        return indice + 1
    }

    async verInforme() {
        await this.getAsignaturas()

        // Por si queremos que se muestre la impresión altiro si no se ha cambiado el establecimiento
        if (
            this.selectedEstablecimiento == this.establecimiento &&
            this.selectedFechaFinal == this.fechaFinal &&
            this.selectedFechaInicial == this.fechaInicial
        ) {
            if (this.lastReady) {
                this.print()
            } else {
                this.wasCancelled = false
                this.loadingModal.show()
            }
        } else {
            this.selectedEstablecimiento = this.establecimiento
            this.selectedFechaFinal = this.fechaFinal
            this.selectedFechaInicial = this.fechaInicial
            if (this.selectedEstablecimiento && this.selectedFechaFinal && this.selectedFechaInicial) {
                this.lastReady = null
                this.showInforme = true
                this.wasCancelled = false
                this.allreadyservice.setCounterWithKey(this.allReadyKey, 8)
                //this.loadingModal.show();
            }
        }
    }

    async getAsignaturas() {
        const asignaturaById = await this.asignaturasById$.pipe(first()).toPromise()

        const aetParams = {
            asignatura_evaluacion_tipo: { plataforma: this.config.plataforma.name },
            evaluacion_tipo: {
                evaluacion_tipo: this.config.plataforma.tiposInstrumentoInformeUso
            },
            render_options: {
                include: {
                    evaluacion_tipo: { only: ["evaluacion_tipo"] },
                    asignatura_material_clasificacion_tipos: null,
                    asignatura_material_niveles: null
                }
            },
            establecimiento_ids: [this.establecimiento.id]
        }

        const asignaturaEvaluacionTipos = await this.asignaturaEvaluacionTiposService.where(aetParams)

        this.asignaturasByEvaluacionTipo = asignaturaEvaluacionTipos.reduce((acc, asignaturaEvaluacionTipo) => {
            const asignatura = asignaturaById[asignaturaEvaluacionTipo.asignatura_id]

            if (!asignatura) {
                return acc
            }

            const newAsignatura: AsignaturaWithConfig = new Asignatura() as AsignaturaWithConfig
            Object.assign(newAsignatura, asignatura)
            newAsignatura.clase = asignaturaEvaluacionTipo.clase
            newAsignatura.icono = asignaturaEvaluacionTipo.icono
            newAsignatura.abreviacion = asignaturaEvaluacionTipo.abreviacion
            newAsignatura.asignaturaPlataforma = asignaturaEvaluacionTipo.asignatura_plataforma

            acc[asignaturaEvaluacionTipo.evaluacion_tipo.evaluacion_tipo] =
                acc[asignaturaEvaluacionTipo.evaluacion_tipo.evaluacion_tipo] || []
            acc[asignaturaEvaluacionTipo.evaluacion_tipo.evaluacion_tipo].push(newAsignatura)

            return acc
        }, {})
    }
}
