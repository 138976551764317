import { PlanEstudioComponent, PlanEstudiosComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"

export const planEstudiosRoutes: Routes = [
    {
        path: "plan_estudios",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanEstudiosComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "plan_estudios/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanEstudioComponent, canActivate: [AuthGuard], data: { tab: "" } }]
    }
]

export const planEstudiosRouting = RouterModule.forChild(planEstudiosRoutes)
