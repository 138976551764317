<div class="filtros">
    <loading-layout #loadingLayout>
        <h4>
            <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
            {{ "reportes.filtrar_datos" | t }}
        </h4>
        <div class="filtro-wrap">
            <div class="filtro">
                <label>{{ "reportes.resumen_uso.evaluacion" | t }}</label>
                <filtro-evaluacion-tipo
                    (evaluacionTipoChange)="onEvaluacionTipoChange($event)"
                    [transformacion]="'filtroEstadistica'"
                ></filtro-evaluacion-tipo>
            </div>
            <div class="filtro">
                <label>{{ "reportes.resumen_uso.asignatura" | t }}</label>
                <select class="form-control push-down" [(ngModel)]="asignatura" (ngModelChange)="onAsignaturaChange()">
                    <option [ngValue]="undefined">-- Seleccione Asignatura --</option>
                    <option *ngFor="let a of asignaturas" [ngValue]="a">
                        {{ a.asignatura }}
                    </option>
                </select>
            </div>
            <div class="filtro">
                <label>{{ "reportes.resumen_uso.tipo" | t }}</label>
                <select class="form-control push-down" [(ngModel)]="generadorInstrumento">
                    <option [ngValue]="undefined">-- Seleccione Tipo --</option>
                    <option *ngFor="let g of generadorInstrumentos" [ngValue]="g">
                        {{ g.generador_instrumento }}
                    </option>
                </select>
            </div>
            <div class="filtro" *ngIf="tipo_resumen == 'asociacion'">
                <label [attr.for]="asociaciones">{{ "reportes.resumen_uso.asociaciones" | t }}</label>
                <select
                    class="form-control push-down"
                    id="establecimientos"
                    (change)="obtenerEstablecimientos(asociacion)"
                    [(ngModel)]="asociacion"
                >
                    <option *ngFor="let a of asociaciones" [ngValue]="a">
                        {{ a.asociacion }}
                    </option>
                </select>
            </div>
            <div class="filtro" *ngIf="tipo_resumen != 'asociacion'">
                <label [attr.for]="establecimientos">{{ "reportes.resumen_uso.establecimiento" | t }}</label>
                <ng-multiselect-dropdown
                    *ngIf="establecimientos"
                    [settings]="dropdownEstablecimientosSettings"
                    [placeholder]="'-- Seleccione Establecimiento --'"
                    [data]="establecimientos"
                    [(ngModel)]="selectedEstablecimientos"
                    (ngModelChange)="getGrupoUsuarios()"
                ></ng-multiselect-dropdown>
            </div>
            <div class="filtro" *ngIf="tipo_resumen != 'asociacion' && grupoUsuarios">
                <label [attr.for]="'grupos'">{{ "reportes.resumen_uso.curso" | t }}</label>
                <select class="form-control push-down" id="grupos" [(ngModel)]="grupoUsuario">
                    <option [ngValue]="undefined">{{ "reportes.resumen_uso.seleccionar_curso" | t }}</option>
                    <option *ngFor="let grupoUsuario of grupoUsuarios" [ngValue]="grupoUsuario">
                        {{ grupoUsuario.nombre }}
                    </option>
                </select>
            </div>
            <div class="filtro">
                <label>{{ "reportes.resumen_uso.desde" | t }}</label>
                <my-date-picker
                    [(ngModel)]="fechaInicio"
                    (ngModelChange)="getGrupoUsuarios()"
                    [options]="myDatePickerOptions"
                    locale="es"
                    class="bottom-space"
                ></my-date-picker>
            </div>
            <div class="filtro">
                <label>{{ "reportes.resumen_uso.hasta" | t }}</label>
                <my-date-picker
                    [(ngModel)]="fechaFin"
                    (ngModelChange)="getGrupoUsuarios()"
                    [options]="myDatePickerOptions"
                    locale="es"
                    class="bottom-space"
                ></my-date-picker>
            </div>
        </div>
        <div class="bottom-options">
            <p class="legenda">{{ "reportes.resumen_uso.info" | t }}</p>
            <cui-button class="btn_style" (click)="showResumen()">
                <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
                &nbsp;{{ "reportes.resumen_uso.buscar" | t }}
            </cui-button>
        </div>
    </loading-layout>
</div>
<div easyScroll [scrollOnVariableChange]="stats" [easyScrollPadding]="-150">
    <loading-layout #loadingLayoutResultados>
        <cui-section-headline *ngIf="resumenActive && statsCombined">
            {{ "reportes.resumen_uso.resultados.resultados_acumulados" | t }}
        </cui-section-headline>
        <div class="row" *ngIf="statsCombined">
            <div class="col-md-12">
                <establecimiento-resumen-utp
                    *ngIf="resumenActive"
                    [stats]="statsCombined"
                ></establecimiento-resumen-utp>
            </div>
        </div>
        <br />
        <cui-section-headline *ngIf="resumenActive">
            {{
                stats && stats.length <= 1
                    ? ("reportes.resumen_uso.resultados.resultados" | t)
                    : ("reportes.resumen_uso.resultados.resultados_establecimiento" | t)
            }}
        </cui-section-headline>
        <div class="row" *ngIf="stats && stats.length > 0; else showText">
            <ng-container *ngFor="let s of stats">
                <div class="col-md-8 col-sm-7">
                    <establecimiento-resumen-utp
                        *ngIf="resumenActive"
                        [establecimiento]="s.establecimiento"
                        [stats]="s.stats"
                    ></establecimiento-resumen-utp>
                </div>
                <div class="col-md-4 col-sm-5">
                    <div class="result-box">
                        <div class="categoria-header">
                            <div class="categoria-row">
                                <fa name="graduation-cap" class="categoria-icono categoria-cell"></fa>
                                <div class="categoria-text categoria-cell">
                                    <p>{{ "reportes.resumen_uso.resultados.actividad_docente.titulo" | t }}</p>
                                </div>
                                <ng-template #popTemplate><div [innerHtml]="htmlPopover"></div></ng-template>
                                <fa
                                    class="help-icon categoria-cell"
                                    [name]="'exclamation-circle'"
                                    placement="left"
                                    [popover]="popTemplate"
                                ></fa>
                            </div>
                        </div>
                        <div class="tableWrap">
                            <table class="table tabla-docentes">
                                <thead>
                                    <tr>
                                        <th>
                                            <sorter [key]="'nombre'" [(objects)]="s.stats.profesores">
                                                {{ "reportes.resumen_uso.resultados.actividad_docente.nombre" | t }}
                                            </sorter>
                                        </th>
                                        <th>
                                            <sorter [key]="'ultimo'" [(objects)]="s.stats.profesores">
                                                {{
                                                    "reportes.resumen_uso.resultados.actividad_docente.ultimo_ingreso"
                                                        | t
                                                }}
                                            </sorter>
                                        </th>
                                        <th>
                                            <sorter [key]="'30dias'" [(objects)]="s.stats.profesores">
                                                {{
                                                    "reportes.resumen_uso.resultados.actividad_docente.ingresos_30_dias"
                                                        | t
                                                }}
                                            </sorter>
                                        </th>
                                        <th>
                                            <sorter [key]="'total_instrumentos'" [(objects)]="s.stats.profesores">
                                                {{
                                                    "reportes.resumen_uso.resultados.actividad_docente.total_instrumentos"
                                                        | t
                                                }}
                                            </sorter>
                                        </th>
                                        <th>
                                            <sorter [key]="'total_evaluaciones'" [(objects)]="s.stats.profesores">
                                                {{
                                                    "reportes.resumen_uso.resultados.actividad_docente.total_evaluaciones"
                                                        | t
                                                }}
                                            </sorter>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let profesor of s.stats.profesores">
                                        <td>
                                            {{ profesor.nombre }} {{ profesor.apellido_paterno }}
                                            {{ profesor.apellido_materno }}
                                        </td>
                                        <td *ngIf="profesor.ultimo_ingreso" class="value">
                                            {{ profesor.ultimo_ingreso | date: "dd/MM/y" }}
                                        </td>
                                        <td *ngIf="!profesor.ultimo_ingreso" class="value">-</td>
                                        <td class="value">{{ profesor.ingresos_30_dias }}</td>
                                        <td class="value">{{ profesor.total_instrumentos }}</td>
                                        <td class="value">{{ profesor.total_evaluaciones }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <ng-template #showText>
            <p>
                <label>{{ "reportes.resumen_uso.resultados.not_found" | t }}</label>
            </p>
        </ng-template>
    </loading-layout>
</div>
