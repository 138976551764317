import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { EvaluacionInstancia, Evaluacion, Evaluaciones } from "@puntaje/puntaje/api-services"
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasById } from "@puntaje/puntaje/store"
import { filter, first } from "rxjs/operators"

@Component({
    selector: "tabla-resultados-por-pregunta",
    templateUrl: "tabla_resultados_por_pregunta.component.html",
    styleUrls: ["tabla_resultados_por_pregunta.component.scss", "estadisticas_tablas_graficos.component.scss"]
})
export class TablaResultadosPorPreguntaComponent implements OnInit {
    @Input() evaluacionInstancias: EvaluacionInstancia[]
    @Input() evaluacion: Evaluacion
    @Input() withAsignatura: boolean = false
    openModal: EventEmitter<any> = new EventEmitter<any>()
    dataModal: any

    @Output("dataReady") onDataReady = new EventEmitter<any>()

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    data: any
    numeroAlternativas = 5
    nAlternativasByPreguntaId: { [key: number]: number } = {}
    letras = "ABCDEFGHIJK"
    letrasArray: string[] = []
    asignaturasById: any

    constructor(private store: Store<State>, private evaluacionesService: Evaluaciones) {}

    ngOnInit() {
        this.setData()
    }

    abrirModal(d) {
        this.dataModal = d
        setTimeout(() => {
            this.openModal.emit()
        }, 100)
    }

    async setData() {
        this.loadingLayout.standby()

        if (this.withAsignatura) {
            this.asignaturasById = await this.store
                .pipe(
                    select(selectAsignaturasById),
                    filter(x => !!x),
                    first()
                )
                .toPromise()
        }

        const response: any = await this.evaluacionesService.tablaResumenPorPregunta(this.evaluacion.id, {
            evaluacion_instancia: { id: this.evaluacionInstancias.map(ei => ei.id) }
        })

        this.data = response.data
        this.letrasArray = this.letras.split("").slice(0, response.numero_alternativas)
        this.numeroAlternativas = this.letrasArray.length
        this.loadingLayout.ready()

        this.onDataReady.emit(this.data)
    }
}
