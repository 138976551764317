import { ChangeDetectorRef, Component, ContentChild, Directive, HostBinding, ViewEncapsulation } from "@angular/core"

@Directive({ selector: "cui-info-card-title" })
export class CUIInfoCardTitle {}

@Directive({ selector: "[cui-info-card-icon]" })
export class CUIInfoCardIcon {}

@Directive({ selector: "cui-info-card-status" })
export class CUIInfoCardStatus {}

@Directive({ selector: "cui-info-card-left-content" })
export class CUIInfoCardLeftContent {}

@Directive({ selector: "cui-info-card-right-content" })
export class CUIInfoCardRightContent {}

@Directive({ selector: "cui-info-card-under-content" })
export class CUIInfoCardUnderContent {}

@Directive({ selector: "div[cui-info-card-area]" })
export class CUIInfoCardArea {
    @HostBinding("class.cui-info-card-area") useClass: boolean = true
}

@Directive({ selector: "label[cui-info-card-data-pair-label]" })
export class CUIInfoCardDataPairLabel {
    @HostBinding("class.cui-info-card-data-pair-label") useClass: boolean = true
}

@Directive({ selector: "p[cui-info-card-data-pair-value]" })
export class CUIInfoCardDataPairValue {
    @HostBinding("class.cui-info-card-data-pair-value") useClass: boolean = true
}

@Component({
    selector: "cui-info-card-data-pair",
    template: `
        <ng-content select="label[cui-info-card-data-pair-label]"></ng-content>
        <ng-content select="p[cui-info-card-data-pair-value]"></ng-content>
    `,
    styleUrls: ["cui-info-card.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class CUIInfoCardDataPairComponent {
    constructor() {}
}

@Component({
    selector: "cui-info-card",
    templateUrl: "cui-info-card.component.html",
    styleUrls: ["cui-info-card.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class CUIInfoCardComponent {
    @ContentChild(CUIInfoCardTitle) title: CUIInfoCardTitle
    @ContentChild(CUIInfoCardUnderContent) underContent: CUIInfoCardUnderContent
    enableResponsive: boolean = false
    constructor(protected cdr: ChangeDetectorRef) {}

    adaptToSize(cs: number) {
        this.enableResponsive = cs > 0 && cs <= 750
        this.cdr.detectChanges()
    }
}
