import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { EscalaGlobal, EscalaGlobales, EscalaGlobalView } from "@puntaje/puntaje/api-services"

@Component({
    selector: "escala-globales-view",
    templateUrl: "./escala-globales-view.component.html",
    styleUrls: ["./escala-globales-view.component.scss"]
})
export class EscalaGlobalesViewComponent implements OnInit {
    escalaGlobal: EscalaGlobal
    params: any = EscalaGlobalView.viewParams

    @Input() escalaGlobalId: number
    @Output() escalaGlobalChange = new EventEmitter<EscalaGlobal>()
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(protected escalaGlobalServices: EscalaGlobales) {}

    ngOnInit() {
        const params = {
            render_options: {
                include: ["escala_global_aliases"]
            }
        }
        this.escalaGlobalServices.find(this.escalaGlobalId, params).then((escalaGlobal: EscalaGlobal) => {
            this.escalaGlobal = escalaGlobal
            this.escalaGlobalChange.emit(escalaGlobal)
            this.loadingLayout.ready()
        })
    }
}
