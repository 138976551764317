import { EventEmitter, Component, ViewChild, Input, Output, OnInit } from "@angular/core"
import { GenericModalComponent } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Evaluaciones, Evaluacion, Instrumento, Instrumentos } from "@puntaje/puntaje/api-services"
import { ImprimirComponent } from "@puntaje/puntaje/new-modules/imprimir"

@Component({
    selector: "evaluacion-preview-modal",
    templateUrl: "evaluacion_preview_modal.component.html",
    styleUrls: ["evaluacion_preview_modal.component.scss"]
})
export class EvaluacionPreviewModalComponent implements OnInit {
    @Input() modalId: string
    @Input() evaluacionId: number
    @Input() enableReporte: boolean = true
    @Input() enableImprimir: boolean = true
    evaluacion: Evaluacion
    instrumento: Instrumento
    @ViewChild(GenericModalComponent, { static: true }) genericModal: GenericModalComponent
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(ImprimirComponent, { static: true }) imprimirComponent: ImprimirComponent

    @Output() closeCrossCallback: EventEmitter<any> = new EventEmitter<any>()

    constructor(protected evaluacionesService: Evaluaciones, protected instrumentosService: Instrumentos) {}

    ngOnInit() {
        this.loadingLayout.ready()
    }

    loadEvalucionData() {
        const params = {
            profesor: 1
        }
        this.loadingLayout.standby()
        this.evaluacionesService.find(this.evaluacionId, params).then((evaluacion: Evaluacion) => {
            this.evaluacion = evaluacion
            const paramsInstrumento = {
                with_generador_instrumento: 1
            }
            this.instrumentosService
                .find(this.evaluacion.instrumento_id, paramsInstrumento)
                .then((instrumento: Instrumento) => {
                    this.instrumento = instrumento
                    this.loadingLayout.ready()
                })
        })
    }

    loadEvaluacion(evaluacion) {
        this.evaluacionId = evaluacion.id
    }

    closeCross() {
        this.closeCrossCallback.emit()
    }

    public open() {
        this.genericModal.buttonPressed()
        this.loadEvalucionData()
    }

    public close() {
        this.genericModal.close()
    }
}
