<cui-section-headline>Estudiantes activos</cui-section-headline>
<p class="tutorial">
    En este ítem se detallan los estudiantes que han realizado evaluaciones enviadas por el establecimiento y también
    las individuales.
</p>
<div *ngIf="configuracionLoaded">
    <div *ngFor="let tipoInstrumento of tiposInstrumentoInformeUso">
        <div class="print-page-break-avoid">
            <h4>
                Número de estudiantes activos en
                {{ tipoInstrumentosDict[tipoInstrumento] | transformInstrumento: "pluralCompleto" | capitalize }}
                {{ "enviadas" | genderize: tipoInstrumento }}
                en el establecimiento
            </h4>
            <ng-container *ngFor="let nivel of configuracion[tipoInstrumento]['niveles']">
                <ng-container *ngIf="configuracion[tipoInstrumento]['data'][nivel]['n_alumnos_total'] != 0">
                    <h5>{{ nivel }}</h5>
                    <div class="progress-container">
                        <fa name="user-o" class="left-icon"></fa>
                        <span class="progress-text">
                            {{ configuracion[tipoInstrumento]["data"][nivel]["n_alumnos_con_eval_establ"] }} /
                            {{ configuracion[tipoInstrumento]["data"][nivel]["n_alumnos_total"] }}
                        </span>
                        <progressbar
                            class="progressbar"
                            [value]="
                                getProgressValue(
                                    configuracion[tipoInstrumento]['data'][nivel]['n_alumnos_con_eval_establ'],
                                    configuracion[tipoInstrumento]['data'][nivel]['n_alumnos_total']
                                )
                            "
                        ></progressbar>
                    </div>
                </ng-container>
            </ng-container>
            <h5><b>Total General</b></h5>
            <div class="progress-container">
                <fa name="user-o" class="left-icon"></fa>
                <span class="progress-text">
                    {{ configuracion[tipoInstrumento]["data"]["total"]["n_alumnos_con_eval_establ"] }} /
                    {{ configuracion[tipoInstrumento]["data"]["total"]["n_alumnos_total"] }}
                </span>
                <progressbar
                    class="progressbar print-page-break-avoid"
                    [value]="
                        getProgressValue(
                            configuracion[tipoInstrumento]['data']['total']['n_alumnos_con_eval_establ'],
                            configuracion[tipoInstrumento]['data']['total']['n_alumnos_total']
                        )
                    "
                ></progressbar>
            </div>
        </div>
    </div>
</div>
