import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { Evaluaciones, Evaluacion, EvaluacionForma, Instrumento, Instrumentos } from "@puntaje/puntaje/api-services"
import { ModalDirective } from "ngx-bootstrap/modal"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "imprimir",
    templateUrl: "imprimir.component.html"
})
export class ImprimirComponent implements OnInit {
    @ViewChild("mandatoryLoading", { static: true }) mandatoryLoading: ModalDirective

    canceled: boolean = false
    errorMsg: boolean = false
    @Input() instrumento: Instrumento
    @Input() evaluacionId: number
    @Input() ultimaEvaluacionId: number

    constructor(protected evaluacionesService: Evaluaciones, protected instrumentosService: Instrumentos) {}

    ngOnInit() {}

    imprimir(perfil: string = null, evaluacionForma: EvaluacionForma = null) {
        this.canceled = false
        this.errorMsg = false
        this.mandatoryLoading.show()
        const params: any = {}
        params.plataforma = config.plataforma.name

        if (perfil == "profesor") {
            params.profesor = 1
            params.group_by =
                config.evaluaciones[
                    this.instrumento.generador_instrumento.tipo_instrumento.tipo_instrumento
                ].clasificaciones.groupBy
            if (!this.instrumento.propio)
                params.clasificacion_tipos_tabla_spec =
                    config.evaluaciones[
                        this.instrumento.generador_instrumento.tipo_instrumento.tipo_instrumento
                    ].clasificaciones.clasificacionTiposTablaEspecificaciones
        } else if (perfil == "alumno") {
            params.alumno = 1
        }

        if (evaluacionForma) {
            params.evaluacion_forma_id = evaluacionForma.id
        }

        const evaluacionId = this.evaluacionId || this.ultimaEvaluacionId

        this.evaluacionesService.enableIgnoreModel()
        this.evaluacionesService.enableIgnoreCatch()
        this.evaluacionesService
            .imprimir(evaluacionId, params)
            .then((obj: any) => {
                if (this.canceled) {
                    throw new Error("Cancelado")
                }
                this.evaluacionesService.disableIgnoreModel()
                const info = obj.info
                this.logInfoLatex(info.statusCode, info.exit_status, info.stdout, info.stderr)
                this.mandatoryLoading.hide()
                const evaluacionFormaStr = evaluacionForma ? "_" + evaluacionForma.forma : ""
                const downloadString = "evaluacion_" + evaluacionId + "_forma_" + evaluacionFormaStr + ".pdf"
                this.createLinkAndOpen(info.link, downloadString)
            })
            .catch(response => {
                this.evaluacionesService.disableIgnoreModel()
                if (!this.canceled) {
                    this.errorMsg = true
                }
            })
    }

    imprimirHojas() {
        this.canceled = false
        this.errorMsg = false
        this.mandatoryLoading.show()
        let params: any = {}
        params.plataforma = config.plataforma.name

        const evaluacionId = this.evaluacionId || this.ultimaEvaluacionId

        this.evaluacionesService.enableIgnoreModel()
        this.evaluacionesService.imprimirHojas(evaluacionId, params).then((obj: any) => {
            const info = obj.info
            this.logInfoLatex(info.statusCode, info.exit_status, info.stdout, info.stderr)

            this.mandatoryLoading.hide()
            const downloadString = "hoja_respuestas_" + evaluacionId + ".pdf"
            console.log(info.link, downloadString)
            this.createLinkAndOpen(info.link, downloadString)
        })
    }

    createLinkAndOpen(link, downloadString) {
        const a = document.createElement("a")
        a.download = downloadString
        a.href = link
        a.click()
    }

    logInfoLatex(statusCodeLambda, exitStatus, stdout, stderr) {
        console.log("STATUS CODE LAMBDA")
        console.log("%c " + statusCodeLambda, "color: blue")
        console.log("EXIT STATUS LATEX")
        console.log("%c " + exitStatus, "color: blue")
        console.log("SALIDA ESTANDAR LATEX")
        console.log("%c " + stdout, "color: blue")
        console.log("SALIDA DE ERRORES LATEX")
        console.log("%c " + stderr, "color: crimson")
    }

    cancelPrint() {
        this.evaluacionesService.disableIgnoreModel()
        this.mandatoryLoading.hide()
        this.canceled = true
    }
}
