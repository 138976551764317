<h4 class="tg-title" *ngIf="data && data.length > 0">{{ "estadisticas.graficos.lpmi.title" | t }}</h4>
<loading-layout #loadingLayout>
    <div *ngFor="let d of data; let i = index" class="data-container">
        <div class="d-row">
            <!--Pregunta número #{{ d.numero }}. {{ d.incorrectas * 100 }}% incorrectas.-->

            <!--<div>
                <div [innerHTML]="d.pregunta.taxativos[0].taxativo"></div>

                <div *ngFor="let alternativa of d.pregunta.contestables[0].alternativas; let i = index; ">
                    {{ alternativa.letra }})
                    <div [innerHTML]="alternativa.alternativa"></div>
                    {{ d.alternativas[i] * 100 }}%
                </div>
            </div>-->
            <div class="d-cell number-cell" [class.longer-number-cell]="useLongerNumberCell">#{{ d.numero }}</div>
            <div class="d-cell pregunta-cell" #preguntaCell>
                <div class="pregunta-cell-inner" [class.hide-while-loading]="!enableView">
                    <div class="pregunta-cell-inner-overlay"></div>
                    <pregunta-informe-estadistica
                        [pregunta]="d.pregunta"
                        [nAlternativas]="d.numero_alternativas"
                        [index]="d.numero"
                    ></pregunta-informe-estadistica>
                    <button type="button" class="btn btn-default btn-sm toggleBtn" (click)="abrirModal(d)">
                        <span class="contracted-text">
                            <span class="glyphicon glyphicon-plus-sign"></span>
                            &nbsp;{{ "estadisticas.graficos.lpmi.ver_mas" | t }}
                        </span>
                    </button>
                </div>
            </div>
            <div class="d-cell percentage-cell {{ setColorClass(d.incorrectas) }}">
                {{ d.incorrectas * 100 | roundfloat }}%
                <small>{{ "estadisticas.graficos.lpmi.resp_incorrectas" | t }}</small>
            </div>
        </div>
    </div>
</loading-layout>
<paginator *ngIf="showPaginator" [getList]="setData.bind(this)" [per]="pageSize"></paginator>
<ng-container *ngIf="dataModal">
    <ver-pregunta-modal
        [openModal]="openModal"
        [dataModal]="dataModal"
        [evaluacionTipo]="evaluacionTipo"
    ></ver-pregunta-modal>
</ng-container>
