<cui-section-headline>{{ "datos_generales_evaluacion.titulo" | t }}</cui-section-headline>
<cui-info-card>
    <cui-info-card-title>
        {{ "datos_generales_evaluacion.titulo_card" | t: { evaluacionId: evaluacion.id } }}
    </cui-info-card-title>
    <ogr-icon cui-info-card-icon name="hacer-prueba"></ogr-icon>
    <cui-info-card-status>
        <div class="{{ currenStatusClass[currentStatusIndex] }} status" *ngIf="!evaluacion.presencial">
            <fa name="calendar" class="status-icon" *ngIf="currentStatusIndex == 0"></fa>
            <ogr-icon name="clock-start" class="status-icon" *ngIf="currentStatusIndex == 1"></ogr-icon>
            <ogr-icon name="clock-stop" class="status-icon" *ngIf="currentStatusIndex == 2"></ogr-icon>
            <span>{{ currenStatus[currentStatusIndex] }}</span>
        </div>
        <div
            class="{{ currenStatusClass[currentStatusIndex] }} status status-responsive"
            *ngIf="!evaluacion.presencial"
        >
            <fa name="calendar" class="status-icon" *ngIf="currentStatusIndex == 0"></fa>
            <ogr-icon name="clock-start" class="status-icon" *ngIf="currentStatusIndex == 1"></ogr-icon>
            <ogr-icon name="clock-stop" class="status-icon" *ngIf="currentStatusIndex == 2"></ogr-icon>
            <span>{{ currenStatus[currentStatusIndex] }}</span>
        </div>
    </cui-info-card-status>
    <cui-info-card-left-content>
        <cui-info-card-data-pair>
            <label cui-info-card-data-pair-label>{{ "datos_generales_evaluacion.nombre" | t }}</label>
            <p cui-info-card-data-pair-value>
                {{ evaluacion.evaluacion }}
            </p>
        </cui-info-card-data-pair>
        <cui-info-card-data-pair>
            <label cui-info-card-data-pair-label>
                <ogr-icon name="clock" class="clock label-icon"></ogr-icon>
                {{ "datos_generales_evaluacion.creacion" | t }}
            </label>
            <p cui-info-card-data-pair-value>
                {{ evaluacion.created_at !== null ? (evaluacion.created_at | date: "dd/MM/yyyy HH:mm") : "" }}
            </p>
        </cui-info-card-data-pair>
        <cui-info-card-data-pair>
            <label cui-info-card-data-pair-label>
                <ogr-icon name="clock-start" class="clock clock-start label-icon"></ogr-icon>
                {{ "datos_generales_evaluacion.inicio" | t }}
            </label>
            <p cui-info-card-data-pair-value>
                {{ evaluacion.fecha_inicio !== null ? (evaluacion.fecha_inicio | date: "dd/MM/yyyy HH:mm") : "" }}
            </p>
        </cui-info-card-data-pair>
        <cui-info-card-data-pair>
            <label cui-info-card-data-pair-label>
                <ogr-icon name="clock-stop" class="clock clock-stop label-icon"></ogr-icon>
                {{ "datos_generales_evaluacion.cierre" | t }}
            </label>
            <p cui-info-card-data-pair-value>
                {{ evaluacion.fecha_termino !== null ? (evaluacion.fecha_termino | date: "dd/MM/yyyy HH:mm") : "" }}
            </p>
        </cui-info-card-data-pair>
        <cui-info-card-data-pair>
            <label cui-info-card-data-pair-label>
                <ogr-icon name="clock-evaluacion" class="clock label-icon"></ogr-icon>
                {{ "datos_generales_evaluacion.resultados" | t }}
            </label>
            <p cui-info-card-data-pair-value>
                {{
                    evaluacion.fecha_mostrar_respuestas !== null
                        ? (evaluacion.fecha_mostrar_respuestas | date: "dd/MM/yyyy HH:mm")
                        : ""
                }}
            </p>
        </cui-info-card-data-pair>
        <cui-info-card-data-pair>
            <label cui-info-card-data-pair-label>{{ "datos_generales_evaluacion.presencial" | t }}</label>
            <p cui-info-card-data-pair-value>
                <span class="espresencial" *ngIf="evaluacion.presencial">
                    <fa name="check" class="icono"></fa>
                    {{ "datos_generales_evaluacion.si" | t }}
                </span>
                <span class="nopresencial" *ngIf="!evaluacion.presencial">
                    <fa name="times" class="icono"></fa>
                    {{ "datos_generales_evaluacion.no" | t }}
                </span>
            </p>
        </cui-info-card-data-pair>
        <cui-info-card-data-pair>
            <label cui-info-card-data-pair-label>{{ "datos_generales_evaluacion.tipo" | t }}</label>
            <p cui-info-card-data-pair-value>
                <span *ngIf="evaluacion.sumativa">
                    {{ "datos_generales_evaluacion.sumativa" | t }}
                </span>
                <span *ngIf="!evaluacion.sumativa">
                    {{ "datos_generales_evaluacion.formativa" | t }}
                </span>
            </p>
        </cui-info-card-data-pair>
    </cui-info-card-left-content>
    <cui-info-card-right-content>
        <div
            cui-info-card-area
            class=""
            *ngIf="evaluacion.evaluacion_usuarios && evaluacion.evaluacion_usuarios.length > 0"
        >
            <div class="grupos-info">
                <label>{{ "datos_generales_evaluacion.compartido_con" | t }}</label>
                <table class="table tabla-grupos">
                    <thead>
                        <tr>
                            <th>{{ nombreGrupo | capitalize }}</th>
                            <th>{{ "datos_generales_evaluacion.fecha" | t }}</th>
                            <th class="acciones">{{ "datos_generales_evaluacion.acciones" | t }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let g of evaluacion.evaluacion_usuarios">
                            <ng-container *ngIf="g.grupo_usuario">
                                <td>{{ g.grupo_usuario.nombre }}</td>
                                <td>
                                    {{ g.created_at !== null ? (g.created_at | date: "dd/MM/yyyy HH:mm") : "" }}
                                </td>
                                <td class="acciones">
                                    <button
                                        type="button"
                                        (click)="
                                            onVerEstadisticas(
                                                g.grupo_usuario.establecimiento.id,
                                                null,
                                                evaluacion.id,
                                                evaluacion.instrumento.generador_instrumento_id,
                                                evaluacion.instrumento.asignatura_id
                                            )
                                        "
                                        class="btn btn-table"
                                    >
                                        <fa [name]="'bar-chart'"></fa>
                                    </button>
                                </td>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </cui-info-card-right-content>
    <cui-info-card-under-content *ngIf="!evaluacion.presencial && canEdit">
        <cui-button-bar [marginBottom]="0">
            <cui-button class="right-side" (click)="edit()">
                {{ "datos_generales_evaluacion.editar_button" | t }}
            </cui-button>
        </cui-button-bar>
    </cui-info-card-under-content>
</cui-info-card>

<editar-evaluacion
    *ngIf="!evaluacion.presencial"
    [evaluacion]="evaluacion"
    [openModalEdit]="openModalEdit"
    [tipoEvaluacion]="evaluacion.evaluacion_tipo.evaluacion_tipo"
    [establecimiento]="null"
></editar-evaluacion>
