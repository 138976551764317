<div class="print-hide">
    <loading-layout #loadingLayout>
        <ng-container *ngIf="!loadingLayout.loading">
            <resumen-estadisticas-evaluacion
                *ngIf="cantidadUsuarios"
                [avance]="currentStatus"
                [total]="cantidadUsuarios"
                [promedio]="promedio"
                [correctas]="totCorrectas"
                [incorrectas]="totIncorrectas"
                [omitidas]="totOmitidas"
                [minimo]="minimo"
                [maximo]="maximo"
                [hideEstadisticas]="hideEstadisticas"
                class="print-hide"
            ></resumen-estadisticas-evaluacion>
            <div class="clearfix" *ngIf="estadisticas">
                <div class="tg-info">
                    <ng-template #helpPopUp2>
                        <ul class="help-list">
                            <li>
                                <label>{{ "estadisticas.graficos.trepa.min" | t }}:</label>
                                {{ notaMin }}
                            </li>
                            <li>
                                <label>{{ "estadisticas.graficos.trepa.max" | t }}:</label>
                                {{ notaMax }}
                            </li>
                            <li>
                                <label>{{ "estadisticas.graficos.trepa.aprob" | t }}:</label>
                                {{ notaProm }} %
                            </li>
                        </ul>
                    </ng-template>
                    <div class="tg-right-options">
                        <button
                            *ngIf="!hideEvolucion"
                            type="button"
                            class="btn-link help-btn print-hide"
                            [popover]="helpPopUp2"
                            [popoverTitle]="('estadisticas.graficos.trepa.escala_desc' | t) + ':'"
                            placement="left"
                            triggers="focus"
                        >
                            <fa name="question-circle-o"></fa>
                            &nbsp;{{ "estadisticas.graficos.trepa.escala" | t }}
                        </button>
                    </div>
                </div>
            </div>
            <h4 class="tg-title">{{ "estadisticas.graficos.trepa.title" | t }}</h4>
            <!-- <cui-button  class="  btn_style_sm pull-right right-space" *ngIf="grupoUsuario" (click)="getExcel()">Hacer reforzamiento</cui-button> -->
            <div class="tg-info">
                <ng-template #helpPopUp1>
                    <ul class="help-list">
                        <li>
                            <label>
                                <fa name="arrow-left"></fa>
                                {{ "estadisticas.graficos.trepa.anterior" | t }}:
                            </label>
                            {{ "estadisticas.graficos.trepa.anterior_desc" | t }}.
                        </li>
                        <li>
                            <label>
                                <fa name="line-chart"></fa>
                                {{ "estadisticas.graficos.trepa.evolucion" | t }}:
                            </label>
                            {{ "estadisticas.graficos.trepa.evolucion_desc" | t }}.
                        </li>
                        <li>
                            <label>
                                <fa name="exchange"></fa>
                                {{ "estadisticas.graficos.trepa.comparacion" | t }}:
                            </label>
                            {{ "estadisticas.graficos.trepa.comparacion_desc" | t }}.
                        </li>
                    </ul>
                </ng-template>
                <p class="tg-description">
                    {{ "estadisticas.graficos.trepa.realizado1" | t }}
                    <span class="status">{{ currentStatus }}</span>
                    {{ "estadisticas.graficos.trepa.realizado2" | t }}
                    <span class="total">{{ cantidadUsuarios }}</span>
                    {{ "estadisticas.graficos.trepa.realizado3" | t }}.
                </p>
                <div class="tg-right-options">
                    <!-- <button
                    *ngIf="!hideEvolucion"
                    type="button"
                    class="btn-link help-btn print-hide"
                    [popover]="helpPopUp1"
                    popoverTitle="Acerca de esta tabla"
                    placement="left"
                    triggers="focus"
                >
                    <fa name="question-circle-o"></fa>
                    &nbsp;Ayuda
                </button> -->
                    <btn-generar-reforzamientos
                        *ngIf="!hideReforzamientos"
                        [evaluacionId]="evaluacion.id"
                        (onSuccess)="onGenerarReforzamientoReload()"
                    ></btn-generar-reforzamientos>
                    <cui-button [id]="'btnExcel'" class="btn_style_sm print-hide" (click)="getExcel()">
                        {{ "estadisticas.graficos.trepa.descargar_excel" | t }}
                    </cui-button>
                </div>
                <!--<cui-button type="button" class="  btn_style pull-right">Exportar a Excel</button>-->
            </div>
            <buscador-usuario-resumen
                [usuariosResumen]="usuarios"
                (usuariosEnviados)="actualizarTabla($event)"
                (limpiarFiltro)="restablecerDatosTabla($event)"
            ></buscador-usuario-resumen>
            <div class="tabla-container clearfix">
                <!-- Tabla nueva -->
                <div class="scroll-wrap">
                    <table class="table tabla-resumen tabla-resp-2">
                        <thead>
                            <tr>
                                <th class="alumno visible-print nombre-per">
                                    {{ "estadisticas.graficos.trepa.estudiante" | t }}
                                </th>
                                <th class="alumno nombre-per hidden-print">
                                    <sorter
                                        [getObjects]="setData.bind(this)"
                                        [objectValue]="getNombreEstudiante"
                                        [key]="'nombre'"
                                        [objects]="data"
                                    >
                                        {{ "estadisticas.graficos.trepa.estudiante" | t }}
                                    </sorter>
                                </th>
                                <th class="hidden-print puntaje">
                                    <sorter
                                        [getObjects]="setData.bind(this)"
                                        [objectValue]="getPuntajeEstudiante"
                                        [key]="'puntaje'"
                                        [objects]="data"
                                    >
                                        {{ "estadisticas.graficos.trepa.puntaje" | t }}
                                    </sorter>
                                </th>
                                <th class="puntaje" *ngIf="enableNotaSobreSiete">
                                    {{ "estadisticas.graficos.trepa.cal" | t }}
                                </th>
                                <th class="reforzamiento" *ngIf="!hideReforzamientos">
                                    {{ "estadisticas.graficos.trepa.reforzamiento" | t }}
                                </th>
                                <th class="puntuacion" *ngIf="evaluacion?.instrumento?.propio">
                                    {{ "estadisticas.graficos.trepa.puntuacion" | t }}
                                </th>
                                <th class="forma" *ngIf="hasForma">
                                    {{ "estadisticas.graficos.trepa.forma" | t }}
                                </th>
                                <th class="resultados">{{ "estadisticas.graficos.trepa.resultados" | t }}</th>
                                <th class="visible-print puntaje">
                                    {{ "*" + "estadisticas.graficos.trepa.puntaje" | t }}
                                </th>
                                <th class="fecha">{{ "estadisticas.graficos.trepa.tiempo" | t }}</th>
                                <th class="fecha">{{ "estadisticas.graficos.trepa.fecha" | t }}</th>
                                <th class="acciones" *ngIf="!disableActions">
                                    {{ "estadisticas.graficos.trepa.acciones" | t }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let d of data; let i = index" id="data_{{ i }}">
                                <td class="usuario alumno">
                                    <p>
                                        <fa name="user" class="icono"></fa>
                                        {{ d.nombre }}
                                    </p>
                                    <p>
                                        <fa name="id-card-o" class="icono"></fa>
                                        {{ d.rut }}
                                    </p>
                                    <p>
                                        <label>{{ "estadisticas.graficos.trepa.curso" | t }}:</label>
                                        {{ d.curso | easyplaceholder: "-" }}
                                    </p>
                                    <p *ngIf="enableColegio">
                                        <label>{{ "estadisticas.graficos.trepa.colegio" | t }}:</label>
                                        {{ d.colegio | easyplaceholder: "-" }}
                                    </p>
                                </td>

                                <td class="puntaje">
                                    <p class="valor puntaje">
                                        {{ d.puntaje | roundfloat: true | easyplaceholder: "-" }}
                                    </p>
                                </td>
                                <td class="" *ngIf="enableNotaSobreSiete">
                                    <p class="valor">{{ d.notaSobreSiete | roundfloat: 1 }}</p>
                                </td>
                                <td
                                    class="reforzamiento"
                                    *ngIf="
                                        !hideReforzamientos &&
                                        (!reforzamientosByEvaluacionId[evaluacion.id] ||
                                            (reforzamientosByEvaluacionId[evaluacion.id] &&
                                                !reforzamientosByEvaluacionId[evaluacion.id][d.usuarioId]))
                                    "
                                >
                                    -
                                </td>
                                <ng-container
                                    *ngIf="
                                        reforzamientosByEvaluacionId[evaluacion.id] &&
                                        reforzamientosByEvaluacionId[evaluacion.id][d.usuarioId]
                                    "
                                >
                                    <td
                                        class="reforzamiento"
                                        [class.unfinished]="
                                            !estadoReforzamientoPorEvaluacion[evaluacion.id][d.usuarioId]
                                        "
                                        [class.finished]="estadoReforzamientoPorEvaluacion[evaluacion.id][d.usuarioId]"
                                    >
                                        {{
                                            estadoReforzamientoPorEvaluacion[evaluacion.id][d.usuarioId]
                                                ? "Realizado"
                                                : "Pendiente"
                                        }}
                                    </td>
                                </ng-container>

                                <td class="puntuacion" *ngIf="evaluacion?.instrumento?.propio">
                                    {{ d.puntuacion ? d.puntuacion : "-" }}
                                </td>

                                <td class="forma" *ngIf="hasForma">
                                    <span class="valor">{{ d.forma | easyplaceholder: "-" }}</span>
                                </td>

                                <td class="detalles">
                                    <div class="celda-resultados">
                                        <div class="col-detalles">
                                            <div class="fila-resultados">
                                                <span class="seccion-resultados">
                                                    <p class="valor correctas">
                                                        {{ d.correctas | easyplaceholder: "-" }}
                                                    </p>
                                                    <p class="subtitulo">
                                                        {{ "estadisticas.graficos.trepa.correctas" | t }}
                                                    </p>
                                                </span>
                                                <span class="seccion-resultados">
                                                    <p class="valor incorrectas">
                                                        {{ d.incorrectas | easyplaceholder: "-" }}
                                                    </p>
                                                    <p class="subtitulo">
                                                        {{ "estadisticas.graficos.trepa.incorrectas" | t }}
                                                    </p>
                                                </span>
                                                <span class="seccion-resultados">
                                                    <p class="valor omitidas">
                                                        {{ d.omitidas | easyplaceholder: "-" }}
                                                    </p>
                                                    <p class="subtitulo">
                                                        {{ "estadisticas.graficos.trepa.omitidas" | t }}
                                                    </p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="col-calificacion">
                                    <p class="valor">
                                        {{ d.tiempo | secondsToTime: "s" | easyplaceholder: "-" }}
                                    </p>
                                </td>
                                <td class="fecha">
                                    <p>{{ d.fecha | date: "dd-MM-yyyy" | easyplaceholder: "-" }}</p>
                                    <p>{{ d.fecha | date: "HH:mm" | easyplaceholder: "-" }}</p>
                                </td>
                                <td class="acciones" *ngIf="!disableActions">
                                    <div *ngIf="d.evaluacionMultiple && d.instanciasSimulacro?.length > 0">
                                        <a
                                            *ngFor="let instancia of d.instanciasSimulacro; let i = index"
                                            class="btn btn-default btn_style_sm"
                                            [routerLink]="['/ensayo_instancias_estudiante', instancia?.id]"
                                        >
                                            {{ "estadisticas.graficos.trepa.ver_sesion" | t }} {{ i + 1 }}
                                        </a>
                                    </div>
                                    <div *ngIf="!d.evaluacionMultiple">
                                        <a
                                            *ngIf="d.evaluacion_instancia_id"
                                            class="btn btn-default btn_style_sm"
                                            [routerLink]="['/ensayo_instancias_estudiante', d.evaluacion_instancia_id]"
                                        >
                                            {{ "estadisticas.graficos.trepa.ver" | t }}
                                        </a>
                                    </div>

                                    <a
                                        *ngIf="d.evaluacion_tiempo_id && !d.evaluacion_instancia_id"
                                        class="btn btn-default btn_style_sm"
                                        (click)="deleteEvaluacionTiempo(d)"
                                    >
                                        {{ "estadisticas.graficos.trepa.reiniciar" | t }}
                                    </a>
                                    <a
                                        *ngIf="
                                            reforzamientosByEvaluacionId[evaluacion.id] &&
                                            reforzamientosByEvaluacionId[evaluacion.id][d.usuarioId]
                                        "
                                        class="btn btn-default btn_style_sm"
                                        [routerLink]="[
                                            '/plan_personal',
                                            reforzamientosByEvaluacionId[evaluacion.id][d.usuarioId].id,
                                            'detalle_alumno',
                                            d.usuarioId
                                        ]"
                                    >
                                        {{ "estadisticas.graficos.trepa.ver_reforzamiento" | t }}
                                    </a>
                                    <a
                                        *ngIf="!disableSelectOficial && d.instancias?.length > 0"
                                        (click)="openModalEvaluacionInstancias(d)"
                                        class="btn btn-default btn_style_sm"
                                    >
                                        {{ "estadisticas.graficos.trepa.seleccionar_oficial" | t }}
                                    </a>
                                    <span *ngIf="!d.correctas && !d.evaluacion_tiempo_id">-</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <paginator [getList]="setData.bind(this)"></paginator>
            </div>
            <!-- Tabla nueva -->
        </ng-container>
    </loading-layout>
</div>

<div *ngIf="!loadingLayout.loading" [hidden]="true" class="print-show-block">
    <h4 class="tg-title">{{ "estadisticas.graficos.trepa.title" | t }}</h4>
    <div class="clearfix">
        <p class="tg-description">
            <span class="status">{{ currentStatus }}</span>
            {{ "estadisticas.graficos.trepa.realizado4" | t }}.
        </p>
        <!--<cui-button type="button" class="  btn_style pull-right">Exportar a Excel</cui-button>-->
    </div>
    <div class="tabla-container clearfix">
        <table class="table tabla-resumen">
            <thead>
                <tr>
                    <th>{{ "estadisticas.graficos.trepa.colegio" | t }}</th>
                    <th>{{ "estadisticas.graficos.trepa.curso" | t }}</th>
                    <th>{{ "estadisticas.graficos.trepa.id_eval" | t }}</th>
                    <th>{{ "estadisticas.graficos.trepa.nombre_eval" | t }}</th>
                    <th>{{ "estadisticas.graficos.trepa.rut" | t }}</th>
                    <th>{{ "estadisticas.graficos.trepa.nombre" | t }}</th>
                    <th class="min-size">{{ "estadisticas.graficos.trepa.correctas" | t }}</th>
                    <th class="min-size">{{ "estadisticas.graficos.trepa.incorrectas" | t }}</th>
                    <th class="min-size">{{ "estadisticas.graficos.trepa.omitidas" | t }}</th>
                    <th class="min-size">{{ "*" + "estadisticas.graficos.trepa.puntaje" | t }}</th>
                    <th class="min-size">{{ "estadisticas.graficos.trepa.tiempo" | t }}</th>
                    <th>{{ "estadisticas.graficos.trepa.fecha" | t }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let d of dataFull; let i = index" [class.print-hide]="!d.puntaje" id="data_full_{{ i }}">
                    <td>{{ d.colegio | easyplaceholder: "-" }}</td>
                    <td>{{ d.curso | easyplaceholder: "-" }}</td>
                    <td>{{ evaluacion.id }}</td>
                    <td>{{ evaluacion.evaluacion }}</td>
                    <td>{{ d.rut }}</td>
                    <td>{{ d.nombre }}</td>
                    <td class="min-size">{{ d.correctas | easyplaceholder: "-" }}</td>
                    <td class="min-size">{{ d.incorrectas | easyplaceholder: "-" }}</td>
                    <td class="min-size">{{ d.omitidas | easyplaceholder: "-" }}</td>
                    <td class="min-size">
                        {{ d.puntaje | roundfloat | easyplaceholder: "-" }}
                        <ng-container *ngIf="d.notaSobreSiete">
                            <br />
                            ({{ "estadisticas.graficos.trepa.nota" | t }}: {{ d.notaSobreSiete | roundfloat: 1 }})
                        </ng-container>
                    </td>
                    <td class="min-size">{{ d.tiempo | easyplaceholder: "-" }}</td>
                    <td>{{ d.fecha | date: "dd-MM-yyyy HH:mm" | easyplaceholder: "-" }}</td>
                </tr>
            </tbody>
        </table>

        <paginator class="print-hide" [getList]="setDataFull.bind(this)" [per]="cantidadUsuarios"></paginator>
    </div>
</div>
<ng-container *ngIf="showModal">
    <generic-modal [modalLarge]="true" (onHide)="closeModalEvaluacionInstancias()">
        <modal-titulo>
            {{ "estadisticas.graficos.trepa.seleccionar_oficial_desc" | t }} {{ datoInstanciaModal.nombre }}
        </modal-titulo>
        <modal-contenido>
            <p>
                {{ "estadisticas.graficos.trepa.seleccionar_oficial_detalle" | t }}
            </p>
            <div class="tabla-container clearfix">
                <div class="scroll-wrap">
                    <table class="table tabla-resumen tabla-resp-2">
                        <thead>
                            <tr>
                                <th class="puntaje" *ngIf="enableNotaSobreSiete">
                                    {{ "estadisticas.graficos.trepa.cal" | t }}
                                </th>
                                <th class="resultados">{{ "estadisticas.graficos.trepa.resultados" | t }}</th>
                                <th class="fecha">{{ "estadisticas.graficos.trepa.tiempo" | t }}</th>
                                <th class="fecha">{{ "estadisticas.graficos.trepa.fecha" | t }}</th>
                                <th class="acciones">{{ "estadisticas.graficos.trepa.oficial" | t }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container
                                *ngIf="!datoInstanciaModal.evaluacionMultiple; else evaluacionInstanciasMultiples"
                            >
                                <tr *ngFor="let d of datoInstanciaModal.instancias; let i = index">
                                    <td class="" *ngIf="enableNotaSobreSiete">
                                        <p class="valor">{{ d.notaSobreSiete | roundfloat: 1 }}</p>
                                    </td>
                                    <td class="detalles">
                                        <div class="celda-resultados">
                                            <div class="col-detalles">
                                                <div class="fila-resultados">
                                                    <div>
                                                        <p class="valor correctas">
                                                            {{ d.correctas | easyplaceholder: "-" }}
                                                        </p>
                                                        <p class="subtitulo">Correctas</p>
                                                    </div>
                                                    <div>
                                                        <p class="valor incorrectas">
                                                            {{ d.incorrectas | easyplaceholder: "-" }}
                                                        </p>
                                                        <p class="subtitulo">Incorrectas</p>
                                                    </div>
                                                    <div>
                                                        <p class="valor omitidas">
                                                            {{ d.omitidas | easyplaceholder: "-" }}
                                                        </p>
                                                        <p class="subtitulo">Omitidas</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="separador-vertical"></div>
                                        </div>
                                    </td>
                                    <td class="col-calificacion">
                                        <p class="valor">{{ d.tiempo | secondsToTime: "s" | easyplaceholder: "-" }}</p>
                                    </td>
                                    <td class="fecha">
                                        <p>{{ d.fecha | date: "dd-MM-yyyy" | easyplaceholder: "-" }}</p>
                                        <p>{{ d.fecha | date: "HH:mm" | easyplaceholder: "-" }}</p>
                                    </td>
                                    <td class="acciones">
                                        <input
                                            class="form-"
                                            (change)="checkOficial(d.id)"
                                            type="checkbox"
                                            [checked]="d.oficial"
                                            [value]="d.oficial"
                                        />
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </modal-contenido>
        <modal-botones>
            <cui-button [disabled]="loadingEvaluacionInstancia" type="button" class="" (click)="closeModal()">
                {{ "estadisticas.graficos.trepa.cancelar" | t }}
            </cui-button>
            <cui-button
                [disabled]="loadingEvaluacionInstancia"
                (click)="updateEvaluacionInstanciaOficial()"
                class="btn_style"
            >
                <loader
                    *ngIf="loadingEvaluacionInstancia"
                    class="pushed pull-right"
                    loaderType="default-xsmall"
                ></loader>
                {{ "estadisticas.graficos.trepa.confirmar" | t }}
            </cui-button>
        </modal-botones>
    </generic-modal>
    <!--Template para-->
    <ng-template #evaluacionInstanciasMultiples>
        {{ datoInstanciaModal.evaluacionMultiple }}
        <tr *ngFor="let d of datoInstanciaModal.instancias; let i = index">
            <td class="" *ngIf="enableNotaSobreSiete">
                <p class="valor">{{ d.notaSobreSiete | roundfloat: 1 }}</p>
            </td>
            <td class="detalles">
                <div class="celda-resultados">
                    <div class="col-detalles">
                        <div class="fila-resultados">
                            <div>
                                <p class="valor correctas">
                                    {{ d.correctas | easyplaceholder: "-" }}
                                </p>
                                <p class="subtitulo">Correctas</p>
                            </div>
                            <div>
                                <p class="valor incorrectas">
                                    {{ d.incorrectas | easyplaceholder: "-" }}
                                </p>
                                <p class="subtitulo">Incorrectas</p>
                            </div>
                            <div>
                                <p class="valor omitidas">
                                    {{ d.omitidas | easyplaceholder: "-" }}
                                </p>
                                <p class="subtitulo">Omitidas</p>
                            </div>
                        </div>
                    </div>
                    <div class="separador-vertical"></div>
                </div>
            </td>
            <td class="col-calificacion">
                <p class="valor">{{ d.tiempo | secondsToTime: "s" | easyplaceholder: "-" }}</p>
            </td>
            <td class="fecha">
                <p>{{ d.fecha | date: "dd-MM-yyyy" | easyplaceholder: "-" }}</p>
                <p>{{ d.fecha | date: "HH:mm" | easyplaceholder: "-" }}</p>
            </td>
            <td class="acciones">
                <input
                    class="form-"
                    (change)="checkOficial(d.id)"
                    type="checkbox"
                    [checked]="d.oficial"
                    [value]="d.oficial"
                />
            </td>
        </tr>
    </ng-template>
</ng-container>
