import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { RouterModule } from "@angular/router"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { UtilModule, PaginatorModule, FormModule, ModelViewModule } from "@puntaje/shared/core"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { CUIContainersModule, CUIInputsModule } from "@puntaje/shared/cui"
import { AyudasAdminComponent } from "./ayudas-admin/ayudas-admin.component"
import { CategoriaAyudasNewComponent } from "./categoria-ayudas.new.component"
import { CategoriaAyudaComponent } from "./categoria-ayuda/categoria-ayuda.component"
import { SortableModule } from "ngx-bootstrap"
import { CategoriaAyudasEditComponent } from "./categoria-ayudas.edit.component"

@NgModule({
    imports: [
        CommonModule,
        CommonsLayoutsModule,
        FormsModule,
        PaginatorModule,
        ReactiveFormsModule,
        FormModule,
        UtilModule,
        ModelViewModule,
        SortableModule,
        CUIInputsModule
    ],
    declarations: [AyudasAdminComponent, CategoriaAyudasNewComponent, CategoriaAyudaComponent, CategoriaAyudasEditComponent],
    exports: [AyudasAdminComponent, CategoriaAyudasNewComponent, CategoriaAyudaComponent, CategoriaAyudasEditComponent],
    providers: []
})
export class AyudasAdminModule {}
