<div *ngIf="itemsIndexes" class="contenedor_clasificador">
    <p>Selecciona una pregunta:</p>
    <ul class="listado-preguntas clearfix">
        <ng-container *ngFor="let item of itemsIndexes">
            <li class="numero-pregunta">
                <a
                    [attr.aria-current]="true"
                    [attr.aria-label]="'pregunta ' + item"
                    [class.seeing]="currentItemIndex == item"
                    (click)="changeItem(item)"
                    [class.ready]="clasificacionesByItemIndex[item] && clasificacionesByItemIndex[item].length > 0"
                    href="javascript:void(0)"
                    id="pregunta_numero_{{ item }}"
                >
                    {{ item + 1 }}
                    <ogr-icon name="ticket" align="text-top" class="ready-icon"></ogr-icon>
                </a>
            </li>
        </ng-container>
    </ul>
    <div class="contenedor-buttons-prev-next">
        <cui-button id="btnPrev" class="btn_style" (click)="prevItem()" [disabled]="currentItemIndex <= 0">
            <fa class="icono" name="arrow-left"></fa>
            &nbsp;
            {{ "evaluar.pasos.clasificar_preguntas.button_prev" | t }}
        </cui-button>
        <cui-button
            id="btnNext"
            class="btn_style"
            (click)="nextItem()"
            [disabled]="currentItemIndex >= numeroItems - 1"
        >
            {{ "evaluar.pasos.clasificar_preguntas.button_next" | t }}&nbsp;
            <fa class="icono" name="arrow-right"></fa>
        </cui-button>
    </div>
    <embed-clasificaciones
        *ngIf="clasificacionesByItemIndex && clasificacionesByItemIndex[currentItemIndex]"
        [recursoEducativo]="tipoRecurso"
        [curriculum]="curriculum"
        [asignaturaId]="asignaturaId"
        [clasificaciones_seleccionadas]="clasificacionesByItemIndex[currentItemIndex]"
        [perfil]="'profesor'"
        (clasificacionesChange)="updateClasificaciones($event)"
    ></embed-clasificaciones>
</div>
