<div *ngIf="toggleId" id="{{ toggleId }}" class="new" expandableContent>
    <div class="step">
        <fa name="arrow-right"></fa>
        Introduzca {{ nombreId }} del {{ tipoUsuario }}.
        <input
            #idValueUsuario="ngModel"
            [(ngModel)]="idValue"
            (ngModelChange)="usuarioLoaded = false"
            type="text"
            class="form-control less-width"
        />
        <div *ngIf="idValueUsuario.dirty && idValueUsuario.touched && !validateIdValue().valid">
            {{ validateIdValue().msg }}
        </div>
        <div class="clearfix">
            <cui-button
                class="btn_style pull-right"
                id="btn-buscar"
                [disabled]="idValueUsuario.dirty && idValueUsuario.touched && !validateIdValue().valid"
                (click)="getUsuario()"
            >
                <fa name="search"></fa>
                Buscar
            </cui-button>
            <cui-button-link
                buttonType="cancel"
                class="btn_style_2 pull-right small-margin-right"
                toggleExpandable="{{ toggleId }}"
                [closeOnlyExpandable]="true"
            >
                Cancelar
            </cui-button-link>
        </div>

        <div *ngIf="usuarioLoaded && !usuario && !searchById">
            <p class="instrucciones">
                El {{ tipoUsuario }} no ha sido encontrado en el sistema. Por favor complete sus datos:
            </p>
            <div class="tbl">
                <div class="tbl-row">
                    <div class="tbl-cell">
                        <b>{{ nombreId }}</b>
                    </div>
                    <div class="tbl-cell">{{ idValue }}</div>
                </div>
                <div class="tbl-row">
                    <div class="tbl-cell"><b>Nombre</b></div>
                    <div class="tbl-cell"><input [(ngModel)]="nombre" required type="text" class="form-control" /></div>
                </div>
                <div class="tbl-row">
                    <div class="tbl-cell"><b>Apellido Paterno</b></div>
                    <div class="tbl-cell">
                        <input [(ngModel)]="apellidoPaterno" required type="text" class="form-control" />
                    </div>
                </div>
                <div class="tbl-row">
                    <div class="tbl-cell"><b>Apellido Materno</b></div>
                    <div class="tbl-cell"><input [(ngModel)]="apellidoMaterno" type="text" class="form-control" /></div>
                </div>
                <div class="tbl-row">
                    <div class="tbl-cell"><b>Email</b></div>
                    <div class="tbl-cell"><input [(ngModel)]="email" type="text" required class="form-control" /></div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>

        <div *ngIf="usuarioLoaded && !usuario && searchById">
            <p class="instrucciones">
                El {{ tipoUsuario }} no ha sido encontrado en el sistema. Por favor intente con otro {{ nombreId }}.
            </p>
        </div>
        <div *ngIf="usuarioLoaded && usuario">
            <p class="instrucciones">
                El {{ tipoUsuario }} ha sido encontrado en el sistema. Revise sus datos y asocielo si corresponde.
            </p>
            <div class="tbl">
                <div class="tbl-row">
                    <div class="tbl-cell">
                        <b>{{ nombreId }}</b>
                    </div>
                    <div class="tbl-cell">{{ idValue }}</div>
                </div>
                <div class="tbl-row">
                    <div class="tbl-cell"><b>Nombre</b></div>
                    <div class="tbl-cell">{{ usuario.nombre }}</div>
                </div>
                <div class="tbl-row">
                    <div class="tbl-cell"><b>Apellido Paterno</b></div>
                    <div class="tbl-cell">{{ usuario.apellido_paterno }}</div>
                </div>
                <div class="tbl-row">
                    <div class="tbl-cell"><b>Apellido Materno</b></div>
                    <div class="tbl-cell">{{ usuario.apellido_materno }}</div>
                </div>
                <div class="tbl-row">
                    <div class="tbl-cell"><b>Email</b></div>
                    <div class="tbl-cell">{{ usuario.email }}</div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>

        <div *ngIf="grupoUsuarios && usuarioLoaded && (usuario || !searchById)">
            <div class="cursosAsociados">
                <p class="instrucciones"><b>Cursos donde se puede asociar:</b></p>
                <div *ngIf="selectedGrupoUsuarios" class="checkbox-list">
                    <div class="checkbox" *ngFor="let grupo_usuario of grupoUsuariosTotales">
                        <label>
                            <input
                                type="checkbox"
                                name="cursos_asociados"
                                [(ngModel)]="selectedGrupoUsuarios[grupo_usuario.id]"
                                (change)="updateGrupoUsuariosAsociaados()"
                            />
                            {{ grupo_usuario.nombre }}
                        </label>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>

        <cui-button-link
            *ngIf="usuarioLoaded && !usuario && !searchById"
            class="btn_style pull-right"
            (click)="createUsuario()"
        >
            Agregar y guardar
        </cui-button-link>

        <cui-button-link *ngIf="usuarioLoaded && usuario" class="btn_style pull-right" id="btn-guardar" (click)="addUsuario(usuario)">
            Guardar
        </cui-button-link>
        <div class="clearfix"></div>
    </div>
</div>
