<div class="resumen-evaluacion">
    <div class="entregados">
        <span class="icono-container"><fa name="user" class="icono"></fa></span>
        <div class="contenido">
            <p>
                <span class="status">{{ avance }}</span>
                /
                <span class="total">{{ total }}</span>
                {{ "estadisticas.graficos.ree.estudiantes" | t }}.
            </p>
            <div class="barra-wrap">
                <div class="barra" [class.fullwidth]="startAnimValues" [style.width]="avanceBarra + '%'"></div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="!hideEstadisticas">
        <span class="separador resp-hide"></span>
        <div class="resultados">
            <p class="resultado">
                <span class="valor">{{ promedio | roundfloat: 0 | easyplaceholder: "-" }}</span>
                <span class="subtitulo">{{ "estadisticas.graficos.ree.promedio" | t }}</span>
            </p>
            <div class="rangos">
                <p class="resultado">
                    <span class="valor">{{ minimo | roundfloat: 0 | easyplaceholder: "-" }}</span>
                    <span class="subtitulo">{{ "estadisticas.graficos.ree.minimo" | t }}</span>
                </p>
                <p class="resultado">
                    <span class="valor">{{ maximo | roundfloat: 0 | easyplaceholder: "-" }}</span>
                    <span class="subtitulo">{{ "estadisticas.graficos.ree.maximo" | t }}</span>
                </p>
            </div>
        </div>
        <span class="separador"></span>
        <div class="respuestas">
            <div class="respuesta">
                <fa name="check-circle-o" class="icono correctas"></fa>
                <p class="valor">{{ correctas | roundfloat: 0 | easyplaceholder: "-" }}</p>
                <p class="subtitulo">{{ "estadisticas.graficos.ree.correctas" | t }}</p>
            </div>
            <div class="respuesta">
                <fa name="times-circle-o" class="icono incorrectas"></fa>
                <p class="valor">{{ incorrectas | roundfloat: 0 | easyplaceholder: "-" }}</p>
                <p class="subtitulo">{{ "estadisticas.graficos.ree.incorrectas" | t }}</p>
            </div>
            <div class="respuesta">
                <fa name="ban" class="icono omitidas"></fa>
                <p class="valor">{{ omitidas | roundfloat: 0 | easyplaceholder: "-" }}</p>
                <p class="subtitulo">{{ "estadisticas.graficos.ree.omitidas" | t }}</p>
            </div>
        </div>
        <span class="separador resp-hide"></span>
    </ng-container>
</div>
