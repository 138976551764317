import { Component, OnInit, Input } from "@angular/core"
import { AuthService } from "@puntaje/shared/core"
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasByEvaluacionTipo } from "@puntaje/puntaje/store"

@Component({
    selector: "resumen-estadisticas-evaluacion",
    templateUrl: "resumen-estadisticas-evaluacion.component.html",
    styleUrls: ["resumen-estadisticas-evaluacion.component.scss"]
})
export class ResumenEstadisticasEvaluacionComponent implements OnInit {
    @Input() avance: number = 0
    @Input() total: number = 0
    @Input() promedio: number
    @Input() minimo: number
    @Input() maximo: number
    @Input() correctas: number
    @Input() incorrectas: number
    @Input() omitidas: number
    @Input() hideEstadisticas = false
    startAnimValues: boolean = false
    avanceBarra: number = 0

    constructor(protected store: Store<State>, protected auth: AuthService) {}

    ngOnInit() {
        this.avanceBarra = Math.round(100 * (this.avance / this.total))
        if (this.avanceBarra > 100) this.avanceBarra = 100
        setTimeout(() => {
            this.startAnimValues = true
        }, 1000)
    }

    getData() {}
}
