import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { RouterModule } from "@angular/router"
import { MyDatePickerModule } from "mydatepicker"

import { ApiModule } from "@puntaje/puntaje/api-services"
import { UtilModule, PaginatorModule, FormModule, ModelViewModule } from "@puntaje/shared/core"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"

import { InstrumentoPredefinidosComponent } from "./instrumento_predefinidos.component"
import { InstrumentoPredefinidosNewComponent } from "./instrumento_predefinidos.new.component"
import { InstrumentoPredefinidosEditComponent } from "./instrumento_predefinidos.edit.component"

import { InstrumentoPredefinidosToolNavbarComponent } from "./instrumento_predefinidos.toolnavbar.component"
import { InstrumentoPredefinidosBuscadorComponent } from "./instrumento-predefinidos-buscador/instrumento-predefinidos-buscador.component"

import { InstrumentoPredefinidosBuscadorService } from "./instrumento-predefinidos-buscador/instrumento-predefinidos-buscador.service"
import { CUIInputsModule } from "@puntaje/shared/cui"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ApiModule,
        UtilModule,
        FormModule,
        CommonsLayoutsModule,
        ModelViewModule,
        PaginatorModule,
        RouterModule,
        Angular2FontawesomeModule,
        MyDatePickerModule,
        CUIInputsModule
    ],
    declarations: [
        InstrumentoPredefinidosComponent,
        InstrumentoPredefinidosToolNavbarComponent,
        InstrumentoPredefinidosEditComponent,
        InstrumentoPredefinidosNewComponent,
        InstrumentoPredefinidosBuscadorComponent
    ],
    exports: [
        InstrumentoPredefinidosComponent,
        InstrumentoPredefinidosToolNavbarComponent,
        InstrumentoPredefinidosEditComponent,
        InstrumentoPredefinidosNewComponent,
        InstrumentoPredefinidosBuscadorComponent
    ],
    providers: [InstrumentoPredefinidosBuscadorService]
})
export class InstrumentoPredefinidosModule {}
