import { Injectable } from "@angular/core"
import { PlanPersonal } from "@puntaje/puntaje/api-services"

@Injectable({
    providedIn: "root"
})
export class PlanClaseModeloService {
    planClaseModelo: PlanPersonal

    constructor() {}
}
