import { TextView, LinkView } from "@puntaje/shared/core"

export class LogroView {
    public static get viewParams() {
        let params = {
            id: {
                label: "ID",
                type: TextView,
                sorterEnabled: true,
                tableVisible: true
            },
            logro: { label: "Logro", type: TextView, sorterEnabled: true, tableVisible: true },
            logo: { label: "Logo", type: LinkView, sorterEnabled: false, tableVisible: true },
            codigo: {
                label: "Código",
                type: TextView,
                sorterEnabled: true,
                tableVisible: true
            },
            experiencia: {
                label: "Experiencia",
                type: TextView,
                sorterEnabled: true,
                tableVisible: true
            },
            monedas: {
                label: "Monedas",
                type: TextView,
                sorterEnabled: true,
                tableVisible: true
            },
            categoria: {
                label: "Categoria",
                type: TextView,
                sorterEnabled: true,
                tableVisible: true
            },
            descripcion: {
                label: "Descripción",
                type: TextView,
                sorterEnabled: false,
                tableVisible: true
            }
        }

        return params
    }
}
