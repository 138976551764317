import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { NoticiasModule } from "@puntaje/puntaje/new-modules/noticias"

import { UtilModule, FormModule } from "@puntaje/shared/core"
import { NoticiaComponent } from "./noticia.component"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { NoticiaAdminComponent } from "./noticia-admin.component"
import { NoticiasEditComponent } from "./noticias_edit.component"
import { NoticiasNewComponent } from "./noticias_new.component"
import { NoticiasComponent } from "./noticias.component"
import { NoticiasProfesorComponent } from "./noticias-profesor.component"

@NgModule({
    imports: [CommonModule, FormsModule, UtilModule, NoticiasModule, CUILayoutsModule],
    declarations: [
        NoticiaComponent,
        NoticiaAdminComponent,
        NoticiasEditComponent,
        NoticiasNewComponent,
        NoticiasComponent,
        NoticiasProfesorComponent
    ],
    exports: [
        NoticiaComponent,
        NoticiaAdminComponent,
        NoticiasEditComponent,
        NoticiasNewComponent,
        NoticiasComponent,
        NoticiasProfesorComponent
    ]
})
export class PuntajeNoticiasModule {}
