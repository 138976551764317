import { StreamingAdminComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"

export const streamingRoutes: Routes = [
    {
        path: "streaming",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: StreamingAdminComponent, canActivate: [AuthGuard] }]
    }
]

export const streamingRouting = RouterModule.forChild(streamingRoutes)
