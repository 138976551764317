import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { Logro, LogroForm } from "@puntaje/achievements/api-services"

@Component({
    selector: "logro-form",
    templateUrl: "./logro-form.component.html",
    styleUrls: ["./logro-form.component.scss"]
})
export class LogroFormComponent implements OnInit {
    @Input() logro: Logro
    copyLogro: Logro
    params = LogroForm.formParams
    form: UntypedFormGroup

    @Output() save = new EventEmitter<Logro>()

    constructor(protected router: Router, protected cdr: ChangeDetectorRef) {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes["logro"].currentValue != changes["logro"].previousValue && this.logro) {
            this.form = LogroForm.getForm(this.logro)
            this.copyLogro = this.logro.clone()
        }
    }

    onSave() {
        LogroForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.save.emit(this.logro)
        }
    }

    clear() {
        setTimeout(() => {
            this.logro = this.copyLogro.clone()
            LogroForm.markFormControlsAsPristine(this.form)
            LogroForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
