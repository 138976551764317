import { Component, OnInit, Input, EventEmitter, ViewChild, ChangeDetectorRef } from "@angular/core"
import { Pregunta, Preguntas } from "@puntaje/nebulosa/api-services"
import { PaginatorComponent } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "listado-preguntas-mas-incorrectas",
    templateUrl: "listado_preguntas_mas_incorrectas.component.html",
    styleUrls: ["listado_preguntas_mas_incorrectas.component.scss", "estadisticas_tablas_graficos.component.scss"]
})
export class ListadoPreguntasMasIncorrectasComponent implements OnInit {
    @Input() dataFull: any
    @Input() useLongerNumberCell: boolean = false
    @Input() evaluacionTipo: string
    @Input() pageSize: number = 5

    data: any
    enableView: boolean = false
    colorRanges = [
        { ranges: [0, 0.25], class: "wrong-0" },
        { ranges: [0.25, 0.4], class: "wrong-25" },
        { ranges: [0.4, 0.6], class: "wrong-50" },
        { ranges: [0.6, 0.75], class: "wrong-75" },
        { ranges: [0.75, 1], class: "wrong-100" }
    ]
    dataModal: any
    openModal: EventEmitter<any> = new EventEmitter<any>()
    showPaginator: boolean = false

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent) paginator: PaginatorComponent

    constructor(public cdr: ChangeDetectorRef, protected preguntasService: Preguntas) {}

    ngOnInit() {
        this.enableView = true
        if (this.dataFull) {
            this.showPaginator = true
        }
    }

    setColorClass(percentage) {
        for (var i = 0; i < this.colorRanges.length; i++) {
            let c = this.colorRanges[i]
            if (c.ranges[0] <= percentage && percentage <= c.ranges[1]) {
                return c.class
            }
        }
        return "wrong-50"
    }

    abrirModal(data) {
        this.dataModal = data
        setTimeout(() => {
            this.openModal.emit()
        }, 100)
    }

    setData(page: number, per: number = 5) {
        this.loadingLayout.loading = true
        this.cdr.detectChanges()

        let data = this.dataFull
            .concat()
            .sort((a, b) => b.incorrectas - a.incorrectas)
            .slice((page - 1) * per, page * per)

        let params = {
            with_grupo_pregunta: 1,
            with_clasificaciones: 1,
            with_clasificaciones_with_clasificacion_tipo: 1,
            pregunta: {
                id: data.map(p => p.pregunta_id)
            }
        }

        return this.preguntasService.where(params).then((preguntas: Pregunta[]) => {
            data.forEach((p, i) => {
                p.pregunta = preguntas[i]
            })

            this.data = data
            this.loadingLayout.loading = false

            return this.dataFull.length
        })
    }
}
