import { Component, OnInit, ViewChild, ChangeDetectorRef, Input, EventEmitter, Output, Injector } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    Noticia,
    Noticias,
    NoticiaForm,
    Establecimiento,
    Establecimientos,
    GrupoUsuarios,
    NoticiasPerfiles,
    NoticiaUsuario,
    NoticiaTipos,
    Lugares
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { NoticiaBaseComponent } from "./noticias.base.component"
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "noticias-edit",
    templateUrl: "noticias.form.component.html"
})
export class NoticiasEditComponent extends NoticiaBaseComponent implements OnInit {
    @Output() closeModal: EventEmitter<any> = new EventEmitter<any>()
    @Input() noticiaId: number
    @Input() fromProfesor: boolean = false
    params: any

    form: UntypedFormGroup
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    display_buttons_options = "all new"

    constructor(
        noticiasService: Noticias,
        establecimientosService: Establecimientos,
        router: Router,
        grupoUsuariosService: GrupoUsuarios,
        noticiaPerfilesService: NoticiasPerfiles,
        authService: AuthService,
        cdr: ChangeDetectorRef,
        noticiaTiposService: NoticiaTipos,
        lugaresService: Lugares,
        protected injector: Injector
    ) {
        super(
            noticiasService,
            establecimientosService,
            router,
            grupoUsuariosService,
            noticiaPerfilesService,
            authService,
            cdr,
            noticiaTiposService,
            lugaresService
        )

        NoticiaForm.injector = this.injector
        this.params = NoticiaForm.formParams
    }

    ngOnInit() {
        this.getNoticiasPerfiles()
        this.getNoticiaTipos()

        if (this.fromProfesor) {
            this.getGrupoUsuarios()
            this.getNoticiasPerfiles()
            this.params.tipo.type = "hidden"
            this.params.notificar.type = "hidden"
            this.params.noticias_perfil_id.type = "hidden"
            this.params.noticia_tipo_id.type = "hidden"
            this.params.destacado.type = "hidden"
        }
        this.form = NoticiaForm.getForm(this.noticia)

        this.noticiasService.find(this.noticiaId, { include: "noticia_usuarios" }).then((response: Noticia) => {
            this.noticia = response
            this.onNoticiaReady()
            this.lugarColegio = response.lugar
            this.oNoticia = response.clone()
            if (!this.fromProfesor) {
                this.loadEstablecimiento()
            } else {
                this.loadingLayout.ready()
            }
        })
    }

    loadEstablecimiento() {
        if (this.noticia.receptor_type == "Establecimiento") {
            this.establecimientosService.find(this.noticia.receptor_id).then(establecimiento => {
                this.establecimiento = establecimiento
                this.loadingLayout.ready()
                this.cdr.detectChanges()
            })
        } else {
            let establecimiento_ids = this.noticia.noticia_usuarios
                .filter(nu => nu.receptor_type == "Establecimiento")
                .map(nu => nu.receptor_id)
            if (establecimiento_ids.length > 0) {
                this.establecimientosService.find(establecimiento_ids[0]).then(establecimiento => {
                    this.establecimiento = establecimiento

                    this.loadingLayout.ready()
                    this.cdr.detectChanges()
                })
            } else {
                this.loadingLayout.ready()
                this.cdr.detectChanges()
            }
        }
    }

    // con nueva tabla
    save() {
        NoticiaForm.markFormControlsAsTouched(this.form)
        this.showWarning = false
        if (this.form.valid) {
            const noticiaUsuario = new NoticiaUsuario()
            noticiaUsuario.usuario_id = this.authService.getUserData().id

            if (this.establecimiento) {
                this.withOpcion(noticiaUsuario, { nombre: "Establecimiento", id: this.establecimiento.id })
            } else if (typeof this.gruposSeleccionados !== "undefined" && this.gruposSeleccionados.length > 0) {
                this.withGrupoUsuarios()
            } else {
                this.withOpcion(noticiaUsuario, { nombre: "Usuario", id: null })
            }

            if (this.lugarColegio) {
                this.noticia.lugar_id = this.lugarColegio.id
            }

            const noticiaTipo = this.noticiaTipos.find(nt => nt.id == this.noticia.noticia_tipo_id)
            if (noticiaTipo && noticiaTipo.noticia_tipo == "aviso") {
                this.noticia.tipo = "ninguno"
            }
            this.noticiasService.update(this.noticia.id, this.noticia).then(response => {
                this.clear()
                if (this.fromProfesor) {
                    this.closeModal.emit()
                }

                this.router.navigate(["noticias"])
            })
        }
    }
}
