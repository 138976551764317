<loading-layout #loadingLayout>
    <div class="info-container clearfix print-page-break-after">
        <div class="info-container-icon">
            <ogr-icon [name]="'prueba'"></ogr-icon>
        </div>
        <div class="info-container-info">
            <div class="row">
                <div class="title-wrap col-md-12">
                    <h4>{{ planPersonal?.plan_personal | easyplaceholder: "-" }}</h4>
                    <cui-button-link
                        *ngIf="planPersonal?.archivo_url"
                        class="btn_style btn-descargar"
                        download
                        [href]="planPersonal?.archivo_url"
                        target="_blank"
                    >
                        <fa name="download"></fa>
                        Descargar planificación anual y por unidad
                    </cui-button-link>
                </div>
                <div class="col-lg-6 col-md-12 print-hide">
                    <div class="info-mini-table">
                        <div class="info-mini-container">
                            <div class="mini-container-header">Asignatura</div>
                            <div class="mini-container-content">
                                <ng-container
                                    *ngIf="planPersonal?.generador_instrumento?.asignatura?.id as asignaturaId"
                                >
                                    <ng-container
                                        *ngIf="asignaturasById$ | async as asignaturasById; else asignaturaDefault"
                                    >
                                        {{ asignaturasById[asignaturaId] }}
                                    </ng-container>
                                    <ng-template #asignaturaDefault>-</ng-template>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="info-mini-table">
                        <div class="info-mini-container">
                            <div class="mini-container-header">Nº de {{ nombreSesiones }}</div>
                            <div class="mini-container-content">
                                {{ numeroSemanas | easyplaceholder: "-" }}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="nivel && !disableNivelCalugas" class="info-mini-table">
                        <div class="info-mini-container">
                            <div class="mini-container-header">Nivel</div>
                            <div class="mini-container-content">
                                {{ nivel?.clasificacion }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 print-full-width">
                    <div class="clasificaciones-info">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Descripción</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {{ planPersonal?.descripcion | easyplaceholder: "-" }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</loading-layout>
