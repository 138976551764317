import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Directive, ChangeDetectorRef } from "@angular/core"
import { FormBuilder, UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    Noticia,
    Noticias,
    NoticiaForm,
    Establecimiento,
    Establecimientos,
    GrupoUsuarios,
    GrupoUsuario,
    NoticiaUsuario,
    NoticiasPerfiles,
    NoticiasPerfil,
    NoticiaTipo,
    NoticiaTipos,
    Lugar,
    Lugares
} from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Directive()
export class NoticiaBaseComponent {
    form: UntypedFormGroup
    logged_layout_title = "Agregar noticia"
    display_buttons_options = "all"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"
    noticiaPerfiles: NoticiasPerfil[]
    noticiaTipos: NoticiaTipo[]
    lugarColegio: Lugar
    filteredLugarColegio: Lugar[]

    noticia: Noticia = new Noticia()
    establecimiento: Establecimiento
    establecimientos: Establecimiento[] = []
    grupoUsuarios: GrupoUsuario[]
    gruposSeleccionados: GrupoUsuario[] = []
    showWarning = false

    noticiaReady = false
    grupoUsuariosReady = false

    oNoticia: Noticia = new Noticia()

    hideInputLugar: boolean = config.noticias
        ? config.noticias.hideInputLugar && config.app.name == "profesores"
        : false

    constructor(
        protected noticiasService: Noticias,
        protected establecimientosService: Establecimientos,
        protected router: Router,
        protected grupoUsuariosService: GrupoUsuarios,
        protected noticiaPerfilesService: NoticiasPerfiles,
        protected authService: AuthService,
        protected cdr: ChangeDetectorRef,
        protected noticiaTiposService: NoticiaTipos,
        protected lugaresService: Lugares
    ) {}

    groupBy = (gu: GrupoUsuario) => gu.establecimiento.establecimiento

    getEstablecimientos(establecimientoFilter: string) {
        this.establecimientosService
            .where({
                per: 100,
                // establecimiento: { establecimiento: establecimientoFilter },
                id_pais_establecimiento: establecimientoFilter,
                pais: config.plataforma.pais,
                include: `[establecimiento_${config.plataforma.pais}]`
            })
            .then((establecimientos: Establecimiento[]) => {
                this.establecimientos = establecimientos
            })
    }

    onSelectEstablecimiento(establecimiento) {
        if (establecimiento instanceof Establecimiento) {
            this.establecimiento = establecimiento
        } else {
            this.establecimiento = null
        }
    }

    getLugarColegio(lugarFilter: string) {
        this.lugaresService
            .where({ per: 100, raw: 1, filter: lugarFilter, lugar_tipo: [config.plataforma.lugarTipo] })
            .then((lugares: Lugar[]) => {
                this.filteredLugarColegio = lugares
            })
    }

    updateLugarColegio(lugar: any) {
        if (lugar instanceof Lugar) {
            this.lugarColegio = lugar
        }
    }

    clear() {
        setTimeout(() => {
            this.noticia = new Noticia()
            NoticiaForm.markFormControlsAsPristine(this.form)
            NoticiaForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }

    getGrupoUsuarios() {
        const params = {
            establecimiento_id_not_nil: 1,
            activos: 1,
            propios: 1,
            utp_establecimiento: 1,
            utp_asociacion: 1,
            grupo_usuario: {
                privado: 1
            },
            grupo_usuario_usuario: {
                propietario: 1
            },
            establecimiento: {
                activo: 1
            }
        }

        this.grupoUsuariosService.where(params).then((grupoUsuarios: GrupoUsuario[]) => {
            this.grupoUsuarios = grupoUsuarios
            this.onGrupoUsuariosReady()
        })
    }

    onGrupoUsuariosReady() {
        this.grupoUsuariosReady = true
        if (this.noticiaReady) {
            this.setGrupoUsuariosSeleccionados()
        }
    }

    onNoticiaReady() {
        this.noticiaReady = true
        if (this.grupoUsuariosReady) {
            this.setGrupoUsuariosSeleccionados()
        }
    }

    setGrupoUsuariosSeleccionados() {
        this.gruposSeleccionados = this.grupoUsuarios.filter(gu =>
            this.noticia.noticia_usuarios.find(nu => gu.id == nu.receptor_id && nu.receptor_type == "GrupoUsuario")
        )
    }

    onChangeCheckbox(selected) {
        if (!selected.find(e => e)) {
            this.showWarning = true
        }
    }

    async getNoticiaTipos() {
        this.noticiaTipos = await this.noticiaTiposService.where()
    }

    async getNoticiasPerfiles() {
        this.noticiaPerfiles = await this.noticiaPerfilesService.where()
    }

    withOpcion(noticiaUsuario, opcion) {
        noticiaUsuario.receptor_type = opcion.nombre
        noticiaUsuario.receptor_id = opcion.id
        this.noticia.noticia_usuarios = [noticiaUsuario]
    }

    withGrupoUsuarios() {
        this.noticia.noticia_usuarios = this.gruposSeleccionados.map((grupoUsuario: GrupoUsuario) => {
            let nu = new NoticiaUsuario()
            nu.usuario_id = this.authService.getUserData().id
            nu.receptor_type = "GrupoUsuario"
            nu.receptor_id = grupoUsuario.id

            return nu
        })
        this.noticia.tipo = "ninguno"
        this.noticia.noticias_perfil_id = this.noticiaPerfiles.filter(np => np.noticia_perfil == "alumnos")[0].id
    }
}
