import {
    BuscadorInstrumentoComponent,
    EnsayosMensualesComponent,
    EnsayosMesComponent,
    InstrumentoDesdeListaPreguntasComponent,
    InstrumentoDesdeLoteComponent,
    GenerarInstrumentoValidadoComponent,
    InstrumentoComponent,
    InstrumentosPredefinidosComponent,
    InstrumentosPredefinidosEditComponent,
    InstrumentosPredefinidosNewComponent,
    InstrumentosComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"

import { TokenGuard } from "@puntaje/puntaje/services"

export const routes: Routes = [
    {
        path: "instrumentos",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: InstrumentosComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "instrumentos/generar/porlote",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: InstrumentoDesdeLoteComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "instrumentos/generar/por_lista_preguntas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: InstrumentoDesdeListaPreguntasComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "instrumento_predefinidos",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: InstrumentosPredefinidosComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "instrumento_predefinidos/new",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: InstrumentosPredefinidosNewComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "instrumento_predefinidos/:id/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: InstrumentosPredefinidosEditComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "instrumentos/generar",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: GenerarInstrumentoValidadoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "instrumentos/mensuales",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayosMensualesComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "instrumentos/mensuales/:month",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayosMesComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "instrumentos/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: InstrumentoComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "visualizador_buscador",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: BuscadorInstrumentoComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
]

export const routing = RouterModule.forChild(routes)
