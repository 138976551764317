<div
    *ngIf="establecimiento || stats"
    class="resumen-wrapper"
    resize-aware
    (currentSize)="adaptToSize($event)"
    [ngClass]="{ medium: enableMediumSize, small: enableSmallSize }"
>
    <div *ngIf="establecimiento" class="establecimiento-box">
        <div class="info-top">
            <p>
                <label>{{ establecimiento["establecimiento_" + config.plataforma.pais]?.getFieldName() }}:&nbsp;</label>
                {{ establecimiento["establecimiento_" + config.plataforma.pais]?.getFieldValue() }}
            </p>
        </div>
        <div class="title">
            <ogr-icon name="establecimiento2" class="icono" [class.big]="enableMediumSize"></ogr-icon>
            <div class="title-wrap" [class.alignCenter]="enableMediumSize">
                <p>Establecimiento</p>
                <h3>{{ establecimiento.establecimiento }}</h3>
            </div>
        </div>
        <div class="info-bottom">
            <fa class="icono" name="map-marker"></fa>
            <p>
                <ng-container *ngFor="let lugar of lugares">
                    <label>{{ lugar.lugar_tipo.lugar_tipo }}:&nbsp;</label>
                    <span>{{ lugar.nombre }}</span>
                </ng-container>
            </p>
        </div>
    </div>
    <div *ngIf="stats" class="stats" [ngClass]="{ medium: enableMediumSize }">
        <div class="info-box">
            <div class="header">
                <fa class="icono" name="graduation-cap"></fa>
                <h3>{{ "reportes.resumen_uso.resultados.docentes" | t }}</h3>
                <span>{{ stats.numero_docentes }}</span>
            </div>
            <div class="content">
                <div class="data">
                    <p class="data-with-icon">
                        <fa class="icono" name="sign-in"></fa>
                        <span class="value">{{ stats.login_docentes }}</span>
                    </p>
                    <p
                        class="type"
                        [popover]="'N° de docentes que ha ingresado al sistema en el rango de fechas definidas.'"
                    >
                        {{ "reportes.resumen_uso.resultados.ingresos_sistema" | t }}
                    </p>
                </div>
                <div class="data">
                    <p class="data-with-icon">
                        <ogr-icon class="icono" name="archivo"></ogr-icon>
                        <span class="value">{{ stats.numero_instrumentos_docentes }}</span>
                    </p>
                    <p
                        class="type"
                        [popover]="
                            'N° de instrumentos (únicos) generados por los docentes en el rango de fechas definidas.'
                        "
                    >
                        {{ "reportes.resumen_uso.resultados.instrumentos_generados" | t }}
                    </p>
                </div>
                <div class="data">
                    <p class="data-with-icon">
                        <ogr-icon class="icono" name="prueba"></ogr-icon>
                        <span class="value">{{ stats.numero_evaluaciones_docentes }}</span>
                    </p>
                    <p
                        class="type"
                        [popover]="
                            'N° de evaluaciones (online + presencial) compartidas por los docentes en el rango de fechas definidas.'
                        "
                    >
                        {{ "reportes.resumen_uso.resultados.evaluaciones_compartidas" | t }}
                    </p>
                </div>
                <div class="data">
                    <p class="data-with-icon">
                        <ogr-icon class="icono" name="hacer-prueba"></ogr-icon>
                        <span class="value">{{ stats.numero_evaluaciones_presenciales }}</span>
                    </p>
                    <p
                        class="type"
                        [popover]="
                            ' N° de evaluaciones (impresas) compartidas por los docentes en el rango de fechas definidas.'
                        "
                    >
                        {{ "reportes.resumen_uso.resultados.evaluaciones_presenciales" | t }}
                    </p>
                </div>
                <div class="data">
                    <p class="data-with-icon">
                        <ogr-icon class="icono" name="editar-prueba"></ogr-icon>
                        <span class="value">{{ stats.numero_evaluaciones_respondidas }}</span>
                    </p>
                    <p
                        class="type"
                        [popover]="
                            'Evaluaciones únicas que se han compartido, de las que al menos un alumno ha contestado.'
                        "
                    >
                        {{ "reportes.resumen_uso.resultados.evaluaciones_contestadas" | t }}
                    </p>
                </div>
            </div>
        </div>
        <div class="info-box">
            <div class="header">
                <ogr-icon name="comunidad" class="icono"></ogr-icon>
                <h3>{{ "reportes.resumen_uso.resultados.estudiantes" | t }}</h3>
                <span>{{ stats.numero_alumnos }}</span>
            </div>
            <div class="content">
                <div class="data">
                    <p class="data-with-icon">
                        <fa class="icono" name="sign-in"></fa>
                        <span class="value">{{ stats.login_alumnos }}</span>
                    </p>
                    <p
                        class="type"
                        [popover]="'N° de alumnos que ha ingresado al sistema en el rango de fechas definidas.'"
                    >
                        {{ "reportes.resumen_uso.resultados.ingresos_sistema" | t }}
                    </p>
                </div>
                <div class="data">
                    <p class="data-with-icon">
                        <ogr-icon class="icono" name="archivo"></ogr-icon>
                        <span class="value">{{ stats.numero_evaluaciones_generadas_alumnos }}</span>
                    </p>
                    <p
                        class="type"
                        [popover]="
                            'N° de instrumentos que fueron generados por alumnos (como estudio autónomo) en el rango de fechas definidas.'
                        "
                    >
                        {{ "reportes.resumen_uso.resultados.instrumentos_generados" | t }}
                    </p>
                </div>
                <div class="data">
                    <p class="data-with-icon">
                        <ogr-icon class="icono" name="editar-prueba"></ogr-icon>
                        <span class="value">{{ stats.numero_evaluaciones_respondidas_total }}</span>
                    </p>
                    <p
                        class="type"
                        [popover]="
                            'Evaluaciones únicas que se han compartido, de las que al menos un alumno ha contestado.'
                        "
                    >
                        {{ "reportes.resumen_uso.resultados.evaluaciones_contestadas" | t }}
                    </p>
                </div>
            </div>
        </div>
        <div class="info-box" id="cuentas-administradoras">
            <div class="header">
                <fa class="icono" name="gear"></fa>
                <h3>{{ "reportes.resumen_uso.resultados.cuentas_administradoras" | t }}</h3>
                <span>{{ stats.numero_admins }}</span>
            </div>
            <div class="content">
                <div class="data">
                    <p class="data-with-icon">
                        <fa class="icono" name="sign-in"></fa>
                        <span class="value">{{ stats.login_admins }}</span>
                    </p>
                    <p class="type">{{ "reportes.resumen_uso.resultados.ingresos_sistema" | t }}</p>
                </div>
                <div class="data">
                    <p class="data-with-icon">
                        <ogr-icon class="icono" name="archivo"></ogr-icon>
                        <span class="value" id="administrador-instrumentos-value">
                            {{ stats.numero_instrumentos_administradores }}
                        </span>
                    </p>
                    <p
                        class="type"
                        id="administrador-instrumentos-titulo"
                        popover="{{ 'reportes.resumen_uso.resultados.administrador_instrumentos_descripcion' | t }}"
                    >
                        {{ "reportes.resumen_uso.resultados.instrumentos_generados" | t }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
