import { Component, OnInit, ViewChild, ChangeDetectorRef, Input, EventEmitter, Output } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { S3, S3Service } from "@puntaje/shared/core"
import { PostForm, Posts, Post } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "posts-edit",
    templateUrl: "posts.form.component.html"
})
export class PostsEditComponent implements OnInit {
    @Input() postId: number
    oPost: Post = new Post()
    post: Post = new Post()
    params = PostForm.formParams
    form: UntypedFormGroup
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(
        protected router: Router,
        private s3Service: S3Service,
        protected authService: AuthService,
        protected cdr: ChangeDetectorRef,
        protected postsService: Posts
    ) {}

    ngOnInit() {
        this.form = PostForm.getForm(this.post)
        this.postsService.find(this.postId, {}).then((response: Post) => {
            this.post = response
            this.oPost = response.clone()
            this.loadingLayout.ready()
            this.cdr.detectChanges()
        })
    }

    // con nueva tabla
    save() {
        PostForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            let promise: Promise<any> = Promise.resolve()

            if (this.post.image_file) {
                promise = new Promise((resolve, reject) => {
                    this.s3Service.where(this.post.getS3Params()).then((policies: S3[]) => {
                        const policy = policies as any as S3
                        this.post.imagen = policy["key"]
                        this.s3Service.uploadToS3(policy, this.post.image_file, this.postsService.tableName, resolve)
                    })
                })
            }

            let promiseSm: Promise<any> = Promise.resolve()
            if (this.post.image_file_sm) {
                promiseSm = new Promise(resolve => {
                    this.s3Service.where(this.post.getS3ParamsSm()).then((policies: S3[]) => {
                        const policy = policies as any as S3
                        this.post.imagen_sm = policy["key"]
                        this.s3Service.uploadToS3(policy, this.post.image_file_sm, this.postsService.tableName, resolve)
                    })
                })
            }

            Promise.all([promise, promiseSm]).then(() => {
                this.doUpdate()
            })
        }
    }

    doUpdate = () => {
        this.postsService.update(this.post.id, this.post).then(response => {
            this.router.navigate(["posts"])
        })
    }

    clear() {
        setTimeout(() => {
            this.post = this.oPost.clone()
            PostForm.markFormControlsAsPristine(this.form)
            PostForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
