import { ActividadEditComponent, ActividadesComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../../layouts/logged_layout/logged_layout.component"
import { RouterModule, Routes } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

export const achievementsRoutes: Routes = [
    {
        path: "achievements/actividades",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ActividadesComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "achievements/actividades/:id/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ActividadEditComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
    //    {
    //        path: "achievements/new",
    //        component: AchievementsNewComponent,
    //        canActivate: [TokenGuard, AuthGuard]
    //    },
    //    {
    //        path: "achievements/:id",
    //        component: AchievementsViewComponent,
    //        canActivate: [TokenGuard, AuthGuard]
    //    },
    //    {
    //        path: "achievements/:id/edit",
    //        component: AchievementsEditComponent,
    //        canActivate: [TokenGuard, AuthGuard]
    //    }
]

export const achievementsActividadesRouting = RouterModule.forChild(achievementsRoutes)
