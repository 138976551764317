import { BaseForm } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"
import { Usuarios } from "./usuarios.service"
import { PostsPerfiles } from "./posts_perfil.service"
import { Posts } from "./posts.service"

export class PostForm extends BaseForm {
    public static get formParams() {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            titulo: {
                label: "Título",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            tag: {
                label: "Tag",
                type: "autocomplete",
                options: {
                    class: Posts,
                    serviceMethod: "getTags",
                    enableAddNotFound: true
                },
                visible: true,
                validations: []
            },
            tiempo_lectura: {
                label: "Tiempo de lectura",
                type: "number",
                visible: true,
                validations: []
            },
            resumen: {
                label: "Resumen",
                type: "textarea",
                visible: true,
                validations: []
            },
            contenido: {
                label: "Contenido",
                type: "ckeditor",
                visible: true,
                validations: [Validators.required, Validators.minLength(4)]
            },
            image_file: {
                label: "Subir imagen (se completará 'imagen' automáticamente, aproximadamente 1500px de ancho)",
                type: "file",
                visible: true,
                validations: []
            },
            imagen: {
                label: "Imagen (link de la imagen)",
                type: "text",
                visible: true,
                validations: []
            },
            image_file_sm: {
                label: "Subir imagen para vista pequeña (se completará 'imagen pequeña' automáticamente, aproximadamente 600px de ancho)",
                type: "file",
                visible: true,
                validations: []
            },
            imagen_sm: {
                label: "Imagen para vista pequeña (link de la imagen)",
                type: "text",
                visible: true,
                validations: []
            },
            visible: {
                label: "Visible",
                type: "checkbox",
                visible: true,
                validations: []
            },
            publico: {
                label: "Landing",
                type: "checkbox",
                visible: true
            },
            no_listado: {
                label: "No listado",
                type: "checkbox",
                visible: true
            },
            usuario_id: {
                label: "Autor",
                type: "select",
                visible: true,
                options: {
                    class: Usuarios,
                    params: { rol: { rol: "Administrador" } }
                },
                validations: [Validators.required]
            },
            post_perfil_id: {
                label: "Post Perfil",
                type: "select",
                visible: true,
                options: { class: PostsPerfiles },
                withLabel: false
            },
            fecha_inicial: {
                label: "Fecha inicial",
                type: "datepicker",
                visible: true,
                validations: []
            },
            fecha_final: {
                label: "Fecha final",
                type: "datepicker",
                visible: true,
                validations: []
            },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true },
            video: {
                label: "Video (link del video)",
                type: "text",
                visible: true,
                validations: []
            }
        }
    }
}
