<generic-modal [buttonClicked]="openModal" [id]="modalId" (closeCrossCallback)="cancelar()">
    <modal-titulo>
        <!-- Compartir {{tipoEvaluacion | transformInstrumento: "singular"}} -->
        <ng-container *ngIf="!evaluacionCompartida && !evaluacionesCompartidas">
            {{
                "compartir_inst.compartir" | t: { evaluacionTipo: (tipoEvaluacion | transformInstrumento: "singular") }
            }}
        </ng-container>
        <ng-container *ngIf="evaluacionCompartida">
            ¡{{ tipoEvaluacion | transformInstrumento: "singular" | capitalize }}
            {{ "compartido" | genderize: tipoEvaluacion }} {{ "compartir_inst.exito" | t }}!
        </ng-container>
        <ng-container *ngIf="evaluacionesCompartidas">
            ¡{{ tipoEvaluacion | transformInstrumento: "plural" | capitalize }}
            {{ "compartidos" | genderize: tipoEvaluacion }} {{ "compartir_inst.exito" | t }}!
        </ng-container>
    </modal-titulo>
    <modal-contenido class="modal-contenido" *ngIf="!resetForm">
        <form #form="ngForm" *ngIf="!evaluacionCompartida && !evaluacionesCompartidas">
            <h4 class="h4_sub_title_1">
                {{
                    "compartir_inst.crear"
                        | t
                            : {
                                  evaluacionTipo:
                                      ("nuevo" | genderize: tipoEvaluacion) +
                                      " " +
                                      (tipoEvaluacion | transformInstrumento: "singular")
                              }
                }}
            </h4>
            <div class="row">
                <div class="col-sm-12">
                    <input
                        #nombre="ngModel"
                        type="text"
                        [(ngModel)]="evaluacion.evaluacion"
                        name="nombre"
                        placeholder="{{ 'compartir_inst.nombre' | t }} {{
                            tipoEvaluacion | transformInstrumento: 'singular'
                        }}"
                        class="form-control"
                        required
                    />
                    <div
                        *ngIf="nombre.errors && (nombre.dirty || nombre.touched || validationsPending)"
                        class="error-warning"
                    >
                        <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                        {{ tipoEvaluacion | transformInstrumento: "conArticulo" | capitalize }}
                        {{ "compartir_inst.error_nombre" | t }}.
                    </div>
                </div>
            </div>

            <ng-container *ngIf="instrumento && !instrumento.propio">
                <!-- <h4 class="h4_sub_title_1">
                    {{ "compartir_inst.marque1" | t }} {{ tipoEvaluacion | transformInstrumento: "conArticulo" }}
                    {{ "compartir_inst.marque2" | t }}:
                </h4>
                <a class="tutorial-info-link" toggleExpandable="formas-info">{{ "compartir_inst.what" | t }}</a>
                <label [attr.for]="'formas'">
                    <input
                        type="checkbox"
                        [disabled]="instrumento && instrumento.propio"
                        (change)="onFormasChange($any($event))"
                        name="formas"
                        id="formas"
                    />
                    &nbsp;{{ "compartir_inst.con_forma" | t }}
                </label> -->
                <div style="margin-top: 10px" id="formas-info" expandableContent>
                    <div class="tutorial-info">
                        {{ "compartir_inst.what_exp" | t }}
                    </div>
                </div>
            </ng-container>
            <div class="row">
                <div class="col-sm-12" *ngIf="!hideTipoEvaluacionCompartir">
                    <h4 class="h4_sub_title_1">{{ "compartir_inst.sel_tipo" | t }}:</h4>
                    <a class="tutorial-info-link" toggleExpandable="tipo-evaluaciones-info">
                        {{ "compartir_inst.what" | t }}
                    </a>
                    <div class="form-check">
                        <input
                            [attr.aria-label]="'sumativa'"
                            class="form-check-input"
                            type="radio"
                            name="sumativa"
                            id="sumativa"
                            [(ngModel)]="evaluacion.sumativa"
                            value="1"
                        />
                        <label class="form-check-label" for="sumativa">
                            &nbsp;{{ "compartir_inst.sumativo" | t }} &nbsp;
                        </label>
                    </div>
                    <div class="form-check">
                        <input
                            [attr.aria-label]="'formativa'"
                            class="form-check-input"
                            type="radio"
                            name="sumativa"
                            id="formativa"
                            [(ngModel)]="evaluacion.sumativa"
                            value="0"
                        />
                        <label class="form-check-label" for="formativa">
                            &nbsp;{{ "compartir_inst.formativo" | t }} &nbsp;
                        </label>
                    </div>

                    <div id="tipo-evaluaciones-info" expandableContent>
                        <div class="tutorial-info">
                            {{ "compartir_inst.what_exp2" | t }}
                        </div>
                        <div class="tutorial-info">
                            {{ "compartir_inst.what_exp3" | t }}
                        </div>
                    </div>
                </div>

                <div class="col-sm-12" *ngIf="enableTipoEscala">
                    <h4 class="h4_sub_title_1">{{ "compartir_inst.sel_escala" | t }}:</h4>
                    <a class="crear-escala" (click)="openModalEscalaGlobal('Crear')">
                        {{ "compartir_inst.crear_escala" | t }}
                    </a>
                    <br />
                    <ng-template [ngIf]="escalaGlobales">
                        <div class="lista-escalas" *ngFor="let escalaGlobal of escalaGlobales; let i = index">
                            <label>
                                <input
                                    type="checkbox"
                                    name="formas"
                                    id="formas"
                                    [checked]="escalaGlobal.check"
                                    (change)="onChangeEscala($any($event), escalaGlobal, i)"
                                />
                                &nbsp;{{ escalaGlobal.nombre }}
                            </label>
                            &nbsp; ({{ escalaGlobal.tipo }}) &nbsp;
                            <a
                                *ngIf="usuario.id != escalaGlobal.usuario_id"
                                class="crear-escala"
                                (click)="openModalEscalaGlobal('Ver', escalaGlobal.id)"
                            >
                                {{ "compartir_inst.ver_mas" | t }}
                            </a>
                            <a
                                *ngIf="usuario.id == escalaGlobal.usuario_id"
                                class="crear-escala"
                                (click)="openModalEscalaGlobal('Editar', escalaGlobal.id)"
                            >
                                {{ "compartir_inst.editar" | t }}
                            </a>
                        </div>
                    </ng-template>
                </div>
            </div>

            <ng-template [ngIf]="!evaluacion.presencial && showCompartirPresencial && !presencial">
                <h4 class="h4_sub_title_1 no-bottom-margin">
                    {{
                        "compartir_inst.exp_fecha"
                            | t: { evaluacionTipo: (tipoEvaluacion | transformInstrumento: "conDeterminante") }
                    }}:
                </h4>
                <div class="row">
                    <div class="col-sm-6">
                        <p>{{ "compartir_inst.desde" | t }}:</p>
                        <datepicker-form
                            #fechaInicio="ngModel"
                            name="fechaInicio"
                            [(ngModel)]="evaluacion.fecha_inicio"
                            required
                        ></datepicker-form>
                        <div
                            *ngIf="
                                fechaInicio.errors && (fechaInicio.dirty || fechaInicio.touched || validationsPending)
                            "
                            class="error-warning"
                        >
                            <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                            {{ tipoEvaluacion | transformInstrumento: "conArticulo" | capitalize }}
                            {{ "compartir_inst.desde_warn" | t }}.
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <p>{{ "compartir_inst.hasta" | t }}:</p>
                        <datepicker-form
                            #fechaTermino="ngModel"
                            name="fechaTermino"
                            [(ngModel)]="evaluacion.fecha_termino"
                            required
                        ></datepicker-form>
                        <div
                            *ngIf="
                                fechaTermino.errors &&
                                (fechaTermino.dirty || fechaTermino.touched || validationsPending)
                            "
                            class="error-warning"
                        >
                            <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                            {{ tipoEvaluacion | transformInstrumento: "conArticulo" | capitalize }}
                            {{ "compartir_inst.hasta_warn" | t }}.
                        </div>
                    </div>
                </div>
                <div *ngIf="!validateDateInterval()" class="error-warning">
                    <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                    {{ "compartir_inst.fechas_warn" | t }}.
                </div>
                <h4 class="h4_sub_title_1">
                    {{
                        "compartir_inst.desde_ver"
                            | t: { evaluacionTipo: (tipoEvaluacion | transformInstrumento: "conDeterminante") }
                    }}
                </h4>
                <div class="row">
                    <div class="col-sm-6">
                        <datepicker-form
                            #fechaResultados="ngModel"
                            name="fechaResultados"
                            [(ngModel)]="evaluacion.fecha_mostrar_respuestas"
                            required
                        ></datepicker-form>
                    </div>
                </div>

                <div
                    *ngIf="
                        fechaResultados.errors &&
                        (fechaResultados.dirty || fechaResultados.touched || validationsPending)
                    "
                    class="error-warning"
                >
                    <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                    {{ tipoEvaluacion | transformInstrumento: "conArticulo" | capitalize }}
                    {{ "compartir_inst.desde_ver1" | t }}.
                </div>
            </ng-template>

            <ng-template [ngIf]="grupoUsuarios && grupoUsuarios.length > 0 && !compartirTodosCursos">
                <h4 class="h4_sub_title_1">
                    {{
                        "compartir_inst.grupos_ver"
                            | t
                                : {
                                      evaluacionTipo: (tipoEvaluacion | transformInstrumento: "conArticulo"),
                                      nombreGrupo: (nombreGrupo | pluralize | capitalize)
                                  }
                    }}:
                </h4>
                <ng-template [ngIf]="grupoUsuarios">
                    <checkboxes
                        #grupos="ngModel"
                        [options]="grupoUsuarios"
                        [(ngModel)]="gruposProfesor"
                        [groupBy]="groupBy"
                        name="grupos"
                    ></checkboxes>
                    <div
                        *ngIf="(grupos.dirty || validationsPending) && gruposProfesor.length <= 0"
                        class="error-warning"
                    >
                        <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                        {{ "compartir_inst.grupos_ver_warn" | t }} {{ nombreGrupo }}
                    </div>
                </ng-template>
            </ng-template>

            <ng-template [ngIf]="!isAdmin && grupoUsuarios && grupoUsuarios.length == 0 && !compartirTodosCursos">
                {{ "compartir_inst.sin_grupos1" | t }} {{ nombreGrupo | pluralize }}
                {{ "compartir_inst.sin_grupos2" | t }}.
            </ng-template>

            <ng-container *ngIf="enableAdminOptions && isAdmin">
                <br />
                <h4 class="h4_sub_title_1">{{ "compartir_inst.sel_eval_masiva" | t }}:</h4>
                <a class="tutorial-info-link" toggleExpandable="masivo-info">{{ "compartir_inst.what" | t }}</a>
                <label [attr.for]="'shareWithEverybodyEver'">
                    <input
                        type="checkbox"
                        [(ngModel)]="shareWithEverybodyEver"
                        name="shareWithEverybodyEver"
                        id="shareWithEverybodyEver"
                    />
                    &nbsp;{{ "compartir_inst.sel_eval_masiva_all" | t }}.
                </label>
                <div id="masivo-info" expandableContent>
                    <div class="tutorial-info">
                        {{ "compartir_inst.what_exp4_1" | t }} {{ nombreGrupo | pluralize }}
                        {{ "compartir_inst.what_exp4_2" | t }}.
                    </div>
                </div>
            </ng-container>
        </form>
        <div *ngIf="evaluacionCompartida">
            <p>
                {{ "compartir_inst.success1" | t }}
                <b>Evaluación ID #{{ evaluacionCompartida.id }}</b>
                {{ "compartir_inst.success2" | t }}:
            </p>
            <ul>
                <ng-container *ngFor="let receptor of evaluacionCompartida.evaluacion_usuarios">
                    <li *ngIf="receptor.receptor_type == 'GrupoUsuario'">
                        {{ receptor.grupo_usuario.nombre }}
                    </li>
                </ng-container>
            </ul>
            <div *ngIf="showLinkAlumnoAlCompartir">
                {{ "compartir_inst.success_can1" | t }} {{ "realizarlo" | genderize: tipoEvaluacion }}
                {{ "compartir_inst.success_can2" | t }}:
                <br />
                <div class="copyLinkContainer">
                    <span #enlaceEstudiante>
                        {{ environment.domain.alumnos }}/evaluaciones/{{ evaluacionCompartida.id }}/realizar
                    </span>
                    <cui-button class="btn-copy btn_style" (click)="copyToClipboard(enlaceEstudiante)">
                        {{ "compartir_inst.copiar" | t }}&nbsp;
                        <fa name="files-o"></fa>
                    </cui-button>
                </div>
            </div>
            <p *ngIf="showTextoListado">
                {{ "compartir_inst.see_list" | t }}

                {{ tipoEvaluacion | transformInstrumento: "plural" }}
                {{ "compartidas" | genderize: tipoEvaluacion }}
                <a data-dismiss="modal" class="pointer" (click)="goToListado()">
                    {{ "compartir_inst.see_list_here" | t }}
                </a>
                .
            </p>
        </div>
        <div *ngIf="evaluacionesCompartidas">
            <p>
                {{ "compartir_inst.comp_cursos_1" | t }}
                <b>
                    {{ "compartir_inst.comp_cursos_2" | t }}
                    <ng-container *ngFor="let evc of evaluacionesCompartidas">#{{ evc.id }}</ng-container>
                </b>
                {{ "compartir_inst.comp_cursos_3" | t }}:
            </p>
            <ul>
                <ng-container *ngFor="let receptor of evaluacionesCompartidas[0].evaluacion_usuarios">
                    <li *ngIf="receptor.receptor_type == 'GrupoUsuario'">
                        {{ receptor.grupo_usuario.nombre }}
                    </li>
                </ng-container>
            </ul>
            <div *ngIf="showLinkAlumnoAlCompartir">
                {{ "compartir_inst.success_can1" | t }}
                Los estudiantes podrán {{ "realizarlos" | genderize: tipoEvaluacion }}
                {{ "compartir_inst.success_can3" | t }}:
                <br />
                <ng-container *ngFor="let evc of evaluacionesCompartidas">
                    <div class="copyLinkContainer">
                        <span #enlaceEstudiante>
                            {{ environment.domain.alumnos }}/evaluaciones/{{ evc.id }}/realizar
                        </span>
                        <cui-button class="btn-copy btn_style" (click)="copyToClipboard(enlaceEstudiante)">
                            {{ "compartir_inst.copiar" | t }}&nbsp;
                            <fa name="files-o"></fa>
                        </cui-button>
                    </div>
                </ng-container>
            </div>
            <p *ngIf="showTextoListado">
                {{ "compartir_inst.see_list" | t }}
                {{ tipoEvaluacion | transformInstrumento: "plural" }}
                {{ "compartidas" | genderize: tipoEvaluacion }}
                <a data-dismiss="modal" class="pointer" (click)="goToListado()">
                    {{ "compartir_inst.see_list_here" | t }}
                </a>
                .
            </p>
        </div>
    </modal-contenido>
    <modal-botones>
        <loader *ngIf="sharing" class="sharing" loaderType="default-xsmall"></loader>
        <cui-button buttonType="cancel" type="button" class="btn_style_2" data-dismiss="modal" (click)="cancelar()">
            <ng-container *ngIf="evaluacionCompartida || evaluacionesCompartidas; else cancelarButton">
                {{ "compartir_inst.cerrar" | t }}
            </ng-container>
            <ng-template #cancelarButton>{{ "compartir_inst.cancelar" | t }}</ng-template>
        </cui-button>
        <cui-button
            type="button"
            class="btn_style"
            *ngIf="!evaluacionCompartida && !evaluacionesCompartidas"
            [disabled]="
                (!generadorInstrumento && !generadorInstrumentosByInstrumentoId) ||
                (!isAdmin && grupoUsuarios && grupoUsuarios.length == 0 && !compartirTodosCursos) ||
                sharing
            "
            (click)="compartir()"
        >
            {{
                "compartir_inst.compartir" | t: { evaluacionTipo: (tipoEvaluacion | transformInstrumento: "singular") }
            }}
        </cui-button>
    </modal-botones>
</generic-modal>

<generic-modal id="modal-create-escala" #genericModalEscalaGlobal (closeCrossCallback)="cerrarModalEscalaGlobal()">
    <modal-titulo id="confirm-title">{{ showTipoModal }} Escala global</modal-titulo>
    <modal-contenido>
        <escala-globales-new
            *ngIf="showTipoModal == 'Crear'"
            [evaluacionTipo]="tipoEvaluacion"
            [fromProfesor]="true"
            (saving)="saveOrUpdate($event)"
        ></escala-globales-new>
        <escala-globales-edit
            *ngIf="showTipoModal == 'Editar' && escalaGlobalId"
            [fromProfesor]="true"
            [escalaGlobalId]="escalaGlobalId"
            [evaluacionTipo]="tipoEvaluacion"
            (saving)="saveOrUpdate($event)"
        ></escala-globales-edit>
        <escala-globales-view
            *ngIf="showTipoModal == 'Ver' && escalaGlobalId"
            [escalaGlobalId]="escalaGlobalId"
        ></escala-globales-view>
    </modal-contenido>
    <modal-botones>
        <div class="pull-right">
            <cui-button buttonType="cancel" class="btn_style_2" (click)="cerrarModalEscalaGlobal()">
                {{ "compartir_inst.cancelar" | t }}
            </cui-button>
        </div>
    </modal-botones>
</generic-modal>
