import { LogroEditComponent, LogrosComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../../layouts/logged_layout/logged_layout.component"
import { RouterModule, Routes } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

export const achievementsRoutes: Routes = [
    {
        path: "achievements/logros",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: LogrosComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "achievements/logros/:id/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: LogroEditComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
    //    {
    //        path: "achievements/logros/new",
    //        component: LogrossNewComponent,
    //        canActivate: [TokenGuard, AuthGuard]
    //    },
    //    {
    //        path: "logros/:id",
    //        component: LogrosViewComponent,
    //        canActivate: [TokenGuard, AuthGuard]
    //    }
]

export const achievementsLogrosRouting = RouterModule.forChild(achievementsRoutes)
