import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from "@angular/core"
import { NgForm } from "@angular/forms"
import { Router } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import {
    Clasificacion,
    Clasificaciones,
    GeneradorInstrumento,
    GeneradorInstrumentos
} from "@puntaje/nebulosa/api-services"
import { AuthService, GenericModalComponent, SessionService } from "@puntaje/shared/core"
import {
    Evaluacion,
    Evaluaciones,
    EvaluacionUsuario,
    GrupoUsuario,
    GrupoUsuarios,
    Instrumento,
    EscalaGlobales,
    EscalaGlobal,
    Usuario,
    EscalaGlobalEvaluacion,
    EvaluacionForma
} from "@puntaje/puntaje/api-services"
import { AppEnv } from "@puntaje/shared/core"
declare const environment: AppEnv

@Component({
    selector: "compartir-instrumento-profesor",
    templateUrl: "compartir_instrumento_profesor.component.html",
    styleUrls: ["compartir_instrumento_profesor.component.scss"]
})
export class CompartirInstrumentoProfesorComponent implements OnInit {
    environment = environment
    @Input() compartirTodosCursos: boolean
    @Input() modalId: string = "compartir"
    @Input() presencial: boolean = false
    private _instrumento: Instrumento
    private _instrumentos: Instrumento[]
    generadorInstrumento: GeneradorInstrumento
    generadorInstrumentosByInstrumentoId: { [id: number]: GeneradorInstrumento }
    evaluacion: Evaluacion = new Evaluacion()
    grupoUsuarios: GrupoUsuario[]
    gruposProfesor: GrupoUsuario[] = []
    cursos: Clasificacion[]
    @Input() openModal: EventEmitter<any>
    validationsPending: boolean = false
    resetForm: boolean = false
    @ViewChild("form") form: NgForm
    @Input() tipoEvaluacion: string
    @Input() redirect: string
    nombreGrupo: string
    isAdmin: boolean = false
    shareWithEverybodyEver: boolean = false
    sharing: boolean = false
    showTextoListado: boolean = true
    @Input() enableAdminOptions: boolean = false
    @Input() enableTipoEscala: boolean = config.plataforma.enableTipoEscala ? config.plataforma.enableTipoEscala : false
    showLinkAlumnoAlCompartir: boolean = false

    @Output("evaluacionCompartida") onEvaluacionCompartida = new EventEmitter<Evaluacion>()
    @Output("evaluacionesCompartidas") onEvaluacionesCompartidas = new EventEmitter<Evaluacion[]>()
    @Input() closeOnFinished: boolean = false
    @ViewChild(GenericModalComponent, { static: true }) genericModal: GenericModalComponent

    @ViewChild("genericModalEscalaGlobal") genericModalEscalaGlobal: GenericModalComponent
    eliminarText: string

    evaluacionCompartida: Evaluacion
    evaluacionesCompartidas: Evaluacion[]

    showCompartirPresencial: boolean = true
    hideTipoEvaluacionCompartir: boolean = false
    escalaGlobales: EscalaGlobal[]
    usuario: Usuario
    showTipoModal: string
    escalaGlobalId: number
    escalaGlobalEvaluaciones: EscalaGlobalEvaluacion[] = []

    constructor(
        protected evaluacionesService: Evaluaciones,
        protected grupoUsuariosService: GrupoUsuarios,
        protected clasificacionesService: Clasificaciones,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected sessionService: SessionService,
        protected router: Router,
        protected cdr: ChangeDetectorRef,
        protected escalaGlobalServices: EscalaGlobales,
        protected authService: AuthService
    ) {
        this.nombreGrupo = config.plataforma.grupoUsuarioAlias || "curso"
        this.usuario = this.authService.getUserData()
    }

    @Input()
    get instrumento() {
        return this._instrumento
    }

    set instrumento(i: Instrumento) {
        this._instrumento = i

        if (i && i.generador_instrumento) {
            this.generadorInstrumento = i.generador_instrumento
        } else {
            i &&
                this.generadorInstrumentosService
                    .find(this.instrumento.generador_instrumento_id)
                    .then((gi: GeneradorInstrumento) => {
                        this.generadorInstrumento = gi
                    })
        }
    }

    @Input()
    get instrumentos() {
        return this._instrumentos
    }

    set instrumentos(i: Instrumento[]) {
        this._instrumentos = i
        if (i) {
            const params = {
                generador_instrumento: {
                    id: i.map(it => it.generador_instrumento_id)
                },
                with_tipo_instrumento: 1
            }

            this.generadorInstrumentosService.where(params).then((generadorInstrumentos: GeneradorInstrumento[]) => {
                this.generadorInstrumentosByInstrumentoId = i.reduce((acc, it) => {
                    acc[it.id] = generadorInstrumentos.find(gi => gi.id == it.generador_instrumento_id)

                    return acc
                }, {})
            })
        }
    }

    ngOnInit() {
        this.enableTipoEscala = config.plataforma.enableTipoEscala
        this.showTextoListado = config.plataforma.showTextListado
        this.showLinkAlumnoAlCompartir = config.plataforma.showLinkAlumnoAlCompartir

        this.showCompartirPresencial = !config.plataforma.disableCompartirPresencial
        this.hideTipoEvaluacionCompartir =
            config.plataforma.disableTipoEvaluacionCompartir !== undefined
                ? config.plataforma.disableTipoEvaluacionCompartir
                : false

        this.setDefaultValues()
        if (this.enableAdminOptions) this.checkAdmin()

        this.grupoUsuariosService.where(this.grupoUsuarioParams()).then((grupoUsuarios: GrupoUsuario[]) => {
            this.grupoUsuarios = grupoUsuarios
        })
        this.clasificacionesService
            .where({
                clasificacion: {
                    clasificacion_tipo_id: 1
                },
                render_options: {
                    except: ["clasificacion_padre_id", "created_at", "updated_at"]
                }
            })
            .then((clasificaciones: Clasificacion[]) => {
                this.cursos = clasificaciones
            })

        let params = {
            evaluacion_tipo: { evaluacion_tipo: this.tipoEvaluacion },
            escala_global: {
                activa: 1
            },
            profesor: { profesor: 1 }
        }

        this.escalaGlobalServices.where(params).then((escalaGlobales: EscalaGlobal[]) => {
            this.escalaGlobales = escalaGlobales
            this.escalaGlobales.map(escala => (escala["check"] = false))
        })
    }

    compartir() {
        if (!this.validateData()) {
            this.validationsPending = true

            return
        }

        this.sharing = true

        if (this.instrumento) {
            this.compartirInstrumento(this.instrumento, this.generadorInstrumento).then((e: Evaluacion) => {
                this.sharing = false
                this.validationsPending = false
                this.form.reset()
                this.setDefaultValues()

                this.evaluacionCompartida = e
                this.onEvaluacionCompartida.emit(e)

                if (this.closeOnFinished) {
                    this.cancelar()
                    this.genericModal.close()
                }
            })
        } else if (this.instrumentos) {
            const promises = this.instrumentos.map(instrumento => {
                return this.compartirInstrumento(instrumento, this.generadorInstrumentosByInstrumentoId[instrumento.id])
            })

            Promise.all(promises).then((evaluaciones: Evaluacion[]) => {
                this.sharing = false
                this.validationsPending = false
                this.form.reset()
                this.setDefaultValues()

                this.evaluacionesCompartidas = evaluaciones
                this.onEvaluacionesCompartidas.emit(evaluaciones)

                if (this.closeOnFinished) {
                    this.cancelar()
                    this.genericModal.close()
                }
            })
        }
    }

    compartirInstrumento(instrumento: Instrumento, generadorInstrumento: GeneradorInstrumento) {
        this.evaluacion.instrumento_id = instrumento.id
        this.evaluacion.presencial = this.presencial
        this.createEscalaGlobalEvaluacion()
        ;(this.evaluacion as any).evaluacion_tipo = generadorInstrumento.tipo_instrumento.tipo_instrumento
        if (!this.validateData()) {
            this.validationsPending = true

            return null
        }
        if (!this.compartirTodosCursos) {
            this.evaluacion.evaluacion_usuarios = this.gruposProfesor.map((grupoUsuario: GrupoUsuario) => {
                let eu = new EvaluacionUsuario()
                eu.receptor_type = "GrupoUsuario"
                eu.receptor_id = grupoUsuario.id

                return eu
            })
        } else {
            const evaluacionUsuario = new EvaluacionUsuario()
            evaluacionUsuario.receptor_type = "GrupoUsuario"
            evaluacionUsuario.receptor_id = null
            this.evaluacion.evaluacion_usuarios = [evaluacionUsuario]
        }
        if (this.evaluacion.presencial) {
            delete this.evaluacion.fecha_inicio
            delete this.evaluacion.fecha_termino
            delete this.evaluacion.fecha_mostrar_respuestas
        }
        if (this.shareWithEverybodyEver) {
            const evaluacionUsuario = new EvaluacionUsuario()
            evaluacionUsuario.receptor_type = "Usuario"
            evaluacionUsuario.receptor_id = null
            this.evaluacion.evaluacion_usuarios = [evaluacionUsuario]
        }
        return this.evaluacionesService.save(this.evaluacion)
    }

    cancelar() {
        this.genericModal.close()
        this.sharing = false
        this.validationsPending = false
        this.form && this.form.reset()
        this.setDefaultValues()
        setTimeout(() => {
            this.evaluacionCompartida = null
            this.evaluacionesCompartidas = null
        }, 500)
    }

    goToListado() {
        this.genericModal.close()
        this.evaluacionCompartida = null
        this.evaluacionesCompartidas = null
        if (this.redirect) {
            this.router.navigate([this.redirect])
        } else if (this.instrumento) {
            this.router.navigate(["evaluaciones"], {
                queryParams: {
                    asignatura_id: this._instrumento.asignatura_id,
                    tipo_instrumento: this.generadorInstrumento.tipo_instrumento.tipo_instrumento
                }
            })
        } else if (this.instrumentos) {
            this.router.navigate(["evaluaciones"], {
                queryParams: {
                    asignatura_id: this._instrumentos[0].asignatura_id,
                    tipo_instrumento:
                        this.generadorInstrumentosByInstrumentoId[this.instrumentos[0].id].tipo_instrumento
                            .tipo_instrumento
                }
            })
        }
    }

    setDefaultValues() {
        this.evaluacion.fecha_inicio = new Date()
        this.evaluacion.fecha_termino = new Date()
        this.evaluacion.fecha_termino.setDate(this.evaluacion.fecha_termino.getDate() + 7)
        this.evaluacion.fecha_mostrar_respuestas = new Date()
        this.evaluacion.tarea = config.evaluaciones[this.tipoEvaluacion].tareaOnCompartir
        this.evaluacion.sumativa = "1" as any

        this.gruposProfesor = []
    }

    groupBy = (gu: GrupoUsuario) => gu.establecimiento.establecimiento

    validateData() {
        return (
            this.evaluacion.evaluacion &&
            (this.evaluacion.presencial ||
                (this.evaluacion.fecha_inicio &&
                    this.evaluacion.fecha_termino &&
                    this.evaluacion.fecha_mostrar_respuestas &&
                    this.validateDateInterval())) &&
            (this.gruposProfesor.length > 0 || this.compartirTodosCursos || this.shareWithEverybodyEver)
        )
    }

    validateDateInterval() {
        if (this.evaluacion.fecha_inicio && this.evaluacion.fecha_termino) {
            if ((this.evaluacion.fecha_termino as any).getTime() < (this.evaluacion.fecha_inicio as any).getTime()) {
                return false
            }
        }
        return true
    }

    checkAdmin() {
        let some_admin = this.sessionService.getRoles().find(r => {
            return r == "SuperAdministrador" || r == "Administrador" || r == "Monitor"
        })
        if (some_admin) {
            this.isAdmin = true
            //this.fetchBannedInfo();
        }
    }

    onFormasChange({ target: { checked } }) {
        if (checked) {
            delete this.evaluacion.evaluacion_formas
            this.evaluacion.evaluacion_formas = ["A", "B"].map(forma => {
                const evaluacionForma = new EvaluacionForma()
                evaluacionForma.forma = forma

                return evaluacionForma
            })
        } else {
            delete this.evaluacion.evaluacion_formas
        }
    }

    copyToClipboard(element) {
        if (window.getSelection) {
            if (window.getSelection().empty) {
                // Chrome
                window.getSelection().empty()
            } else if (window.getSelection().removeAllRanges) {
                // Firefox
                window.getSelection().removeAllRanges()
            }
        } else if ((<any>document).selection) {
            // IE?
            ;(<any>document).selection.empty()
        }

        if ((<any>document).selection) {
            const range = (<any>document.body).createTextRange()
            range.moveToElementText(element)
            range.select().createTextRange()
            document.execCommand("copy")
        } else if (window.getSelection) {
            const range = document.createRange()
            range.selectNode(element)
            window.getSelection().addRange(range)
            document.execCommand("copy")
        }
    }

    openModalEscalaGlobal(tipo, id: number = null) {
        this.showTipoModal = tipo
        this.escalaGlobalId = id
        this.cancelar()
        this.genericModalEscalaGlobal.buttonPressed()
    }

    saveOrUpdate(escalaGlobal: EscalaGlobal) {
        if (this.showTipoModal == "Editar") {
            const index = this.escalaGlobales.findIndex(valor => valor.id == escalaGlobal.id)
            this.escalaGlobales.splice(index, 1)
        }
        escalaGlobal["check"] = false
        this.escalaGlobales.push(escalaGlobal)
        this.cerrarModalEscalaGlobal()
    }

    cerrarModalEscalaGlobal() {
        this.escalaGlobalId = null
        this.genericModalEscalaGlobal.close()
        this.genericModal.buttonPressed()
    }

    onChangeEscala({ target: { checked } }, escalaGlobal: EscalaGlobal, index) {
        let oldIndex =
            escalaGlobal.tipo == "Imagen"
                ? this.escalaGlobales.findIndex(valor => valor.tipo == "Imagen" && valor["check"])
                : this.escalaGlobales.findIndex(valor => valor.tipo != "Imagen" && valor["check"])
        if (oldIndex != -1) this.escalaGlobales[oldIndex]["check"] = false
        this.escalaGlobales[index]["check"] = checked
    }

    createEscalaGlobalEvaluacion() {
        this.escalaGlobales.forEach(escala => {
            let escalaGlobalEvaluacion = new EscalaGlobalEvaluacion()
            if (escala["check"]) {
                escalaGlobalEvaluacion.escala_global_id = escala.id
                this.escalaGlobalEvaluaciones.push(escalaGlobalEvaluacion)
            }
        })
        this.evaluacion.escala_global_evaluaciones = this.escalaGlobalEvaluaciones
    }

    private grupoUsuarioParams() {
        return {
            establecimiento_id_not_nil: 1,
            activos: 1,
            propios: 1,
            utp_establecimiento: 1,
            utp_asociacion: 1,
            grupo_usuario: {
                privado: 1
            },
            grupo_usuario_usuario: {
                propietario: 1
            },
            establecimiento: {
                activo: 1
            },
            render_options: {
                only: ["id", "nombre", "establecimiento_id", "curso_id"],
                include: {
                    establecimiento: {
                        only: ["id", "establecimiento"]
                    }
                }
            }
        }
    }
}
