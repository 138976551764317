import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { UtilModule } from "@puntaje/shared/core"
import { InstrumentoPredefinidosModule, InstrumentosModule } from "@puntaje/puntaje/new-modules/instrumentos"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { TabsModule } from "ngx-bootstrap"
import { CUITextsModule, CUIInputsModule } from "@puntaje/shared/cui"
import { PruebasPropiasComponent } from "./pruebas-propia/pruebas-propias.component"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { SubirPruebaPropiaComponent } from "./subir-prueba-propia/subir-prueba-propia.component"
import { InstrumentoComponent } from "./instrumento.component"
import { InstrumentoDesdeLoteComponent } from "./instrumento_desde_lote.component"
import { InstrumentoDesdeListaPreguntasComponent } from "./instrumento_desde_lista_preguntas.component"
import { InstrumentosComponent } from "./instrumentos.component"
import { InstrumentosPredefinidosComponent } from "./instrumentos_predefinidos.component"
import { InstrumentosPredefinidosNewComponent } from "./instrumentos_predefinidos.new.component"
import { InstrumentosPredefinidosEditComponent } from "./instrumentos_predefinidos.edit.component"
import { InstrumentoMultipleComponent } from "./instrumento_multiple.component"
import { BuscadorInstrumentoComponent } from "./buscador_instrumento.component"
import { EnsayosMensualesAdminComponent } from "./ensayos_mensuales_admin.component"
import { EnsayosMesComponent } from "./ensayos_mes.component"
import { RouterModule } from "@angular/router"
import { CrearInstrumentoMultipleComponent } from "./crear-instrumento-multiple.component"
import { GeneradorDiagnosticoComponent } from "./generador-diagnostico/generador-diagnostico.component"
import { GeneradorDiagnosticoEvaluacionComponent } from "./generador-diagnostico/generador-diagnostico-evaluacion.component"
import { GeneradorDiagnosticoEvaluacionEditComponent } from "./generador-diagnostico/generador-diagnostico-evaluacion.edit.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        InstrumentosModule,
        InstrumentoPredefinidosModule,
        UtilModule,
        TabsModule,
        SharedModule,
        CUITextsModule,
        CUIInputsModule,
        CUILayoutsModule,
        RouterModule
    ],
    exports: [
        PruebasPropiasComponent,
        SubirPruebaPropiaComponent,
        InstrumentoComponent,
        EnsayosMensualesAdminComponent,
        InstrumentoDesdeLoteComponent,
        InstrumentoDesdeListaPreguntasComponent,
        InstrumentosComponent,
        InstrumentosPredefinidosComponent,
        InstrumentosPredefinidosNewComponent,
        InstrumentosPredefinidosEditComponent,
        InstrumentoMultipleComponent,
        BuscadorInstrumentoComponent,
        EnsayosMesComponent,
        CrearInstrumentoMultipleComponent,
        GeneradorDiagnosticoComponent,
        GeneradorDiagnosticoEvaluacionComponent,
        GeneradorDiagnosticoEvaluacionEditComponent
    ],
    declarations: [
        PruebasPropiasComponent,
        SubirPruebaPropiaComponent,
        InstrumentoComponent,
        EnsayosMensualesAdminComponent,
        InstrumentoDesdeLoteComponent,
        InstrumentoDesdeListaPreguntasComponent,
        InstrumentosComponent,
        InstrumentosPredefinidosComponent,
        InstrumentosPredefinidosNewComponent,
        InstrumentosPredefinidosEditComponent,
        InstrumentoMultipleComponent,
        BuscadorInstrumentoComponent,
        EnsayosMesComponent,
        CrearInstrumentoMultipleComponent,
        GeneradorDiagnosticoComponent,
        GeneradorDiagnosticoEvaluacionComponent,
        GeneradorDiagnosticoEvaluacionEditComponent
    ]
})
export class PuntajeInstrumentosModule {}
