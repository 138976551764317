import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core"
import { FormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { CategoriaAyuda, CategoriaAyudaForm, CategoriaAyudas } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "categoria-ayudas-new",
    templateUrl: "categoria-ayudas.form.component.html",
    styleUrls: ["categoria-ayudas.form.component.scss"]
})
export class CategoriaAyudasNewComponent implements OnInit {
    categoriaAyuda = new CategoriaAyuda()
    params = CategoriaAyudaForm.formParams
    form: FormGroup
    logged_layout_title = "Crear categoria ayuda"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(
        protected router: Router,
        protected authService: AuthService,
        protected cdr: ChangeDetectorRef,
        protected categoriaAyudasService: CategoriaAyudas
    ) {}

    ngOnInit() {
        this.form = CategoriaAyudaForm.getForm(this.categoriaAyuda)
        this.loadingLayout.ready()
    }

    save() {
        CategoriaAyudaForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.doSave()
        }
    }

    doSave = () => {
        this.categoriaAyudasService.save(this.categoriaAyuda).then(response => {
            this.router.navigate(["categoria_ayudas"])
        })
    }

    clear() {
        setTimeout(() => {
            this.categoriaAyuda = new CategoriaAyuda()
            CategoriaAyudaForm.markFormControlsAsPristine(this.form)
            CategoriaAyudaForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
