import { Component, OnInit, Input, SimpleChanges, OnChanges } from "@angular/core"
import { Establecimientos, GrupoUsuario } from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"
import { TipoInstrumentoPlataformas } from "@puntaje/nebulosa/api-services"
declare const config: AppConfig
import { AllReadyService } from "@puntaje/puntaje/new-modules/shared"

@Component({
    selector: "tabla-desempeno-estudiantes",
    templateUrl: "./tabla-desempeno-estudiantes.component.html",
    styleUrls: ["./tabla-desempeno-estudiantes.component.scss"]
})
export class TablaDesempenoEstudiantesComponent implements OnInit, OnChanges {
    @Input() establecimientoId: number
    @Input() fechaInicial: Date
    @Input() fechaFinal: Date
    tablaDesempeno: any
    niveles: any = {}
    @Input() year: number = 2018
    @Input() tipoInstrumentosDict: any

    configuracionLoaded: boolean = false

    tiposInstrumentoInformeUso: string[] = config.plataforma.tiposInstrumentoInformeUso || ["ensayo", "curricular"]
    configuracion = {}

    constructor(
        protected establecimientosService: Establecimientos, 
        protected allreadyservice: AllReadyService,
        private tipoInstrumentoPlataformasService: TipoInstrumentoPlataformas
        ) {}

    ngOnInit() {
        this.configurar()
    }

    configurar() {
        this.configuracionLoaded = false
        this.tiposInstrumentoInformeUso.forEach((t: string) => {
            const clasificaciones = config.evaluaciones[t]?.informeFinal?.cursos || config.evaluaciones[t].cursos
            const niveles = clasificaciones.map(c => c.clasificacion)

            const params = {
                year: this.year,
                fecha_inicial: this.fechaInicial,
                fecha_final: this.fechaFinal,
                tipo_instrumento: t,
                niveles: niveles
            }

            this.configuracion[t] = {
                niveles: niveles,
                data: null,
                promise: null
            }

            const promise = this.establecimientosService
                .tabla_desempeno_alumnos(this.establecimientoId, params)
                .then(data => {
                    this.configuracion[t]["data"] = data
                })
            this.configuracion[t]["promise"] = promise
        })
        if (!this.tipoInstrumentosDict) {
            this.tipoInstrumentosDict = {}
            this.tiposInstrumentoInformeUso.map(ti => {
                this.tipoInstrumentosDict[ti] = ti
            })
        }
        const promises = Object.keys(this.configuracion).map(k => this.configuracion[k]["promise"])
        Promise.all(promises).then(values => {
            this.configuracionLoaded = true
            this.allreadyservice.updateCounterWithKey("informe-anual")
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            (changes["establecimientoId"] &&
                !changes["establecimientoId"].firstChange &&
                changes["establecimientoId"].currentValue != changes["establecimientoId"].previousValue) ||
            (changes["fechaInicial"] &&
                !changes["fechaInicial"].firstChange &&
                changes["fechaInicial"].currentValue != changes["fechaInicial"].previousValue) ||
            (changes["fechaFinal"] &&
                !changes["fechaFinal"].firstChange &&
                changes["fechaFinal"].currentValue != changes["fechaFinal"].previousValue)
        ) {
            this.configurar()
        }
    }
}
