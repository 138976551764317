import { Component, Input, EventEmitter, ViewChild, Output, ContentChild, ElementRef } from "@angular/core"
import { ModalModule, ModalDirective } from "ngx-bootstrap/modal"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "long-wait-loading-modal",
    templateUrl: "long_wait_loading_modal.component.html",
    styleUrls: ["long_wait_loading_modal.component.scss"]
})
export class LongWaitLoadingModalComponent {
    openModal: EventEmitter<any> = new EventEmitter<any>()
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent
    @ViewChild("mandatoryLoading", { static: true }) mandatoryLoading: ModalDirective

    @ContentChild("messageElement") messageElement: ElementRef
    @ContentChild("errorMessageElement") errorMessageElement: ElementRef

    canceled: boolean = false
    @Input() errorMsg: boolean = false
    @Output() onCancel = new EventEmitter()

    constructor() {}

    public cancel() {
        this.mandatoryLoading.hide()
        this.canceled = true
        this.onCancel.emit()
    }

    public show() {
        this.mandatoryLoading.show()
    }

    public hide() {
        this.mandatoryLoading.hide()
    }
}
