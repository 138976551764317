<loading-layout #loadingLayout>
    <ng-container *ngIf="data && data.length > 0">
        <h4 class="tg-title">{{ "estadisticas.graficos.rpp.title" | t }}</h4>
        <p class="tg-description">{{ "estadisticas.graficos.rpp.desc" | t }}.</p>
        <p class="tg-description">{{ "estadisticas.graficos.rpp.msg_decimales" | t }}</p>
        <div class="content-leyenda">
            <div class="leyenda"></div>
            <p class="tg-description">{{ "estadisticas.graficos.rpp.legend" | t }}.</p>
        </div>
        <div class="table-container">
            <table class="table tabla-resultados" *ngIf="data && data.length > 0">
                <thead>
                    <tr>
                        <th rowspan="2" class="min-size">{{ "estadisticas.graficos.rpp.pregunta" | t }}</th>
                        <th rowspan="2" class="min-size" *ngIf="data[0] && data[0].a">
                            {{ "estadisticas.graficos.rpp.forma_a" | t }}
                        </th>
                        <th rowspan="2" class="min-size" *ngIf="data[0] && data[0].b">
                            {{ "estadisticas.graficos.rpp.forma_b" | t }}
                        </th>
                        <th *ngIf="withAsignatura" rowspan="2" class="min-size">
                            {{ "estadisticas.graficos.rpp.asignatura" | t }}
                        </th>
                        <th [attr.colspan]="numeroAlternativas">{{ "estadisticas.graficos.rpp.alternativas" | t }}</th>
                        <th rowspan="2" class="min-size">
                            {{ "estadisticas.graficos.rpp.porcentaje" | t }}
                            <br />
                            {{ "estadisticas.graficos.rpp.omitidas" | t }}
                        </th>
                        <th rowspan="2" class="min-size">
                            {{ "estadisticas.graficos.rpp.porcentaje" | t }}
                            <br />
                            {{ "estadisticas.graficos.rpp.incorrectas" | t }}
                        </th>
                        <th rowspan="2" class="min-size">
                            {{ "estadisticas.graficos.rpp.tiempo_prom" | t }}
                            <br />
                            {{ "estadisticas.graficos.rpp.curso" | t }}
                        </th>
                        <th rowspan="2" class="min-size">
                            {{ "estadisticas.graficos.rpp.tiempo_prom" | t }}
                            <br />
                            {{ "estadisticas.graficos.rpp.universo" | t }}
                        </th>
                    </tr>
                    <tr>
                        <th *ngFor="let letra of letrasArray">{{ letra }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let d of data; let i = index" id="data_resultados_pregunta_{{ i }}">
                        <td>
                            <a href="javascript:void(0)" (click)="abrirModal(d)">{{ d.numero }}</a>
                        </td>
                        <td *ngIf="d && d.a">{{ d.a }}</td>
                        <td *ngIf="d && d.b">{{ d.b }}</td>
                        <td *ngIf="withAsignatura">{{ asignaturasById[d.asignatura_id]?.asignatura }}</td>
                        <td
                            *ngFor="let letra of letrasArray; let j = index"
                            [class.correcta]="j === d.alternativa_correcta"
                        >
                            <!-- <td *ngFor="let alt of d.alternativas; let j = index" -->

                            <ng-container
                                *ngIf="
                                    !(evaluacion.instrumento && evaluacion.instrumento.propio);
                                    else instrumentoPropio
                                "
                            >
                                <span *ngIf="j < d.numero_alternativas">
                                    {{ d.alternativas[j] | roundfloat | percent }}
                                </span>
                                <span *ngIf="j >= d.numero_alternativas">-</span>
                            </ng-container>
                            <ng-template #instrumentoPropio>
                                {{ d.alternativas[j] | roundfloat | percent }}
                            </ng-template>
                        </td>
                        <td>{{ d.omitidas | roundfloat | percent }}</td>
                        <td>{{ d.incorrectas | roundfloat | percent }}</td>
                        <td>{{ d.tiempo_promedio_curso | roundfloat: 0 | secondsToTime: "s" }}</td>
                        <td>
                            {{ d.tiempo_promedio_universo | roundfloat: 0 | secondsToTime: "s" | easyplaceholder: "-" }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-container *ngIf="dataModal">
            <ver-pregunta-modal
                [openModal]="openModal"
                [dataModal]="dataModal"
                [evaluacionTipo]="evaluacion.evaluacion_tipo.evaluacion_tipo"
                [generadorInstrumento]="evaluacion.instrumento.generador_instrumento"
            ></ver-pregunta-modal>
        </ng-container>
    </ng-container>
</loading-layout>
