<div class="clearfix" id="step1">
    <div class="row">
        <div class="col-md-8">
            <div class="content-f">
                <div class="tutorial">
                    <div class="container-tutorial-icon">
                        <div class="tutorial-icon-pos">
                            <ogr-icon name="hacer-prueba" align="top" class="icon"></ogr-icon>
                        </div>
                    </div>
                </div>
                <div class="content-text">
                    <ng-container *ngIf="!hideHeading">
                        <h3 class="step">{{ "evaluar.pasos.step" | t }} {{ step }}</h3>
                        <p class="tutorial-text">{{ "evaluar.pasos.subir_pauta_mixta.message" | t }}</p>
                    </ng-container>

                    <ul class="tutorial-text-descripcion">
                        <li>
                            {{ "evaluar.pasos.subir_pauta_mixta.desc" | t }}
                            <input
                                type="number"
                                class="form-control"
                                placeholder="4"
                                #nPreguntas
                                [ngModel]="_numeroPreguntas"
                                [disabled]="disableChangeNumeroPreguntas"
                                (blur)="onNumeroPreguntasChange(nPreguntas.value)"
                            />
                            <div class="warning hide-warning" [class.show-warning]="validationNumeroPreguntas">
                                <div class="warning-content">
                                    <fa name="warning" class="warning-icon"></fa>
                                    <div class="warning-message">
                                        <p>{{ mensajePreguntas }}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li *ngIf="!disableChangeContestableTipo">
                            {{ "evaluar.pasos.subir_pauta_mixta.instr1" | t }}
                        </li>
                        <li>
                            {{ "evaluar.pasos.subir_pauta_mixta.instr2" | t }}
                        </li>
                        <li *ngIf="!disableChangePuntuacion">
                            {{ "evaluar.pasos.subir_pauta_mixta.instruccion_puntuacion" | t }}
                        </li>
                        <li>{{ "evaluar.pasos.subir_pauta_mixta.instr3" | t }}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-4"></div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="clearfix" *ngIf="!hideContinue">
                <cui-button class="btn_style pull-right" (click)="continue()">
                    <ogr-icon name="ticket" align="text-top"></ogr-icon>
                    &nbsp;{{ "evaluar.pasos.subir_pauta_mixta.button" | t }}
                </cui-button>
            </div>

            <div class="form-group">
                <div class="warning hide-warning" [class.show-warning]="blankPauta">
                    <div class="warning-content">
                        <fa name="warning" class="warning-icon"></fa>
                        <div class="warning-message">
                            <p>
                                {{ "evaluar.pasos.subir_pauta_mixta.warn1" | t }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="warning hide-warning" [class.show-warning]="numeroAlternativasWrong">
                    <div class="warning-content">
                        <fa name="warning" class="warning-icon"></fa>
                        <div class="warning-message">
                            <p>{{ "evaluar.pasos.subir_pauta_mixta.warn2" | t }}</p>
                        </div>
                    </div>
                </div>
                <div class="warning hide-warning" [class.show-warning]="alternativaNotPermitted">
                    <div class="warning-content">
                        <fa name="warning" class="warning-icon"></fa>
                        <div class="warning-message">
                            <p>
                                {{ "evaluar.pasos.subir_pauta_mixta.warn3" | t }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="warning hide-warning" [class.show-warning]="alternativaTooLarge">
                    <div class="warning-content">
                        <fa name="warning" class="warning-icon"></fa>
                        <div class="warning-message">
                            <p>{{ "evaluar.pasos.subir_pauta_mixta.warn4" | t }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-12">
            <div class="warning hide-warning" [class.show-warning]="validationNumeroAlternativas">
                <div class="warning-content">
                    <fa name="warning" class="warning-icon"></fa>
                    <div class="warning-message">
                        <p>{{ mensajeAlternativas }}</p>
                    </div>
                </div>
            </div>
            <div *ngFor="let pautaPregunta of _pauta; let i = index" class="pauta-pregunta">
                <div class="numero-pregunta">{{ i + 1 }}:</div>
                <div class="pauta-pregunta-wrap">
                    <div class="contestable-tipo">
                        <select
                            class="form-control"
                            [(ngModel)]="pautaPregunta.contestableTipo"
                            [disabled]="disableChangeContestableTipo"
                            [compareWith]="compareFn"
                        >
                            <option *ngFor="let contestableTipo of contestableTipos" [ngValue]="contestableTipo">
                                {{ contestableTipo.contestable_tipo }}
                            </option>
                        </select>
                    </div>
                    <ng-container *ngIf="pautaPregunta.contestableTipo?.contestable_tipo == 'Pregunta de alternativas'">
                        <div class="cantidad-alternativa">
                            <input
                                type="number"
                                class="form-control"
                                [(ngModel)]="pautaPregunta.cantidadAlternativas"
                                placeholder="Cantidad alternativas"
                                [disabled]="disableChangeNumeroAlternativas"
                                (blur)="validadaNumeroAlternativas()"
                            />
                        </div>
                        <div class="alternativa-correcta">
                            <input
                                type="text"
                                class="form-control"
                                [(ngModel)]="pautaPregunta.letra"
                                placeholder="Alternativa correcta"
                                (keydown)="filterLetra($event, pautaPregunta.cantidadAlternativas)"
                                maxlength="1"
                                [disabled]="
                                    pautaPregunta.cantidadAlternativas == undefined ||
                                    pautaPregunta.cantidadAlternativas == null
                                "
                            />
                        </div>
                    </ng-container>

                    <div class="puntuacion">
                        <input
                            type="number"
                            class="form-control"
                            [(ngModel)]="pautaPregunta.puntuacion"
                            placeholder="Puntuacion"
                            [disabled]="disableChangePuntuacion"
                        />
                    </div>

                    <cui-pills class="pilotaje">
                        <cui-pill [isActive]="pautaPregunta.piloto" (click)="onClickPiloto(pautaPregunta)">P</cui-pill>
                    </cui-pills>
                </div>
            </div>
        </div>
    </div>
</div>
