import { Component, Input, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core"
import { Router } from "@angular/router"
import { select, Store } from "@ngrx/store"
import { Logro, Logros, LogroView } from "@puntaje/achievements/api-services"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AuthService, PaginatorComponent, SessionService } from "@puntaje/shared/core"
import { selectAsignaturasByEvaluacionTipo, State } from "@puntaje/puntaje/store"
import { Subscription } from "rxjs"
import { filter, first } from "rxjs/operators"
import { LogrosBuscadorService } from "../logros-buscador/logros-buscador.service"

@Component({
    selector: "logros",
    templateUrl: "logros.component.html",
    styleUrls: ["logros.component.scss"]
})
export class LogrosComponent implements OnInit, OnDestroy {
    logros: Logro[]
    params = LogroView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("tableLoading", { static: true }) tableLoading: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent

    sub: Subscription
    searchParams: any = {}
    buscadorReady = false

    constructor(
        protected logrosService: Logros,
        protected router: Router,
        protected authService: AuthService,
        protected sessionService: SessionService,
        protected simpleModalService: SimpleModalService,
        protected logrosBuscadorService: LogrosBuscadorService,
        protected store: Store<State>,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" }
            /*  edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" } */
            // delete: {function: this.showModalDelete.bind(this), text: 'Borrar', callModal: true, glyphicon:"glyphicon-trash"},
        }
        this.sub = this.logrosBuscadorService.searchParams.subscribe(params => {
            this.searchParams = params
            this.paginator.changePage(1)
        })
    }

    onBuscadorReady() {
        this.buscadorReady = true
        this.loadingLayout.ready()
    }

    showAll = (page: number = 1, count: number = 20, order: number = null, key: string = null) => {
        if (!this.buscadorReady)
            return new Promise<number>(resolve => {
                resolve(0)
            })
        this.tableLoading.standby()
        let params: any = {
            page: page,
            per: count,
            raw: 1,
            include: "[categoria]",
            sort_by: "id",
            order: "desc",
            render_options: {
                include: ["categoria"]
            }
        }

        if (order && key) {
            params.order = ""
            if (order == 1) params.order = "asc"
            if (order == -1) params.order = "desc"

            if (key == "categoria") {
                params.sort_by = "categoria_id"
            } else {
                params.sort_by = key
            }
        }

        params = {
            ...params,
            ...this.searchParams,
            logro: {
                ...params.logro,
                ...this.searchParams.logro
            },
            plataforma: {
                plataforma: config.plataforma.name
            }
        }

        return this.logrosService.where(params).then((logros: Logro[], total: number) => {
            this.logros = logros
            this.tableLoading.ready()

            return total
        })
    }

    view(logro: Logro) {
        this.router.navigate(["/achievements/logros/" + logro.id])
    }

    edit(logro: Logro) {
        this.router.navigate(["/achievements/logros/" + logro.id + "/edit"])
    }

    delete(id: number) {
        this.logrosService.remove(id).then(_ => {
            this.paginator.changePage(this.paginator.currentPage)
        })
    }

    showModalDelete(logro: Logro) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar este logro?")
        this.simpleModalService.setModalCallback(() => this.delete(logro.id))
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
