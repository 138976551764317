import { BaseForm, BooleanView } from "@puntaje/shared/core"

export class ActividadForm extends BaseForm {
    public static get formParams() {
        return {
            id: { label: "ID", type: "hidden", visible: false },
            actividad: { label: "Actividad", type: "text", visible: true },
            experiencia: { label: "Puntuación", type: "number", visible: true },
            fecha_inicio: { label: "Fecha inicio", type: "datepicker", visible: true },
            fecha_termino: { label: "Fecha termino", type: "datepicker", visible: true },
            activo: { label: "Activo", type: "checkbox", visible: true }
        }
    }
}
