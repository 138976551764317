import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Establecimientos } from "@puntaje/puntaje/api-services"
import { AllReadyService } from "@puntaje/puntaje/new-modules/shared"

@Component({
    selector: "top-docentes-anual",
    templateUrl: "./top-docentes-anual.component.html",
    styleUrls: ["./top-docentes-anual.component.scss"]
})
export class TopDocentesAnualComponent implements OnInit, OnChanges {
    stats: any
    pais: string = config.plataforma.pais
    idPais: string = config.plataforma.identificadorUsuario[0].toLocaleLowerCase()
    @Input() year: number = 2018
    @Input() establecimientoId: number

    constructor(protected establecimientosService: Establecimientos, protected allreadyservice: AllReadyService) {}

    ngOnInit() {
        this.setData()
    }

    setData() {
        const params = { pais: this.pais, year: this.year }
        this.establecimientosService.top_docentes(this.establecimientoId, params).then(data => {
            this.stats = data
            this.allreadyservice.updateCounterWithKey("informe-anual")
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            (changes["establecimientoId"] &&
                !changes["establecimientoId"].firstChange &&
                changes["establecimientoId"].currentValue != changes["establecimientoId"].previousValue) ||
            (changes["year"] &&
                !changes["year"].firstChange &&
                changes["year"].currentValue != changes["year"].previousValue)
        ) {
            this.setData()
        }
    }
}
