<loading-layout #loadingLayout>
    <div *ngIf="!loadingLayout.loading">
        <div class="push-bot">
            <ng-container *ngFor="let key of params | keys">
                <model-simple-view
                    *ngIf="!params[key].showHidden"
                    [params]="params[key]"
                    [value]="escalaGlobal"
                    [key]="key"
                ></model-simple-view>
            </ng-container>
        </div>
        <br />
        <div *ngIf="escalaGlobal.escala_global_aliases">
            <cui-section-headline>Aliases</cui-section-headline>
            <table class="table">
                <thead>
                    <tr>
                        <td>ID</td>
                        <td>Equivalencia Mínima</td>
                        <td>Equivalencia Máxima</td>
                        <td>Alias</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let alias of escalaGlobal.escala_global_aliases">
                        <td>{{ alias.id }}</td>
                        <td>{{ alias.equivalencia_min }}</td>
                        <td>{{ alias.equivalencia_max }}</td>
                        <td *ngIf="escalaGlobal.tipo === 'Imagen'">
                            <div class="contentImg">
                                <img class="img" *ngIf="alias.alias" [src]="alias.alias" />
                            </div>
                        </td>
                        <td *ngIf="escalaGlobal.tipo !== 'Imagen'">
                            {{ alias.alias }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</loading-layout>
