<div class="print-show-block print-info print-page-break-after">
    <img class="portada-logo" src="{{ logosrc }}" alt="logo portada" />
    <h2 *ngIf="establecimiento">{{ establecimiento.establecimiento }}</h2>
    <p class="subtitle">Informe de evaluación</p>
    <p class="dotted-line clearfix" *ngIf="grupoUsuario">
        <label>{{ nombreGrupo | capitalize }}:</label>
        <span class="value">{{ grupoUsuario.nombre }}</span>
    </p>
    <p class="dotted-line clearfix" *ngIf="evaluacion">
        <label>{{ evaluacionTipo | transformInstrumento: "singular" | capitalize }}:</label>
        <span class="value">#{{ evaluacion.id }} - {{ evaluacion.evaluacion }}</span>
    </p>
    <p class="dotted-line clearfix" *ngIf="asignatura">
        <label>Asignatura:</label>
        <span class="value">{{ asignatura.asignatura }}</span>
    </p>
    <div class="row printInfo-tables">
        <div class="col-md-4 col-sm-6 left-col" *ngIf="grupoUsuario && evaluacionInstancias">
            <table class="table minitable-printInfo">
                <tbody>
                    <tr>
                        <td rowspan="2" class="category">
                            <fa name="user" class="icono"></fa>
                            <br />
                            Estudiantes
                        </td>
                        <th>Total</th>
                        <th>Se han evaluado</th>
                    </tr>
                    <tr>
                        <td>{{ grupoUsuario.usuarios.length }}</td>
                        <td>{{ evaluacionInstancias.length }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-8 col-sm-6 right-col" *ngIf="grupoUsuario && evaluacionInstancias">
            <table class="table minitable-printInfo">
                <tbody>
                    <tr>
                        <td rowspan="2" class="category">
                            <ogr-icon name="prueba" class="icono"></ogr-icon>
                            <br />
                            Resultados
                        </td>
                        <th>Promedio</th>
                        <th>Mínimo</th>
                        <th>Máximo</th>
                    </tr>
                    <tr>
                        <td>{{ estadisticas[0]?.promedio | roundfloat }}</td>
                        <td>{{ estadisticas[0]?.minimo }}</td>
                        <td>{{ estadisticas[0]?.maximo }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
