import { Subscription } from "rxjs"
import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"

import { Instrumento, Instrumentos, Evaluacion } from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"

declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "instrumento.component.html",
    styleUrls: ["instrumento.component.scss"]
})
export class InstrumentoComponent implements OnInit {
    instrumento: Instrumento
    evaluacionId: number
    subParams: Subscription
    subQuery: Subscription
    subData: Subscription

    enableAdminOptions = config.app.name == "admin"
    showButtonGuardar = false

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected instrumentosService: Instrumentos
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.instrumentos.instrumento")
        this.subParams = this.route.params.subscribe(params => {
            this.instrumentosService
                .find(+params["id"], { include: "[instrumento_material]", with_generador_instrumento: 1 })
                .then((instrumento: Instrumento) => {
                    this.instrumento = instrumento
                })
        })

        this.subQuery = this.route.queryParams.subscribe(params => {
            this.evaluacionId = +params["evaluacion_id"]
        })

        this.subData = this.route.data.subscribe(data => {
            this.enableAdminOptions = data.enableAdminOptions ?? this.enableAdminOptions
            this.showButtonGuardar = data.showButtonGuardar ?? this.showButtonGuardar
        })
    }

    ngOnDestroy() {
        this.subParams.unsubscribe()
        this.subQuery.unsubscribe()
        this.subData.unsubscribe()
    }
}
