import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core"
import { PlanPersonal } from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "barra-avance-plan-clase",
    templateUrl: "./barra-avance-plan-clase.component.html",
    styleUrls: ["./barra-avance-plan-clase.component.scss"]
})
export class BarraAvancePlanClaseComponent implements OnInit {
    @Input() planPersonal: PlanPersonal

    hideBarraAvanceEstimado: boolean = config?.plataforma?.hideBarraAvanceEstimado || true
    startAnimValues: boolean = false
    enableSmallSize: boolean = false
    avance: number = 0
    avanceEsperado: number = 0
    avancePorcentual: number = 0
    avanceEsperadoPorcentual: number = 0
    countInWeeks: boolean = true
    currentDate: Date
    startDate: Date
    endDate: Date
    weeksTotal: number = 36
    nombreSesiones: string = config.plataforma.nombreSesiones || "semanas"

    totalProgress: number

    constructor(protected cdr: ChangeDetectorRef) {
        this.countInWeeks = config.plataforma.planClasesCountInWeeks
    }

    ngOnInit() {
        this.setAvanceBar()
    }

    public setAvanceBar() {
        this.avance = 0
        let count = 0
        let total = 0
        if (this.planPersonal) {
            this.planPersonal.plan_personal_ciclos.forEach(ppc => {
                ppc.plan_personal_sesiones.forEach(pps => {
                    total++

                    const planPersonalSesionGrupoUsuario = pps.plan_personal_sesion_grupo_usuarios[0]
                    if (planPersonalSesionGrupoUsuario && planPersonalSesionGrupoUsuario.visto) {
                        count++
                    }
                })
            })

            this.avancePorcentual = Math.round(100 * (count / total))
            this.avance = count
            this.totalProgress = total
        }

        if (!this.planPersonal.fecha_inicial) return

        this.currentDate = new Date()
        this.startDate = new Date(this.planPersonal.fecha_inicial)
        this.endDate = new Date(this.startDate.getTime() + 7 * 24 * 60 * 60 * 1000 * total)
        const currentWeeks = this.weeksBetween(this.startDate, this.currentDate)
        this.avanceEsperado = Math.max(currentWeeks, 0)
        this.avanceEsperadoPorcentual = Math.round(100 * (currentWeeks / total))
        this.avanceEsperadoPorcentual = Math.min(this.avanceEsperado, 100)

        if (this.countInWeeks) {
            this.totalProgress = this.weeksTotal
        } else {
            this.avanceEsperado = Math.round((this.totalProgress * this.avanceEsperadoPorcentual) / 100)
        }

        setTimeout(() => {
            this.startAnimValues = true
        }, 1000)
    }

    weeksBetween(d1, d2) {
        return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000))
    }
}
