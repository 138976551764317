import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription, BehaviorSubject, combineLatest } from "rxjs"
import { IMyDpOptions, IMyDayLabels, IMyDateModel } from "mydatepicker"

import { Establecimiento, Establecimientos, Lugar, Lugares } from "@puntaje/puntaje/api-services"
import { Asignatura, Asignaturas, GeneradorInstrumento, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasByEvaluacionTipo } from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"

@Component({
    selector: "establecimiento-resumen-utp",
    templateUrl: "establecimiento_resumen_utp.component.html",
    styleUrls: ["establecimiento_resumen_utp.component.scss"]
})
export class EstablecimientoResumenUtpComponent implements OnInit {
    @Input() establecimiento: Establecimiento
    @Input() stats: any
    lugares: Lugar[]
    config: typeof config = config
    showInfo: boolean = false
    enableMediumSize: boolean = false
    enableSmallSize: boolean = false

    constructor(
        protected route: ActivatedRoute,
        protected authService: AuthService,
        protected store: Store<State>,
        protected lugaresService: Lugares,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.showResumen()
    }

    showResumen() {
        if (this.establecimiento) {
            let lugaresPromise = this.lugaresService
                .find(this.establecimiento.lugar_id, { recursive: 1, with_lugar_tipo: 1 })
                .then((lugar: Lugar) => {
                    this.lugares = [lugar]
                    let l = lugar
                    while (l.lugar) {
                        this.lugares.push(l)
                        l = l.lugar
                    }
                })

            Promise.all([lugaresPromise]).then(res => {
                this.showInfo = true
            })
        }
    }

    adaptToSize(cs: number) {
        this.enableMediumSize = cs > 0 && cs <= 850
        this.enableSmallSize = cs > 0 && cs <= 480
        this.cdr.detectChanges()
    }
}
