import { BaseForm } from "@puntaje/shared/core"
import { Categorias } from "./categorias.service"
import { Plataformas } from "@puntaje/nebulosa/api-services"

export class LogroForm extends BaseForm {
    public static get formParams() {
        return {
            id: { label: "ID", type: "hidden", visible: false },
            logro: { label: "Logro", type: "text", visible: true },
            logo: { label: "Logo", type: "text", visible: true },
            codigo: { label: "Codigo", type: "text", visible: true },
            descripcion: { label: "Descripcion", type: "text", visible: true },
            experiencia: { label: "Puntuación", type: "number", visible: true },
            monedas: { label: "Monedas", type: "number", visible: true },
            categoria_id: { label: "Categorias", type: "select", visible: true, options: { class: Categorias } },
            plataforma_id: { label: "Plataformas", type: "select", visible: true, options: { class: Plataformas } }
        }
    }
}
