import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from "@angular/core"
import { NgModel } from "@angular/forms"
import {
    Usuario,
    GrupoUsuario,
    Usuarios,
    GrupoUsuarios,
    GrupoUsuarioUsuarios,
    GrupoUsuarioUsuario
} from "@puntaje/puntaje/api-services"
import { Router, ActivatedRoute } from "@angular/router"
import { SimpleModalService } from "@puntaje/shared/layouts"
import { Clasificaciones } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "asociar-usuario",
    templateUrl: "./asociar-usuario.component.html",
    styleUrls: ["./asociar-usuario.component.scss"]
})
export class AsociarUsuarioComponent implements OnInit {
    pais: string = config.plataforma.pais
    idPais: any = config.plataforma.identificadorUsuario
    aliases = config.plataforma?.identificadorUsuarioAlias
    nombreId: string = this.idPais.map(id => this.aliases?.[id] || id).join(" o ")
    grupoUsuarioAlias: string = config.plataforma.grupoUsuarioAlias

    idValue: string
    nombre: string
    apellidoPaterno: string
    apellidoMaterno: string
    email: string
    invalidEmail: boolean = false
    emailDuplicado: boolean = false
    errorMsg: string

    usuario: Usuario
    usuarioLoaded: boolean = false
    usuarioInGrupo: boolean = false
    usuarioInAllGrupos: boolean = false
    tipoUsuario: string
    _grupoUsuarios: GrupoUsuario[]
    grupoUsuariosAsociados: GrupoUsuario[]
    grupoUsuariosNoAsociados: GrupoUsuario[]
    selectedGrupoUsuarios: { [key: number]: boolean } = {}
    addUsuarioOpen: boolean = false

    @Input() toggleId: string = ""
    @Input() propietario: boolean = false
    @Input() grupoUsuario: GrupoUsuario
    @Input()
    set grupoUsuarios(gus: GrupoUsuario[]) {
        this._grupoUsuarios = gus
        gus.forEach(gu => (this.selectedGrupoUsuarios[gu.id] = false))
    }
    get grupoUsuarios() {
        return this._grupoUsuarios
    }

    msgPais = {
        chile: `El ${this.nombreId} ingresado no es válido.`,
        colombia: "El número de identificación no es válido.",
        mexico: "El número de identificación no es válido.",
        brasil: "El número de identificación no es válido."
    }

    searchById: boolean = false

    @Output() usuarioAsociadoEvent = new EventEmitter()

    @ViewChild("idValueUsuario") idValueUsuario: NgModel

    constructor(
        protected usuariosService: Usuarios,
        protected grupoUsuariosService: GrupoUsuarios,
        protected grupoUsuarioUsuariosService: GrupoUsuarioUsuarios,
        protected router: Router,
        protected route: ActivatedRoute,
        protected simpleModalService: SimpleModalService,
        protected clasificacionesService: Clasificaciones,
    ) { }

    ngOnInit() {
        this.tipoUsuario = this.propietario ? "docente" : "alumno"
    }

    validateIdValueFun(idValue) {
        if (!idValue) idValue = ""

        return {
            valid: this.validateIdPais(idValue) || this.validateId(idValue),
            msg: this.msgPais[this.pais] || "País de la plataforma no disponible"
        }
    }

    validateIdValue() {
        return this.validateIdValueFun(this.idValue)
    }

    validateIdPais(idValue) {
        switch (this.pais) {
            case "chile":
                return this.validaRut(idValue)
            case "colombia":
                return idValue.length > 8 && idValue.length <= 11 && /^[1-9][0-9]+$/.test(idValue)
            case "mexico":
                return idValue.length > 4
            case "brasil":
                return idValue.length > 4
            default:
                return false
        }
    }

    validateId(idValue) {
        return /^\d+$/.test(idValue) && this.msgPais[this.pais]
    }

    getUsuarioFun(idValue, propietario = null) {
        let paramsUsuario = {}

        if (this.grupoUsuario) {
            paramsUsuario["grupo_usuario"] = { id: this.grupoUsuario.id }
        } else if (this.grupoUsuarios) {
            paramsUsuario["grupo_usuario"] = { id: this.grupoUsuarios.map(gu => gu.id) }
        } else {
            return null
        }

        if (propietario != null) {
            paramsUsuario["grupo_usuario_usuario"] = { propietario: propietario }
        }

        paramsUsuario["usuario_" + this.pais] = {}
        paramsUsuario["usuario_" + this.pais][this.idPais.join("_o_").toLowerCase()] = idValue

        return this.usuariosService.where(paramsUsuario).then((usuarios: Usuario[]) => {
            delete paramsUsuario["grupo_usuario"]
            delete paramsUsuario["grupo_usuario_usuario"]
            if (usuarios[0]) {
                this.usuarioInGrupo = true
                if (this.grupoUsuarios) {
                    this.usuarioInAllGrupos = usuarios[0].grupo_usuarios.length >= this.grupoUsuarios.length
                }

                // dirty stuff: si no viene propietario, es porque lo estoy usando en la edicion de usuario,
                // y necesito el usuario que va a buscar
                if (
                    propietario == null ||
                    propietario == undefined ||
                    (this.grupoUsuarios && !this.usuarioInAllGrupos)
                ) {
                    this.usuarioLoaded = true
                    this.usuario = usuarios[0]

                    if (this.grupoUsuarios) this.setGrupoUsuariosAsociados()
                }
            } else {
                this.usuarioInGrupo = false
                this.usuarioInAllGrupos = false

                this.usuariosService.where(paramsUsuario).then((usuarios: Usuario[]) => {
                    this.usuarioLoaded = true
                    this.usuario = usuarios[0]

                    if (this.grupoUsuarios) this.setGrupoUsuariosAsociados()
                })
            }
        })
    }

    getUsuario() {
        let propietario = this.propietario ? 1 : 0
        this.usuarioLoaded = false

        this.nombre = ""
        this.apellidoPaterno = ""
        this.apellidoMaterno = ""
        this.email = ""

        this.searchById = !this.validateIdPais(this.idValue)

        this.getUsuarioFun(this.idValue, propietario)
    }

    setGrupoUsuariosAsociados() {
        this.grupoUsuariosAsociados = []
        this.grupoUsuariosNoAsociados = []

        if (this.usuario && this.usuario.grupo_usuarios.length > 0) {
            this.grupoUsuarios.forEach(gu => {
                if (this.usuario.grupo_usuarios.filter(ugu => ugu.id == gu.id).length > 0) {
                    this.grupoUsuariosAsociados.push(gu)
                } else {
                    this.grupoUsuariosNoAsociados.push(gu)
                }
            })
        } else {
            this.grupoUsuariosNoAsociados = this.grupoUsuarios
        }
    }

    createUsuario() {
        if (!this.invalidEmail || !this.emailDuplicado || !this.emailDuplicado && !this.invalidEmail) {
            let usuario = new Usuario()
            delete usuario.usuario_chile
            delete usuario.usuario_colombia

            usuario.nombre = this.nombre
            usuario.apellido_paterno = this.apellidoPaterno
            usuario.apellido_materno = this.apellidoMaterno
            usuario.email = this.email
            usuario.password = this.idValue.substring(0, 6)
            usuario.password_confirmation = this.idValue.substring(0, 6)
            usuario.nomina = true
            usuario.confirmed_at = new Date()
            usuario["usuario_" + this.pais] = new Usuario["usuario_" + this.pais]()
            usuario["usuario_" + this.pais][this.idPais[0].toLowerCase()] = this.idValue.toLowerCase()
            usuario.manual_reset_password = true

            if (!this.grupoUsuario && !this.grupoUsuarios) {
                return
            }

            usuario.grupo_usuario_usuarios = this.createGrupoUsuarioUsuarios()

            this.usuariosService.enableIgnoreModel()
            this.usuariosService.save(usuario).then((usuario: Usuario) => {
                this.usuariosService.disableIgnoreModel()
                this.finishProcess()
            })
        }
    }

    createGrupoUsuarioUsuario(grupoUsuarioId, propietario) {
        let grupoUsuarioUsuario = new GrupoUsuarioUsuario()
        grupoUsuarioUsuario.propietario = propietario
        grupoUsuarioUsuario.grupo_usuario_id = grupoUsuarioId

        return grupoUsuarioUsuario
    }

    createGrupoUsuarioUsuarios() {
        let grupoUsuarioUsuarios = []
        let propietario = this.propietario ? 1 : 0
        if (this.grupoUsuario) {
            let grupoUsuarioUsuario = this.createGrupoUsuarioUsuario(this.grupoUsuario.id, propietario)

            grupoUsuarioUsuarios = [grupoUsuarioUsuario]
        } else if (this.grupoUsuarios) {
            let grupoUsuarios = this.grupoUsuariosNoAsociados.filter(gu => this.selectedGrupoUsuarios[gu.id])
            grupoUsuarioUsuarios = grupoUsuarios.map(gu => this.createGrupoUsuarioUsuario(gu.id, propietario))
        }

        return grupoUsuarioUsuarios
    }

    addUsuario(usuario) {
        delete usuario.usuario_chile
        delete usuario.usuario_colombia

        if (!this.grupoUsuario && !this.grupoUsuarios) {
            return
        }

        usuario.grupo_usuario_usuarios = this.createGrupoUsuarioUsuarios()

        this.usuariosService.enableIgnoreModel()
        this.usuariosService.update(usuario.id, usuario).then((usuario: Usuario) => {
            this.usuariosService.disableIgnoreModel()
            this.finishProcess()
        })
    }

    finishProcess() {
        this.idValue = ""
        this.usuarioLoaded = false
        this.usuarioInGrupo = false
        this.usuarioInAllGrupos = false
        this.resetSelectedGrupoUsuarios()
        this.addUsuarioOpen = false
        if (this.idValueUsuario) {
            this.idValueUsuario.control.markAsPristine()
        }
        this.showModal()

        this.usuarioAsociadoEvent.emit({ addUsuarioOpen: this.addUsuarioOpen })
    }

    showModal() {
        this.simpleModalService.cleanModal()
        this.simpleModalService.setModalHeader("Aviso")
        this.simpleModalService.setModalStringContent("Usuario asociado con éxito.")
        this.simpleModalService.showSimpleModal()
    }

    validaRut(rutCompleto) {
        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false
        var tmp = rutCompleto.split("-")
        var digv = tmp[1]
        var rut = tmp[0]
        if (digv == "K") digv = "k"
        return this.dv(rut) == digv
    }

    validateEmail() {
        const EMAIL_REGEXP =
            /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|school|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i

        return EMAIL_REGEXP.test(this.email)
            ? this.invalidEmail = false
            : this.invalidEmail = true
    }

    loseFocusEmail() {
        this.validateEmail()
        this.duplicatedEmail()
    }

    duplicatedEmail() {
        this.usuariosService.exists({ usuario: { email: this.email } }).then((response: any) => {
            return !response.empty
                ? this.emailDuplicado = true
                : this.emailDuplicado = false
        })
    }

    dv(T) {
        var M = 0,
            S = 1
        for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11
        return S ? S - 1 : "k"
    }

    resetSelectedGrupoUsuarios() {
        Object.keys(this.selectedGrupoUsuarios).map(id => (this.selectedGrupoUsuarios[id] = false))
    }
}
