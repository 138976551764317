import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { ContestableTipo, ContestableTipos, GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import { AppConfig, I18nService } from "@puntaje/shared/core"
import { isThisSecond } from "date-fns"
declare const config: AppConfig

interface Pauta {
    contestableTipo: ContestableTipo
    cantidadAlternativas?: number
    letra?: string
    puntuacion: number
    piloto: boolean
}

@Component({
    selector: "subir-pauta-mixta",
    templateUrl: "./subir-pauta-mixta.component.html",
    styleUrls: ["../subir-prueba-propia.component.scss", "./subir-pauta-mixta.component.scss"]
})
export class SubirPautaMixtaComponent implements OnInit {
    @Input() step: number
    @Output() changeData = new EventEmitter<any>()
    @Output() changeNumeroPreguntas = new EventEmitter<any>()
    @Input() hideHeading = false
    @Input() hideContinue = false
    @Input() disableChangeContestableTipo = false
    @Input() disableChangeNumeroPreguntas = false
    @Input() disableChangeNumeroAlternativas = false
    @Input() disableChangePuntuacion = false
    @Input() contestableTipo: ContestableTipo
    @Input() generadorInstrumento: GeneradorInstrumento

    validationNumeroPreguntas: boolean = false
    mensajePreguntas: string = "Error"
    mensajeAlternativas: string

    contestableTipos: ContestableTipo[]

    blankPauta: boolean = false
    numeroAlternativasWrong: boolean = false
    alternativaNotPermitted: boolean = false
    alternativaTooLarge: boolean = false
    validationNumeroAlternativas = false

    _pauta: Pauta[] = []
    _pautaOriginal: Pauta[]
    _numeroPreguntas: number

    constructor(protected contestableTiposService: ContestableTipos, protected i18nService: I18nService) {}

    @Input()
    set pauta(pauta: Pauta[]) {
        this._pautaOriginal = pauta
        this._pauta = pauta?.map(p => ({ ...p })) ?? []

        if (this._pauta.length > 0) {
            this.numeroPreguntas = this._pauta.length
        }
    }

    set numeroPreguntas(numeroPreguntas: number) {
        this._numeroPreguntas = numeroPreguntas
    }

    ngOnInit() {
        const cts = ["Pregunta de alternativas", "Respuesta libre", "Respuesta archivo"]
        this.contestableTiposService.where({ contestable_tipo: { contestable_tipo: cts } }).then(contestableTipos => {
            this.contestableTipos = contestableTipos
        })
    }

    onNumeroPreguntasChange(numeroPreguntas) {
        this.validateNumeroPreguntas(numeroPreguntas)

        if (this.validationNumeroPreguntas) {
            return
        }

        this._numeroPreguntas = numeroPreguntas
        this.changeNumeroPreguntas.emit(this._numeroPreguntas)

        const oldNumeroPreguntas = this._pauta.length
        const numeroPreguntasPautaOriginal = this._pautaOriginal?.length

        const contestableTipo =
            this.contestableTipos.find(ct => ct.id == this.contestableTipo?.id) ?? this.contestableTipos[0]

        if (numeroPreguntas > oldNumeroPreguntas) {
            let toAdd = numeroPreguntas - oldNumeroPreguntas

            if (numeroPreguntasPautaOriginal && numeroPreguntasPautaOriginal > oldNumeroPreguntas) {
                const toAddPautaOriginal = numeroPreguntasPautaOriginal - oldNumeroPreguntas

                for (let i = 0; i < toAddPautaOriginal; i++) {
                    this._pauta.push({ ...this._pautaOriginal[oldNumeroPreguntas + i] })
                }

                toAdd -= toAddPautaOriginal
            }

            for (let i = 0; i < toAdd; i++) {
                this._pauta.push({ contestableTipo, piloto: false, puntuacion: 1 })
            }
        } else if (oldNumeroPreguntas > numeroPreguntas) {
            this._pauta.splice(numeroPreguntas)
        }
    }

    onClickPiloto(pautaPregunta) {
        pautaPregunta.piloto = !pautaPregunta.piloto
    }

    validateNumeroPreguntas(numeroPreguntas) {
        const preguntas = numeroPreguntas || this._numeroPreguntas
        const preguntasMax = 90
        if (!preguntas || preguntas <= 0) {
            this.mensajePreguntas = this.i18nService.translate("evaluar.pasos.subir_pauta_mixta.warn", {})
            this.validationNumeroPreguntas = true
        } else if (preguntas > preguntasMax) {
            this.mensajePreguntas = this.i18nService.translate("evaluar.pasos.subir_pauta_mixta.warn5", {})
            this.validationNumeroPreguntas = true
            return
        } else {
            this.validationNumeroPreguntas = false
        }
    }

    validadaNumeroAlternativas(numero = null) {
        const numeroAlternativasPauta = numero || this._pauta.map(p => p.cantidadAlternativas)

        const letras_max = this.generadorInstrumento.maximo_alternativas
        const letras_min = 2

        if (numeroAlternativasPauta.find(a => a < letras_min)) {
            this.mensajeAlternativas = this.i18nService.translate("evaluar.pasos.subir_pauta_mixta.warn2", {})
            this.validationNumeroAlternativas = true
        } else if (letras_max && numeroAlternativasPauta.find(a => a > letras_max)) {
            this.mensajeAlternativas = this.i18nService.translate("alternativas.max", { count: letras_max })
            this.validationNumeroAlternativas = true
        } else {
            this.validationNumeroAlternativas = false
        }
    }

    validatePauta() {
        this.validadaNumeroAlternativas()

        this.blankPauta = this._pauta.reduce((acc: boolean, p) => {
            if (p.contestableTipo.contestable_tipo == "Pregunta de alternativas") {
                return acc || !p.cantidadAlternativas || !p.letra || p.puntuacion == null || p.puntuacion == undefined
            } else {
                return acc
            }
        }, false)

        this.numeroAlternativasWrong = this._pauta.reduce((acc: boolean, p) => {
            if (p.contestableTipo.contestable_tipo == "Pregunta de alternativas") {
                return acc || p.cantidadAlternativas < 2
            } else {
                return acc
            }
        }, false)

        const permittedLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

        this.alternativaNotPermitted = this._pauta.reduce((acc: boolean, p) => {
            if (p.contestableTipo.contestable_tipo == "Pregunta de alternativas") {
                const letraIndex = permittedLetters.indexOf(p.letra)

                return acc || letraIndex >= p.cantidadAlternativas
            } else {
                return acc
            }
        }, false)

        this.alternativaTooLarge = this._pauta.reduce((acc: boolean, p) => {
            if (p.contestableTipo.contestable_tipo == "Pregunta de alternativas") {
                return acc || (p.letra && p.letra.length > 1)
            } else {
                return acc
            }
        }, false)
    }

    continue() {
        this.validateNumeroPreguntas(this._numeroPreguntas)
        this.validatePauta()

        if (
            this.blankPauta ||
            this.numeroAlternativasWrong ||
            this.alternativaNotPermitted ||
            this.validationNumeroPreguntas ||
            this.validationNumeroAlternativas
        ) {
            return
        }

        const numeroAlternativas = this._pauta.reduce((acc, p) => {
            if (p.cantidadAlternativas > acc) {
                return p.cantidadAlternativas
            }

            return acc
        }, 0)

        this.changeData.emit({
            pauta: this._pauta,
            numero_alternativas: numeroAlternativas > 0 ? numeroAlternativas : null,
            numero_preguntas: this._numeroPreguntas,
            showClasificarPreguntas: true
        })
    }

    filterLetra(event, numeroAlternativas) {
        const permittedLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".substring(0, numeroAlternativas || 4)

        if (event.key == "Backspace" || event.key == "Tab") {
            return true
        }

        if (permittedLetters.indexOf(event.key.toUpperCase()) < 0) {
            event.preventDefault()

            return false
        }

        return true
    }

    compareFn(c1: ContestableTipo, c2: ContestableTipo) {
        return c1 && c2 ? c1.id === c2.id : c1 === c2
    }
}
