<loading-layout #loadingLayout>
    <div class="row" *ngIf="!loadingLayout.loading">
        <div class="col-md-12 col-sm-12">
            <form novalidate [formGroup]="form">
                <form-input
                    *ngFor="let key of params | keys"
                    [form]="form"
                    [params]="params[key]"
                    [(value)]="noticia[key]"
                    [key]="key"
                    [withLabel]="params[key].withLabel != undefined ? params[key].withLabel : true"
                >
                    <label *ngIf="key == 'noticias_perfil_id'">
                        {{ "noticias.profe.noticia_perfil" | t }}
                        <fa
                            [name]="'question-circle-o'"
                            [outsideClick]="true"
                            popoverTitle="Ayuda"
                            [popover]="popTemplate"
                        ></fa>
                    </label>
                </form-input>

                <ng-container *ngIf="!hideInputLugar">
                    <label>{{ "noticias.profe.lugar_aplicar" | t }}</label>
                    <autocomplete
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="lugarColegio"
                        [options]="filteredLugarColegio"
                        class="autocomplete"
                        (filterChange)="getLugarColegio($event)"
                        (controlValue)="updateLugarColegio($event)"
                    ></autocomplete>
                    <br />
                </ng-container>

                <ng-template #popTemplate>
                    <ul>
                        <li>
                            <b>{{ "noticias.profe.alumnos" | t }}:</b>
                            {{ "noticias.profe.alumnos_desc" | t }}.
                        </li>
                        <li>
                            <b>{{ "noticias.profe.alumnos_libres" | t }}:</b>
                            {{ "noticias.profe.alumnos_libres_desc" | t }}
                            activos.
                        </li>
                        <li>
                            <b>{{ "noticias.profe.alumnos_nomina" | t }}:</b>
                            {{ "noticias.profe.alumnos_nomina_desc" | t }}.
                        </li>
                    </ul>
                </ng-template>

                <ng-container *ngIf="!fromProfesor">
                    <label>{{ "noticias.profe.establecimient_aplicar" | t }}</label>
                    <autocomplete
                        [ngModel]="establecimiento"
                        [ngModelOptions]="{ standalone: true }"
                        [autoSelect]="false"
                        [options]="establecimientos"
                        (filterChange)="getEstablecimientos($event)"
                        (controlValue)="onSelectEstablecimiento($event)"
                        showTextFun="toStringWithIdPais"
                    ></autocomplete>
                </ng-container>

                <ng-container *ngIf="fromProfesor">
                    <div>
                        <label>{{ "noticias.profe.cursos_compartir" | t }}:</label>
                    </div>
                    <ng-template [ngIf]="grupoUsuarios">
                        <checkboxes
                            #grupos="ngModel"
                            [options]="grupoUsuarios"
                            [(ngModel)]="gruposSeleccionados"
                            [groupBy]="groupBy"
                            name="grupos"
                            [ngModelOptions]="{ standalone: true }"
                            (changeSelected)="onChangeCheckbox($event)"
                        ></checkboxes>

                        <div
                            *ngIf="showWarning && gruposSeleccionados.length <= 0"
                            class="error-warning"
                            style="color: red"
                        >
                            <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                            {{ "noticias.profe.cursos_warn" | t }}
                        </div>
                    </ng-template>
                </ng-container>
                <br />

                <ng-container *ngIf="!fromProfesor; else oneButton">
                    <button (click)="save()" type="button" class="btn btn-default">
                        {{ save_button_text | easyplaceholder: "Guardar" }}
                    </button>
                    <button (click)="clear()" type="button" class="btn btn-default">
                        {{ clear_button_text | easyplaceholder: "Borrar" }}
                    </button>
                </ng-container>

                <ng-template #oneButton>
                    <cui-button
                        (click)="save()"
                        type="button"
                        class="pull-right btn_style"
                        [disabled]="showWarning && gruposSeleccionados.length <= 0"
                    >
                        {{ "noticias.profe.publicar_boton" | t }}
                    </cui-button>
                </ng-template>
            </form>
        </div>
    </div>
</loading-layout>
