import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core"
import { Clasificacion } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "clasificador-recursos",
    templateUrl: "./clasificador-recursos.component.html",
    styleUrls: ["./clasificador-recursos.component.scss"]
})
export class ClasificadorRecursosComponent implements OnInit {
    @Input() numeroItems: number
    @Input() curriculum: string = "LGE"
    @Input() asignaturaId: number
    @Input() tipoRecurso: string = "pregunta"
    @Input() items: any[]

    @Output() changeData: EventEmitter<any> = new EventEmitter()

    clasificacionesByItemIndex: Clasificacion[][]
    numeroItemsOriginal: number
    currentItemIndex: number = 0
    itemsIndexes: number[]
    showClasificador: boolean = false

    constructor() {}

    ngOnInit(): void {
        if (this.items) {
            this.clasificacionesByItemIndex = this.items.map(i => i.clasificaciones)
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes["numeroItems"]) {
            this.reset()
        }

        if (
            changes &&
            changes["items"] &&
            changes["items"].currentValue !== null &&
            changes["items"].currentValue != undefined
        ) {
            this.reset()
        }
    }

    reset() {
        this.currentItemIndex = 0
        if (this.numeroItems) {
            this.numeroItems = +this.numeroItems
            this.setInitVariables()
        } else if (this.items) {
            this.numeroItemsOriginal = undefined
            this.numeroItems = this.items.length
        } else {
            this.itemsIndexes = undefined
            this.clasificacionesByItemIndex = undefined
        }
    }

    setInitVariables() {
        if (!this.numeroItemsOriginal) {
            this.numeroItemsOriginal = this.numeroItems
            this.clasificacionesByItemIndex = Array(this.numeroItems).fill([])

            if (this.items) {
                this.clasificacionesByItemIndex = this.items.map(item => item.clasificaciones)
            }
        } else if (this.numeroItems > this.numeroItemsOriginal) {
            const toAddOriginal = this.numeroItems - this.numeroItemsOriginal

            for (let i = 0; i < toAddOriginal; i++) {
                this.clasificacionesByItemIndex.push([])
            }
        }

        this.itemsIndexes = Array.from(Array(this.numeroItems).keys())
    }

    changeItem(index) {
        this.currentItemIndex = index
    }

    prevItem() {
        if (this.currentItemIndex > 0) {
            this.currentItemIndex -= 1
        }
    }

    nextItem() {
        if (this.currentItemIndex < this.numeroItems - 1) {
            this.currentItemIndex += 1
        }
    }

    updateClasificaciones(clasificaciones) {
        this.clasificacionesByItemIndex[this.currentItemIndex] = clasificaciones
    }

    sendData() {
        const data = {
            clasificacionesByItemIndex: this.clasificacionesByItemIndex.slice(0, this.numeroItems)
        }
        this.changeData.emit(data)
    }
}
